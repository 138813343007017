import { CheckoutAEService } from "../../../service";

const postUserDetails = (token, isTradeIn, params) => () => {
    return new Promise((resolve, reject) => {
        CheckoutAEService.postOmsUserDetails(token, isTradeIn, params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const fetchUserDetails = (token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutAEService.fetchOmsUserDetails(token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export {
    fetchUserDetails,
    postUserDetails
};
