/* eslint-disable max-params */
export default (api) => {

    const getFaq = ({ type = "BUY", isHomepage = false, city }) => {
        return api.get(`api/v2/masterdata/faq?type=${type}${isHomepage ? "&homePage=true" : ""}${city ? `&city=${city}` : ""}`);
    };

    const fetchBrands = () => {
        return api.get(`api/v2/masterdata/make?size=1000`, { parse: true });
    };

    const fetchYearList = (modelId) => {
        return api.get(`api/v2/masterdata/model/list/${modelId}?active=true`);
    };

    const fetchNationality = () => {
        return api.get("api/v1/masterdata/country?size=1000&page=0");
    };

    const fetchRegions = () => {
        return api.get("api/v1/masterdata/city");
    };

    return {
        getFaq,
        fetchBrands,
        fetchYearList,
        fetchNationality,
        fetchRegions
    };
};
