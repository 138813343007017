import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShareAppLink from "./component";
import { sendAppLinkToUser } from "../get-cars24-app-v2/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    sendAppLinkToUserConnect: sendAppLinkToUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShareAppLink);
