export default (api) => {

    const getIpInfo = (mockIp) => {
        const endpoint = `https://ipinfo.io${mockIp ? `/${mockIp}` : ""}`;
        return api.get(endpoint, {
            params: {
                token: process.env.IP_INFO_API_KEY
            }
        });
    };

    return {
        getIpInfo
    };
};
