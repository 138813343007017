import { INSPECTION_TYPE } from "../../../constants/seller-app-constants";
import formatTimeFromMs from "../../../utils/helpers/format-time-from-ms";
import { formattedTimeSlots, getDateAndDayMonthYearAbbr } from "../../../utils/helpers/get-day-from-date";

const formatDate = (stringDate, abbr) => {
    let formattedDateTime;
    try {
        formattedDateTime = formatTimeFromMs(new Date(stringDate), abbr, "Asia/Dubai");
    } catch (error) {
        formattedDateTime = formatTimeFromMs(new Date(), abbr, "Asia/Dubai");
    }
    return formattedDateTime;
};
const convertMillisecondsToHoursObj = (obj = {}) => {
    const { hour: fromHour, minute: fromMinute } = formatDate(obj.from);
    const { hour: toHour, minute: toMinute } = formatDate(obj.to);
    obj.startTime = fromHour;
    obj.endTime = toHour;
    obj.displaySlots = `${formatDate(obj.from, true)?.hour} - ${formatDate(obj.to, true)?.hour}`;
    obj.startMinute = fromMinute;
    obj.endMinute = toMinute;
    obj.displaySlots = formattedTimeSlots(obj);
    return obj;
};
const formatArrayObjectsToFitExistingTimeSlots = (dateArr) => {
    return dateArr?.length ? dateArr.map(v => {
        return convertMillisecondsToHoursObj(v);
    }) : [];
};

export const formatDateTimeSlotsForHomeInspection = (availableSlots, inspectionType) => {
    const dto = {};
    if (availableSlots?.length) {
        for (const i of availableSlots) {
            dto[i.slotDate] = {
                date: `${getDateAndDayMonthYearAbbr(new Date(i.slotDate))}`,
                ...inspectionType !== INSPECTION_TYPE.HOME && {
                    timeSlots: i.slots || []
                },
                ...inspectionType === INSPECTION_TYPE.HOME && {
                    popularSlots: i?.popularSlots || [], otherSlots: i?.otherSlots || []
                }
            };
        }
        for (const i in dto) {
            if (inspectionType === INSPECTION_TYPE.HOME) {
                dto[i].popularSlots = formatArrayObjectsToFitExistingTimeSlots(dto[i]?.popularSlots);
                dto[i].otherSlots = formatArrayObjectsToFitExistingTimeSlots(dto[i]?.otherSlots);
            } else {
                dto[i].timeSlots = formatArrayObjectsToFitExistingTimeSlots(dto[i].timeSlots);
            }
        }
    }
    return dto;
};
