import React, { Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Make sure to import the effect CSS

const LazyImage = (props) => (
    <Fragment>
        <LazyLoadImage {...props} effect="blur" />
        <noscript>
            <img {...props} />
        </noscript>
    </Fragment>
);

export default LazyImage;
