import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const LiWithArrow = ({
    title,
    url,
    value,
    target = "",
    onClickCallback = () => {}
}) => {
    return (
        <li styleName={"styles.arrowLink"}>
            <a
                onClick={onClickCallback}
                href={`${url}`}
                target={target}
            >
                {title || value}
            </a>
        </li>
    );
};

LiWithArrow.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
    url: PropTypes.string.isRequired,
    onClickCallback: PropTypes.func,
    target: PropTypes.string
};

export default LiWithArrow;
