import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isEmiCalculatorLoading: false,
    isEmiCalculatorFetched: false,
    emiCalculator: {}
};

// FETCH EMI CALCULATOR
const fetchEmiCalculatorInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isEmiCalculatorLoading: true,
        isEmiCalculatorFetched: false
    };
};

const fetchEmiCalculatorSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isEmiCalculatorLoading: false,
        isEmiCalculatorFetched: true,
        emiCalculator: data
    };
};

const fetchEmiCalculatorFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isEmiCalculatorLoading: false,
        isEmiCalculatorFetched: true,
        emiCalculator: {}
    };
};

export const HANDLERS = {
    [Types.FETCH_EMI_CALCULATOR_INIT]: fetchEmiCalculatorInit,
    [Types.FETCH_EMI_CALCULATOR_SUCCESS]: fetchEmiCalculatorSuccess,
    [Types.FETCH_EMI_CALCULATOR_FAILURE]: fetchEmiCalculatorFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
