/* eslint-disable complexity */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Styling
import styles from "./styles.css";

// Images
import CarBanner from "./images/banner.webp";
import ImageQr from "./images/qr.png";
import ReactangeBg from "./images/bg-rect.svg";

// Components
import Button from "../../shared/button";
import LazyImage from "../../shared/lazy-image";

import { parseSeasonalSaleData } from "../../../utils/helpers/parse-seasonal-sale";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

// Utils
import Filters from "../../../utils/filters-v2";

const SeasonalSaleHome = ({
    selectedCityCode,
    selectedCityName,
    hasCitySkip,
    activeSaleConfig = {},
    reloadCarListConnect,
    clearAllFiltersConnect
}) => {

    const history = useHistory();
    const { saleDetails = {} } = activeSaleConfig || {};
    const { homePageBannerInfo = {} } = saleDetails || {};
    const { additionalData = {} } = homePageBannerInfo || {};
    const { backgroundGradients = "", imageUrl = "" } = additionalData || {};
    const parsedSaleData = useMemo(() => parseSeasonalSaleData(homePageBannerInfo), [homePageBannerInfo]) || {};
    const { title = "", subTitle = "", description = [] } = parsedSaleData || {};
    const inlineStyles = backgroundGradients ? { backgroundImage: `linear-gradient(${backgroundGradients})` } : {};

    const getViewAllRedirectUrl = () => {
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {}, isGlobalUrl: true, hasCitySkip });
        return relativeURL;
    };

    const handleShowAllCars = () => {
        clearAllFiltersConnect();
        trackDesktopCustomEventsAE("viewAllCarsHome", {});
        reloadCarListConnect(true);
        history.push(getViewAllRedirectUrl());
    };

    return (
        <div styleName={"styles.bannerWrapper"} style={inlineStyles}>
            <div className="container">
                <div styleName={"styles.flexRow"}>
                    <div styleName={"styles.content"}>
                        <h1 dangerouslySetInnerHTML={{ __html: title || "" }}/>
                        <p dangerouslySetInnerHTML={{ __html: subTitle || "" }}/>
                        <ul>
                            {(description || []).map((item, index) => {
                                return <li key={index} dangerouslySetInnerHTML={{ __html: item || "" }} />;
                            })}
                        </ul>
                        <Button onClick={handleShowAllCars} text={"VIEW ALL CARS"} />
                    </div>
                    <div styleName={"styles.banner"}>
                        <LazyImage src={imageUrl || CarBanner} alt="Sale Banner" width={252} height={168} />
                        <LazyImage src={ImageQr} alt="Sale QR" width={236} height={102} />
                    </div>
                    <img src={ReactangeBg} alt="Background Img" styleName={"styles.rectangeBg"} height={224} />
                </div>
            </div>
        </div>
    );
};

SeasonalSaleHome.propTypes = {
    activeSaleConfig: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    hasCitySkip: PropTypes.bool
};

export default SeasonalSaleHome;
