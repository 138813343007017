import React from "react";
import NoImageFound from "./images/image-not-found.svg";

const NoCarImageFound = () => {
    return <img src={NoImageFound} className={"img-fluid w-100"} alt="No Image Found" />;
};

export const NoCarImageFoundPath = NoImageFound;

export default NoCarImageFound;
