export const STEP_NAMES = {
    TINTING: "TINTING",
    COATING: "COATING",
    SERVICE_CONTRACT: "SERVICE_CONTRACT",
    INSURANCE: "INSURANCE",
    WARRANTY: "WARRANTY",
    REFURB_AND_WARRANTY: "REFURB_AND_WARRANTY",
    WARRANTY_BASE: "WARRANTY_BASE",
    GAP_INSURANCE: "GAP_INSURANCE",
    ILOE: "ILOE",
    SUMMARY: "SUMMARY",
    DETAILS: "DETAILS",
    BUNDLES: "BUNDLES",
    ANCHOR: "ANCHOR",
    MENU: "MENU"
};

export const VAS_HEADER_MAP = {
    TINTING: "Tinting",
    COATING: "Coating",
    SERVICE_CONTRACT: "Service Contract",
    INSURANCE: "Insurance",
    WARRANTY: "Warranty",
    WARRANTY_BASE: "Warranty",
    REFURB_AND_WARRANTY: "Refurb & Warranty",
    GAP_INSURANCE: "GAP Insurance",
    ILOE: "ILOE Insurance",
    DETAILS: "VALUE ADDED SERVICES",
    BUNDLES: "BUNDLES",
    ANCHOR: "CREATE A BUNDLE",
    SUMMARY: "Order summary",
    ADDON: "ADD ONS"
};

export const VAS_FOOTER_CTA = {
    NEXT: "NEXT",
    CONTINUE: "CONTINUE",
    SKIP: "SKIP",
    CONFIRM: "CONFIRM"
};

export const WARRANTY_TYPES = {
    BASE: "BASE",
    EXTENTED: "EXTENTED"
};

export const allowedFileTypes = ".png, .jpg, .jpeg";

export const DEFAULT_VAS_HEADER = "Value Added Services";
