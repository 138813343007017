/*

filters: {
    make: {
        key: "make",
        displayText: "Make",
        filterType: "sf",
        uiType: "",
        onScreen: true,
        isActive: true,
        options: [
            {
                key: "hyundai",
                displayText: "Hyundai",
                subFilter: {
                    key: "model",
                    displayText: "Model",
                    options: [
                        {
                            key: "eon",
                            dispalyText: "Eon",
                            isSelected: false
                        }
                    ]
                }
            }
        ]
    },
    fuelType: {
        key: "fuelType",
        displayText: "Fuel Type",
        isCollapsed: false,
        uiType: "",
        options: [
            key: "",
            displayText: "",
            isSelected: false
        ]
    },
    odometerReading: {
        key: "odometerReading",
        filterType: "rf",
        min: 0,
        max: 50000,
        uiType: "",
        onScreen: false
    }
}

onScreenFilters: ["price", "year", "km", "make"]
offScreenFilter: ["fuelType", "bodyType"]

*/

import { ON_SCREENT_FILTERS, OFF_SCREEN_FILTERS, FILTER_TYPES, VARIANT_FILTERS } from "./constants";

const uiTypeMap = {
    "STATS": "RANGE",
    "TERMS": "CHECKBOX"
};

const getParsedOptions = (buckets, isRangeFilter) => {
    return buckets.map((bucket) => ({
        key: bucket.name,
        displayText: bucket?.displayName || bucket.name,
        count: bucket.count,
        isSubFacetFullySelected: bucket?.isSubFacetFullySelected || false,
        ...(
            isRangeFilter && {
                min: bucket.min,
                max: bucket.max
            }
        ),
        ...(bucket.subFacets && {
            subFacets: bucket.subFacets
        }),
        ...(bucket.subFacet && {
            subFilter: {
                key: bucket.subFacet.name,
                dispalyText: bucket.subFacet.name,
                count: bucket.subFacet.count,
                options: bucket.subFacet.buckets.map((subBucket) => ({
                    key: subBucket.name,
                    displayText: subBucket.name
                }))
            }
        }
        ),
        ...(bucket.description && {
            description: bucket.description
        }
        )
    }));
};

const getFiltersData = (filters, variant) => {
    const modifiedFilters = {};
    const onScreenFilters = ON_SCREENT_FILTERS.filter(item => !!filters[item]);
    const offScreenFilters = OFF_SCREEN_FILTERS.filter(item => !!filters[item]);
    const variantFilters = VARIANT_FILTERS.filter(item => !!filters[item]);

    Object.keys(filters).forEach((filterKey) => {
        const {
            filterType,
            type,
            min,
            max,
            mainFilter,
            buckets,
            isSingleEntity,
            displayName,
            enabledForSeo,
            priority,
            stepRate,
            stepRates = [],
            suggestions,
            groupFacet
        } = filters[filterKey];

        modifiedFilters[filterKey] = {
            key: filterKey,
            displayText: displayName,
            filterType,
            enabledForSeo,
            priority,
            mainFilter,
            stepRate,
            uiType: uiTypeMap[type],
            isSingleEntity,
            ...(
                stepRates && stepRates.length && {
                    stepRates
                }
            ),
            ...(
                suggestions && {
                    suggestions: getParsedOptions(suggestions, filterType === FILTER_TYPES.RF)
                }
            ),
            ...(
                filterType === FILTER_TYPES.RF && {
                    min,
                    max
                }
            ),
            ...(
                buckets && buckets.length && {
                    options: getParsedOptions(buckets)
                }
            ),
            ...(
                filterType === FILTER_TYPES.GF && groupFacet && {
                    groupFacet: getFiltersData(groupFacet).allFilters
                }
            )
        };
    });
    return variant ? {
        allFilters: modifiedFilters,
        onScreenFilters,
        offScreenFilters,
        variantFilters
    } : {
        allFilters: modifiedFilters,
        onScreenFilters,
        offScreenFilters
    };
};

export default getFiltersData;
