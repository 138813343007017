import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ProfileInfo from "./component";
import { updateProfile, updateDNDStatus } from "../../ae.configuration/actions";
import { getUserConsent, updateUserConsent } from "../login/actions";

const mapStateToProps = ({
  user: {
    mobile,
    email,
    isDNDActive
  }
}) => ({
  email,
  mobile,
  isDNDActive
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateProfileConnect: updateProfile,
  updateDNDStatusConnect: updateDNDStatus,
  getUserConsentConnect: getUserConsent,
  updateUserConsentConnect: updateUserConsent
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileInfo));
