import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LandingHeroBanner from "./component";
import { reloadCarList } from "../car-listing/actions";
import { clearAllFilters, fetchAutoSuggestions } from "../filters/actions";

const mapStateToProps = ({
    filters: {
        selectedFilters,
        allFilters,
        urlOptions
    },
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    },
    config: {
        activeSales: { weekendSale } = {},
        activeSaleConfig = {}
    }
}) => ({
    urlOptions,
    allFilters,
    selectedFilters,
    selectedCityCode,
    activeSaleConfig,
    weekendSale,
    selectedCityName
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAutoSuggestionsConnect: fetchAutoSuggestions,
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandingHeroBanner);
