import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_COMPARING_DATA
    FETCH_COMPARING_DATA_SUCCESS
    FETCH_COMPARING_DATA_FAILURE

    SET_COMPARING_WIDGET
    SET_COMPARING_ACTIVE

    ADD_CAR_TO_COMPARE
    REMOVE_CAR_COMPARE

    SET_COMPARE_CAR_LIMIT_ERROR
`,
    {
        prefix: "comparewidget/"
    }
);
