import React, { Fragment, useRef, useEffect, useState } from "react";
import styles from "./styles/index.css";
import PropTypes from "prop-types";
import scrollToView from "../../../utils/helpers/scroll-to-view";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { NUMBER } from "../../../constants/app-constants";

// eslint-disable-next-line complexity
const Button = (props) => {
    const {
        onClick = () => {},
        id = "",
        subText = null,
        rupee = null,
        dataTitle = "",
        text = "",
        transparent = false,
        disabled = false,
        rightImage = null,
        leftImage = null,
        customStyle = [],
        customClass = "",
        isLoading = false,
        hideLoader = false,
        scrollTop,
        ...restProps
    } = props;

    const [localCtaLoader, setLocalCtaLoader] = useState(false);

    const ref = useRef();

    useEffect(() => {
        if (scrollTop) {
            window.setTimeout(() => {
                scrollToView(ref.current);
            }, 0);
        }
    }, [scrollTop]);

    let buttonStyles = [];
    customStyle.forEach(item => {
        buttonStyles.push(`styles.${item}`);
    });
    buttonStyles = buttonStyles.join(" ");
    const transparentStyle = transparent ? "styles.transparent-btn" : "";
    const disabledStyle = disabled ? "styles.disable-btn" : "";
    //eslint-disable-next-line
    const styleName = `styles.common-btn styles.btn-styles ${disabledStyle} ${transparentStyle} ${buttonStyles}`;

    const onClickHandler = async (e) => {
        setLocalCtaLoader(true);
        await yieldToMainThread();
        onClick(e);
        await yieldToMainThread(NUMBER.FIVE_HUNDRED);
        setLocalCtaLoader(false);
    };

    const isLoadingState = isLoading || localCtaLoader;

    return (
        <Fragment>
            <button ref={ref} id={id} data-title={dataTitle}
                disabled={disabled || isLoadingState} onClick={onClickHandler}
                type="button" className={`btn ${customClass}`}
                styleName={`${styleName} ${isLoadingState ? "styles.common-btn-loading" : ""}`}
                {...restProps}
            >
                {leftImage && <img styleName={"styles.img-left"} src={leftImage} />}
                {!isLoadingState && text}
                {rightImage && <img styleName={"styles.img-right"} src={rightImage} />}
                {rupee && <span>{rupee}</span>}
                {
                    isLoadingState && !hideLoader &&
                    <i className="spinner" />
                }
                {subText && <span styleName={"styles.sub-text"}>{subText}</span>}
            </button>
        </Fragment>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string,
    rupee: PropTypes.any,
    subText: PropTypes.string,
    dataTitle: PropTypes.string,
    text: PropTypes.string,
    transparent: PropTypes.bool,
    disabled: PropTypes.bool,
    rightImage: PropTypes.any,
    leftImage: PropTypes.any,
    customStyle: PropTypes.array,
    customClass: PropTypes.string,
    isLoading: PropTypes.bool,
    scrollTop: PropTypes.bool,
    hideLoader: PropTypes.bool
};

export default Button;
