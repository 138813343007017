import { SEND_APP_LINK_EXTERNAL_API } from "../constants/api-constants";

export default (api) => {

    const sendAppLink = (params) => {
        return api.post(SEND_APP_LINK_EXTERNAL_API, { ...params }, {
            headers: {
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "CAR"
            }});
    };

    return {
        sendAppLink
    };
};
