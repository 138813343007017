import React from "react";
import styles from "./styles.css";

import Button from "../../shared/button";

//Images
import ImageSellCarBanner from "./images/sell-car-banner.png";
import ArrowPattern from "./images/arrow-pattern.png";

import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { SELLER_HOME_ROUTE } from "../../../constants/ae/sell-car-online";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

const SellCarBanner = () => {

    const { customPush } = useCustomHistoryOperations();
    const handleSellCar = async () => {
        trackDesktopCustomEventsAE("buySellMyCar", { eventLabel: "sell_banner" });
        await customPush(SELLER_HOME_ROUTE);
    };

    return (
        <div styleName={"styles.sellYourCarWrap"} onClick={handleSellCar}>
            <article>
                <h3>Sell your car to the most trusted used car company in the UAE</h3>
                <Button text="Know more" />
            </article>
            <figure>
                <img src={ImageSellCarBanner} alt="" width={141} height={169} />
            </figure>
            <img styleName={"styles.arrowPattern"} src={ArrowPattern} alt="" />
        </div>
    );
};
export default SellCarBanner;
