/* eslint-disable complexity */
import isEmpty from "./is-empty";
const extractKeys = (inputVariable) => {
    const keysObject = {};
    (inputVariable || []).forEach((variable) => {
        keysObject[variable.key] = variable.value;
    });
    return keysObject;
};

const replaceKeys = (inputString, keysObject) => {
    const regex = /<([^>]+)>/g;
    return (inputString || "").replace(regex, (match, p1) => {
        const replacement = keysObject[p1];
        if (replacement) {
            return `<span>${replacement}</span>`;
            // return replacement;
        }
        return match;
    });
};

const replaceKeysText = (inputString, keysObject) => {
    const regex = /<([^>]+)>/g;
    return (inputString || "").replace(regex, (match, p1) => {
        const replacement = keysObject[p1];
        if (replacement) {
            return replacement;
        }
        return match;
    });
};

export const parseSeasonalSaleData = (saleDetails, textOutput = false) => {

    if (isEmpty(saleDetails)) {
        return {
            title: "", subTitle: "", description: ["", ""]
        };
    }
    const { title, subTitle, description } = saleDetails || {};
    const { text: titleText, variable: titleVairable } = title || {};
    const keysInTitle = extractKeys(titleVairable);
    const replacedTitle = !textOutput ? replaceKeys(titleText?.[0] || "", keysInTitle) : replaceKeysText(titleText?.[0] || "", keysInTitle);

    const { text: subTitleText, variable: subTitleVairable } = subTitle || {};
    const keysInsubTitle = extractKeys(subTitleVairable);
    const replacedsubTitle = !textOutput ? replaceKeys(subTitleText?.[0] || "", keysInsubTitle) : replaceKeysText(subTitleText?.[0] || "", keysInsubTitle);

    const { text: descriptionText, variable: descriptionVairable } = description || {};
    const keysIndescription = extractKeys(descriptionVairable);
    const replaceddescription = !textOutput ? replaceKeys(
        descriptionText?.[0] || "",
        keysIndescription
    ) : replaceKeysText(descriptionText?.[0] || "",
        keysIndescription);

    const replaceddescription1 = !textOutput ?  replaceKeys(
        descriptionText?.[1] || "",
        keysIndescription
    ) : replaceKeysText(descriptionText?.[1] || "",
        keysIndescription);

    return {
        title: replacedTitle,
        subTitle: replacedsubTitle,
        description: [replaceddescription, replaceddescription1]
    };
};
