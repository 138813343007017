import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const SemiModal = ({
    children,
    hasOverlay = true,
    onOverlayClick
}) => {
    return (
        <React.Fragment>
            {hasOverlay && <div onClick={onOverlayClick} styleName="styles.overlay" />}
            <div styleName="styles.semiModal" className="semiModalGlobal">
                {children}
            </div>
        </React.Fragment>
    );
};
SemiModal.propTypes = {
    children: PropTypes.object,
    hasOverlay: PropTypes.bool,
    onOverlayClick: PropTypes.func
};
export default SemiModal;
