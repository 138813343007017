import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
// import CarImage from "./images/car.png";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { NoCarImageFoundPath } from "../no-car-image-found";
import { makePriceLabelRoundAE } from "../../../utils/helpers/make-price-label";

const CheckoutCar = ({carDetails}) => {
    const { content: {year, make, variant, price, mainImage, threeSixtyViewUrl}, config} = carDetails;

    const carFullName = `${year} ${make} ${variant || ""}`;
    const priceLabel = makePriceLabelRoundAE(price);

    const isView360Enabled = !!threeSixtyViewUrl;
    const hasImage = mainImage && mainImage.path;
    const imageUrl = hasImage ? fastlyUrl({basePath: (config || {}).imageHost, path: mainImage.path,
    type: isView360Enabled ? imageTypes.gsCarCard360 : imageTypes.gsCarCard}) : NoCarImageFoundPath;

    return (
        <React.Fragment>
            <div className={"media mb-4"}>
                <div styleName={"styles.carImage"}>
                    <img src={imageUrl} alt="Car" />
                </div>
                <div className={"media-body"}>
                    <p styleName={"styles.heading"}>{carFullName}</p>
                    <strong styleName={"styles.price"}>{priceLabel}</strong>
                </div>
            </div>
        </React.Fragment>
    );
};

CheckoutCar.propTypes = {
    carDetails: PropTypes.object
};

export default CheckoutCar;
