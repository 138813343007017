/* eslint-disable no-magic-numbers */
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    data: [],
    transmission: [],
    fuelType: [],
    years: [],
    cylinders: [],
    error: null
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, ...data, error: null };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [] };
};

export const HANDLERS = {
    [Types.FETCH_LEAD_YEAR_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_LEAD_YEAR_FAILURE]: fetchDataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
