import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Styles
import styles from "./styles.css";

// Images
import OpenInMobileIllustration from "./images/open-in-mobile.svg";

// Component
import SocialIcon from "../../shared/icon-social";

// Constant
import { DOWNLOAD_APP_URL, DOWNLOAD_APP_URL_IOS } from "../../../constants/app-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import MetaTagsCarServiceContract from "../../shared/meta-tags-car-service-contract";
import { ROUTE_NAME } from "../../../constants/routes-constants";

const OpenInMobile = ({ route }) => {
    const onAppStoreClick = () => {
        trackDesktopCustomEventsAE("appStoreClick");
    };

    const onPlayStoreClick = () => {
        trackDesktopCustomEventsAE("playStoreClick");
    };

    return (
        <Fragment>
            {[ROUTE_NAME.SERVICE_CONTRACT, ROUTE_NAME.SERVICE_CONTRACT_HOME].includes(route?.name) && <MetaTagsCarServiceContract />}
            <div styleName={"styles.wrapper"}>
                <h2>Please Open In Mobile</h2>
                <img src={OpenInMobileIllustration} alt="Please Open In Mobile" />
                <p>
                    <strong>Sorry, this link will not work on desktop</strong>
                    Please open the same link in your mobile, or download our app and<br/>access your booking there.
                </p>
                <div styleName={"styles.AppDownloadWrapper"}>
                    <a onClick={onAppStoreClick} href={DOWNLOAD_APP_URL_IOS}><SocialIcon name="appStore" /></a>
                    <a onClick={onPlayStoreClick} href={DOWNLOAD_APP_URL}><SocialIcon name="googlePlay" /></a>
                </div>
            </div>
        </Fragment>
    );
};

OpenInMobile.propTypes = {
    route: PropTypes.object
};

export default withRouter(OpenInMobile);
