import React from "react";
import PropTypes from "prop-types";
import Success from "./images/success.svg";
import SuccessCircle from "./images/success-circle.svg";

const IconSuccess = ({ type, ...props }) => {
    let iconSrc = Success;
    if (type === "successCircle") {
        iconSrc = SuccessCircle;
    }

    return (
        <img src={iconSrc} alt="" {...props} />
    );
};

IconSuccess.propTypes = {
    iconSrc: PropTypes.string,
    type: PropTypes.string
};
export default IconSuccess;
