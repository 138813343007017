import mobileEventsAE from "./ae.mobile-events";
import mobileSellerEventsAE from "./ae.mobile-seller-events";
import { trackCustomEvents } from "./custom-event";

// MOBILE
export const trackMobileCustomEventsAE = async (category, extras = {}) => {
    if (window) {
        window.setTimeout(() => {
            trackCustomEvents({
                platformEvents: mobileEventsAE,
                category
            }, extras);
        }, 0);
    }
};

export const trackMobileSellerCustomEvents = async (category, extras = {}) => {
    if (window) {
        window.setTimeout(() => {
            trackCustomEvents({
                platformEvents: mobileSellerEventsAE, category
            }, extras);
        }, 0);
    }
};
