/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Constants
import {
    DEVICE_TYPE
} from "../../../constants/app-constants";
import { appUrl } from "../../../constants/url-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";

const SeoLinksListingPageV2 = ({
    platform,
    carDetailsFooterMetaContent = [],
    carListingFooterMetaContent = [],
    pageSource
}) => {
    const isMobile = platform === DEVICE_TYPE.MOBILE;

    let linkingData = [];

    if (pageSource === ROUTE_NAME.DETAIL_PAGE) {
        linkingData = carDetailsFooterMetaContent;
    } else if (pageSource === ROUTE_NAME.LISTING) {
        linkingData = carListingFooterMetaContent;
    }

    return (
        <div styleName={`styles.categoryLinksWrap ${isMobile ? "styles.categoryLinksWrapMobile" : ""}`}>
            {(linkingData || [])?.length > 0 && <h2>Used Cars in UAE</h2>}
            {(linkingData || [])?.map(category => {
                return (
                    <div key={category.title} styleName={"styles.linkCategory"}>
                        <span>
                            <span styleName={"styles.linkCategoryTitle"}>{category.title}: </span>
                            {category.links.map((link, i) => {
                                return (
                                    <React.Fragment key={link?.linkingAnchor}>
                                        <a href={`${appUrl()}/${link.spath}`} styleName={"styles.linkCategoryItem"} >{link.linkingAnchor}</a>
                                        <span> {i < category.links.length - 1 && "|"} </span>
                                    </React.Fragment>);
                            })}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

SeoLinksListingPageV2.propTypes = {
    carDetailsFooterMetaContent: PropTypes.array,
    carListingFooterMetaContent: PropTypes.array,
    platform: PropTypes.string,
    pageSource: PropTypes.string
};

export default SeoLinksListingPageV2;
