import React from "react";
import PropTypes from "prop-types";
import styles from "./tick.css";

const Tick = ({ tick }) => {
    return (
        <div>
            <div styleName={"styles.sliderTicks"}
                style={{
                    top: `${tick.percent}%`
                }}
            />
        </div>
    );
};

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired
};

Tick.defaultProps = {
    format: d => d
};

export default Tick;
