/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Component
import withLogin from "../with-login";
import TdSchedulePostBcC2C from "../td-schedule-post-bc-c2c";
import PostBcTdComplete from "../post-bc-td-complete";

// Images
import { API_SOURCES } from "../../../constants/api-constants";

//Utils
import gaId from "../../../utils/helpers/get-ga-from-cookie";
import { C2C_OFFER_STATUS, ORDER_STATUS } from "../../../constants/ae/checkout-constants";
import { TOAST_VARIANT } from "../../shared/toast-message/constant";

const TdScheduleC2C = ({
    match,
    carDetails = {},
    order = {},
    fetchCarDetailsConnect,
    submitCarOfferConnect,
    showToastConnect
}) => {

    const { params: { lid: carId } } = match || {};
    const { orderId = "", status: orderStatus, latestOffer = {} } = order || {};
    const { content: { appointmentId } = {} } = carDetails || {};
    const { offerStatus = "" } = latestOffer || {};

    useEffect(() => {
        if (carId && (appointmentId !== carId)) {
            fetchCarDetailsConnect(carId, false, "updatedGalleryDesign");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carId, appointmentId]);

    const generateCreateC2cOrderPayload = () => {
        return {
            appointmentId,
            source: API_SOURCES.WEBAPP,
            ...(gaId && { gaId })
        };
    };

    const submitOffer = async () => {
        try {
            const payLoad = generateCreateC2cOrderPayload();
            await submitCarOfferConnect(payLoad);
        } catch (error) {
            showToastConnect({
                variant: TOAST_VARIANT.FAILURE,
                text: "Something went wrong"
            });
        }
    };

    useEffect(() => {
        if (!orderId || !offerStatus) {
            submitOffer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, offerStatus]);

    const isOfferAccepted = (offerStatus === C2C_OFFER_STATUS.ACCEPTED) && (orderStatus === ORDER_STATUS.BOOKED);
    const isShowOfferConfirmationScreen = (offerStatus === C2C_OFFER_STATUS.ACCEPTED) && (orderStatus  === ORDER_STATUS.CREATED);

    if (isShowOfferConfirmationScreen) {
        return <TdSchedulePostBcC2C />;
    } else if (isOfferAccepted) {
        return <PostBcTdComplete />;
    }

    return null;
};

TdScheduleC2C.propTypes = {
    match: PropTypes.object,
    carDetails: PropTypes.object,
    order: PropTypes.object,
    fetchCarDetailsConnect: PropTypes.func,
    submitCarOfferConnect: PropTypes.func,
    showToastConnect: PropTypes.func
};

export default withLogin(TdScheduleC2C);
