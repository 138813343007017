import React from "react";
import styles from "./styles.css";
import CloseButton from "../icon-cross";
import Right from "./images/check-icon.svg";
import PropTypes from "prop-types";

const DownloadAppSuccessStrip = ({
    message, onClose
}) => {
    return (
        <div styleName={"styles.wrapper"}>
            <img src={Right} />
            <p styleName={"styles.message"}>{message}</p>
            <span styleName={"styles.close"}><CloseButton type="green" onClickHandler={onClose} /></span>
        </div>
    );
};

DownloadAppSuccessStrip.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func
};

export default DownloadAppSuccessStrip;
