import { createTypes } from "../../utils/redux/create-types";

export default createTypes(
    `
    SET_NAME
    SET_PROFILE_NAME
    SET_MOBILE
    SET_PROFILE_MOBILE
    SET_EMAIL
    GET_PROFILE_DETAILS_SUCCESS
    MARK_UNAUTHORIZED
    CHECK_AUTH
    SET_FIRST_LOAD
    UPDATE_LEAD_TYPE
    UPDATE_SHOW_COUPON_NUDGE
    
    SET_SOCKET_ID
    TRUECALLER_PROMPT_COUNT
    UPDATE_WEBVIEW_STATUS
    UPDATE_DND_STATUS
    UPDATE_WA_STATUS

    SET_GA_ID
    UPDATE_CURRENT_PAGE_TYPE

    UPDATE_PROFILE
    UPDATE_PROFILE_SUCCESS
    UPDATE_PROFILE_FAILURE

    SET_LAST_VERIFIED_MOBILE

    FETCH_PINCODE_CONFIG_SUCCESS
    FETCH_PINCODE_CONFIG_FAILURE

    LOGOUT_SUCCESS
    LOGOUT_FAILURE
    
    SET_LOGIN_MODAL

    UPDATE_LOCATION_POPUP_SHOWN
    UPDATE_OPEN_AI_CAR_FINDER_MODAL
    UPDATE_LOAN_BUDGET_NUDGE
    UPDATE_DISCOUNT_COUPON
    UPDATE_DOWNLOAD_NUDGE_STATUS
`,
    {
        prefix: "configuration/"
    }
);
