import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import H2 from "../h2/component";
import Helpline from "./images/landline.svg";
import Email from "./images/email.svg";
import Address from "./images/office-address.svg";
import CallUs from "./images/helpline.svg";
import MailAddress from "./images/email-desktop.svg";
import OfficeAddress from "./images/address-desktop.svg";

const ContactCard = ({ heading, children, imageType }) => {
    const image = {
        helpline: Helpline,
        email: Email,
        address: Address,
        number: CallUs,
        mail: MailAddress,
        office: OfficeAddress
    };

    return (
        <div styleName={"styles.cardWrap"}
            style={{background: `#fff url(${image[imageType]}) no-repeat bottom right`}}>
            <H2 text={heading} />
            {children}
        </div>

    );
};

ContactCard.propTypes = {
    heading: PropTypes.string,
    children: PropTypes.object,
    imageType: PropTypes.string
};

export default ContactCard;
