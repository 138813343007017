
/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import axios from "axios";
import { captureException } from "@sentry/browser";
import { b2cVasApiUrl } from "../constants/url-constants";
import authRefresh from "../utils/helpers/auth-refresh";
import { COUNTRY_CODE } from "../constants/app-constants";

const DEBUG = process.env.NODE_ENV === "development";

const BASE_API_URL = `${b2cVasApiUrl()}`;

/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X_COUNTRY": COUNTRY_CODE.AE,
        "X_VEHICLE_TYPE": "CAR"
    }
});

// Request Interceptor which handles token refresh pre and post token expiry
api.interceptors.request.use(authRefresh, (error) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("✉️ ", error);
    }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("📩 ", response);
    }
    return response;
}, (error) => {
    captureException(error);
    if (error.response && error.response.data) {
        if (DEBUG) {
            // eslint-disable-next-line no-undef
            console.error("Error: ", error.response.data);
        }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("📩 ", error);
    }
    return Promise.reject(error.message);
});

export default api;
