import React from "react";
import PropTypes from "prop-types";
import InputRadioButton from "../../shared/input-radio-button";
import styles from "./styles.css";
import Filters from "../../../utils/filters-v2";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { useHistory, useLocation } from "react-router-dom";

const SortBy = ({
    sortIds = [],
    sortData,
    updateSelectedSortConnect,
    selectedCity,
    updateUrlOptionsConnect,
    allFilters,
    onToggleSort,
    reloadCarListConnect,
    selectedSort,
    appliedFilters
}) => {
    const history = useHistory();
    const location = useLocation();

    const queryParams = parseLocationSearchParams(location.search);
    const searchQuery = queryParams.search;

    const onChangeSort = (displayText, e) => {
        trackDesktopCustomEventsAE("filterSortListingPage", {
          eventLabel: displayText
        });
        updateUrlOptionsConnect({sort: e.target.value});
        updateSelectedSortConnect({
          key: e.target.value,
          displayText
        });
        const filters = new Filters({cityCode: selectedCity.code, cityName: selectedCity.name});
        const {relativeURL} = filters.getListingPayload({
          selectedFilters: appliedFilters,
          filters: allFilters,
          options: {
            sort: e.target.value,
            ...(
              searchQuery && {
                search: searchQuery
              }
            )
          }
        });
        onToggleSort();
        reloadCarListConnect(true);
        history.replace(relativeURL);
      };

    return (
        <React.Fragment>
            <div className="filter-section" styleName={"styles.wrapper"}>
                {
                    (sortIds || []).sort().map((id) => (
                        <div key={id} styleName={"styles.sortByRow"}>
                            {sortData[id].name && <h3 styleName={"styles.heading"}>{sortData[id].name}</h3>}
                            <ul styleName={"styles.sortBy"}>
                                {
                                    sortData[id].options.map((sortOption, index) => (
                                        <li key={index}>
                                            <InputRadioButton
                                                onChange={onChangeSort.bind(null, sortOption.displayText)}
                                                checked={selectedSort.key === sortOption.key}
                                                id={sortOption.key}
                                                value={sortOption.key}
                                                text={sortOption.displayText} />
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>
        </React.Fragment>
    );
};

SortBy.propTypes = {
    sortIds: PropTypes.array,
    sortData: PropTypes.object,
    updateSelectedSortConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    updateUrlOptionsConnect: PropTypes.func,
    allFilters: PropTypes.object,
    onToggleSort: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    selectedSort: PropTypes.object,
    appliedFilters: PropTypes.array
};

export default SortBy;
