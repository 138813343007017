import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    // EMI CALCULATOR
    FETCH_EMI_CALCULATOR_INIT
    FETCH_EMI_CALCULATOR_SUCCESS
    FETCH_EMI_CALCULATOR_FAILURE

    // LOAN ELIGIBILITY
    LOAN_ELIGIBILITY_INIT
    LOAN_ELIGIBILITY_SUCCESS
    LOAN_ELIGIBILITY_FAILURE

    // USER LOAN ELIGIBILITY
    USER_LOAN_ELIGIBILITY_INIT
    USER_LOAN_ELIGIBILITY_SUCCESS
    USER_LOAN_ELIGIBILITY_FAILURE
`,
    {
        prefix: "carLoan/"
    }
);
