// import { createTypes } from "reduxsauce";

import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    INITIALIZE_REDUCER
`,
    {
        prefix: "testingInjection/"
    }
);
