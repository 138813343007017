// Images
import DownloadBanner from "./images/download-banner.png";
import FinanceBanner from "./images/quick-easy-finance-banner.png";
import HomeTestDrive from "./images/home-test-drive.png";
import MoneybackGuarantee from "./images/money-back-guarantee-new-2.png";
import ZeroDPBanner from "./images/zero-dp-banner.png";
import SaleDownloadBanner from "./images/sale-download-banner.png";
import WishListBanner from "./images/wishlist.png";

// Constants
import { NUMBER } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/routes-constants";

export const SHOW_AD_INDEXES = [
    NUMBER.FIVE,
    NUMBER.FOURTEEN,
    NUMBER.TWENTY_THREE,
    NUMBER.THIRTY_TWO,
    NUMBER.FOURTY_ONE,
    NUMBER.FIFTY,
    NUMBER.FIFTY_NINE,
    NUMBER.SIXTY_EIGHT,
    NUMBER.SEVENTY_SEVEN,
    NUMBER.EIGHTY_SIX
];

export const BANNER_ID = {
    DOWNLOAD_BANNER: "download-banner",
    ZERO_DP_BANNER: "zero-dp-banner",
    EXCHANGE_BANNER: "exhange-banner",
    HOME_TD_BANNER: "home-td-banner",
    MONEYBACK_BANNER: "moneyback-banner",
    ABU_DHABI_HUB_BANNER: "abu-dhabi-hub-banner",
    WISHLIST_BANNER: "wishlist-banner",
    FINANCE_BANNER: "finance-banner",
    SALE_DOWNLOAD_BANNER: "sale-download-banner",
    SEASONAL_SALE_BANNER: "seasonal-sale-banner"
};

export const BANNER_LIST = {
    [NUMBER.FIVE]: [{
        id: BANNER_ID.DOWNLOAD_BANNER,
        src: DownloadBanner,
        action: { isClickable: false }
    }],
    [NUMBER.FOURTEEN]: [{
        id: BANNER_ID.FINANCE_BANNER,
        src: FinanceBanner,
        action: { isClickable: false }
    }, {
        id: BANNER_ID.ZERO_DP_BANNER,
        src: ZeroDPBanner,
        action: { isClickable: true, to: `/${ROUTE_PATHS.carLoan}` }
    }],
    [NUMBER.TWENTY_THREE]: [{
        id: BANNER_ID.HOME_TD_BANNER,
        src: HomeTestDrive,
        action: { isClickable: false }
    }],
    [NUMBER.THIRTY_TWO]: [
        {
            id: BANNER_ID.MONEYBACK_BANNER,
            src: MoneybackGuarantee,
            action: { isClickable: false }
        },
        {
            id: BANNER_ID.WISHLIST_BANNER,
            src: WishListBanner,
            action: { isClickable: true, to: `/${ROUTE_PATHS.wishlist}` }
        }]
};

export const DEFAULT_SORT = "releavance";
export const SEARCH_SORT = "releavance";
export const EXACT_SEARCH_SORT = "search-releavance";

export const SALE_BANNER = [{
    id: BANNER_ID.SALE_DOWNLOAD_BANNER,
    src: SaleDownloadBanner,
    action: { isClickable: false }
}];
