import React from "react";
import PropTypes from "prop-types";

//Images
import IconSuccess from "./image/success-icon.svg";
import InputText from "../../shared/input-text";

import styles from "./styles.css";
import { withRouter } from "react-router-dom";

const CreateAccount = ({
    mobile = "",
    name = "",
    email = "",
    handleAccountDetails = () => { },
    nameValidator,
    validateEmail
    // checkout,
    // carId
}) => {

    return (
        <div styleName={"styles.createAccountWrap"}>
            <div styleName={"styles.numberVerify"}>
                    <span styleName={"styles.mobileText"}>Mobile Number</span>
                    <label>{mobile}</label>
                    <span styleName={"styles.verifiedText"}>Verified </span>
                    <img src={IconSuccess} alt="" width={24} height={24}/>
            </div>
            <h3 styleName={"styles.personalDetailText"}>Personal Details</h3>
            <div styleName={"styles.formGroup"}>
                <InputText
                    text="Full Name"
                    validator={nameValidator}
                    value={name}
                    id="name"
                    onChange={handleAccountDetails}
                    errorMessage="Please enter a valid name!"
                />
            </div>
            <div styleName={"styles.formGroup"}>
                <InputText
                    value={email}
                    id="email"
                    onChange={handleAccountDetails}
                    validator={validateEmail}
                    errorMessage="Please enter a valid email!"
                    text="Email Id"
                    />
            </div>
        </div>
    );
};

CreateAccount.propTypes = {
    mobile: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    handleAccountDetails: PropTypes.func,
    nameValidator: PropTypes.func,
    validateEmail: PropTypes.func
    // checkout: PropTypes.object,
    // carId: PropTypes.string
};

export default withRouter(CreateAccount);

