import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_EMI_CALCULATOR_INIT
    FETCH_EMI_CALCULATOR_SUCCESS
    FETCH_EMI_CALCULATOR_FAILURE
`,
    {
        prefix: "carLoan/"
    }
);
