import { createReducer } from "../../../utils/redux/create-reducer";
import sortByKey from "../../../utils/helpers/sort-by-key";
import Types from "./types";

export const INITIAL_STATE = {
    content: null,
    isFetching: false,
    homePageFaq: null,
    sellerFaq: null
};

export const fetchFaqInit = (state = INITIAL_STATE) => {
    return { ...state, isFetching: true };
};

export const fetchFaqSuccess = (state = INITIAL_STATE, { content, isHomepage, isSeller }) => {
    return {
        ...state,
        ...((!isHomepage && !isSeller) && {
            content: content.map(item => ({ ...item, faqList: sortByKey(item.faqList, "faqSequence", "asc") }))
        }),
        ...((isHomepage  && !isSeller) && {
            homePageFaq: content
        }),
        ...(isSeller && {
            sellerFaq: (content || []).map(item => ({ ...item, faqList: sortByKey(item?.faqList, "faqSequence", "asc") }))
        }),
        isFetching: false,
        error: null
    };
};

export const fetchFaqFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isFetching: false };
};

export const HANDLERS = {
    [Types.FETCH_FAQ]: fetchFaqInit,
    [Types.FETCH_FAQ_SUCCESS]: fetchFaqSuccess,
    [Types.FETCH_FAQ_FAILURE]: fetchFaqFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
