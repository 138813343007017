const homeObject = {
    "meta": {
        "title": "CARS24 UAE - Buy and Sell Used Cars Online | Second Hand Cars for Sale",
        "description": "Buy or sell your car with ease at CARS24 in UAE.Thousands of quality used cars for sale, delivered to your home.Get the Best Price in 1 hour when selling a car."
    },
    "searchPage": {
        "placeholder": "Which car do you want to buy?",
        "recentSearches": "Recent Searches",
        "searchSuggestions": "Suggested Brands",
        "noDataFound": "No cars found"

    },
    "bannerInfo": {
        "bannerHeading": "BEST PLACE TO BUY & SELL <br/> A USED CAR IN THE UAE",
        "bannerSubheading": "We offer the best used cars for sale!"
    },
    "userDropDown": {
        "hi": "Hi",
        "activity": "Activity",
        "login": "Login",
        "myBooking": "My Bookings",
        "myWishlist": "Wishlist",
        "faqText": "FAQ",
        "buyCar": "Buy Car",
        "am": "am",
        "pm": "pm",
        "helpCenter": "Help Centre"
    },
    "profile": {
        "heading": "Personal Details",
        "info": {
            "mobile": {
                "label": "MOBILE NUMBER",
                "error": ""
            },
            "name": {
                "label": "Full Name",
                "error": "Please enter a valid name!"
            },
            "email": {
                "label": "Email ID",
                "error": "Please enter a valid email!"
            },
            "button": {
                "update": "Save Changes",
                "updating": "Saving Changes"
            }
        },
        "dndLabel": "Receive updates & notifications",
        "logout": "Log out"
    },
    "exclusiveBenefits": {
        "title": "Explore CARS24",
        "slider": [
            {
                "key": "testdrive",
                "thumbText": "Test Drive On All Cars",
                "title": "Test Drive On All Cars",
                "subtitle": "Visit us or book test drive from the comfort of your home.",
                "type": "TESTDRIVE"
            },
            {
                "key": "warranty",
                "thumbText": "7 Day Trial",
                "title": "7 Day Trial",
                "subtitle": "Return your car, and get a refund of the car value.T&C Apply.",
                "type": "SERVICED"
            },
            {
                "key": "insurance",
                "thumbText": "Easy Insurance Purchase",
                "title": "Easy Insurance Purchase",
                "subtitle": "Buy top-notch insurance plans at great discounts.",
                "type": "INSURANCE"
            },

            {
                "key": "trial",
                "thumbText": "Upto 6 Month Warranty",
                "title": "Month Warranty",
                "subtitle": "10,000 kms service & extended warranty plans available.",
                "type": "WARRANTY"
            }
        ]
    },
    "howToBuyFromCars": {
        "title": "How To Buy From Cars24 ?",
        "items": [
            {
                "key": "bookTestDrive",
                "title": "1. Book your Test Drive",
                "description": "Pay refundable fees and book a free test drive."
            },
            {
                "key": "testDriveCar",
                "title": "2. Test drive your car",
                "description": "Visit us or book free test drive from the comfort of your home."
            },
            {
                "key": "payment",
                "title": "3.Payment & delivery",
                "description": "Complete the payment and we will deliver it to your doorstep."
            },
            {
                "key": "trial",
                "title": "4. 7 Day Trial",
                "description": "If you dont like the car in 7 days, return it and get 100% refund."
            }
        ]
    },
    "getCarsApp": {
        "heading": "Get the CARS24 App",
        "alterHeading": "To know more about your booking get the CARS24 App",
        "desc": "We will send you a link, open it on your phone to download the app",
        "itemsHeading": "Enjoy Seamless app experience",
        "items": [
            {
                "heading": "Top-quality pre-owned cars"
            },
            {
                "heading": "Best Offers"
            },
            {
                "heading": "Easy to Access"
            }
        ]
    }
};
export default homeObject;
