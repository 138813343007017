/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Constants
import { NUMBER, MISSED_CALL_CHECK_INTERVAL } from "../../../constants/app-constants";

// Components
import CountdownTimer from "../countdown-timer";

// Utils
import { autoReadSMS } from "../../../utils/helpers/web-otp";

const SellerBookingOTP = ({
    otpHeading = "",
    isOTPInvalid,
    otpLength = NUMBER.FOUR,
    errorMessage,
    newTheme = false,
    resendOTP,
    maxResentCount = NUMBER.SIX,
    onOTPVerifyCallback,
    onOtpChange
}) => {

    const inputOtpRef = useRef(null);
    const [resentOtp, setResentOtp] = useState(false);
    const [resentCount, setResentCount] = useState(NUMBER.ZERO);
    const [showLoader, setShowLoader] = useState(true);
    const [maxLength, setMaxLength] = useState(NUMBER.FOUR);

    const getDefaultOtp = useMemo(() => new Array(otpLength).fill(""), [otpLength]);
    const [otp, setOTP] = useState(getDefaultOtp);

    const isShowOtpText = (!showLoader && resentCount <= maxResentCount);

    useEffect(() => {
        if (otp?.length === otpLength && otp.every(value => (value || value === NUMBER.ZERO))) {
            if (onOtpChange) onOtpChange(otp.join(""));
            onOTPVerifyCallback(otp.join(""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp]);

    const onAutoDetectOTP = (code) => {
        if (code) {
            setOTP(`${code}`.split(""));
        }
    };

    useEffect(() => {
        autoReadSMS(onAutoDetectOTP);
    }, []);

    useEffect(() => {
        if (resentOtp) {
            window.setTimeout(() => {
                setResentOtp(false);
                setShowLoader(false);
            }, MISSED_CALL_CHECK_INTERVAL);
        }
    }, [resentOtp, setShowLoader]);

    const resetOtp = () => {
        setOTP(getDefaultOtp);
    };

    const resendOtpHandler = () => {
        resetOtp();
        setResentCount(resentCount + 1);
        if (resentCount < maxResentCount) {
            resendOTP();
            setResentOtp(true);
            setShowLoader(true);
            autoReadSMS(onAutoDetectOTP);
        }
    };

    const onCompleteTimer = () => {
        setShowLoader(false);
    };

    const onOtpChangeHandler = (idx, event) => {
        if (idx === NUMBER.THREE) setMaxLength(NUMBER.ONE);
        const { value, nextSibling, previousSibling } = event.target;
        if (isNaN(value)) return;
        if (value?.length === otpLength) {
            // Condition for Chrome ios
            setOTP(`${value}`.split(""));
        }
        if (value?.length === NUMBER.ONE || !value) {
            setOTP([...otp.map((otpNo, i) => i === idx ? value : otpNo)]);
            if (!value && previousSibling) {
                previousSibling.focus();
            }
            if (value && nextSibling) {
                inputOtpRef.current.blur();
                nextSibling.focus();
            }
        }
    };

    return (
        <React.Fragment>
            <div styleName={"styles.otpGrid"}>
                <label>{otpHeading}</label>
                <div styleName={`styles.otpInputWrap ${newTheme ? `styles.inputNew ${otp.every(value => (value || value === NUMBER.ZERO)) ? "styles.successInput" : ""} ${isOTPInvalid ? "styles.errorInput" : ""}` : ""} ${isOTPInvalid ? "styles.error" : ""}`}>
                    {Array.isArray(otp) && otp.map((data, index) => (
                        <input
                            ref={index === NUMBER.ZERO ? inputOtpRef : null}
                            key={index}
                            placeholder="0"
                            type="tel"
                            maxLength={maxLength}
                            value={data}
                            // eslint-disable-next-line no-invalid-this
                            onChange={onOtpChangeHandler.bind(this, index)}
                            onFocus={(e) => e.target.select()}
                        />
                    ))}
                </div>
                {(newTheme ? (!isOTPInvalid && showLoader) : showLoader) && (
                    <p styleName={"styles.resendText"}>Resend verification code in &nbsp;
                        <span><CountdownTimer interval={30} onlyText={true} onComplete={onCompleteTimer} /> &nbsp;sec</span>
                    </p>
                )}
                {(isOTPInvalid && !isShowOtpText) && !newTheme && (
                    <p styleName={"styles.textError"}>
                        {errorMessage || "Please enter the correct verification code"}</p>
                )}
                {(isOTPInvalid && !isShowOtpText) && newTheme && (
                    <div styleName={"styles.resendCodeGrid"}>
                        Incorrect OTP, try again ? <span styleName={"styles.resendCode"} onClick={resendOtpHandler} >Resend OTP</span>
                    </div>
                )}
                {isShowOtpText && (
                    <div styleName={"styles.resendCodeGrid"}>
                        Didn’t receive the code? <span styleName={"styles.resendCode"} onClick={resendOtpHandler} >Resend OTP</span>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

SellerBookingOTP.propTypes = {
    otpHeading: PropTypes.string,
    isOTPInvalid: PropTypes.bool,
    maxResentCount: PropTypes.number,
    otpLength: PropTypes.number,
    errorMessage: PropTypes.string,
    newTheme: PropTypes.bool,
    onOTPVerifyCallback: PropTypes.func,
    resendOTP: PropTypes.func,
    onOtpChange: PropTypes.func
};

export default SellerBookingOTP;
