/* eslint-disable max-params */
/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import ButtonImage from "../../shared/button-image";
import ListTab from "../../shared/list-tab";

// Images
import DefaultBodyType from "../../shared/images/filter-body-type/default.svg";

// Constants
import { getPopularBrandUrl } from "../../../utils/helpers/cdn-image-url";
import { FILTER_ENTITIES, POPULAR_CATEGORY_OPTIONS } from "../../../utils/filters-v2/constants";
import { FILTER_DESCRIPTION_MAP, CATEGORY_TAB_MAP, HOME_BRAND_LIMIT, CATEGORY_EVENT_MAP } from "./constant";

// Utilities
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import Filters from "../../../utils/filters-v2";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { NUMBER } from "../../../constants/app-constants";
import SlickWrapper from "../../shared/slick-wrapper";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
const settings = {
    slidesToShow: 10,
    slidesToScroll: 1,
    arrow: true,
    dots: false,
    infinite: false,
    gap: 8
};
const UsedCarsCatagory = ({
    filterTypes,
    allFilters,
    isFilterSSR,
    updateFilterSSRStatusConnect,
    fetchFiltersConnect,
    updateFilterOptionDataConnect
}) => {
    const history = useHistory();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onTabClick = (index, item) => {
        trackDesktopCustomEventsAE("newHomeFilterTabClick", {
            eventLabel: item
        });
        setSelectedIndex(index);
    };

    const renderTabs = () => {
        return filterTypes.map((item, index) => {
            if (allFilters[item]) {
                return (
                    <li
                        key={`tab_${index}`}
                        onClick={onTabClick.bind(null, index, item)}
                        styleName={selectedIndex === index ? "styles.activeTab" : ""}>
                        <button>{CATEGORY_TAB_MAP[allFilters[item].key]}</button>
                    </li>
                );
            } else {
                return null;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const fetchSeoUrl = (data, type) => {
        const filtersObj = new Filters({});
        let url;
        if (type?.key === FILTER_ENTITIES.CATEGORY) {
            const selectedFilter = {
                filterKey: type?.key,
                optionKey: data?.key,
                subOptionKey: "true",
                displayText: type?.displayText,
                isSelected: true
            };
            const { relativeURL } = filtersObj.getListingPayload({ selectedFilters: [...selectedFilter], filters: allFilters });
            url = relativeURL;
        } else {
            let entities = [type?.key];
            if (selectedIndex) {
                entities = [filterTypes[selectedIndex]];
            }
            url = filtersObj.getRelativeURL(entities, data);
        }
        return url;
    };

    const onFilterOptionClick = async (url, option, optionKey, filterKey) => {
        await yieldToMainThread();
        if (filterKey === FILTER_ENTITIES.CATEGORY) {
            const selectedFilter = {
                filterKey,
                optionKey,
                subOptionKey: "true",
                displayText: option,
                isSelected: true
            };
            updateFilterOptionDataConnect(selectedFilter);
        }
        await yieldToMainThread();
        history.push(url);
        await yieldToMainThread();
        scrollToTop();
        trackDesktopCustomEventsAE(CATEGORY_EVENT_MAP[filterTypes[selectedIndex]], {
            eventLabel: option || "NA"
        });
    };

    const handleCategoryClick = async (url, e) => {
        e.preventDefault();
        await yieldToMainThread();
        history.push(url);
    };

    const renderImageButton = (filter, optionCards, { imageMapping = {}, defaultImage }) => (
        (optionCards || []).map((item, index) => {
            const url = fetchSeoUrl(item, filter);
            if (filter?.key === FILTER_ENTITIES.CATEGORY) {
                return (
                    <a key={index}
                        onClick={(e) => handleCategoryClick(url, e)}
                        styleName={`styles.bodyTypeBlock ${filter?.key === "bodyType" ? "" : `styles.${filter?.key}Wrap`}`}
                    >
                        <ButtonImage
                            onClick={onFilterOptionClick.bind(null, url, item.displayText, item?.key, filter?.key)}
                            data={{
                                // Hide text for brands tab
                                text: item?.displayText?.length === NUMBER.THREE ? item?.displayText?.toUpperCase() : item?.displayText?.toLowerCase(),
                                imgSrc: [FILTER_ENTITIES.BODY_TYPE, FILTER_ENTITIES.CATEGORY].includes(filter?.key) ? (imageMapping[item?.key] || defaultImage || DefaultBodyType) : getPopularBrandUrl(item?.key)
                            }}
                        />
                    </a>
                );
            } else {
                return (
                    <a key={index}
                        onClick={(e) => handleCategoryClick(url, e)}
                        styleName={`styles.bodyTypeBlock ${filter?.key === "bodyType" ? "" : `styles.${filter?.key}Wrap`}`}
                    >
                        <ButtonImage
                            onClick={onFilterOptionClick.bind(null, url, item.displayText, item?.key, filter?.key)}
                            data={{
                                // Hide text for brands tab
                                text: item?.displayText?.length === NUMBER.THREE ? item?.displayText?.toUpperCase() : item?.displayText?.toLowerCase(),
                                imgSrc: [FILTER_ENTITIES.BODY_TYPE, FILTER_ENTITIES.CATEGORY].includes(filter?.key) ? (imageMapping[item?.key] || defaultImage || DefaultBodyType) : getPopularBrandUrl(item?.key)
                            }}
                        />
                    </a>
                );
            }

        })
);

    /*
     * doing this because dynamic data contains electric and hybrid
     * removing that temporarily
     * remove this check for it to become dynamic
     */
    const renderListTab = (filter) => (
        (filter.suggestions || []).map((item, index) => {
            // const { min = "", max = "" } = item || {};
            const url = fetchSeoUrl(item, {key: filter?.key});

            const handleClick = () => {
                onFilterOptionClick(url, item.displayText);
                trackDesktopCustomEventsAE("newHomeBudgetFriendlyClick", {
                    eventLabel: item.displayText
                });
            };

            return (
                <a
                    key={`tab_list_${index}`}
                    href={url}
                    onClick={(e) => { e.preventDefault(); }}
                >
                    <ListTab
                        onClick={handleClick}
                        data={item}
                        dataKey="key"
                        labelKey="displayText" />
                </a>
            );
        })
    );

    const renderList = (selectedFilter = {}, filterType, currentIndex) => {
        let component = null;
        if (FILTER_DESCRIPTION_MAP[filterType].renderType === "image-button") {
            let optionCards = [];
            if (filterType === FILTER_ENTITIES.CATEGORY) {
                optionCards = Object.values(selectedFilter?.groupFacet || {});
                optionCards = (optionCards || []).filter((optionCard) => POPULAR_CATEGORY_OPTIONS.includes(optionCard?.key));
            } else {
                optionCards = filterType === FILTER_ENTITIES.MAKE ? (selectedFilter?.options || []).slice(0, HOME_BRAND_LIMIT) : selectedFilter?.options;
            }
            // Doing this to show limited brands
            component = (
                <div styleName={`styles.catagoryBodyType ${filterType === FILTER_ENTITIES.MAKE || filterType === FILTER_ENTITIES.BODY_TYPE ? "styles.catagoryBrandType" : ""} ${selectedIndex === currentIndex ? "styles.visible" : "styles.hidden"}`}>
                    {filterType === FILTER_ENTITIES.MAKE || filterType === FILTER_ENTITIES.BODY_TYPE ? <SlickWrapper {...settings}>
                        {renderImageButton(selectedFilter, optionCards, FILTER_DESCRIPTION_MAP[filterType])}
                    </SlickWrapper> :
                        <div styleName="styles.otherQuickFilters">
                            {renderImageButton(selectedFilter, optionCards, FILTER_DESCRIPTION_MAP[filterType])}
                        </div>
                    }
                </div>
            );
        } else if (FILTER_DESCRIPTION_MAP[filterType].renderType === "list") {
            component = (
                <div styleName={`styles.catagoryPrice ${selectedIndex === currentIndex ? "styles.visible" : "styles.hidden"}`}>
                    {renderListTab(selectedFilter)}
                </div>
            );
        }
        return component;
    };

    useEffect(() => {
        if (!isFilterSSR) {
            try {
                const filterParams = { variant: "filterV5" };
                fetchFiltersConnect(filterParams);
            } catch (err) {
                // Handle if any error
            }
        }
        updateFilterSSRStatusConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName={"styles.wrapper"} className="container">
            <div styleName={"styles.secondWrapper"}>
                <span styleName={"styles.borderSepratorWrap"} />
                <ul styleName={"styles.tabPanel"}>
                    {renderTabs()}
                </ul>
                <span styleName={"styles.borderSepratorWrap"} />
            </div>
            <div>
                {
                    filterTypes.map((item, index) => {
                        const selection = allFilters[filterTypes[index]] || {};
                        return renderList(selection, filterTypes[index], index);
                    })
                }
            </div>
        </div>
    );
};

UsedCarsCatagory.propTypes = {
    filterTypes: PropTypes.array,
    allFilters: PropTypes.object,
    isFilterSSR: PropTypes.bool,
    updateFilterSSRStatusConnect: PropTypes.func,
    fetchFiltersConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func
};

export default UsedCarsCatagory;
