import getImageUrl from "./get-image-url";
import { FASTLY_BASE_URL } from "../../constants/app-constants";

const currentEnv = process.env.HOST_ENV;

export const imageTypes = {
    desktopCarCard: "desktopCarCard",
    desktopCarouselImage: "desktopCarouselImage",
    inspectionPreview: "inspectionPreview",
    desktopInspectionImage: "desktopInspectionImage",
    inspectionThumbnail: "inspectionThumbnail",
    makeAnOfferThumbnail: "makeAnOfferThumbnail",
    mobileCarDetailImage: "mobileCarDetailImage",
    inspectionPreviewThumbnail: "inspectionPreviewThumbnail",
    mobileCarDetailZoomable: "mobileCarDetailZoomable",
    mobileMakeAnOfferThumbnail: "mobileMakeAnOfferThumbnail",
    carService: "carService",
    carServiceThumbnail: "carServiceThumbnail",
    newInspectionThumbnail: "newInspectionThumbnail",
    appstoreIcon: "appstoreIcon",
    gsCarCard: "gsCarCard",
    gsCarCard360: "gsCarCard360",
    default: "default",
    spinCarPreview: "spinCarPreview",
    spinCarZoomable: "spinCarZoomable",
    aeCarDetailPreview: "aeCarDetailPreview",
    auCarDetailPreview: "auCarDetailPreview",
    gsCarCardAU: "gsCarCardAU"
};

export const imageProps = {
    [imageTypes.desktopCarCard]: { fastly: { w: 260, h: 188, auto: "format" }, imgix: { w: 260, h: 188, auto: "format" }, thumbor: { hasBucket: false, size: "260x188" }, cloudimage: { width: 260, height: 188 } },
    [imageTypes.desktopCarouselImage]: { fastly: { auto: "format" }, imgix: { w: 720, auto: "format" }, thumbor: { hasBucket: false, size: "720x0" }, cloudimage: { width: 720 } },
    [imageTypes.inspectionPreview]: { fastly: { w: 58, h: 38, auto: "format" }, imgix: { w: 58, h: 38, auto: "format" }, fit: "crop", thumbor: { hasBucket: false, size: "58x38" }, cloudimage: { width: 58, height: 38 } },
    [imageTypes.desktopInspectionImage]: { fastly: { w: 710, h: 400, auto: "format", fit: "crop" }, imgix: { w: 710, h: 400, auto: "format" }, thumbor: { hasBucket: false, size: "710x400", fitIn: true, filterColor: "black" }, cloudimage: { width: 710, height: 400, func: "fit", bg_img_fit: 1, bg_blur: "20" } },
    [imageTypes.inspectionThumbnail]: { fastly: { w: 80, h: 60, auto: "format" }, imgix: { w: 80, h: 60, auto: "format" }, thumbor: { hasBucket: false, size: "80x60" }, cloudimage: { width: 80, height: 60 } },
    [imageTypes.makeAnOfferThumbnail]: { fastly: { w: 152, h: 96, auto: "format" }, imgix: { w: 152, h: 96, auto: "format" }, thumbor: { hasBucket: false, size: "260x188" }, cloudimage: { width: 152, height: 96 } },
    [imageTypes.mobileCarDetailImage]: { fastly: { w: 373, h: 300, auto: "format" }, imgix: { w: 373, h: 300, auto: "format" }, thumbor: { hasBucket: false, size: "373x300", fitIn: true, filterColor: "black" }, cloudimage: { width: 373, height: 300 } },
    [imageTypes.inspectionPreviewThumbnail]: { fastly: { w: 58, h: 36, auto: "format" }, imgix: { w: 58, h: 36, auto: "format" }, fit: "crop", thumbor: { hasBucket: false, size: "58x36" }, cloudimage: { width: 58, height: 36 } },
    [imageTypes.newInspectionThumbnail]: { fastly: { w: 146, h: 146, auto: "format" }, imgix: { w: 146, h: 146, auto: "format" }, fit: "crop", thumbor: { hasBucket: false, size: "146x146" }, cloudimage: { width: 146, height: 146 } },
    [imageTypes.mobileCarDetailZoomable]: { fastly: { w: 1080, auto: "format" }, imgix: { w: 1080, auto: "format" }, thumbor: { hasBucket: false, size: "1080x0" }, cloudimage: { width: 1080 } },
    [imageTypes.mobileMakeAnOfferThumbnail]: { fastly: { w: 80, h: 50, auto: "format" }, imgix: { w: 80, h: 50, auto: "format" }, thumbor: { hasBucket: false, size: "80x50" }, cloudimage: { width: 80, height: 50 } },
    [imageTypes.carService]: { fastly: { w: 414, h: 180, fit: "crop", auto: "format" }, imgix: { w: 414, h: 180, auto: "format" }, thumbor: { hasBucket: false, size: "414x180" }, cloudimage: { width: 414, height: 180 } },
    [imageTypes.carServiceThumbnail]: { fastly: { w: 80, h: 80, fit: "crop", auto: "format" }, imgix: { w: 80, h: 80, auto: "format" }, thumbor: { hasBucket: false, size: "80x80" }, cloudimage: { width: 80, height: 80 } },
    [imageTypes.appstoreIcon]: { fastly: { h: 39, auto: "format" }, imgix: { h: 39, auto: "format" }, thumbor: { hasBucket: false, size: "0x39" }, cloudimage: { height: 39 } },
    [imageTypes.default]: { fastly: { auto: "format" }, imgix: { auto: "format" }, thumbor: { hasBucket: false }, cloudimage: {} },
    [imageTypes.gsCarCard]: { fastly: { w: 720, auto: "format" }, imgix: { w: 345, h: 249, auto: "format" }, thumbor: { hasBucket: false, size: "345x249" }, cloudimage: { width: 345, height: 249 } },
    [imageTypes.spinCarPreview]: { fastly: { w: 375, auto: "format" }, imgix: { w: 375, auto: "format" }, thumbor: { hasBucket: false, size: "370x0" }, cloudimage: { width: 375 } },
    [imageTypes.spinCarZoomable]: { fastly: { w: 1080, auto: "format" }, imgix: { w: 1080, auto: "format" }, thumbor: { hasBucket: false, size: "370x0" }, cloudimage: { width: 1080 } },
    [imageTypes.gsCarCard360]: { fastly: { w: 720, auto: "format" }, imgix: { w: 345, auto: "format" }, thumbor: { hasBucket: false, size: "345x0" }, cloudimage: { width: 345 } },
    [imageTypes.aeCarDetailPreview]: { fastly: { w: 700, auto: "format" }, imgix: { w: 700, auto: "format" }, thumbor: { hasBucket: false, size: "700x0" }, cloudimage: { width: 700 } },
    [imageTypes.auCarDetailPreview]: { fastly: { w: 700, auto: "format" }, imgix: { w: 700, auto: "format" }, thumbor: { hasBucket: false, size: "700x0" }, cloudimage: { width: 700 } },
    [imageTypes.auCarDetailThumbnail]: { fastly: { w: 128, auto: "format" }, imgix: { w: 128, auto: "format" }, thumbor: { hasBucket: false, size: "128x0" }, cloudimage: { width: 128 } },
    [imageTypes.gsCarCardAU]: { fastly: { w: 397, crop: "1600,900,x150,y200", auto: "format" }, imgix: { w: 345, h: 249, auto: "format" }, thumbor: { hasBucket: false, size: "345x249" }, cloudimage: { width: 345, height: 249 } }

};

export const cdnBaseUrl = () => {
    const cdns = {
        QA: FASTLY_BASE_URL,
        STAGING: FASTLY_BASE_URL,
        PRODUCTION: FASTLY_BASE_URL
    };
    return cdns[currentEnv];
};

export const propsBuilder = (url, imageProperties) => {
    const finalUrl = [url];
    const properties = Object.keys(imageProperties).reduce((pv, cv) => {
        if (imageProperties[cv] && cv !== "thumborProps" && cv !== "thumbor" && cv !== "baseUrl" && cv !== "fitIn" && cv !== "size") {
            return [...pv, `${cv}=${imageProperties[cv]}`];
        }
        return pv;
    }, []);
    finalUrl.push(properties.join("&"));
    return finalUrl.join("?");
};

export const fastlyUrl = ({ basePath = "", path = "", type = imageTypes.default, width, quality = "", queryParams = "" }) => {
    const fastlyObj = imageProps[type].fastly;
    if (width) {
        fastlyObj.w = width;
    }
    if (quality) {
        fastlyObj.quality = quality;
    }
    const urlParts = [[basePath, path].join("")];
    return propsBuilder(urlParts.join("/"), {
        ...fastlyObj,
        ...queryParams
    });
};

export const cdnImageUrl = (url, type, imageProperties = {}) => {
    const currentPath = cdnBaseUrl();
    if ((currentEnv === "STAGING" || imageProperties.useThumbor === true)) {
        return getImageUrl(url, {
            ...imageProps[type].thumbor,
            ...imageProperties.thumborProps

        });
    }
    const cdnType = (currentEnv === "PRODUCTION") ? "fastly" : "thumbor";
    const urlParts = [currentPath];
    urlParts.push(url);
    return propsBuilder(urlParts.join("/"), {
        ...imageProps[type][cdnType],
        ...imageProperties
    });
};

export const getPopularBrandUrl = (brandKey) => {
    const isBenzMake = brandKey === "MERCEDES BENZ" || brandKey === "MERCEDES-BENZ";
    brandKey = (brandKey || "").trim().toUpperCase().replace(/\s/g, "_");
    return `${cdnBaseUrl()}/cars24/ae/${isBenzMake ? "MERCEDES-BENZ" : brandKey}.${isBenzMake ? "webp" : "png"}?width=60&format=png&auto=webp`;
};

