/* eslint-disable complexity */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

import ImageSale from "./images/sale.png";

import { parseSeasonalSaleData } from "../../../utils/helpers/parse-seasonal-sale";

const WeekendSaleStrip = ({ activeSaleConfig }) => {
    const {
        active: isSaleActive,
        saleDetails = {}
    } = activeSaleConfig;

    const {homePageBannerInfo = {}} = saleDetails || {};

    const parsedSaleData = useMemo(() => parseSeasonalSaleData(homePageBannerInfo), [homePageBannerInfo]) || {};
    const { title, subTitle } = parsedSaleData || {};

    const { additionalData = {}} = homePageBannerInfo || {};
    const { imageUrl = "", backgroundGradients } = additionalData || {};
    const inlineStyles = backgroundGradients && isSaleActive ? { backgroundImage: `linear-gradient(${backgroundGradients})` } : {};

    if (!isSaleActive && !title) {
        return null;
    }

    return (
        <div styleName={"styles.stripWrapper"} style={inlineStyles}>
            <article>
                <h3 dangerouslySetInnerHTML={{ __html: title || "" }} />
                {subTitle && <p dangerouslySetInnerHTML={{ __html: subTitle || "" }} />}
            </article>
            <figure>
                {imageUrl ? <img src={imageUrl} alt="" width={76} height={48} /> : <img src={ImageSale} alt="" width={76} height={48} />}
            </figure>
        </div>
    );
};
export default WeekendSaleStrip;

WeekendSaleStrip.propTypes = {
    activeSaleConfig: PropTypes.object
};
