/* eslint-disable max-params */
/* eslint-disable max-statements */
export default (api) => {

    const fetchListing = (params, queryString, version = "v2", token, platform) => {
        return api.get(`/${version || "v2"}/vehicle${queryString ? `?${queryString}` : ``}`, {
            params,
            variant: "filterV3",
            headers: {
                Authorization: token ? `Bearer ${token}` : "",
                "X_PLATFORM": platform ? platform : ""
            }
        });
    };

    const fetchSeoContent = () => {
        return api.get(`/api/v1/seo`);
    };

    const fetchCarDetail = (appointmentId, variantType, token, platform) => {
        const variant = variantType ? `?variant=${variantType}` : "";
        return api.get(`/v1/vehicle/${appointmentId}${variant}`, {headers: {
            Authorization: token ? `Bearer ${token}` : "",
            "X_PLATFORM": platform ? platform : ""
        }});
    };
    const fetchFilters = (params, token) => {
        return api.get(`/v1/filter`, { params, headers: {
            Authorization: token ? `Bearer ${token}` : ""
        }  });
    };

    const fetchSiteMap = (params) => {
        return api.get(`/v1/siteMap/sitemap-data`, { params });
    };

    const fetchLinkingPattern = (params) => {
        return api.get(`/v1/siteMap/linking-pattern`, { params });
    };

    const fetchSimilarCars = (vehicleId, params, token, platform) => {
        return api.get(`/v1/vehicle/similar/${vehicleId}`, { params, headers: {
            Authorization: token ? `Bearer ${token}` : "",
            "X_PLATFORM": platform ? platform : ""
        } });
    };

    const fetchSimilarCarsV2 = (vehicleId, params, token, platform) => {
        return api.get(`/v2/vehicle/similar/${vehicleId}`, { params, headers: {
            Authorization: token ? `Bearer ${token}` : "",
            "X_PLATFORM": platform ? platform : ""
        } });
    };

    const fetchWishlistCarsList = (vehicleId, params, token) => {
        return api.get(`/v1/vehicle/similar/${vehicleId}`, { params, headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    const fetchAutoSuggestions = (params, token) => {
        return api.get(`/v2/auto-suggest`, { params, headers: {
            Authorization: token ? `Bearer ${token}` : ""
        }});
    };

    const fetchCarComparisonDetails = (params, token) => {
        return api.get(`/v1/vehicle/comparison`, { params, headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    const sendFiltersForNotification = (queryString, token) => {
        return api.put(`/v1/filter${queryString ? `?${queryString}&variant=filterV3` : ``}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const deleteNotificationInDb = (nid, token) => {
        return api.delete(`/v1/filter/${nid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchTrendingSearch = (params, token) => {
        return api.get(`/v1/popular/search?`, { params, headers: {
            Authorization: token ? `Bearer ${token}` : ""
        }  });
    };

    const fetchPostBcCarDetail = (appointmentId, token) => {
        return api.get(`/v1/vehicle/order/summary/${appointmentId}`, { headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    const fetchHandPickedCars = (params, token) => {
        return api.get(`/v3/vehicle?${params}`, { headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    const fetchSeoCarDetail = (make = "", model = "", token) => {
        return api.get(`/v1/siteMap/new-car-page-data?make=${make}&model=${model}`, { headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    const fetchAiRecommendedCars = (params, token) => {
        return api.get(`/v1/vehicle/ai-recommended`, {
            params,
            headers: {
                Authorization: token ? `Bearer ${token}` : ""
            }
        });
    };

    // Save filters
    const saveAppliedFilters = (queryString, token) => {
        return api.put(`/v1/filter`, queryString, { headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    const fetchSavedFilters = (token) => {
        return api.get(`/v1/filter/fetch`, { headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    const deleteSavedFilter = (savedId, token) => {
        return api.delete(`/v1/filter/${savedId}`, { headers: {
            Authorization: token ? `Bearer ${token}` : ""
        } });
    };

    return {
        fetchListing,
        fetchCarDetail,
        fetchFilters,
        fetchSiteMap,
        fetchLinkingPattern,
        fetchSimilarCars,
        fetchSimilarCarsV2,
        fetchWishlistCarsList,
        fetchAutoSuggestions,
        fetchCarComparisonDetails,
        sendFiltersForNotification,
        deleteNotificationInDb,
        fetchTrendingSearch,
        fetchHandPickedCars,
        fetchPostBcCarDetail,
        fetchSeoCarDetail,
        fetchAiRecommendedCars,
        saveAppliedFilters,
        fetchSavedFilters,
        deleteSavedFilter,
        fetchSeoContent
    };
};
