/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Constants
import { NUMBER, RECENT_SEARCHES_LABEL, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { DEFAULT_SORT, EXACT_SEARCH_SORT } from "../car-list/constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

// Utils
import Filters from "../../../utils/filters-v2";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { updateSearchList, getRecentSearches } from "../../../utils/helpers/search-brand-selectors";

// Components
import CloseButton from "../../shared/icon-cross";
import Button from "../../shared/button";

const SearchInfo = ({
    fetchAutoSuggestionsConnect,
    locales,
    selectedCityCode,
    clearAllFiltersConnect,
    selectedCityName,
    urlOptions,
    allFilters,
    selectedFilters,
    history,
    location,
    reloadCarListConnect,
    source = "home",
    mobile,
    isLoggedIn,
    iconOrange,
    hasCitySkip
}) => {
//   const inputRef = useRef(null);
  const queryParams = parseLocationSearchParams(location.search);
  const [searchInput, setsearchInput] = useState(queryParams.search || "");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const isSortSelected = urlOptions && urlOptions.sort && urlOptions.sort !== DEFAULT_SORT;

  const handleMountSearchOptions = useCallback(() => {
    const historySearch = getRecentSearches().find(e => e.label === RECENT_SEARCHES_LABEL);
    if (historySearch) setRecentSearches(historySearch.options);

    fetchAutoSuggestionsConnect({ search: "" }).then((response) => {
        const { defaultSuggestion = [ ] } = response || {};
        setSearchSuggestions(defaultSuggestion);
    }).catch(() => {
        setSearchSuggestions([ ]);
    });
  }, [fetchAutoSuggestionsConnect]);

  useEffect(() => {
    handleMountSearchOptions();
    if (queryParams) {
        setsearchInput(queryParams?.search || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAutoSuggestionsConnect, location?.search]);

//   const getOptions = (inputValue) => {
//     const payload = { search: inputValue };
//     if (inputValue.length > NUMBER.ZERO) {
//         fetchAutoSuggestionsConnect(payload).then(response => {
//             const { suggestions = [ ] } = response || {};
//             if (suggestions.length > NUMBER.ZERO) {
//                 setSearchSuggestions(suggestions);
//             }
//         }).catch(() => {
//             setSearchSuggestions([ ]);
//         });
//     }
// };

//   const delayedQuery = useCallback(
//     debounce((val) => {
//         getOptions(val);
//     }, NUMBER.THREE_HUNDRED),
//     []
//   );

//   const handleInputChange = (event) => {
//     const searchInputValue = event.target.value.replace("=", "");
//     setsearchInput(searchInputValue);
//     setShowDropdown(true);
//     delayedQuery(searchInputValue);
//   };

  const triggerGA4SearchEvent = (value = "") => {
    if (value?.length < NUMBER.ONE) return;
    trackDesktopCustomEventsAE("ga4Search", {
        search_term: value,
        user_id: mobile,
        user_status: isLoggedIn ? USER_LOGIN_STATUS.LOGGED_IN : USER_LOGIN_STATUS.LOGGED_OUT,
        login_type: isLoggedIn ? USER_LOGIN_MEDIUM.PHONE : USER_LOGIN_MEDIUM.NONE
    });
  };

  const onSearchClickHandler = (option) => {
    const { value = "", filter = "" } = option || { };
    clearAllFiltersConnect();
    triggerGA4SearchEvent(value);
    const currentFilters = new Filters({cityCode: selectedCityCode, cityName: selectedCityName });
    const { relativeURL: listingUrl } = currentFilters.getListingPayload({
      filters: allFilters,
      options: {
        ...(
          value && {
            search: value,
            matchType: "EXACT",
            ...(!isSortSelected && {
                sort: EXACT_SEARCH_SORT
            })
          }
        ),
        ...(
            filter && {
                searchFilter: filter
            }
        )
      }
    });
    reloadCarListConnect(true);
    setShowDropdown(false);
    setsearchInput("");
    history.replace(listingUrl);
    if (value.length) {
        updateSearchList({ value });
    }
  };

    //   const handleOnKeyDown = (event) => {
    //     if (event.keyCode === NUMBER.THIRTEEN || event.which === NUMBER.THIRTEEN) {
    //       if (event.target.value.length) onSearchClickHandler({ value: event.target.value });
    //     }
    //   };

    const getViewAllRedirectUrl = () => {
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {}, isGlobalUrl: true, hasCitySkip });
        return relativeURL;
    };

    const handleShowAllCars = () => {
        clearAllFiltersConnect();
        trackDesktopCustomEventsAE("viewAllCarsHome", {});
        reloadCarListConnect(true);
        history.push(getViewAllRedirectUrl());
    };

    const handleSuggestionClick = (e, option = { }) => {
        e.stopPropagation();
        e.preventDefault();
        const { suggestionText = " "} = option;
        const searchObj = { ...option, value: suggestionText};
        onSearchClickHandler(searchObj);
    };

    const onSearchRemove = () => {
        const filters = new Filters({cityCode: selectedCityCode, cityName: selectedCityName});
        const {relativeURL} = filters.getListingPayload({selectedFilters, filters: allFilters, options: urlOptions});
        reloadCarListConnect(true);
        history.replace(relativeURL);
        window.setTimeout(() => window.scrollTo(0, 0));
    };

    return (
        <React.Fragment>
            <div styleName={`styles.buyUsedCarsSearch ${iconOrange ? "iconOrange" : ""}`} className="buyUsedCarsSearchNew">
                {/* <InputText
          placeholder={"Which car do you want to buy?"}
          value={searchInput}
          inputRef={inputRef}
          onChange={handleInputChange}
          onKeyPress={handleOnKeyDown}
          onFocusCallback={() => setShowDropdown(true)}
          onBlurCallback={() =>  setShowDropdown(false)}
        /> */}
                {source === "home" && <Button onClick={handleShowAllCars} text={"VIEW ALL CARS"} />}
                {searchInput && source !== "home" && <span className={"closeSearchButtonFilter"}><CloseButton type="blue" onClickHandler={onSearchRemove} /></span>}
                {/* search values */}
                {showDropdown && <div styleName={"styles.resultContainers"} className={"resultContainers"}>
                    {!searchInput && recentSearches.length !== 0 && <div styleName={"styles.recentSearchs"}>
                        {recentSearches && recentSearches.map((recSearch, index) => (
                            <React.Fragment key={index}>
                                <h3 styleName={"styles.searchResultTitles"}>{locales.recentSearches}</h3>
                                <ul>
                                    {recSearch.options && recSearch.options.map((option, indexIn) =>
                                    (<li key={indexIn} id="searchList" onMouseDown={() => onSearchClickHandler(option)}>
                                        {option.label}
                                    </li>))}
                                </ul>
                            </React.Fragment>

                        ))}
                    </div>}
                    {(searchSuggestions?.length > NUMBER.ZERO) &&
                        <div styleName={"styles.searchSuggestions"}>
                            <h3 styleName={"styles.searchResultTitles"}>{locales?.searchSuggestions}</h3>
                            <ul>
                                {searchSuggestions?.map((option, index) => (
                                    <li id="searchList" key={index} onMouseDown={(e) => handleSuggestionClick(e, option)}>{option?.suggestionText}</li>
                                ))}
                            </ul>
                        </div>
                    }
                </div>}
            </div>
        </React.Fragment>
    );
};

SearchInfo.propTypes = {
  locales: PropTypes.object,
  fetchAutoSuggestionsConnect: PropTypes.func,
  urlOptions: PropTypes.object,
  selectedFilters: PropTypes.array,
  allFilters: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  reloadCarListConnect: PropTypes.func,
  selectedCityCode: PropTypes.string,
  selectedCityName: PropTypes.string,
  clearAllFiltersConnect: PropTypes.func,
  source: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  mobile: PropTypes.string,
  iconOrange: PropTypes.bool,
  hasCitySkip: PropTypes.bool
};

export default SearchInfo;
