/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Styling
import styles from "./styles.css";

// Actions
import { clearSuggestions } from "../filters/actions";

// Component
import RangeFilterV2 from "../range-filter-v2/component";

// Utils
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import { getClosestNumberInArray } from "../../../utils/helpers/get-closest-number-in-array";

// Constants
import { NUMBER } from "../../../constants/app-constants";

const PriceFilterRevamp = ({ filter, onOptionSelection, appliedFilters, clearSuggestionsConnect, ...restProps }) => {
    const {
        min = 0,
        max = 1,
        stepRates,
        key
    } = filter || {};

    const totalStepsCount = (stepRates || [])?.length || NUMBER.ZERO;

    const handleOptionSelection = (rangeData) => {
        const updatedRangeData = {
            ...rangeData,
            min: stepRates[rangeData?.min] || NUMBER.ZERO,
            max: stepRates[rangeData?.max] || NUMBER.ZERO
        };

        if (updatedRangeData && updatedRangeData?.min !== updatedRangeData?.max) {
            onOptionSelection({
                filterKey: key,
                isSelected: true,
                isRangeFilter: true,
                rangeValues: {
                    ...updatedRangeData
                }
            });
        }

    };

    const getFilterValueFromSet = (filterArray = [], value, isLargeValue) => {
        let targetIndex = (filterArray || [])?.findIndex(fi => fi === value);
        if (targetIndex <= -NUMBER.ONE) {
            targetIndex = (filterArray || [])?.findIndex(fi => fi === getClosestNumberInArray(filterArray, value, isLargeValue));
        }
        return targetIndex;
    };

    let appliedMin = getFilterValueFromSet(stepRates, min, stepRates?.[NUMBER.ZERO] > min);
    let appliedMax = getFilterValueFromSet(stepRates, max, stepRates?.[totalStepsCount - NUMBER.ONE] > max);

    const selectedAppliedFilter = getAppliedFilter({ filterKey: key }, appliedFilters);
    if (selectedAppliedFilter) {
        appliedMin = getFilterValueFromSet(stepRates, selectedAppliedFilter?.min, stepRates?.[NUMBER.ZERO] > selectedAppliedFilter?.min);
        appliedMax = getFilterValueFromSet(stepRates, selectedAppliedFilter?.max, stepRates?.[totalStepsCount - NUMBER.ONE] > selectedAppliedFilter?.max);
    }

    return (
        <React.Fragment>
            <div styleName={"styles.rangeFilterWrap"}>
                <div styleName="styles.priceFilterType">FULL PRICE</div>
                <RangeFilterV2
                    filterLabel={{
                        min: "Min",
                        max: "Max"
                    }}
                    minPrefix={"AED"}
                    maxPrefix={"AED"}
                    minValue={appliedMin}
                    maxValue={appliedMax}
                    minRange={NUMBER.ZERO}
                    maxRange={totalStepsCount - NUMBER.ONE}
                    priceFormatting={true}
                    step={NUMBER.ONE}
                    showLabelTop={true}
                    filterKey={key}
                    onOptionSelection={handleOptionSelection}
                    clearSuggestionsConnect={clearSuggestionsConnect.bind(null, key)}
                    vertical={false}
                    editable={true}
                    showColumnLayout={false}
                    tickCount={totalStepsCount}
                    rangeValues={stepRates}
                    {...restProps}
                />
            </div>
        </React.Fragment>
    );
};

PriceFilterRevamp.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    clearSuggestionsConnect: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearSuggestionsConnect: clearSuggestions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceFilterRevamp);
