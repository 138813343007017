import { AB_TEST_VARIATIONS } from "../app-constants";

export const CHECKOUT_STEP_KEY = {
    PersonalDetail: "personaldetail",
    Process: "process",
    Financing: "financing",
    Delivery: "delivery",
    BookingType: "bookingType",
    ConfirmBooking: "confirm",
    Kyc: "kyc",
    Confirmation: "confirmation",
    TradeIn: "TradeIn",
    TestDriveType: "testDriveType"
};

export const VIRTUAL_TD_LOCATION_CODE = "VIRTUAL_TD_UAE";

export const CHECKOUT_STATE = {
    CREATED: "CREATED",
    PAYMENT_MODE: "PAYMENT_MODE",
    DELIVERY: "DELIVERY",
    BOOKING_TYPE: "BOOKING_TYPE",
    BOOKING_PAYMENT: "BOOKING_PAYMENT",
    TEST_DRIVE_TYPE: "TEST_DRIVE_TYPE"
};

export const FINANCE_TAB = {
    GetFinancing: "getfinancing",
    PayOnDelivery: "payondelivery"
};

export const GENDER = {
    Male: "Male",
    Female: "Female",
    Other: "Other"
};

export const DELIVERY_PICKUP_TAB = {
    SelfPickup: "PICK_UP",
    HomeDelivery: "HOME_DELIVERY"
};

const CHECKOUT_STEP_PROGRESS_CONTROL_ITEMS = [
    {
        heading: "TEST DRIVE LOCATION",
        key: CHECKOUT_STEP_KEY.Delivery
    },
    {
        heading: "TRADE IN CHOICE",
        key: CHECKOUT_STEP_KEY.TradeIn
    }
    // {
    //     heading: "Booking",
    //     key: CHECKOUT_STEP_KEY.ConfirmBooking
    // }
];

const CHECKOUT_STEP_PROGRESS_VARIANT_ITEMS = [
    {
        heading: "TEST DRIVE TYPE",
        key: CHECKOUT_STEP_KEY.TestDriveType
    },
    {
        heading: "TEST DRIVE LOCATION",
        key: CHECKOUT_STEP_KEY.Delivery
    },
    {
        heading: "TRADE IN CHOICE",
        key: CHECKOUT_STEP_KEY.TradeIn
    },
    {
        heading: "Booking",
        key: CHECKOUT_STEP_KEY.ConfirmBooking
    }
];

export const CHECKOUT_STEP_CONTROL_TAB = [
    { name: CHECKOUT_STEP_KEY.Process, index: 1 },
    { name: CHECKOUT_STEP_KEY.Delivery, index: 2 },
    { name: CHECKOUT_STEP_KEY.TradeIn, index: 3 },
    { name: CHECKOUT_STEP_KEY.ConfirmBooking, index: 4 }
];

/**Desktop constants Begin */

export const CHECKOUT_STEP_KEY_DESKTOP = {
    PersonalDetail: "personaldetail",
    Process: "process",
    Financing: "financing",
    Delivery: "delivery",
    BookingType: "bookingType",
    ConfirmBooking: "confirm",
    Kyc: "kyc",
    Confirmation: "confirmation",
    TradeIn: "TradeIn",
    TDType: "TestDriveType",
    TDLocation: "TestDriveLocation"
};

const CHECKOUT_STEP_PROGRESS_CONTROL_ITEMS_DESKTOP = [
    {
        heading: "Test Drive Location",
        subHeading: "Choose Location",
        key: CHECKOUT_STEP_KEY_DESKTOP.TDLocation
    },
    {
        heading: "Trade In Choice",
        subHeading: "Optional Trade-In",
        key: CHECKOUT_STEP_KEY_DESKTOP.TradeIn
    },
    {
        heading: "Confirm Booking",
        subHeading: "Order Summary",
        key: CHECKOUT_STEP_KEY_DESKTOP.ConfirmBooking
    }
];

const CHECKOUT_STEP_PROGRESS_VARIANT_ITEMS_DESKTOP = [
    {
        heading: "Test Drive Type",
        subHeading: "Choose Type",
        key: CHECKOUT_STEP_KEY_DESKTOP.TDType
    },
    {
        heading: "Test Drive Location",
        subHeading: "Choose Location",
        key: CHECKOUT_STEP_KEY_DESKTOP.TDLocation
    },
    {
        heading: "Trade In Choice",
        subHeading: "Optional Trade-In",
        key: CHECKOUT_STEP_KEY_DESKTOP.TradeIn
    },
    {
        heading: "Confirm Booking",
        subHeading: "Order Summary",
        key: CHECKOUT_STEP_KEY_DESKTOP.ConfirmBooking
    }
];

export const CHECKOUT_STEP_VARIANT_TAB_DESKTOP = [
    { name: CHECKOUT_STEP_KEY_DESKTOP.Process, index: 1 },
    { name: CHECKOUT_STEP_KEY_DESKTOP.TDType, index: 2 },
    { name: CHECKOUT_STEP_KEY_DESKTOP.TDLocation, index: 3 },
    { name: CHECKOUT_STEP_KEY_DESKTOP.TradeIn, index: 4 },
    { name: CHECKOUT_STEP_KEY_DESKTOP.ConfirmBooking, index: 5 }
];

export const CHECKOUT_STEP_CONTROL_TAB_DESKTOP = [
    { name: CHECKOUT_STEP_KEY_DESKTOP.Process, index: 1 },
    { name: CHECKOUT_STEP_KEY_DESKTOP.TDLocation, index: 2 },
    { name: CHECKOUT_STEP_KEY_DESKTOP.TradeIn, index: 3 },
    { name: CHECKOUT_STEP_KEY_DESKTOP.ConfirmBooking, index: 4 }
];

export const CHECKOUT_STEP_TAB_DESKTOP = {
    [AB_TEST_VARIATIONS.CONTROL]: CHECKOUT_STEP_CONTROL_TAB_DESKTOP,
    [AB_TEST_VARIATIONS.VARIANT]: CHECKOUT_STEP_VARIANT_TAB_DESKTOP
};

export const STEP_PROGRESS_ITEMS_DESKTOP = {
    [AB_TEST_VARIATIONS.CONTROL]: CHECKOUT_STEP_PROGRESS_CONTROL_ITEMS_DESKTOP,
    [AB_TEST_VARIATIONS.VARIANT]: CHECKOUT_STEP_PROGRESS_VARIANT_ITEMS_DESKTOP
};

/**END DESKTOP CONSTANTS */

export const STEP_PROGRESS_ITEMS = {
    [AB_TEST_VARIATIONS.CONTROL]: CHECKOUT_STEP_PROGRESS_CONTROL_ITEMS,
    [AB_TEST_VARIATIONS.VARIANT]: CHECKOUT_STEP_PROGRESS_VARIANT_ITEMS
};

export const OFFER_TYPE = {
    User: "USER",
    System: "SYSTEM",
    Customise: "CUSTOMISE"
};

export const KYC_SCREEN_TYPE = {
    DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
    BOOKING_CONFIRMED: "BOOKING_CONFIRMED"
};

export const EMI_DEFAULT_STEP = 1;
export const DOWNPAYMENT_DEFAULT_STEP = 1;

export const TIME_UNIT = 60;
export const START_TIMER_MIN_VAL = 0;
export const SEC_START_VALUE = 59;
export const INITIAL_TIMER_VALUE = 1;
export const MILLI_IN_MUNUTE = 60000;
export const RESERVED_HOURS = 96;

export const ORDER_STATUS = {
    CREATED: "CREATED",
    EXPIRED: "EXPIRED",
    CANCELED: "CANCEL",
    BOOKED: "BOOKED",
    DELIVERED: "DELIVERED",
    SOLD: "SOLD"
};

export const FINANCING_TAB_TYPE = {
    GET_FINANCING: "Get Financing",
    PAY_ON_DELIVERY: "Pay on Delivery"
};

export const FINANCE_SCREEN = {
    FINANCE_MODE: "FINANCE_MODE",
    FINANCE: "GET_FINANCE",
    PAY_ON_DELIVERY: "PAY_ON_DELIVERY",
    FINANCE_BREAKUP: "FINANCE_BREAKUP",
    CF_FORM: "CF_FORM",
    FINANCE_APPROVED: "FINANCE_APPROVED",
    FINANCE_CUSTOMISE: "FINANCE_CUSTOMISE"
};

export const BOOKING_SERVICE_CHARGES = [
    {
        name: "Shipping charges",
        amount: 0,
        displayText: "FREE",
        key: "shipping_charge",
        description: "Get your car delivered at selected location"
    },
    {
        name: "6 months warranty",
        amount: 0,
        displayText: "FREE",
        key: "warranty",
        description: "@599 per month"
    },
    {
        name: "Insurance",
        amount: 0,
        displayText: "FREE",
        key: "insurance",
        description: "3rd party insurance with validity is included"
    },
    {
        name: "Interstate RC Transfer",
        amount: 0,
        displayText: "FREE",
        key: "rc_charge",
        description: "CARS24 will facilitate the RC transfer of the car"
    },
    {
        name: "Service charges",
        amount: 0,
        displayText: "NEVER",
        key: "service_charge",
        description: "No service charges"
    }
];

export const SLIDE_TYPES = {
    EMI: "EMI",
    DOWN_PAYMENT: "DOWN_PAYMENT"
};

export const FINANCE_DOCUMENTS = [
    "Emirates ID",
    "Passport & visa copy",
    "Salary certificate",
    "Three months bank statement (if salary not transfering in same bank)",
    "Driving license",
    "Traffic file ID"
];

export const NON_FINANCE_DOCUMENTS = [
    "Emirates ID",
    "Passport with valid visa",
    "Driving license"
];

export const BOOKING_TYPES = {
    EX: "EX",
    NEX: "NEX",
    MRL: "MRL",
    VIRTUAL_TD: "VIRTUAL_TD",
    C2C: "C2C"
};

export const BOOKING_TYPES_CONFIRMATION_PAGE = {
    NEX: "HOME TEST DRIVE",
    MRL: "HUB TEST DRIVE"
};

export const getBookingTypesProps = (type) => {
    switch (type) {
        case BOOKING_TYPES.NEX: {
            return {
                title: "Booking Fee",
                subTitle: "",
                description: [
                    "It allows multiple customers to test drive the car.",
                    "The one who pays token amount first will have exclusive right to purchase the car.",
                    "Your booking might get cancelled if another customer exclusively reserve it before you."
                ]
            };
        }
        case BOOKING_TYPES.MRL: {
            return {
                title: "Booking Fee",
                subTitle: "",
                description: [
                    "It allows multiple test drives of available cars.",
                    "Your test drive might get cancelled if another customer pays token amount before you."
                ]
            };
        }
        default: {
            return {};
        }
    }
};

export const CHECKOUT_STATES = {
    CREATED: "CREATED",
    PAYMENT_MODE: "PAYMENT_MODE",
    DELIVERY: "DELIVERY",
    BOOKING_TYPE: "BOOKING_TYPE",
    BOOKING_PAYMENT: "BOOKING_PAYMENT"
};

export const DEFAULT = "DEFAULT"; // To define any default value

export const TEST_DRIVE_MODES = {
    HUB: "HUB",
    HOME: "HOME",
    VIRTUAL_TD: "VIRTUAL_TD"
};

export const C2C_OFFER_STATUS = {
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    SUBMITTED: "SUBMITTED"
};

export const C2C_BOOKING_TYPE = {
    PRE_BC: "PRE_BC",
    POST_BC: "POST_BC",
    PRE_BC_SUBMIT_OFFER: "PRE_BC_SUBMIT_OFFER"
};
