const makeScript = (src, id, loadType = "") => (
    function (d, s) {
        if (d.getElementById(id)) { return; }
        const element = d.getElementsByTagName(s)[0];
        const jscript = d.createElement(s);
        jscript.src = src;
        jscript.id = id;
        if (loadType) {
            jscript[loadType] = true;
        }
        element.parentNode.insertBefore(jscript, element);
    }(document, "script"));

const multiListener = (l, t = "a") => {
    const e = "scroll mousedown mousemove touchstart scroll keydown".split(" ");
    for (let i = 0, iLen = e.length; i < iLen; i++) {
        if (t === "a") {
            window.addEventListener(e[i], l, false);
        } else {
            window.removeEventListener(e[i], l, false);
        }
    }
};
export const loadGoogleMap = () => makeScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places&region=AE&language=en&callback=Function.prototype`, "google-place", "defer");

export const loadScriptsOnFirstInteraction = () => {
    const loadScript = () => {
        multiListener(loadScript, "r");
    };
    multiListener(loadScript);
};

export const loadDapi = () => makeScript(`https://cdn.dapi.co/dapi/v2/sdk.js`, "dapi-script", "defer");

export const loadClevertap = () => {
    window.clevertap = {event: [], profile: [{Site: {"GA-ID": `${process.env.GA_ID}`}}], account: [], onUserLogin: [], notifications: [], privacy: []};
    window.clevertap.account.push({"id": process.env.CLEVERTAP_ACCOUNT_ID});
    window.clevertap.privacy.push({optOut: false});
    window.clevertap.privacy.push({useIP: false});
    (function () {
        const wzrk = document.createElement("script");
        wzrk.type = "text/javascript"; wzrk.async = true; wzrk.src = `${document.location.protocol === "https:" ? "https://d2r1yp2w7bby2u.cloudfront.net" : "http://static.cleverTap.com"  }/js/a.js`;
        const s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(wzrk, s);
    })();
};
