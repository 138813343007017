import { AB_TEST_VARIATIONS } from "../../constants/app-constants";

export const getExperimentsData = (experiments) => {
    const experimentData = {};
    for (const key in experiments) {
        const newKey = key.replace(/-([a-z])/g, (g) => { return g[1].toUpperCase(); });
        experimentData[newKey] = experiments[key] ? AB_TEST_VARIATIONS.CONTROL : AB_TEST_VARIATIONS.VARIANT;
    }
    return experimentData;
};
