import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

//Images
import Icon from "./images/arrow-icon.svg";
import { DEFAULT_IMG_MORE_CARS } from "../../../constants/app-constants";
import LazyImage from "../../shared/lazy-image";

const InternalLinkCarDetailCard = ({linkLabel, carCount, link, imgSrc}) => {
    const handleOnError = (event) => {
        event.target.src = DEFAULT_IMG_MORE_CARS;
        event.onerror = null;
    };
    return (
        <div styleName={"styles.card"}>
            <div styleName={"styles.iconWrap"}>
                <LazyImage src={imgSrc} alt={linkLabel} onError={handleOnError} />
            </div>
            <div>
                <a href={link} styleName={"styles.viewCar"}>{linkLabel} <img src={Icon} /> </a>
                <h6 styleName={"styles.carName"}>{carCount} Cars Available</h6>
            </div>

        </div>
    );
};
InternalLinkCarDetailCard.propTypes = {
    linkLabel: PropTypes.string,
    carCount: PropTypes.number,
    link: PropTypes.string,
    imgSrc: PropTypes.string
};
export default InternalLinkCarDetailCard;
