/* eslint-disable complexity */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Component
import withLogin from "../with-login";
import LoginRevamp from "../login-revamp";
import LazyImage from "../../shared/lazy-image";

// Images
import SaleBanner from "./images/sale.png";

// Constants
import { SOURCES } from "../login/constants";

// Utils
import { parseSeasonalSaleData } from "../../../utils/helpers/parse-seasonal-sale";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const SaleStripDetailPage = ({
    isLoggedIn,
    showLogin,
    setShowLogin,
    appointmentId,
    fetchCarDetailsConnect,
    activeSaleConfig = {}
}) => {

    const { saleDetails = {} } = activeSaleConfig || {};
    const { detailPageBannerInfo = {} } = saleDetails || {};
    const { additionalData = {} } = detailPageBannerInfo || {};
    const { backgroundGradients = "", imageUrl = "" } = additionalData || {};
    const parsedSaleData = useMemo(() => parseSeasonalSaleData(detailPageBannerInfo), [detailPageBannerInfo]) || {};
    const { title = "", subTitle = "" } = parsedSaleData || {};
    const inlineStyles = backgroundGradients ? { backgroundImage: `linear-gradient(${backgroundGradients})` } : {};

    const onLoginSucess = () => {
        fetchCarDetailsConnect(appointmentId, true, "updatedGalleryDesign");
        setShowLogin(false);
    };

    const onLoginClose = () => {
        setShowLogin(false);
    };

    const handleLoginClick = () => {
        trackDesktopCustomEventsAE("saleBannerLoginClickDetailPage");
        setShowLogin(true);
    };

    return (
        <LoginRevamp
            isVisible={showLogin}
            onSuccess={onLoginSucess}
            onClose={onLoginClose}
            loginFor={SOURCES.DP_SALE_LINE}
        >
            <div styleName={"styles.wrapper"} style={inlineStyles}>
                <article>
                    <h3 dangerouslySetInnerHTML={{ __html: title || "" }} />
                    <span dangerouslySetInnerHTML={{ __html: subTitle || "" }} />
                    {!isLoggedIn && <span onClick={handleLoginClick} styleName={"styles.loginCta"}>Login now</span>}
                </article>
                <figure>
                    <LazyImage src={imageUrl || SaleBanner} alt="Sale Banner" width={87} height={59} />
                </figure>
            </div>
        </LoginRevamp>
    );
};

SaleStripDetailPage.propTypes = {
    isLoggedIn: PropTypes.bool,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    secureToken: PropTypes.string,
    activeSaleConfig: PropTypes.object,
    appointmentId: PropTypes.string,
    fetchCarDetailsConnect: PropTypes.func
};

export default withLogin(SaleStripDetailPage);
