import {LANGUAGES} from "../../constants/app-constants";
import Hatchback from "./images/hatchback.png";
import Mpv from "./images/mpv.svg";
import Sedan from "./images/sedan.svg";
import Suv from "./images/suv.svg";
import Pickup from "./images/truck.png";
import Sport from "./images/sports.svg";
import SportBack from "./images/sportback.svg";
import Coupe from "./images/coupe.svg";
import Seater from "./images/7seater.svg";
import Truck from "./images/truck.png";
import MiniVan from "./images/minivan.png";
import Wagon from "./images/wagon.svg";

// brands/make
import BMW from "./images/bmw.svg";
import Honda from "./images/honda.svg";
import Isuzu from "./images/isuzu.svg";
import Mercedes from "./images/mercedes.svg";
import Toyota from "./images/toyota.svg";
import Mitsubishi from "./images/mitsubishi.svg";
import Nissan from "./images/nissan.svg";
import Mazda from "./images/mazda.svg";
import Ford from "./images/ford.svg";
import Jeep from "./images/jeep.png";
import Hyundai from "./images/hyundai.png";
import Chevrolet from "./images/chevrolet.png";
import Audi from "./images/audi.png";
import Mini from "./images/mini.png";
import Kia from "./images/kia.png";
import Renault from "./images/renault.png";
// fuel type
import Petrol from "./images/petrol.svg";
import Diesel from "./images/diesel.svg";
import Electric from "./images/electric.svg";
import Hybrid from "./images/hybrid.svg";
//default
import BodyDefault from "./images/bodyDefault.svg";
import BrandDefault from "./images/brandDefault.svg";
import FuelDefault from "./images/fuelDefault.svg";
import DoubleCabUtility from "./images/doubleCabUtility.svg";
import SuvCoupe from "./images/suv_coupe.svg";

export const imageMappingMap = {
  [LANGUAGES.en.code]: {
    "imageMapping": {
      bodyType: {
        "pickup": Pickup,
        "sedan": Sedan,
        "hatchback": Hatchback,
        "mpv": Mpv,
        "suv": Suv,
        "coupe": Coupe,
        "sportback": SportBack,
        "7seater": Seater,
        "truck": Truck,
        "convertible": Sport,
        "default": BodyDefault,
        "minivan": MiniVan,
        "wagon": Wagon,
        "double cab utility": DoubleCabUtility,
        "suv coupe": SuvCoupe
      },
      fuelType: {
        "diesel": Diesel,
        "petrol": Petrol,
        "hybrid": Hybrid,
        "electric": Electric,
        "default": FuelDefault
      },
      make: {
        "bmw": BMW,
        "honda": Honda,
        "isuzu": Isuzu,
        "mercedesBenz": Mercedes,
        "toyota": Toyota,
        "mitsubishi": Mitsubishi,
        "nissan": Nissan,
        "mazda": Mazda,
        "ford": Ford,
        "jeep": Jeep,
        "hyundai": Hyundai,
        "chevrolet": Chevrolet,
        "audi": Audi,
        "mini": Mini,
        "kia": Kia,
        "default": BrandDefault,
        "renault": Renault
      }
    }
  }
};
