import { useEffect, useRef } from "react";

const useOutsideClick = (callback) => {
    const ref = useRef();
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback]);
    return ref;
};

export default useOutsideClick;
