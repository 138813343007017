import React from "react";
import PropTypes from "prop-types";
import styles from "./style.css";

const ToggleSwitch = ({
    checked,
    onChangeCallback = () => {},
    disabled = false,
    inputRef,
    blueVariant
}) => {

    const onChangeHandler = (e) => {
        onChangeCallback(e);
    };

    return (
        <div styleName={`styles.toggle ${blueVariant ? "styles.blueToggle" : ""}`}>
            <input
                ref={inputRef}
                type="checkbox"
                styleName={"styles.check"}
                checked={checked}
                onChange={onChangeHandler}
                disabled={disabled}
                aria-label="Toggle"
            />
            <b styleName={"styles.b styles.switch"} className="toggleCircle rippleEffect orange"/>
            <b styleName={"styles.b styles.track"}/>
        </div>
    );
};

ToggleSwitch.propTypes = {
    checked: PropTypes.bool,
    blueVariant: PropTypes.bool,
    onChangeCallback: PropTypes.func,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    inputRef: PropTypes.object
};

export default ToggleSwitch;
