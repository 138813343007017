import { createReducer } from "../../../utils/redux/create-reducer";
import { NUMBER } from "../../../constants/app-constants";
import Types from "./types";

const ACTIVE_STEP_INITIAL_STATE = {
    isLoading: true,
    error: null
};

export const INITIAL_STATE = {
    orderId: null,
    isMRL: null,
    expiryDate: null,
    order: {},
    steps: [],
    isLoading: true,
    error: null,
    isFetched: false,
    postBcConfig: {
        homeDeliveryCharge: NUMBER.ZERO,
        documentationCharge: NUMBER.ZERO
    },
    isScheduleTestDriveShowMoreCTASelected: false,
    activeStepData: ACTIVE_STEP_INITIAL_STATE,
    tdAllActiveBookings: [],
    isTdAllActiveBookingsLoading: true
};

const updateSlotDetailsTD = (state = INITIAL_STATE, { bookedSlotDetail }) => {
    const { address: tdAddress, from: tdSlotStart, to: tdSlotEnd, giftAvailable } = bookedSlotDetail;
    const oldTestDriveprops = (state?.steps || []).shift();
    const testDriveProps = { ...oldTestDriveprops, tdAddress, tdSlotStart, tdSlotEnd, giftAvailable };
    return {
        ...state,
        steps: [testDriveProps, ...state?.steps]
    };
};

const updateSlotDetailsDelivery = (state = INITIAL_STATE, { bookedSlotDetail }) => {
    const { address: deliveryAddress, from: deliverySlotStart, to: deliverySlotEnd } = bookedSlotDetail;
    const oldDeliveryProps = (state?.steps || []).pop();
    const deliveryProps = { ...oldDeliveryProps, deliveryAddress, deliverySlotStart, deliverySlotEnd };
    return {
        ...state,
        steps: [...(state?.steps || []), deliveryProps]
    };
};

const setIsMRL = (state = INITIAL_STATE, { isMRL }) => {
    return {
        ...state,
        isMRL
    };
};

const setScheduleTestDriveShowMoreCTA = (state = INITIAL_STATE, { isSelected }) => {
    return {
        ...state,
        isScheduleTestDriveShowMoreCTASelected: isSelected
    };
};

const fetchPostBookingConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        postBcConfig: {...data}
    };
};

const fetchPostBookingConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        postBcConfig: {
            ...state.postBcConfig,
            error
        }
    };
};

const fetchOrderPostBcDetailsInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

const fetchOrderPostBcDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, error: null, isLoading: false, ...data, isFetched: true };
};

const fetchOrderPostBcDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isLoading: false, error, isFetched: true };
};

const fetchActiveStepDataInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        activeStepData: {
            ...ACTIVE_STEP_INITIAL_STATE,
            isLoading: true
        }
    };
};

const fetchActiveStepDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        activeStepData: {
            ...ACTIVE_STEP_INITIAL_STATE,
            isLoading: false,
            error: null,
            ...data
        }
    };
};

const fetchActiveStepDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        activeStepData: {
            ...ACTIVE_STEP_INITIAL_STATE,
            isLoading: false,
            error
        }
    };
};

const resetActiveStepData = (state = INITIAL_STATE) => {
    return {
        ...state,
        activeStepData: ACTIVE_STEP_INITIAL_STATE
    };
};

const fetchTdAllActiveBookingsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        tdAllActiveBookings: [],
        isTdAllActiveBookingsLoading: true
    };
};

const fetchTdAllActiveBookingsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tdAllActiveBookings: data,
        isTdAllActiveBookingsLoading: false
    };
};

const fetchTdAllActiveBookingsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        tdAllActiveBookings: [],
        isTdAllActiveBookingsLoading: false
    };
};

export const HANDLERS = {
    [Types.UPDATE_SLOT_TD]: updateSlotDetailsTD,
    [Types.UPDATE_SLOT_DL]: updateSlotDetailsDelivery,

    [Types.SET_IS_MRL]: setIsMRL,
    [Types.SET_SCHEDULE_TEST_DRIVE_SHOW_MORE_CTA]: setScheduleTestDriveShowMoreCTA,

    [Types.FETCH_POST_BC_CONFIG_SUCCESS]: fetchPostBookingConfigSuccess,
    [Types.FETCH_POST_BC_CONFIG_FAILURE]: fetchPostBookingConfigFailure,

    [Types.FETCH_ORDER_POSTBC_DETAILS_INIT]: fetchOrderPostBcDetailsInit,
    [Types.FETCH_ORDER_POSTBC_DETAILS_SUCCESS]: fetchOrderPostBcDetailsSuccess,
    [Types.FETCH_ORDER_POSTBC_DETAILS_FAILURE]: fetchOrderPostBcDetailsFailure,

    [Types.FETCH_ACTIVE_STEP_DATA_INIT]: fetchActiveStepDataInit,
    [Types.FETCH_ACTIVE_STEP_DATA_SUCCESS]: fetchActiveStepDataSuccess,
    [Types.FETCH_ACTIVE_STEP_DATA_FAILURE]: fetchActiveStepDataFailure,
    [Types.RESET_ACTIVE_STEP_DATA]: resetActiveStepData,

    [Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_INIT]: fetchTdAllActiveBookingsInit,
    [Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_SUCCESS]: fetchTdAllActiveBookingsSuccess,
    [Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_FAILURE]: fetchTdAllActiveBookingsFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
