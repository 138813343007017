export default (api) => {
    const fetchCouponDetails = (coupon) => {
        return api.get(`v1/coupon/${coupon}`);
    };

    const fetchTradeInCouponDetails = (token) => {
        return api.get(`/v1/coupon/trade-in`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };
    const getLockedCoupons = (params) => {
        return api.get("v1/gaid/coupon/availability", {
            headers: {},
            params
        });
    };

    const unlockCoupon = ({ userId, gaid }, token) => {
        return api.put(`/v1/gaid/coupon/unlock?gaid=${gaid}&userId=${userId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    return {
        unlockCoupon,
        fetchCouponDetails,
        fetchTradeInCouponDetails,
        getLockedCoupons
    };
};
