/* eslint-disable no-unused-expressions */
import Types from "./types";
import { CheckoutAEService } from "../../../service";

const getMyBookingsInitiated = () => ({
    type: Types.GET_MY_BOOKINGS_INITIATED
});

const getMyBookingsSuccess = (data, params) => ({
    type: Types.GET_MY_BOOKINGS_SUCCESS,
    data,
    params
});

const getMyBookingsFailure = (error) => ({
    type: Types.GET_MY_BOOKINGS_FAILURE,
    error
});

const refreshMyBookingsInitiated = () => ({
    type: Types.REFRESH_MY_BOOKINGS_INITIATED
});

const getMyBookings = (token, params = {}, state = {isRefresh: false}) => (dispatch) => {
    state.isRefresh ? dispatch(refreshMyBookingsInitiated()) : dispatch(getMyBookingsInitiated());
    dispatch(getMyBookingsInitiated());
    CheckoutAEService.getMyBookings(token, params).then((response) => {
        dispatch(getMyBookingsSuccess(response.data, params));
    }).catch((error) => {
        dispatch(getMyBookingsFailure(error));
    });
};

const cancelBookingInitiated = () => ({
    type: Types.BOOKING_CANCELLATION_INITIATED
});

const cancelBookingSuccess = (data) => ({
    type: Types.BOOKING_CANCELLATION_SUCCESS,
    data
});

const cancelBookingFailure = (error) => ({
    type: Types.BOOKING_CANCELLATION_FAILURE,
    error
});

const cancelBooking = (orderId, token) => (dispatch) => {
    dispatch(cancelBookingInitiated());
    return new Promise((resolve, reject) => {
        CheckoutAEService.cancelOrder(orderId, token).then((response) => {
            dispatch(cancelBookingSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(cancelBookingFailure(error));
            reject(error);
        });
    });
};

const updateSelectedCar = (carId) => ({
    type: Types.UPDATE_SELECTED_CAR,
    carId
});

export {
    getMyBookings,
    cancelBooking,
    updateSelectedCar
};
