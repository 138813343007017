import { useState, useEffect, useRef } from "react";
import { NUMBER } from "../constants/app-constants";

const useCollapse = (items, itemClassName) => {
    const [collapsed, setCollapsed] = useState(true);
    const containerRef = useRef(null);
    const [maxElementsPerLine, setMaxElementsPerLine] = useState(0);

    useEffect(() => {
        setMaxElementsPerLine(0);
        const containerWidth = containerRef.current?.clientWidth;
        const elements = containerRef.current?.getElementsByClassName(itemClassName) || [];
        let totalWidth = 0;
        let elementsPerLine = 0;
        let currentCompletedLine = 0;

        for (let i = 0; i < elements.length; i++) {
            totalWidth += elements[i].offsetWidth;

            if (totalWidth > containerWidth) {
                elementsPerLine = i;
                if (currentCompletedLine > 0) {
                    setMaxElementsPerLine(elementsPerLine + NUMBER.TWO);
                    break;
                }
                currentCompletedLine += 1;
            }
        }

    }, [itemClassName, items.length]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const getVisibleItems = () => {
        if (!collapsed || maxElementsPerLine === 0) {
            return items;
        }
        return items.slice(0, maxElementsPerLine);
    };

    const getHiddenItemsCount = () => {
        if (!collapsed || maxElementsPerLine === 0) {
            return 0;
        }
        return items.length - maxElementsPerLine;
    };

    return { toggleCollapsed, getVisibleItems, getHiddenItemsCount, containerRef };
};

export default useCollapse;
