import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MyWishlist from "./component";
import { fetchWishlistedCars, fetchWishlistedCarsList, updateSelectedCar } from "./actions";
import { withRouter } from "react-router-dom";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    myWishlist: {
        wishlistedCars,
        selectedCarId,
        showMyWishlist,
        isFetching,
        config
    },
    location: {
        selectedCity
    },
    user: { mobile, isLoggedIn, isFetched: isUserDetailsFetched, secureToken }
}) => ({
    wishlistedCars,
    selectedCarId,
    showMyWishlist,
    isFetching,
    config,
    selectedCity,
    mobile,
    isLoggedIn,
    isUserDetailsFetched,
    secureToken
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchWishlistedCarsConnect: fetchWishlistedCars,
    updateSelectedCarConnect: updateSelectedCar,
    reloadCarListConnect: reloadCarList,
    fetchWishlistedCarsListConnect: fetchWishlistedCarsList
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyWishlist));
