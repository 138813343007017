/* eslint-disable max-params */

const checkAllOptionStatus = (filterKey, optionKey, optionsLength, selectedFilters) => {
    const currentSelectedFilters = selectedFilters.filter((selectedFilter) => {
        return selectedFilter.filterKey === filterKey && selectedFilter.optionKey === optionKey;
    });

    return currentSelectedFilters.length === optionsLength;
};
export default checkAllOptionStatus;
