import { FILTER_TYPES } from "./constants";

/* eslint-disable max-params */
export const checkIfFilterExists = ({ filterKey, optionKey, subOptionKey, isRangeFilter, filterType}, selectedFilters) => {
    let existingFilter = [];

    if (isRangeFilter && filterType !== FILTER_TYPES.GF) {
        return false;
    } else {
        existingFilter = selectedFilters.filter(selectedFilter => selectedFilter.filterKey === filterKey &&
            (optionKey ? selectedFilter.optionKey === optionKey : true) && ((subOptionKey ? selectedFilter.subOptionKey === subOptionKey : true))
        )[0];
    }
    return !!existingFilter;
};

const addFilter = ({ filterKey, optionKey, subOptionKey, displayText, isRangeFilter, rangeValues, filterType}, selectedFilters) => {
    let updatedAppliedFilters = [
        ...selectedFilters
    ];
    if (!checkIfFilterExists({ filterKey, optionKey, subOptionKey, isRangeFilter, filterType}, selectedFilters)) {
        updatedAppliedFilters = updatedAppliedFilters.concat({
            filterKey,
            ...(optionKey && {optionKey}),
            ...(subOptionKey && {subOptionKey}),
            displayText,
            ...(isRangeFilter && rangeValues && {isRangeFilter, min: rangeValues.min, max: rangeValues.max}),
            ...(filterType && {filterType})
        });
    }
    return updatedAppliedFilters;
};

const removeFilter = ({filterKey, optionKey, subOptionKey}, selectedFilters) => {
    let updatedAppliedFilters = [];
    updatedAppliedFilters = selectedFilters.filter((filter) => {
        const isFilterKeyMatched = filterKey === filter.filterKey;
        const isOptionKeyMatched = optionKey ? optionKey === filter.optionKey : true;
        const isSubOptionKeyMatched = subOptionKey ? subOptionKey === filter.subOptionKey : true;

        return !(isFilterKeyMatched && isOptionKeyMatched && isSubOptionKeyMatched);
    });

    return updatedAppliedFilters;
};

const getUpdatedAppliedFilters = ({ filterKey, optionKey, subOptionKey, isSelected, displayText, isRangeFilter, rangeValues, isSingleEntity, filterType}, selectedFilters) => {
    if (isRangeFilter || isSingleEntity) {
        let updatedFilters = [];
        if (isSelected) {
            updatedFilters = filterType === FILTER_TYPES.GF ? removeFilter({ filterKey, optionKey }, selectedFilters) : removeFilter({ filterKey }, selectedFilters);
            updatedFilters = addFilter({ filterKey, optionKey, subOptionKey, displayText, isRangeFilter, rangeValues, filterType }, updatedFilters);
        } else {
            return removeFilter({filterKey, optionKey, subOptionKey}, selectedFilters);
        }
        return updatedFilters;
    } else if (isSelected) {
        return addFilter({filterKey, optionKey, subOptionKey, displayText, isRangeFilter, rangeValues}, selectedFilters);
    } else {
        return removeFilter({filterKey, optionKey, subOptionKey}, selectedFilters);
    }

};

export const getUpdatedSubFilterOptions = ({filterKey, optionKey, isSelected, selectedFilters, allFilters}) => {
    const selectedFilter = allFilters[filterKey];
    const selectedOption = selectedFilter.options.filter(filterOption => filterOption.key === optionKey)[0] || {};
    const selectedSubFilterOptions = selectedOption.subFilter.options;

    let updatedFilters = [
        ...selectedFilters
    ];

    selectedSubFilterOptions.forEach((subOption) => {
        if (isSelected) {
            updatedFilters = addFilter({filterKey, optionKey, subOptionKey: subOption.key, displayText: subOption.displayText}, updatedFilters);
        } else {
            updatedFilters = removeFilter({filterKey, optionKey, subOptionKey: subOption.key}, updatedFilters);
        }
    });

    return updatedFilters;
};

export default getUpdatedAppliedFilters;
