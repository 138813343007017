import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
const ReactModal = loadable.lib(() => import("react-modal"), { ssr: false });

// Styles
import styles from "./styles.css";

const Modal = ({ children,
  isOpen,
  close,
  contentClass = "",
  overlayClass = "overlayClass",
  onAfterOpen,
  hideOverlay = false,
  hideContent = false
}) => {

  return (
    <ReactModal>
      {({ default: AsyncReactModal }) => (<AsyncReactModal isOpen={isOpen}
        contentLabel="a"
        onRequestClose={close}
        shouldCloseOnOverlayClick={true}
        overlayClassName={`${overlayClass} ${hideOverlay ? `hideOverlay` : ``}`}
        className={`contentClass ${contentClass} ${hideContent ? `hideContent` : ``} `}
        onAfterOpen={onAfterOpen}
        ariaHideApp={false} >
        {children}
      </AsyncReactModal>)}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  contentClass: PropTypes.string,
  overlayClass: PropTypes.string,
  onRequestClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  hideOverlay: PropTypes.bool,
  hideContent: PropTypes.bool
};
export default Modal;
