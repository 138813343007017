import { AE_CITY } from "../../../constants/app-constants";
import { findByKey } from "../../../utils/helpers/find-by-key";
import saveCookie from "../../../utils/helpers/save-cookie";
import Types from "./types";

// eslint-disable-next-line max-params
const updateCity = (city, lat = null, lng = null, hasCitySkip = false) => (dispatch, store) => {
    const cityList = store().config.cityList;
    const selectedCity = findByKey(cityList, "code", city);
    saveCookie(AE_CITY, selectedCity.code);
    return dispatch({
        type: Types.UPDATE_CITY,
        city,
        cityList,
        lat,
        lng,
        hasCitySkip
    });
};

// eslint-disable-next-line max-params
const updateCityWithCode = (cityCode, lat = null, lng = null, hasCitySkip = false, name) => (dispatch, store) => {
    const cityList = store().config.cityList;
    const selectedCity = findByKey(cityList, "code", cityCode);
    saveCookie(AE_CITY, selectedCity.code);
    return dispatch({
        type: Types.UPDATE_CITY_WITH_CODE,
        cityCode,
        cityList,
        lat,
        lng,
        hasCitySkip,
        name: name || selectedCity?.name
    });
};

export {
    updateCity,
    updateCityWithCode
};
