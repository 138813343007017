import React from "react";
import ScrollIcon from "./images/scroll.svg";
import styles from "./styles.css";
import PropTypes from "prop-types";

const ScrollButton = ({ text, onClick }) => {
    return (
        <div className={styles.scrollBtnWrap}>
            <button className={styles.scrollBtn} onClick={onClick}>
                <img src={ScrollIcon} alt={"button icon"} />
                <span>{text}</span>
            </button>
        </div>
    );
};
ScrollButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.string
};

export default ScrollButton;
