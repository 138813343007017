import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BuyCarBreadCrumb from "./component";
import { reloadCarList, updateSSRStatus } from "../../ae.mobile/car-listing/actions";
import { clearAllFilters } from "../filters/actions";

const mapStateToProps = ({
    location: { selectedCity },
    carDetails: { content },
    filters: {
        appliedFilters
    }

}) => {
    return {
        content,
        selectedCity,
        appliedFilters
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters,
    updateSSRStatusConnect: updateSSRStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuyCarBreadCrumb);
