import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarDetails from "./component";
import { fetchSimilarCars, fetchCarDetails, updateCarDetailsSSRStatus } from "./actions";
import { fetchRecentlyViewedCarList } from "../recently-viewed-car-list/actions";
import { fetchFinance, getDeliveryInfo, getOrderDetail, setChargeDetailsList, setOrder, setOrderId, resetCheckoutData } from "../checkout-revamp-v2/action";
import { reloadCarList } from "../car-listing/actions";
import { fetchFilters, clearAllFilters } from "../filters/actions";
import { fetchWishlistedCarsList } from "../my-wishlist/actions";
import { getNumberOfBookings } from "../config-ssr/actions";

const mapStateToProps = ({
    carDetails: {
        content,
        isSSR,
        config: detailConfig,
        galleryProps: {
            isOpen
        },
        similarCars,
        internalLinks,
        config,
        isFetching: isCarDetailsFetching,
        impressionSource,
        error: carDetailError,
        isFetching
    },
    recentlyViewed: {
        content: recentlyViewedList
    },
    location: {
        selectedCity
    },
    checkout,
    config: {
        bookingAllowed,
        userVariant
    },
    user: { mobile },
    filters: {
        isSSR: isFilterSSR, allFilters
    }
}) => ({
    content,
    isSSR,
    recentlyViewedList,
    isGalleryOpen: isOpen,
    similarVehicleCount: (similarCars || []).length,
    selectedCity,
    checkout,
    config,
    internalLinks,
    isCarDetailsFetching,
    impressionSource,
    detailConfig,
    bookingAllowed,
    userVariant,
    mobile,
    carDetailError,
    isFetching,
    isFilterSSR, allFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    fetchSimilarCarsConnect: fetchSimilarCars,
    fetchCarDetailsConnect: fetchCarDetails,
    fetchRecentlyViewedCarListConnect: fetchRecentlyViewedCarList,
    fetchFinanceConnect: fetchFinance,
    getDeliveryInfoConnect: getDeliveryInfo,
    getOrderDetailConnect: getOrderDetail,
    setChargeDetailsListConnect: setChargeDetailsList,
    setOrderIdConnect: setOrderId,
    setOrderConnect: setOrder,
    updateCarDetailsSSRStatusConnect: updateCarDetailsSSRStatus,
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters,
    resetCheckoutDataConnect: resetCheckoutData,
    fetchWishlistedCarsListConnect: fetchWishlistedCarsList,
    getNumberOfBookingsConnect: getNumberOfBookings
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarDetails));
