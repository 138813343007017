/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, {useState } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

// Styles
import styles from "./styles.css";

//Images
import IconStar from "./images/star.svg";
import IconPrev from "./images/thumb-nav-prev.svg";
import IconNext from "./images/thumb-nav-next.svg";
import IconTopHighlights from "./images/top-highlight.svg";

// Components
import Swipeable from "../../shared/swipeable";
import CarDetailOverview from "../car-detail-overview";
const FeaturesAndSpecs = loadable(() => import("../features-and-specs"), { ssr: false });
const CarDetailInspectionReport = loadable(() => import("../car-detail-inspection-report"), { ssr: false });
const LoanTypeBannerDetail = loadable(() => import("../loan-type-banner-detail"), { ssr: false });
const CarDetailHighlightsWithImgV2 = loadable(() => import("../car-detail-highlights"), { ssr: false });
import CarDetailServiceHistory from "../car-detail-service-history";

// Utilities
import { checkCarStatus, getCarName } from "../../../utils/helpers/car-properties.ae";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import spinCarImageListParser from "../../../utils/helpers/spin-car-image-list-parser";
import { neutralizeBack, revivalBack } from "../../../utils/helpers/modal-back-control";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

// Constants
import { GALLERY_TAB_TYPE } from "../car-details/constant";
import { CAR_STATUS, NUMBER, directionConstant } from "../../../constants/app-constants";

const options = {
    disableLastArrows: true,
    enableMouseEvents: true,
    enableArrows: true,
    enableDots: false
};

const CarDetailMainSlider = ({
    content,
    imageHost,
    resolutions,
    setGsGalleryDataConnect,
    togglePriceBenifitsConnect
}) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(1);
    const { spinMedia, carHighlights, warrantyDuration, assortmentCategory, vin: vinNumber, odometerReading, booked, listingActive, inventoryType, assortmentFlag,
    carUsps = [], mainImage, isComingSoon} = content;
    const [showLeftSlider, setShowLeftSlider] = useState(false);
    const { interiorImages, exteriorImages } = spinMedia || {};
    const interiorImagesList = spinCarImageListParser(interiorImages);
    const exteriorImagesList = spinCarImageListParser(exteriorImages);
    const carName = getCarName(content);

    let images = [...exteriorImagesList.map((item) => ({ ...item, type: GALLERY_TAB_TYPE.EXTERIOR })),
    ...interiorImagesList.map((item) => ({ ...item, type: GALLERY_TAB_TYPE.INTERIOR }))];
    images = images.sort((a, b) => a?.order - b?.order);
    const isReservedCar = checkCarStatus({booked, listingActive}, CAR_STATUS.RESERVED);
    const isCarSold = checkCarStatus({ listingActive }, CAR_STATUS.SOLD);

    const carImageUrl = (path, index) => fastlyUrl({
        basePath: imageHost,
        path,
        type: imageTypes.aeCarDetailPreview,
        width: resolutions.carDetails,
        queryParams: index === NUMBER.ZERO && mainImage?.params // Fist image is supped to be the mainImage so apply zoom to 1st image
    });

    const handleScroll = (direction) => {
        let container = "";
        if (document.getElementById("horizontalSliderContainer")) {
            container = document.getElementById("horizontalSliderContainer");
            const scrollAmount = direction === "right" ? 128 : -128;
            container?.scrollBy({
                left: scrollAmount,
                behavior: "smooth"
            });
            container.addEventListener("scroll", () => {
                if (container?.scrollLeft !== 0) {
                    setShowLeftSlider(true);
                } else {
                    setShowLeftSlider(false);
                }
              });

        }
    };

    const onChangeIndexCallback = (index) => {
        trackDesktopCustomEventsAE("galleryClickDetailPage", {
            eventLabel: currentImageIndex > index + 1 ? directionConstant.LEFT : directionConstant.RIGHT
        });
        trackDesktopCustomEventsAE("gallerySwipe", {
            eventLabel: index
        });
        setCurrentImageIndex(index + 1);
        currentImageIndex > index + 1 ? handleScroll("left") : handleScroll("right");
    };

    const onClickImage = ({index, type, imageName, gaEventLabel}) => {
        if (isComingSoon) {
            return;
        }
        const activeTab = type;
        setGsGalleryDataConnect({
            isOpen: true,
            activeTab,
            selectedImageIndex: index,
            activeImageName: imageName
        });
        neutralizeBack(() => {
            setGsGalleryDataConnect({ isOpen: false });
            revivalBack();
        });
        trackDesktopCustomEventsAE("newGalleryClick", {eventLabel: gaEventLabel });
    };

    return (
        <div>
            <Helmet>
                <link rel="preload" href={carImageUrl(images[0]?.path)} as={"image"} type="image/jpg" />
            </Helmet>
            <div styleName={`${isCarSold ? "styles.soldWrap" : ""}`}>
                <div styleName={`styles.imageWrapper`}>
                    {!!images?.length && (
                        <Swipeable options={{...options, index: currentImageIndex - NUMBER.ONE}} onChangeIndexCallback={onChangeIndexCallback}>
                            {images.map((image, index) => {
                                    return (
                                        <div key={index}>
                                            {<img
                                                key={index}
                                                loading={index === NUMBER.ZERO ? "eager" : "lazy"}
                                                src={carImageUrl(image?.path, index)}
                                                styleName={"styles.carImage"}
                                                alt={`${carName}-${image?.label}`}
                                                onClick={() => onClickImage({
                                                    index: carHighlights?.length ?
                                                        (index - NUMBER.ONE < NUMBER.ZERO ? NUMBER.ZERO : index - NUMBER.ONE) : index,
                                                    type: image?.type,
                                                    imageName: image?.label,
                                                    gaEventLabel: "main_gallery"
                                                })}
                                                width={NUMBER.THREE_HUNDRED_SIXTY}
                                                height="465"
                                            />}
                                        </div>
                                    );
                                })}
                            </Swipeable>
                    )}
                    {!!images?.length &&
                        <div onClick={() => onClickImage({
                            index: carHighlights?.length ?
                                (currentImageIndex - NUMBER.ONE < NUMBER.ZERO ? NUMBER.ZERO : currentImageIndex - NUMBER.ONE) : currentImageIndex,
                            type: images[currentImageIndex - NUMBER.ONE]?.type,
                            imageName: images[currentImageIndex - NUMBER.ONE]?.label,
                            gaEventLabel: "main_gallery"
                        })}
                        >
                            {
                                isCarSold ? <p styleName={"styles.soldTag"}>SOLD</p> : (isReservedCar ? <p styleName={"styles.reservedTag"}>RESERVED</p> : (images[currentImageIndex - 1]?.highlightName && !isReservedCar && !isCarSold ?
                                    <span styleName={"styles.labelGrid"}>
                                        <img src={IconStar} width={NUMBER.TWELVE} height={NUMBER.TWELVE} alt={images[currentImageIndex - NUMBER.ONE]?.highlightName} />
                                        {images[currentImageIndex - NUMBER.ONE]?.highlightName}
                                    </span> : null))}
                        </div>
                    }
                    {currentImageIndex === NUMBER.TWO && (carHighlights || []).length > 0 &&  <CarDetailHighlightsWithImgV2 />}
                </div>
                {isComingSoon &&  (
                    <div styleName={"styles.comingSoonStrip"}>
                    We are doing final touches on this car. It will be available soon
                    </div>)
                }
            </div>
            {!isComingSoon && <div styleName={`styles.thumbSliderWrapper ${isCarSold ? "styles.thumbSoldWrap" : ""}`}>
           {showLeftSlider && <div styleName={"styles.prevIcon"} onClick={() => handleScroll("left")}> <img src={IconPrev} width={32} height={40} alt="prev icon"  /></div>}
                <div styleName="styles.horizontalSliderWrapper" id="horizontalSliderContainer" >
                    {images.map((image, index) => {
                        const thumbnailInlineStyle = index === NUMBER.ONE ? { background: `linear-gradient(90.65deg, rgba(3, 59, 93, 0.7) 0.56%, rgba(0, 26, 42, 0.7) 99.44%), url(${carImageUrl(image?.path)})` } : {};
                    return (
                        <div styleName={`styles.imageItem ${currentImageIndex - NUMBER.ONE === index ? "styles.active" : ""} ${index === NUMBER.ONE ? "styles.topHighlightItem" : ""}` } style={thumbnailInlineStyle} key={index} onClick={() => {
                            setCurrentImageIndex(index + 1);
                            onClickImage({
                                index: carHighlights?.length ? (index - NUMBER.ONE < NUMBER.ZERO ? NUMBER.ZERO : index) : index,
                                type: image?.type,
                                imageName: image?.label,
                                gaEventLabel: "lower_tile"
                            });
                        }}>
                            {index !== NUMBER.ONE ?
                                <img
                                    key={index}
                                    src={carImageUrl(image?.path)}
                                    alt={`${carName}-${image?.label}`}
                                    width="120"
                                    height="66"
                                /> :
                                <><img
                                    key={index}
                                    src={IconTopHighlights}
                                    alt={`${carName}-${image?.label}`}
                                    width="32"
                                    height="28"
                                /></>
                            }
                            <p>{ index !== NUMBER.ONE ?  image?.highlightName : "Top Highlights"}</p>
                        </div>
                    );
                })}

                </div>
                <div styleName={"styles.nextIcon"} onClick={() => handleScroll("right")}> <img src={IconNext} width={32} height={40} alt="next icon"  /></div>

            </div>}
            {!isCarSold && <div>
                <CarDetailOverview
                    carHighlights={carHighlights}
                    vinNumber={vinNumber}
                    odometerReading={odometerReading}
                    assortmentCategory={assortmentCategory}
                    inventoryType={inventoryType}
                    warrantyDuration={warrantyDuration}
                    carUsps={carUsps}
                    assortmentFlag={assortmentFlag}
                    togglePriceBenifitsConnect={togglePriceBenifitsConnect}
                />
                <FeaturesAndSpecs />
                <CarDetailInspectionReport />
                <CarDetailServiceHistory />
                <LoanTypeBannerDetail />
            </div>}
        </div>
    );
};

CarDetailMainSlider.propTypes = {
    content: PropTypes.object,
    secureToken: PropTypes.string,
    selectedCity: PropTypes.object,
    imageHost: PropTypes.string,
    isReservedCar: PropTypes.bool,
    getDeliveryInfoConnect: PropTypes.func,
    isWishlistedCar: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    setGsGalleryDataConnect: PropTypes.func,
    onShareClick: PropTypes.func,
    onClick: PropTypes.func,
    resolutions: PropTypes.object,
    activeTab: PropTypes.string,
    isTabFixed: PropTypes.bool,
    handleBackClick: PropTypes.func,
    togglePriceBenifitsConnect: PropTypes.func
};

export default CarDetailMainSlider;
