/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";
// Icons
import TreatIcon from "./images/treat.svg";
// Utils
import { makePriceLabelRoundAE } from "../../../utils/helpers/make-price-label";
import { makeOdometerLabelAE } from "../../../utils/helpers/make-odometer-label";

// Constants
import { NUMBER } from "../../../constants/app-constants";

const CarDetailCard = ({
    carDetails = {},
    homeTdAvailable = true,
    isLoggedIn = false,
    saleLive,
    vehiclePrice = NUMBER.ZERO
}) => {
    const { content, config } = carDetails;
    const { imageHost } = config || {};
    const { year, make, model, odometerReading, engineSize, variant, mainImage = {}, targetPrice, discountAmount, noOfCylinders } = content || {};
    const hasEpicSaleDiscount = saleLive && discountAmount > 0;
    return (
        <div styleName={`styles.carMeta ${!isLoggedIn ? "styles.carMetaLoggedin" : ""}`} className="carCardwhiteBg">
            <div styleName={"styles.carImageWrap"} className="imageWrap">
                <img
                    src={`${imageHost}${mainImage?.path}`}
                    alt={mainImage?.label}
                    width="60"
                    height="60"
                />
            </div>
            <div styleName={"styles.contentWrap"} className="contentWrap">
                <h3>{`${year || ""} ${make || ""} ${model || ""} `}</h3>
                <p>
                    {variant ? `${variant} | ` : ""}
                    {odometerReading ? `${makeOdometerLabelAE(odometerReading)} ${homeTdAvailable ? "|" : ""} ` : ""}
                    {homeTdAvailable && (engineSize || noOfCylinders) ? `${noOfCylinders}cyl ${engineSize} L` : ""}{" "}
                </p>
                {vehiclePrice > NUMBER.ZERO &&
                    <p>
                        <strong>{makePriceLabelRoundAE(vehiclePrice)}</strong>
                        {hasEpicSaleDiscount && <span styleName="styles.saleStrikeOff">{makePriceLabelRoundAE(targetPrice)}</span>}
                    </p>
                }
                {hasEpicSaleDiscount && <p styleName="styles.saleWrapper" className="saleWrapper">
                    <img width={16} height={16} src={TreatIcon} alt="Celebration" />
                    <span>You saved {makePriceLabelRoundAE(discountAmount)} with this SALE</span>
                </p>}
            </div>
        </div>
    );
};

CarDetailCard.propTypes = {
    carDetails: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    saleLive: PropTypes.bool,
    homeTdAvailable: PropTypes.bool,
    vehiclePrice: PropTypes.number
};

export default CarDetailCard;
