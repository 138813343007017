import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Styles
import styles from "./styles.css";

// Constants
import { NUMBER } from "../../../constants/app-constants";
import { iconMap } from "./constant";

const ToastMessage = ({
    toast,
    autoHide = true,
    autoHideTime = NUMBER.THREE_THOUSAND,
    hideToastConnect
}) => {
    const { text = "", toastStyle = "", variant = "SUCCESS" } = toast || {};

    useEffect(() => {
        if (autoHide && text) {
            window.setTimeout(() => {
                hideToastConnect();
            }, autoHideTime);
        }
    });

    const stylesList = [
        "styles.toastWrapper",
        toastStyle ? `styles.${toastStyle}` : ""
    ];
    return (
        <React.Fragment>
            {text && (
                <div styleName={stylesList.filter(Boolean).join(" ")}>
                    {iconMap[variant] && <img src={iconMap[variant]} alt={variant} />}
                    {text}
                </div>
            )}
        </React.Fragment>
    );
};

ToastMessage.propTypes = {
    toast: PropTypes.object,
    autoHide: PropTypes.bool,
    autoHideTime: PropTypes.number,
    hideToastConnect: PropTypes.func,
    match: PropTypes.object
};

export default withRouter(ToastMessage);
