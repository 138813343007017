export default (name) => {
    let returnValue = null;
    try {
        const cookies = document && document.cookie ? document.cookie.split(";") : [];
        cookies.forEach(item => {
            const items = item.trim().split("=");
            if (items[0].includes(name) && items[0].length === name.length) {
                returnValue = item.split("=")[1];
            }
        });
    } catch (e) {
        return null;
    }
    return returnValue;
};
