/* eslint-disable max-statements */
import React, { useState, useEffect, useRef } from "react";
import H1 from "../../shared/h1/component";
import styles from "./styles.css";
import Search from "../../shared/search";
import FaqCategory from "../faq-category";
import FaqContent from "../faq-content";
import CloseButton from "../../shared/icon-cross";
import PropTypes from "prop-types";
import Skeleton from "./skeleton";
import { useLocation } from "react-router-dom";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { IDENTIFIERS } from "../../../constants/app-constants";

const Faq = ({
    content: buyerFaq,
    sellerFaq,
    isFetching,
    fetchFaqConnect
}) => {
    const [activeFaq, setActiveFaq] = useState(null);
    const activeFaqRef = useRef();
    const location = useLocation();
    const {type = IDENTIFIERS.BUY} = parseLocationSearchParams(location.search) || {};
    const content = type === IDENTIFIERS.SELL ? sellerFaq : buyerFaq;
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        if (!content) {
            fetchFaqConnect({type});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (activeFaqRef && activeFaqRef.current) {
            const offset = 80;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = activeFaqRef.current.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }, [activeFaq, activeFaqRef]);

    const searchData = (content || []).map(({ categoryTitle }, index) => ({ label: categoryTitle, value: categoryTitle, index }));
    const onClickSearchItem = (data) => {
        setActiveFaq(data.index);
    };
    const onClickClear = () => {
        setActiveFaq(null);
    };

    return (
        <section>
            <div className={"container"}>
                <div
                    className={"row"}
                    styleName={"styles.headingRow"}>
                    <div className={"col-3"}>
                        <div styleName={"styles.leftCol"}>
                            <H1 text={"FAQs"} />
                            <p styleName={"styles.lastUpdatedText"}>
                                Search topics to find help you need
                            </p>
                            <div className={"clearfix"} />
                            <FaqCategory setActiveFaq={setActiveFaq} content={content || []} />
                            <p styleName={"styles.lastUpdatedText"} />
                        </div>
                    </div>
                    <div className={"col-9"}>
                        {/* <div styleName={"styles.searchBox styles.sticky"}> */}
                        <div styleName={"styles.searchBox"}>
                            <Search key={activeFaq} onChange={onClickSearchItem} data={searchData} placeholder={"Search"} value={searchData[activeFaq]} />
                            {activeFaq && <div
                                styleName={"styles.crossSearch"}>
                                <CloseButton onClickHandler={onClickClear} type="blue" />
                            </div>}
                        </div>
                        { isFetching ? <Skeleton /> : <FaqContent activeFaq={activeFaq} activeFaqRef={activeFaqRef} content={content || []} /> }
                    </div>
                </div>
            </div>
        </section>
    );
};

Faq.propTypes = {
    content: PropTypes.array,
    sellerFaq: PropTypes.array,
    isFetching: PropTypes.bool,
    fetchFaqConnect: PropTypes.func
};

export default Faq;
