import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const BgGradeintStripped = ({ children }) => {
    return (
        <div styleName={"styles.banner"}>
            <div styleName={"styles.striped-bottom"} />
            {children}

        </div>
    );
};

BgGradeintStripped.propTypes = {
    children: PropTypes.any
};

export default BgGradeintStripped;
