import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ToastMessage from "./component";
import { hideToast } from "./actions";

const mapStateToProps = ({
    toast: { toast }
}) => ({
    toast
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    hideToastConnect: hideToast
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage);
