/* eslint-disable max-params */
/* eslint-disable max-statements */
export default (api) => {

    const getConfig = (params) => {
        return api.get(`/v1/config`, {
            headers: {
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const fetchFinanceDetail = (params) => {
        return api.get(`/finance`, { params });
    };

    const getOrderSummary = (appointmentId, token) => {
        return api.get(`/v1/order/${appointmentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const createOrder = (params, token, SOURCE) => {
        return api.post(`/v1/order`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car",
                SOURCE
            }
        });
    };

    const updateFinanceDetail = (order, orderId, token) => {
        return api.put(`/v1/order/${orderId}/finance`, {...order}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updateDeliveryMode = (order, orderId, token) => {
        return api.put(`/v1/order/${orderId}/address`, {...order}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updateBookingType = (orderId, bookingType, token) => {
        return api.put(`/v1/order/${orderId}/booking-type?type=${bookingType}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updateFinancePersonalDetail = (params, orderId, token) => {
        return api.post(`/v1/order/${orderId}/finance/offer`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getPaymentStatus = (orderId, paymentOrderId, token) => {
        return api.get(`/v1/order/${orderId}/payment/${paymentOrderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const initiateOrderPayment = (orderId, payload, token) => {
        return api.put(`/v2/order/${orderId}/payment`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    // Nex: Non-Exclusive
    const initiateNexOrderPayment = ({orderId, bookingType, payload, token, gaId}) => {
        return api.put(`/v2/order/${orderId}/payment?type=${bookingType}${gaId ? `&gaId=${gaId}` : ""}`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    // Ex: Exclusive
    const initiateExOrderPayment = (orderId, payload, token, gaId) => {
        return api.put(`/v3/order/${orderId}/payment?type=EX${gaId ? `&gaId=${gaId}` : ""}`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const initiateBookingPayment = (orderId, payload, token, params) => {
        return api.post(`/v4/order/${orderId}/payment-initiate`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const initiateSeamlessBookingPayment = (orderId, payload, token, params) => {
        return api.post(`/v5/order/${orderId}/payment-initiate`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const updateOrderBooking = (orderId, token) => {
        return api.put(`/v1/order/${orderId}/book`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const cancelOrder = (orderId, token, reason) => {
        return api.put(`/v1/order/${orderId}/cancel`, { reason }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getMyBookings = (token, params) => {
        return api.get(`/v1/order/user/app`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const fetchOrderCancelReasons = (orderId, token) => {
        return api.get(`/v1/order/${orderId}/cancel/reasons`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchOmsUserDetails = (token) => {
        return api.get(`/v1/user/details`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const postOmsUserDetails = (token, isTradeIn = false, params) => {
        return api.post(`/v1/user/details?isTradeIn=${isTradeIn}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                source: params.source
            }
        });
    };

    const getTestDriveLocations = (params, token) => {
        return api.get(`/v2/config/test-drive-location`, {
            headers: {
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car",
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const getBookingCount = (token) => {
        return api.get(`/v1/order/user/booking-allowed`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const initiateBookingChangePayment = ({orderId, payload, token}) => {
        return api.put(`/v1/order/${orderId}/booking-type-payment`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const applyCoupon = (orderId, { couponCode, userId, gaId }, token) => {
        return api.post(`v1/order/${orderId}/coupon`,  { couponCode, userId, gaId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const removeCoupon = (orderId, token, params) => {
        return api.delete(`v1/order/${orderId}/coupon`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            },
            data: {...params}
        });
    };

    const getCouponList = (params) => {
        return api.get("/v1/coupons/all", {
            headers: {
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };
    // Seller C2c
    // Seller center
    const fetchOffers = (appId, orderStatus = "APPROVED", secureToken) => api.get(`/v1/offer/seller/get-c2c-orders?appointmentId=${appId}&panelOrderStates=${orderStatus}&page=0&size=100`, {headers: {
        "Authorization": `Bearer ${secureToken}`,
        X_VEHICLE_TYPE: "car"
     }});

    const acceptOffer = (offerId, payload, secureToken) => api.put(`/v1/offer/seller/accept/${offerId}`, payload, {headers: {
        "Authorization": `Bearer ${secureToken}`,
        X_VEHICLE_TYPE: "car"
     }});

     const notifyBuyer = (appointmentId, orderId, secureToken) => api.put(`/v1/offer/seller/notify-buyer-tds?appointmentId=${appointmentId}&orderId=${orderId}`, {}, {headers: {
        "Authorization": `Bearer ${secureToken}`,
        X_VEHICLE_TYPE: "car"
     }});

     const submitCounterOffer = (offerId, payload, secureToken) => api.put(`/v1/offer/seller/counter/${offerId}`, payload, {headers: { "Authorization": `Bearer ${secureToken}`, X_VEHICLE_TYPE: "car" }});

    const rescheduleC2CCar = (orderId, payload, token) => {
        return api.post(`order-delivery/${orderId}/td-confirmation`, payload, {
                headers: {
                Authorization: `Bearer ${token}`
            }}
        );
    };

    const submitOfferC2c = (params, token, SOURCE) => {
        return api.post(`/v1/offer/buyer`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car",
                SOURCE
            }
        });
    };

    const acceptOfferC2c = (offerId, params, token, SOURCE) => {
        return api.put(`/v1/offer/buyer/accept-revised/${offerId}`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car",
                SOURCE
            }
        });
    };

    return {
        getConfig,
        fetchFinanceDetail,
        updateFinancePersonalDetail,
        getOrderSummary,
        createOrder,
        updateFinanceDetail,
        updateDeliveryMode,
        updateBookingType,
        getPaymentStatus,
        initiateOrderPayment,
        initiateNexOrderPayment,
        initiateExOrderPayment,
        updateOrderBooking,
        cancelOrder,
        getMyBookings,
        fetchOrderCancelReasons,
        fetchOmsUserDetails,
        postOmsUserDetails,
        getTestDriveLocations,
        getBookingCount,
        initiateBookingChangePayment,
        applyCoupon,
        removeCoupon,
        initiateBookingPayment,
        initiateSeamlessBookingPayment,
        getCouponList,
        fetchOffers,
        acceptOffer,
        notifyBuyer,
        submitCounterOffer,
        submitOfferC2c,
        acceptOfferC2c,
        rescheduleC2CCar
    };
};
