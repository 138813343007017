import React from "react";

// Styles
import styles from "./styles.css";

// Images
import internetConnectionIcon from "./images/internet-connection-icon.svg";

// Components
import Button from "../../shared/button";

// Hooks
import { useIsOnline } from "../../../hooks/use-is-online";

const NoInternet = () => {
    const isOnline = useIsOnline();

    const reloadPage = () => {
        if (window !== undefined) {
            const location = window.location;
            location.reload(false);
        }
    };

    return (
        <div styleName={`styles.wrapper ${isOnline ? "" : "styles.noInternet"}`}>
            <img styleName={"styles.mainImg"} src={internetConnectionIcon} alt="" />
            <h3 styleName={"styles.titleText"}>We Lost You...</h3>
            <p styleName={"styles.contentText"}>Slow or no internet connection<br/> Please check your internet settings</p>
            <Button text="Try again" onClick={reloadPage} />
        </div>
    );
};

export default NoInternet;
