import Types from "./types";
import { ListingService } from "../../../service";

const setComparingActive = (isComparingActive) => ({
    type: Types.SET_COMPARING_ACTIVE,
    isComparingActive
});

const setCompareCarsLimitError = (isComparingLimitError) => ({
    type: Types.SET_COMPARE_CAR_LIMIT_ERROR,
    isComparingLimitError
});

const addCarToCompare = ({carDetails, config}) => ({
    type: Types.ADD_CAR_TO_COMPARE,
    carDetails,
    config
});

const removeCarCompare = (appId) => ({
    type: Types.REMOVE_CAR_COMPARE,
    appId
});

const fetchComparingDataInit = () => ({
    type: Types.FETCH_COMPARING_DATA
});

const fetchComparingDataSuccess = (data) => ({
    type: Types.FETCH_COMPARING_DATA_SUCCESS,
    data
});

const fetchComparingDataFailure = (error) => ({ type: Types.FETCH_COMPARING_DATA_FAILURE, error });

const fetchComparingData = (params) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchComparingDataInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchCarComparisonDetails(params, secureToken)
            .then(response => {
                dispatch(fetchComparingDataSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchComparingDataFailure(error));
                reject(error);
            });
    });
};

export {
    setComparingActive,
    addCarToCompare,
    removeCarCompare,
    fetchComparingData,
    setCompareCarsLimitError
};
