/* eslint-disable no-invalid-this */
/* eslint-disable consistent-this */
export default (func, delay) => {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        if (typeof window !== "undefined") {
            window.clearTimeout(debounceTimer);
            debounceTimer = window.setTimeout(() => func.apply(context, args), delay);
        }
    };
};
