import { CHECKOUT_STEP_KEY } from "../../../constants/ae/checkout-constants";

export const GENDER = {
  Male: "Male",
  Female: "Female",
  Other: "Other"
};

const COMMON_TAB = [
  { name: CHECKOUT_STEP_KEY.Financing, index: 1 },
  { name: CHECKOUT_STEP_KEY.Delivery, index: 2 }
];

export const CHECKOUT_STEP_CONTROL_TAB = [
  ...COMMON_TAB,
  { name: CHECKOUT_STEP_KEY.Kyc, index: 4 },
  { name: CHECKOUT_STEP_KEY.ConfirmBooking, index: 5 }
];

export const CHECKOUT_STEP_TAB = [
  ...COMMON_TAB,
  { name: CHECKOUT_STEP_KEY.BookingType, index: 3 },
  { name: CHECKOUT_STEP_KEY.ConfirmBooking, index: 4 }
];

export const STEP_PROGRESS_ITEMS = [
  {
    heading: "Financing",
    subHeading: "Full Price or Finance",
    key: CHECKOUT_STEP_KEY.Financing
  },
  {
    heading: "Delivery or Pick-up",
    subHeading: "Schedule Time and Place",
    key: CHECKOUT_STEP_KEY.Delivery
  },
  {
    heading: "Booking Type",
    subHeading: "Open or Exclusive",
    key: CHECKOUT_STEP_KEY.BookingType
  },
  {
    heading: "Confirm Booking",
    subHeading: "Order Summary",
    key: CHECKOUT_STEP_KEY.ConfirmBooking
  }
];

export const DOCUMENT_TYPE = {
  Adhar: "AADHAAR",
  Pan: "PAN"
};

export const KYC_SCREEN_TYPE = {
  DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
  BOOKING_CONFIRMED: "BOOKING_CONFIRMED"
};

export const SHIPPING_CHARGE_DESCRIPTIONS = "Get your car delivered at selected location";
export const RC_TRANSFER_CHARGE_DESCRIPTIONS = "CARS24 will facilitate the RC transfer of the car";
export const EMI_DEFAULT_STEP = 500;
export const DOWNPAYMENT_DEFAULT_STEP = 5000;
export const CHECKOUT_BOOKING_PAYMENT_AMOUNT_FOR_CONTROL_FLOW = 0;
export const DEFAULT_PROCESSING_FEE = 0.0236;
export const NUMBER_TO_PERCENT_FACTOR = 0.01;
export const NUMBER_FOUR = 4;
export const PINCODE_LENGTH = 6;
export const ACTIVE_PICKUP_INDEX = "ACTIVE_PICKUP_LOCATION_INDEX";
export const SHIPPING_CHARGE_KEY = "shipping_charge";
export const RC_CHARGE_KEY = "rc_charge";
export const FREE_AMOUNT_TAG = "FREE";
