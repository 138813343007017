/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Component

import Button from "../../shared/button";
import withLogin from "../with-login";
import CarDetailCard from "../../shared/car-detail-card";
import OfferConfirmation from "../offer-confirmation";

// Images
import IconBookTestDrive from "./images/book-test-drive.svg";
import IconTestDriveExpert from "./images/test-drive-expert.svg";
import IconTokenPayment from "./images/token-payment.svg";
import { API_SOURCES } from "../../../constants/api-constants";

//Utils
import gaId from "../../../utils/helpers/get-ga-from-cookie";
import { C2C_BOOKING_TYPE} from "../../../constants/ae/checkout-constants";
import { TOAST_VARIANT } from "../../shared/toast-message/constant";
import { NUMBER } from "../../../constants/app-constants";

const CheckoutSteps = () => {
    return (<React.Fragment>
        <div styleName={"styles.flexItem"}>
            <figure>
                <img src={IconBookTestDrive} alt="" />
            </figure>
            <div styleName={"styles.content"}>
                <h3>1.  Book your test drive slot</h3>
                <p>Select your slot in the next screen and we will confirm it with seller</p>
            </div>
        </div>
        <div styleName={"styles.flexItem"}>
            <figure>
                <img src={IconTestDriveExpert} alt="" />
            </figure>
            <div styleName={"styles.content"}>
                <h3>2. Test drive car with our expert</h3>
                <p>A car expert will assist you for test drive & respond to all your queries</p>
            </div>

        </div>
        <div styleName={"styles.flexItem"}>
            <figure>
                <img src={IconTokenPayment} alt="" />
            </figure>
            <div styleName={"styles.content"}>
                <h3>3. Finalise purchase with seller</h3>
                <p>Pay token and let us assist you with registration and delivery</p>
            </div>
        </div>
    </React.Fragment>);
};

const TdSchedulePostBcC2C = ({
    carDetails = {},
    order = {},
    submitCarOfferConnect,
    showToastConnect
}) => {

    const { orderId = "", latestOffer = {} } = order || {};
    const { offerPrice = NUMBER.ZERO, offerId } = latestOffer || {};
    const { content: { price: listingPrice, appointmentId } = {} } = carDetails || {};

    const generateCreateC2cOrderPayload = () => {
        return {
            appointmentId,
            offerPrice,
            offerId,
            source: API_SOURCES.WEBAPP,
            ...(gaId && { gaId }),
            offerType: C2C_BOOKING_TYPE.PRE_BC_SUBMIT_OFFER
        };
    };

    const submitPreBCOfferAcceptance = async () => {
        try {
            const payLoad = generateCreateC2cOrderPayload();
            await submitCarOfferConnect(payLoad);
        } catch (error) {
            showToastConnect({
                variant: TOAST_VARIANT.FAILURE,
                text: "Something went wrong"
            });
        }
    };

    return (
        <Fragment>
            <div styleName={"styles.leftSection"}>
                <OfferConfirmation />
            </div>
            <div styleName={"styles.rightSection"}>
                <div styleName={"styles.bookingId"}>
                    Booking ID : #{orderId}
                </div>
                <div styleName={"styles.carCardWrap"}>
                    <CarDetailCard vehiclePrice={listingPrice} carDetails={carDetails} />
                </div>
                <div styleName={"styles.whatsHappenSectionNew"}>
                    <h2>What happens next ?</h2>
                    <CheckoutSteps />
                </div>
                <div styleName={"styles.buttonWrapper"}>
                    <Button
                        text={"BOOK FREE TEST DRIVE"}
                        onClick={submitPreBCOfferAcceptance}
                    />
                </div>
            </div>
        </Fragment>
    );
};

TdSchedulePostBcC2C.propTypes = {
    match: PropTypes.object,
    carDetails: PropTypes.object,
    order: PropTypes.object,
    fetchCarDetailsConnect: PropTypes.func,
    submitCarOfferConnect: PropTypes.func,
    showToastConnect: PropTypes.func
};

export default withLogin(TdSchedulePostBcC2C);
