import React from "react";

// Images
import IconManufacturer from "./images/manufacturer-warranty.svg";
import IconGreatCondition from "./images/great-condition.svg";
import IconMusicNote from "./images/music-note.svg";
import IconSafety from "./images/safety.svg";
import IconNewTyre from "./images/new-tyres.svg";
import IconPowerPacked from "./images/power-packed.svg";
import IconFuelEfficient from "./images/fuel-efficient.svg";
import IconConvertableTop from "./images/convertable-top.svg";
import IconPanoramicRoof from "./images/panoramic-roof.svg";
import IconLowMileage from "./images/low-mileage.svg";
import IconFourWheelDrive from "./images/four-wheel-drive.svg";
import IconSupercharger from "./images/supercharger.svg";
import IconDoor from "./images/door.svg";
import IconSunRoof from "./images/sun-roof.svg";
import IconMoonroof from "./images/moonroof.svg";
import IconTopSpeed from "./images/top-speed.svg";
import IconFastCharging from "./images/fast_charging.svg";
import IconOnePedalDrive from "./images/one-pedal-driving.svg";
import IconAutoPilot from "./images/auto-pilot.svg";
import IconRegenerativeBrake from "./images/regenerative-brake.svg";
import IconDirectionCars from "./images/directions-car.svg";
import IconServiceHistory from "./images/last-service.svg";
import IconDefault from "./images/default-check-circle.svg";

const ICON_CAR_SPECS = {
    "manufacturer_warranty": IconManufacturer,
    "music_note": IconMusicNote,
    "security": IconManufacturer,
    "safety": IconSafety,
    "directions_car": IconDirectionCars,
    "great_condition": IconGreatCondition,
    "power_packed": IconPowerPacked,
    "new_tyres": IconNewTyre,
    "fuel_efficient": IconFuelEfficient,
    "convertable_top": IconConvertableTop,
    "panoramic_roof": IconPanoramicRoof,
    "low_mileage": IconLowMileage,
    "four_wheel_drive": IconFourWheelDrive,
    "supercharger": IconSupercharger,
    "door": IconDoor,
    "sun_roof": IconSunRoof,
    "moon_roof": IconMoonroof,
    "top_speed": IconTopSpeed,
    "dc_fast_charging": IconFastCharging,
    "one_pedal_drving": IconOnePedalDrive,
    "auto_pilot": IconAutoPilot,
    "regenerative_brake": IconRegenerativeBrake,
    "service_history": IconServiceHistory,
    "default": IconDefault
};

export default ICON_CAR_SPECS;

