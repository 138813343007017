import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import SaleStripDetailPage from "./component";
// Actions
import { fetchCarDetails } from "../car-details/actions";

const mapStateToProps = ({
    config: {
        activeSaleConfig = {}
    },
    carDetails: {
        content: {
            appointmentId
        }
    }
}) => ({
    activeSaleConfig,
    appointmentId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarDetailsConnect: fetchCarDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleStripDetailPage);

