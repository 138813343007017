// Images
import DataDrivenIcon from "./images/data-driven-orange.svg";
import QualityIcon from "./images/quality-orange.svg";
import MrlIcon from "./images/mrl-orange.svg";
import OdometerIcon from "./images/odometer-orange.svg";
import RtaStandardIcon from "./images/rta-standard.svg";
import FreeReturnIcon from "./images/free-return-orange.svg";
import SearchIcon from "./images/search-orange.svg";
import IconEco from "./images/eco.svg";
import IconStructuralDamage from "./images/structural-damage.svg";
import IconFreeWarranty from "./images/free-warranty.svg";
import IconGovtIncentive from "./images/govt-incentive.svg";
import IconEngineSound from "./images/engine-sound.svg";
import IconVerified from "./images/verified.svg";
import IconSecureTransaction from "./images/secure-transaction.svg";
import IconHassleFree from "./images/hassle-free.svg";
import IconLoanAssistance from "./images/loan-assistance.svg";
import IconFloodFree from "./images/flood-free.svg";

export const SECTION_NAME = {
    ZERO_EMISSIONS: "ZERO_EMISSIONS",
    NO_STRUCTURAL_DAMAGE: "NO_STRUCTURAL_DAMAGE",
    FREE_WARRANTY: "FREE_WARRANTY",
    EV_GOVT_INCENTIVE: "EV_GOVT_INCENTIVE",
    MRL_CERTIFIED: "MRL_CERTIFIED",
    NO_ENGINE_SOUND: "NO_ENGINE_SOUND",
    QUALITY_CHECKS: "QUALITY_CHECKS",
    FREE_RETURN: "FREE_RETURN",
    NO_ODOMETER: "NO_ODOMETER",
    RTA_STANDARD: "RTA_STANDARD",
    COMPLETE_TRANSPARENCY: "COMPLETE_TRANSPARENCY",
    DATA_DRIVEN: "DATA_DRIVEN",
    MRL_VERIFIED: "MRL_VERIFIED",
    VERIFIED: "VERIFIED",
    SECURE_TRANSACTIONS: "SECURE_TRANSACTIONS",
    HASSLE_FREE: "HASSLE_FREE",
    LOAN_ASSISTANCE: "LOAN_ASSISTANCE",
    FLOOD_FREE: "FLOOD_FREE"
};

export const FEATURE_ICON = {
    [SECTION_NAME.QUALITY_CHECKS]: QualityIcon,
    [SECTION_NAME.FREE_RETURN]: FreeReturnIcon,
    [SECTION_NAME.DATA_DRIVEN]: DataDrivenIcon,
    [SECTION_NAME.MRL_VERIFIED]: MrlIcon,
    [SECTION_NAME.NO_ODOMETER]: OdometerIcon,
    [SECTION_NAME.RTA_STANDARD]: RtaStandardIcon,
    [SECTION_NAME.COMPLETE_TRANSPARENCY]: SearchIcon,
    [SECTION_NAME.ZERO_EMISSIONS]: IconEco,
    [SECTION_NAME.NO_STRUCTURAL_DAMAGE]: IconStructuralDamage,
    [SECTION_NAME.FREE_WARRANTY]: IconFreeWarranty,
    [SECTION_NAME.EV_GOVT_INCENTIVE]: IconGovtIncentive,
    [SECTION_NAME.NO_ENGINE_SOUND]: IconEngineSound,
    [SECTION_NAME.VERIFIED]: IconVerified,
    [SECTION_NAME.SECURE_TRANSACTIONS]: IconSecureTransaction,
    [SECTION_NAME.HASSLE_FREE]: IconHassleFree,
    [SECTION_NAME.LOAN_ASSISTANCE]: IconLoanAssistance,
    [SECTION_NAME.FLOOD_FREE]: IconFloodFree
};
