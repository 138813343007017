import React from "react";
import PropTypes from "prop-types";

// Component
import withLayout from "../../../components/ae.desktop/layout";
import CarDetails from "../../../components/ae.desktop/car-details";

const CarDetail = () => {
    return <CarDetails />;
};

CarDetail.propTypes = {
    inventoryType: PropTypes.string
};

export default withLayout(CarDetail, true, true, { headerProps: { alternateHeader: true, showCallIcon: true } });
