import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import CarDescriptionSidebar from "../car-description-sidebar";
import CarDetailMainSlider from "../car-detail-main-slider";

const CarDetailAtf = ({
    ctaProps
}) => {

    return (
        <div>
            <div className={"container"}>
                <div styleName={"styles.wrapper"}>
                    <div styleName={"styles.mainSlider"}>
                        <CarDetailMainSlider />
                    </div>
                    <div styleName={"styles.sideBar"}>
                        <CarDescriptionSidebar ctaProps={ctaProps} />
                    </div>
                </div>
            </div>
        </div>
    );
};

CarDetailAtf.propTypes = {
    ctaProps: PropTypes.object
};

export default CarDetailAtf;
