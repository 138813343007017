/* eslint-disable complexity */

import { NUMBER } from "../app-constants";

/* eslint-disable max-statements */
export const ROUTE_PATHS = {
    status: "status",
    testDrive: "test-drive",
    tokenPayment: "token-payment",
    vas: "value-added-services",
    finalPayment: "final-payment",
    documentUpload: "document-upload",
    loanApplication: "loan-application",
    insurancePayment: "insurance-payment",
    carRegistration: "car-registration",
    delivery: "delivery",
    seeMore: "details",
    delivered: "delivered"
};

export const ORDER_STATUS_POST_BC = {
    DELIVERED: "DELIVERED"
};

export const ROUTE_PATHS_KEYS = {
    status: "status",
    "test-drive": "testDrive",
    "token-payment": "tokenPayment",
    "final-payment": "finalPayment",
    "document-upload": "documentUpload",
    "insurance-payment": "insurancePayment",
    "car-registration": "carRegistration",
    delivery: "delivery",
    details: "seeMore"
};

export const POSTBC_BASE_ROUTE = "/booking-confirmation";

export const getPostBcStepUrl = (lid, step, source) => {
    return `${POSTBC_BASE_ROUTE}/${lid}/${ROUTE_PATHS[step]}${source ? `?source=${source}` : ""}`;
};

export const POST_BC_STEPS_FEEDBACK_MAP = {
    testDrive: "test-drive",
    delivery: "delivery"
};

export const POST_BC_STEPS = {
    testDrive: "testDrive",
    tdScheduled: "tdScheduled",
    tdReschedule: "tdReschedule",
    dlUploaded: "dlUploaded",
    tdCompleted: "tdCompleted",
    bookingFeeRefunded: "bookingFeeRefunded",
    tokenPayment: "tokenPayment",
    refurbRequest: "refurbRequest",
    loanPayment: "loanPayment",
    fullPayment: "fullPayment",
    finalPayment: "finalPayment",
    insurancePayment: "insurancePayment",
    carRegistration: "carRegistration",
    documentUpload: "documentUpload",
    delivery: "delivery",
    carRegistrationDone: "carRegistrationDone",
    finalDeliveryScheduled: "finalDeliveryScheduled",
    delivered: "delivered",
    seeMore: "seeMore",
    anchor: "anchor",
    vas: "vas",
    loanApplication: "loanApplication",
    salesAgreement: "salesAgreement",
    carOffer: "carOffer"
};

export const getPostBcActiveStepInfoMessages = ({
    activeTdStep,
    getInTouchTime
} = {}) => {
    let payload = {};
    switch (activeTdStep) {
        case "testDrive": {
            payload = {
                text: `Our representative will get in touch with you to schedule a test drive${getInTouchTime ? ` by ${getInTouchTime}` : ""}.`
            };
            break;
        }
        case "tdScheduled": {
            payload = {
                text: "Please upload your driving license before your test drive."
            };
            break;
        }
        case "dlUploaded": {
            payload = {
                text: "Test Drive Duration of car would be ~30 mins"
            };
            break;
        }
        case "tdCompleted": {
            payload = {
                text: "Our representative will get in touch with you to discuss test drive feedback and next steps"
            };
            break;
        }
        case "tokenPayment": {
            payload = {
                text: "Please pay the token amount to proceed with your booking."
            };
            break;
        }
        case "loanPayment": {
            payload = {
                text: "Please pay the token amount to proceed with your booking."
            };
            break;
        }
        case "fullPayment": {
            payload = {
                text: "Please pay the full amount to proceed with your booking."
            };
            break;
        }
        case "finalPayment": {
            payload = {
                text: "Our representative will get in touch with you in case we need any further information."
            };
            break;
        }
        case "documentUpload": {
            payload = {
                text: "Our team will verify these documents and reach out to you for the next steps."
            };
            break;
        }
        case "delivery": {
            payload = {
                text: "Please wait while we get the car registered in your name."
            };
            break;
        }
        case "carRegistrationDone": {
            payload = {
                text: "Our representative will get in touch with you to schedule final delivery."
            };
            break;
        }
        case "finalDeliveryScheduled": {
            payload = {
                text: "Your dream car is getting ready for delivery!"
            };
            break;
        }
        case "delivered": {
            payload = {
                text: "Please pay the token amount to proceed with your booking."
            };
            break;
        }
        case "vas": {
            payload = {
                text: "Please pay the token amount to proceed with your booking."
            };
            break;
        }
        default: {
            payload = {
                text: "",
                subText: ""
            };
        }
    }
    return payload;
};

export const STEPS_STATUS_TYPES = {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    COMPLETED: "COMPLETED"
};

export const getCompletedStepsProps = ({
    step,
    bookingFee,
    tokenAmount,
    documents,
    paymentMode,
    isFinanceOpted,
    downPayment,
    tdCompleteDate,
    isNonExclusiveBooking,
    PostBookingCompletedStepsFeedback,
    servicePlanList,
    VasPostBookingWarrantyCard
}) => {
    let payload = {};
    switch (step) {
        case "booked": {
            payload = {
                text: "Reservation Confirmed"
            };
            break;
        }
        case "testDrive": {
            payload = {
                text: "Test Drive Completed",
                child: [
                    { text: "Test Drive Completed", subText: tdCompleteDate, imagePath: "testDriveComplete", ExternalComponent: PostBookingCompletedStepsFeedback },
                    ...(isNonExclusiveBooking ? [{ text: "Booking Fee refunded", subText: bookingFee, imagePath: "Refund", isInfo: true, infoType: "BOOKING_INFO" }] : [])
                ],
                ExternalComponent: PostBookingCompletedStepsFeedback
            };
            break;
        }
        case "vas": {
            payload = {
                text: "Value added services",
                child: [{
                    infoProps: Array.isArray(servicePlanList) && servicePlanList,
                    ExternalComponent: VasPostBookingWarrantyCard
                }]
            };
            break;
        }
        case "tokenPayment": {
            payload = {
                text: "Token Amount Paid",
                child: [
                    { text: `Payment Mode : ${paymentMode}`, imagePath: "paymentModeIcon" },
                    { text: "Token Paid Successfully ", subText: `${tokenAmount} (Adjustable in final car price)`, imagePath: "tokenPaidSuccess" }
                ]
            };
            break;
        }
        case "documentUpload": {
            payload = {
                text: "Documents Uploaded",
                child: [
                    {
                        text: "All documents have been verified successfully.", subText: documents
                    }
                ]
            };
            break;
        }
        case "loanApplication": {
            payload = {
                text: "Loan Approved"
            };
            break;
        }
        case "finalPayment": {
            payload = {
                text: "Final Payment Done",
                child: [
                    { text: `Payment Mode : ${paymentMode}`, imagePath: "paymentModeIcon" },
                    ...(isFinanceOpted ? [{ text: "Loan Approved", imagePath: "loanApproved" }] : []),
                    { text: `${isFinanceOpted ? "Balance Down Payment Done" : "Balance Payment Done"}`, subText: downPayment, imagePath: "downPaymentDone" }
                ]
            };
            break;
        }
        case "insurancePayment": {
            payload = {
                text: "Insurance payment done",
                child: [
                    {
                        text: "Insurance payment completed",
                        imagePath: "paymentModeIcon"
                    }
                ]
            };
            break;
        }
        case "carRegistration": {
            payload = {
                text: "Car registration done",
                child: [
                    {
                        text: "Car registered successfully",
                        imagePath: "registrationCard"
                    }
                ]
            };
            break;
        }
        case "delivery": {
            payload = {
                text: "Car Delivered"
            };
            break;
        }
        default: {
            payload = {};
        }
    }
    return payload;
};

export const PENDING_STEP_PROPS = {
    tokenPayment: {
        title: "Token Payment",
        imgId: "Token",
        detail: "Pay token fee to confirm your car purchase and we will exclusively reserve the car for you."
    },
    documentUpload: {
        title: "Document Upload",
        imgId: "Document",
        detail: "Upload required documents and move one step closer to getting your dream car."
    },
    loanApplication: {
        title: "Loan",
        imgId: "Loan",
        detail: "Once documents are approved cars24 finance team will help you finance your dream car."
    },
    finalPayment: {
        title: "Final Payment",
        imgId: "DownPayment",
        detail: "Make the final payment and let us take care of registration."
    },
    loanPayment: {
        title: "Loan Down Payment",
        imgId: "DownPayment",
        detail: "Make the down payment after loan approval and let us take care of registration."
    },
    delivery: {
        title: "Receive your car",
        imgId: "Delivery",
        detail: "Try your car for 7 days and get a full refund if you don’t like it."
    },
    vas: {
        title: "Value added service",
        imgId: "Vas",
        detail: "Choose your warranty, insurance and other value added packages."
    },
    insurancePayment: {
        title: "Insurance",
        imgId: "InsurancePayment",
        detail: "Pay for your selected insurance plan"
    },
    carRegistration: {
        title: "Car registration",
        imgId: "CarRegistration",
        detail: "Cars24 team to assist you in getting your car registered."
    }
};

export const PAYMENT_MODES = {
    FULL_PAYMENT: "FULL_PAYMENT",
    FINANCING: "FINANCING"
};

export const PAYMENT_MODES_TEXT = {
    FULL_PAYMENT: "Full payment",
    FINANCING: "Financing"
};

export const DELIVERY_MODES = {
    HOME_DELIVERY: "HOME_DELIVERY",
    SELF_PICKUP: "PICK_UP"
};

export const PAYMENT_TYPES = {
    PART: "PART",
    FULL: "FULL"
};

export const STEP_SCROLL_VALUE = 40;

export const TRANSACTION_TYPE = {
    TOKEN_PAYMENT: "TOKEN_PAYMENT",
    BOOKING_PAYMENT: "BOOKING_PAYMENT",
    SPLIT_FINAL_PAYMENT_SF: "SPLIT_FINAL_PAYMENT_SF",
    SPLIT_FINAL_PAYMENT: "SPLIT_FINAL_PAYMENT",
    PB_INSURANCE: "PB_INSURANCE"
};

export const POPUP_TYPES = {
    BOOKING_INFO: {
        heading: "Booking Fee Refunded",
        details: [
            "We only hold the booking fee in your account and release it immediately after test drive or in case of cancellation.",
            "Therefore, it might not reflect as a debit or credit entry in your bank statement."
        ]
    },
    BOOKING_TO_BE_REFUNDED: {
        heading: "Booking Fee To Be Refunded",
        details: [
            "We only hold the booking fee in your account and release it immediately after test drive or in case of cancellation.",
            "Therefore, it might not reflect as a debit or credit entry in your bank statement."
        ]
    },
    BOOKING_INFO_PAID: {
        heading: "Booking Fee Paid",
        details: [
            "We only hold the booking fee in your account and release it immediately after test drive or in case of cancellation.",
            "Therefore, it might not reflect as a debit or credit entry in your bank statement."
        ]
    },
    TOKEN_INFO: {
        heading: "What is token amount?",
        // Customised locally to bold words
        details: [
            "A confirmation fee to purchase the car.",
            "Exclusively reserves the car for you for 5 more days.",
            "Fully refundable in case you cancel at any time."
        ]
    },
    TOKEN_REFUND_INFO: {
        heading: "Token Fee",
        details: [
            "We initiate the refund as soon as booking is cancelled or expired.",
            "Refund will take 5-7 working days to reflect in your bank account."
        ]
    },
    OPEN_BOOKING_INFO: {
        heading: "Open Booking",
        details: [
            "It allows multiple customers to test drive the car.",
            "The one who pays token amount first will have exclusive right to purchase the car.",
            "Your booking might get cancelled if another customer exclusively reserve it before you."
        ]
    },
    VAS_WARRANTY_INFO: {
        heading: "Selected Plans",
        details: [],
        isVasWarranty: true
    },
    EXCLUSIVE_BOOKING_INFO: {
        heading: "Exclusive Booking",
        details: [
            "Pay token amount upfront and exclusively book this car for 96 hrs.",
            "This token amount will be adjustable from your final purchase amount."
        ]
    },
    SPLIT_PAYMENT: {
        heading: "Split Payment",
        details: [
            "Service fee, if any, will  also be refunded",
            "We initiate the refund as soon as booking is cancelled or expired.",
            "Refund will take 5-7 working days to reflect in your bank account."
        ]
    },
    EXTRA_AMOUNT_TO_BE_REFUNDED: {
        heading: "Refund to be processed offline",
        details: ["Your refund will be processed offline, please contact the relationship manager for more details"]
    },
    CANCEL_FEE: {
        heading: "Cancellation Fee",
        details: ["100 AED will be charged from token amount in case of booking cancellation or expiry"]
    }
};

export const POPUP_KEYS = {
    BOOKING_INFO: "BOOKING_INFO",
    BOOKING_TO_BE_REFUNDED: "BOOKING_TO_BE_REFUNDED",
    BOOKING_INFO_PAID: "BOOKING_INFO_PAID",
    TOKEN_INFO: "TOKEN_INFO",
    TOKEN_REFUND_INFO: "TOKEN_REFUND_INFO",
    VAS_WARRANTY_INFO: "VAS_WARRANTY_INFO",
    OPEN_BOOKING_INFO: "OPEN_BOOKING_INFO",
    EXCLUSIVE_BOOKING_INFO: "EXCLUSIVE_BOOKING_INFO",
    SPLIT_PAYMENT: "SPLIT_PAYMENT",
    EXTRA_AMOUNT_TO_BE_REFUNDED: "EXTRA_AMOUNT_TO_BE_REFUNDED",
    CANCEL_FEE: "CANCEL_FEE"
};

export const CONTACT_US_PHONE = "+97142909641";
export const CONTACT_US_MAIL = "care.uae@cars24.com";

export const FEEDBACK_TYPE = {
    POST_BC: "post-bc",
    TEST_DRIVE: "test-drive"
};

export const HIDE_FEEDBACK_MODAL_DAYS = 7;

export const POST_BC_POLLING_INTERVAL = 5000;
export const POST_BC_POLLING_THRESHOLD = 3;

export const POST_BC_ERROR_TYPES = {
    ORDER_CANCELLED: "ORDER_CANCELLED",
    ORDER_AUTO_CANCELLED: "ORDER_AUTO_CANCELLED",
    ORDER_EXPIRED: "ORDER_EXPIRED",
    ORDER_NOT_FOUND: "ORDER_NOT_FOUND"
};

export const POST_BC_ERROR_MESSAGES = {
    ORDER_CANCELLED: {
        heading: "Apologies, your booking has been cancelled",
        detail: "You can no longer proceed ahead with this booking",
        ctaText: "Back To Cars"
    },
    ORDER_AUTO_CANCELLED: {
        heading: "Apologies, your booking has been cancelled",
        detail: "We have received exclusive booking on this car. You can no longer proceed ahead with this booking",
        ctaText: "Back To Cars"
    },
    ORDER_EXPIRED: {
        heading: "Apologies, this booking is not valid anymore",
        detail: "You can no longer proceed ahead with this booking",
        ctaText: "Back To Cars"
    }
};

export const TOKEN_TYPES = {
    EX_TOKEN: "EX_TOKEN",
    NEX_TOKEN: "NEX_TOKEN"
};

export const ONBOARDING_GUIDE_INIT_STEP = {
    [POST_BC_STEPS.testDrive]: NUMBER.ZERO,
    [POST_BC_STEPS.tokenPayment]: NUMBER.ONE,
    [POST_BC_STEPS.vas]: NUMBER.TWO,
    [POST_BC_STEPS.documentUpload]: NUMBER.THREE,
    [POST_BC_STEPS.finalPayment]: NUMBER.FOUR,
    [POST_BC_STEPS.delivery]: NUMBER.FIVE,
    [POST_BC_STEPS.delivered]: NUMBER.FIVE,
    [POST_BC_STEPS.carRegistration]: NUMBER.FIVE,
    [POST_BC_STEPS.carRegistrationDone]: NUMBER.FIVE,
    [POST_BC_STEPS.finalDeliveryScheduled]: NUMBER.FIVE
};
