/* eslint-disable max-params */
// Constants
import { AE_LISTING_API_COUNT, COOKIE_CONSTANTS, NUMBER } from "../../constants/app-constants";

// Utils
import saveCookie from "./save-cookie";
import deleteCookie from "./delete-cookie";
import { uniqueStack } from "./unique-stack";

export const getCustomRecommendedCarsPayload = ({
        apptIdsBiButNoBc = [],
        apptIdsSpButNoBi = [],
        wishlistedCarsList = [],
        apptIdsFilterResumeSession = [],
        apptIdsRecentlyViewed = [],
        isRemoveRecentlyViewedCars = false,
        apptListSize = AE_LISTING_API_COUNT
    }) => {

    const biButNoBcCars = [...(apptIdsBiButNoBc || [ ])?.slice(NUMBER, NUMBER.TWENTY)];
    const spButNoBiCars = [...(apptIdsSpButNoBi || [ ])?.slice(NUMBER, NUMBER.TWENTY)];
    const wishlistedCars = [...(wishlistedCarsList || [ ])?.slice(NUMBER, NUMBER.TWENTY)];
    const filterResumeSession = [...(apptIdsFilterResumeSession || [ ])?.slice(NUMBER, NUMBER.TEN)];
    const recentlyViewedCars = [...(apptIdsRecentlyViewed || [ ])?.slice(NUMBER, NUMBER.FIVE)];

    let finalAppointmentIds = [ ...biButNoBcCars, ...spButNoBiCars, ...wishlistedCars, ...filterResumeSession];

    // Remove duplicate from finalAppointmentIds
    finalAppointmentIds = Array.from(new Set(finalAppointmentIds));

    // Remove recently_viewed Id's from final appt list
    if (isRemoveRecentlyViewedCars) {
        finalAppointmentIds = (finalAppointmentIds || [])?.filter((value) => !(recentlyViewedCars || [])?.includes(value));
    }

    return (finalAppointmentIds || [])?.slice(NUMBER.ZERO, apptListSize);
};

export const addToFilterResumeSessionIds = (carList = []) => {
    try {
        const apptIdsFilterResumeSession = (carList || [])?.slice(NUMBER.ZERO, NUMBER.TEN)?.map(({appointmentId = ""}) => appointmentId);
        saveCookie(COOKIE_CONSTANTS.APPT_IDS_FILTER_RESUME_SESSION, JSON.stringify(apptIdsFilterResumeSession), NUMBER.THREE_HUNDRED_SIXTY_FIVE);
    } catch (e) {
        deleteCookie(COOKIE_CONSTANTS.APPT_IDS_FILTER_RESUME_SESSION);
    }
};

export const addToBiButNoBcIds = (apptIdsBiButNoBc = [], apptId = "") => {
    try {
        const updatedApptIdsBiButNoBc = uniqueStack(apptIdsBiButNoBc, NUMBER.TWENTY)(apptId);
        saveCookie(COOKIE_CONSTANTS.APPT_IDS_BI_BUT_NO_BC, JSON.stringify(updatedApptIdsBiButNoBc), NUMBER.THREE_HUNDRED_SIXTY_FIVE);
    } catch (e) {
        deleteCookie(COOKIE_CONSTANTS.APPT_IDS_BI_BUT_NO_BC);
    }
};

export const removeFromBiButNoBcIds = (apptIdsBiButNoBc = [], apptId = "") => {
    try {
        const updatedApptIdsBiButNoBc = (apptIdsBiButNoBc || [])?.filter(itemId => itemId !== apptId);
        saveCookie(COOKIE_CONSTANTS.APPT_IDS_BI_BUT_NO_BC, JSON.stringify(updatedApptIdsBiButNoBc), NUMBER.THREE_HUNDRED_SIXTY_FIVE);
    } catch (e) {
        deleteCookie(COOKIE_CONSTANTS.APPT_IDS_BI_BUT_NO_BC);
    }
};

export const addToSpButNoBiIds = (apptIdsSpButNoBi = [], apptId = "") => {
    try {
        const updatedApptIdsSpButNoBi = uniqueStack(apptIdsSpButNoBi, NUMBER.TWENTY)(apptId);
        saveCookie(COOKIE_CONSTANTS.APPT_IDS_SP_BUT_NO_BI, JSON.stringify(updatedApptIdsSpButNoBi), NUMBER.THREE_HUNDRED_SIXTY_FIVE);
    } catch (e) {
        deleteCookie(COOKIE_CONSTANTS.APPT_IDS_SP_BUT_NO_BI);
    }
};

export const removeFromSpButNoBiIds = (apptIdsSpButNoBi = [], apptId = "") => {
    try {
        const updatedApptIdsSpButNoBi = (apptIdsSpButNoBi || [])?.filter(itemId => itemId !== apptId);
        saveCookie(COOKIE_CONSTANTS.APPT_IDS_SP_BUT_NO_BI, JSON.stringify(updatedApptIdsSpButNoBi), NUMBER.THREE_HUNDRED_SIXTY_FIVE);
    } catch (e) {
        deleteCookie(COOKIE_CONSTANTS.APPT_IDS_SP_BUT_NO_BI);
    }
};
