import React, { useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
const SwipeableViews = loadable(() => import("react-swipeable-views"), { ssr: true });
import { autoPlay } from "react-swipeable-views-utils";
import Pagination from "./pagination";

const AutoSwipeable = autoPlay(SwipeableViews);

const Swipeable = ({ children, options, onChangeIndexCallback = () => {}, initIndex = 0 }) => {
    const [swipeIndex, setSwipeIndex] = useState(initIndex);

    const handleChangeIndex = (index, indexLatest) => {
        setSwipeIndex(index);
        onChangeIndexCallback(index, indexLatest);
    };

    const {
        enableArrows,
        enableDots,
        enablePagination = true,
        arrowColor,
        allActive,
        initialIndex,
        disableLastArrows,
        showAllDots,
        ...swipeOptions
    } = options;
    const snackCardItems = Array.isArray(children) ? children?.flat()?.filter(item => item) : children;
    return (
        <React.Fragment>
            {swipeOptions && swipeOptions.autoplay ?
                <AutoSwipeable index={swipeIndex} onChangeIndex={handleChangeIndex} {...swipeOptions}>
                    {snackCardItems}
                </AutoSwipeable> :
                <SwipeableViews index={initialIndex || swipeIndex} onChangeIndex={handleChangeIndex} {...swipeOptions}>
                    {snackCardItems}
                </SwipeableViews>}
            {enablePagination && (
                <Pagination
                    disableLastArrows={disableLastArrows}
                    showAllDots={showAllDots}
                    enableArrows={enableArrows}
                    enableDots={enableDots}
                    dots={snackCardItems.length}
                    index={swipeIndex}
                    onChangeIndex={handleChangeIndex}
                    arrowColor={arrowColor}
                    allActive={allActive}
                />
            )}
        </React.Fragment>
    );
};

Swipeable.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    options: PropTypes.object,
    onChangeIndexCallback: PropTypes.func,
    initIndex: PropTypes.number
};

export default Swipeable;
