import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { showWishlistNudge } from "../my-wishlist/actions";

// Component
import InventoryCarCard from "./component";

const mapStateToProps = ({
    config: {
        resolutionInfo: {
            desktop: resolutions
        }
    }
}) => ({
    resolutions
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showWishlistNudgeConnect: showWishlistNudge
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCarCard);
