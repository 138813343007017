/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Components
import withLogin from "../with-login";
import PageLoader from "../../shared/page-loader";
const CheckoutNew = loadable(() => import("../checkout-new"), { fallback: <PageLoader /> });
const CheckoutC2C = loadable(() => import("../checkout-info-c2c"), { fallback: <PageLoader /> });

// Constants
import { INVENTORY_TYPE_LABEL } from "../../../constants/app-constants";

const CheckoutWrapper = ({
    secureToken,
    carDetails,
    fetchCarDetailsConnect,
    orderId,
    match: { params: { lid } }
}) => {
    const appointmentId = lid;
    const { content: carDetailsContent} = carDetails || {};

    const { inventoryType } = carDetailsContent || {};

    const isCarC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;

    useEffect(() => {
        if (carDetailsContent?.appointmentId !== appointmentId) {
            fetchCarDetailsConnect(appointmentId, false, "updatedGalleryDesign");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId, orderId, secureToken]);

    return (
        <React.Fragment>
            {isCarC2C ? <CheckoutC2C/> : <CheckoutNew/>}
        </React.Fragment>
    );
};

CheckoutWrapper.propTypes = {
    secureToken: PropTypes.string,
    orderId: PropTypes.string,
    carDetails: PropTypes.object,
    match: PropTypes.any,
    fetchCarDetailsConnect: PropTypes.func
};

export default withLogin(CheckoutWrapper);

