/* eslint-disable max-statements */
import React from "react";
import styles from "./skeleton.css";

const Skeleton = () => {

    return (
        <section>
            <div className="shimmer" styleName={"styles.titleText"} />
            <div styleName={"styles.contentTextOne"}>
                <p className="shimmer" />
                <p className="shimmer" />
                <p className="shimmer" />
                <p className="shimmer" />
                <p className="shimmer" />
                <p className="shimmer" />
                <p className="shimmer" />
            </div>
            <div styleName={"styles.contentTextOne"}>
                <p className="shimmer" />
                <p className="shimmer" />
                <p className="shimmer" />
                <p className="shimmer" />
            </div>
        </section>
    );
};

export default Skeleton;
