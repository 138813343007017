import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_VAS_LIST_INIT
    FETCH_VAS_LIST_SUCCESS
    FETCH_VAS_LIST_FAILURE
    TOGGLE_EMI_POP_UP

    // EMI Details
    GET_EMI_INFO_REQUEST
    GET_EMI_INFO_SUCCESS
    GET_EMI_INFO_FAILURE

    // VAS Config
    FETCH_VAS_CONFIG_INIT
    FETCH_VAS_CONFIG_SUCCESS
    FETCH_VAS_CONFIG_FAILURE

    // BUNDLE
    SET_BUNDLE_INFO
    RESET_BUNDLE_INFO

    // PLANS
    SET_SELECTED_PLANS
    REMOVE_SELECTED_PLANS
    RESET_SELECTED_PLANS
    TOTAL_PLAN_COUNT

    // TINTING
    SET_TINTING_INFO
    RESET_TINTING_INFO

    // WARRANTY
    SET_WARRANTY_INFO
    RESET_WARRANTY_INFO

    // INSURANCE
    SET_INSURED_BUYER_DATA
    SHOW_COMPARE_INSURANCE_WIDGET
    ADD_INSURANCE_TO_COMPARE
    REMOVE_INSURANCE_FROM_COMPARE
    SET_COMPARE_INSURANCE_LIMIT_ERROR
    
    // SERVICE CONTRACT
    SET_TYPES_OF_SERVICES_MODAL_VIEW
    FETCH_SERVICE_DESCRIPTION_INIT
    FETCH_SERVICE_DESCRIPTION_SUCCESS
    FETCH_SERVICE_DESCRIPTION_FAILURE   
     
    FETCH_BUNDLE_LIST_SUCCESS
`,
    {
        prefix: "postbcvas/"
    }
);

