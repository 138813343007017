import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarDetailAtf from "./component";

const mapStateToProps = ({
    carDetails: {
        config,
        content,
        isFetching: isCarDetailsFetching
    }
}) => ({
    config,
    content,
    isCarDetailsFetching
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarDetailAtf);
