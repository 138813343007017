const homeLocale = {
    ["ae"]: {
        "title": "Sell Any Car Online | Sell My Car for Best Price | CARS24",
        "description": "Want to sell any car in UAE? CARS24 offers a simple, hassle-free online platform to sell your car for the best price. Get an instant online valuation now.",
        "h1": "Sell Any Car Online in UAE"
    },
    ["dubai"]: {
        "title": "Sell Any Car Online in Dubai | Sell My Car for Best Price | Cars24",
        "description": "CARS24 is the best place to sell your car in Dubai. We offer a hassle-free way to sell any car and get the best price. Get a free online valuation now",
        "h1": "Sell Any Car Online in Dubai"
    },
    ["abu-dhabi"]: {
        "title": "Sell Any Car Online in Abu Dhabi | Sell My Car for Best Price | Cars24",
        "description": "Sell any car, sell your car, or sell my car in Abu Dhabi with CARS24. Get the best price for your used car and enjoy a hassle-free experience. Sell car online or visit us at our branch in Abu Dhabi.",
        "h1": "Sell Any Car Online in Abu Dhabi"
    }
};
export default homeLocale;
