import React from "react";
import Notify from "./images/notify-orange.svg";
import PropTypes from "prop-types";

const NotifyIcon = ({ mobile, onClick = () => {} }) => {
    let styles = {};
    if (mobile) {
        styles = {
            width: 12,
            height: 12
        };
    }
    return <img src={Notify} style={styles} alt="Notify" onClick={onClick} />;
};

NotifyIcon.propTypes = {
    mobile: PropTypes.any,
    onClick: PropTypes.func
};

export default NotifyIcon;

