import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { LISTING_TYPE, NUMBER } from "../../../constants/app-constants";
import InventoryCarCard from "../inventory-car-card";
import TrackableCarCardItem from "../trackable-car-card-item";
import { carListingEventDataParserGA4 } from "../../../tracking/parser/car-listing-ga4";
import SlickWrapper from "./../../shared/slick-wrapper";
import { SOURCE } from "../inventory-car-card/constant";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const SimilarCarList = ({
    similarCars,
    // appointmentId,
    // fetchSimilarCarsConnect,
    limit = NUMBER.FOUR,
    config,
    mobile
}) => {
    // useEffect(() => {
    //     if (appointmentId) {
    //         fetchSimilarCarsConnect(appointmentId, {size: 20});
    //     }
    // }, [appointmentId]);

    // const onClickCarCardCallback = (index, carObject = {}) => {
    //     onCardClick();
    // };

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrow: true,
        dots: false
    };

    const triggerGA4ViewItemListEvent = (index = 0, carObject = { }) => {
        const dataGA4 = carListingEventDataParserGA4(carObject, {
            position: index + 1,
            filterDimensions: { },
            listingType: LISTING_TYPE.CAR_DETAILS_PAGE
        });
        return {
            name: "ga4ViewItemList",
            data: {
                user_id: mobile,
                ...dataGA4
            }
        };
    };

    const triggerGA4SelectContentEvent = (index, car = { }) => {
        const dataGA4 = carListingEventDataParserGA4(car, {
            position: index + 1,
            filterDimensions: { },
            listingType: LISTING_TYPE.CAR_DETAILS_PAGE
        });
        trackDesktopCustomEventsAE("clearEccomerce");
        trackDesktopCustomEventsAE("ga4SelectContent", {
            user_id: mobile,
            ...dataGA4
        });
    };

    const onClickCallback = (index, car = { }) => {
        triggerGA4SelectContentEvent(index, car);
    };

    return (<div styleName={"styles.carouselWrap"}><SlickWrapper {...settings}>
        {(similarCars || [])
            .slice(0, limit)
            .map((item, index) => (
                <div styleName={"styles.cardWrap"} key={index}>
                    <TrackableCarCardItem
                        eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                        itemIndex={index}
                    >
                        <InventoryCarCard
                            {...item}
                            carData={item}
                            config={config}
                            source={SOURCE.SIMILAR_CARS}
                            onClickCallback={() => onClickCallback(index, item)}
                        />
                    </TrackableCarCardItem>
                </div>
            ))}
    </SlickWrapper></div>);

};

SimilarCarList.propTypes = {
    similarCars: PropTypes.array,
    carDetailsContent: PropTypes.object,
    fetchSimilarCarListConnect: PropTypes.func,
    config: PropTypes.object,
    limit: PropTypes.number,
    mobile: PropTypes.string
};

export default SimilarCarList;
