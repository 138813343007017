import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE
    FETCH_CAR_LIST_TOTAL

    UPDATE_SSR_STATUS
    RESET_CAR_LIST

    RELOAD_CAR_LIST

    FETCH_RECOMMENDED_CAR_LIST
    FETCH_RECOMMENDED_CAR_LIST_SUCCESS
    FETCH_RECOMMENDED_CAR_LIST_FAILURE
    RESET_RECOMMENDED_CAR_LIST
    RELOAD_RECOMMENDED_CAR_LIST
    INCREMENT_SIMILAR_CAR_PAGE_COUNT
    RESET_SIMILAR_CAR_PAGE_COUNT
`,
    {
        prefix: "carlisting/"
    }
);
