/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { DEFAULT_AE_CITY_NAME, GLOBAL_URL, NUMBER } from "../../../constants/app-constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import dashedLowercase from "../../../../client/utils/helpers/dashed-lowercase";
import { getCarName } from "../../../utils/helpers/car-properties.ae";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import Filters from "../../../utils/filters-v2";
import { useHistory, useRouteMatch } from "react-router-dom";

// eslint-disable-next-line complexity
const BuyCarBreadCrumb = ({
    selectedCity = {},
    content = {},
    onClickCallback,
    reloadCarListConnect,
    appliedFilters,
    clearAllFiltersConnect,
    updateSSRStatusConnect
}) => {
    const history = useHistory();
    const match = useRouteMatch();

    const { name, code } = selectedCity;
    const { path, url: pathUrl } = match;
    const isGlobalUaeUrl = (pathUrl === "/buy-used-cars-uae/" || pathUrl === "/buy-used-cars-uae");
    const carName = getCarName(content);
    const { make = "", model = "" } = content;
    const selectedFilters = Array.isArray(appliedFilters) && !!appliedFilters.length && appliedFilters[0];
    const { optionKey = "", subOptionKey = "", min = 0, max = 0, filterKey = "NA", isSubFacetFullySelected = false } = selectedFilters;
    const isDetailsPage = path === "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)";
    const isListingPage = path === "/buy-used-:filterQuery";
    const citySlug = ((name || DEFAULT_AE_CITY_NAME).replace(/ /g, "-")).toLowerCase();

    const trackEvent = (eventLabel) => {
        trackDesktopCustomEventsAE("breadcrumbsDetailClick", {
            eventLabel
        });
    };

    const onLinkClickHandler = (e, type = "", { url }) => {
        e.preventDefault();
        reloadCarListConnect(true);
        trackEvent(url);
        updateSSRStatusConnect(false);
        if (type === "city") {
            clearAllFiltersConnect();
        } else if (type === "globalUrl") {
            clearAllFiltersConnect();
            const filters = new Filters({ cityCode: code, cityName: name });
            const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {}, isGlobalUrl: true, hasCitySkip: selectedCity?.hasCitySkip });
            reloadCarListConnect(true);
            history.push(relativeURL);
            return;
        }
        onClickCallback({ type, url });
    };

    const renderBudgetFilter = () => {
        if (min === 0) {
            return `Under ${max}`;
        } else if (min >= NUMBER.SIXTY_FIVE_THOSAND) {
            return `Above ${min}`;
        } else {
            return `Between ${min}-${max}`;
        }
    };

    const isLisitingPageFilter = isListingPage && selectedFilters;

    const renderBreadCrumList = (listValue) => {
        return (<li className="breadcrumb-item" styleName={"styles.linkTextWrap"}>
            <span styleName={"styles.linkText"}>
                {listValue}
            </span>
        </li>);

    };

    return (
        <div styleName={"styles.wrap"}>
            <nav aria-label="breadcrumb">
                <ul className="breadcrumb" styleName={`${isDetailsPage ? "styles.detailPageBread" : ""}`}>
                    <li className="breadcrumb-item">
                        <a href={`/`} onClick={(e) => onLinkClickHandler(e, "home", { url: "/" })}>Home</a>
                    </li>
                    {isGlobalUaeUrl && renderBreadCrumList(`Used Cars in UAE`)}
                    {!isGlobalUaeUrl && (
                        <React.Fragment>
                            <li className="breadcrumb-item">
                                <a href={GLOBAL_URL}
                                    onClick={(e) => onLinkClickHandler(e, "globalUrl", { url: GLOBAL_URL })}>
                                    {`Used Cars in UAE`}
                                </a>
                            </li>
                            {(!isLisitingPageFilter && !isDetailsPage) ? renderBreadCrumList(`Used Cars In ${name || DEFAULT_AE_CITY_NAME}`) :
                                <li className="breadcrumb-item">
                                    <a href={`/buy-used-cars-${citySlug}/`}
                                        onClick={(e) => onLinkClickHandler(e, "city", { url: `/buy-used-cars-${citySlug}/` })}>
                                        {`Used Cars In ${name || DEFAULT_AE_CITY_NAME}`}
                                    </a>
                                </li>}
                        </React.Fragment>
                    )}

                    {isLisitingPageFilter && ((FILTER_ENTITIES.MAKE === filterKey && optionKey && !subOptionKey) || FILTER_ENTITIES.BODY_TYPE === filterKey) && optionKey &&
                        renderBreadCrumList(`Used ${optionKey} Cars in ${name || DEFAULT_AE_CITY_NAME}`)
                    }
                    {isLisitingPageFilter && FILTER_ENTITIES.MAKE === filterKey && optionKey && subOptionKey && (
                        !isSubFacetFullySelected ?
                            <li className="breadcrumb-item" aria-current="page">
                                <a href={`/buy-used-${dashedLowercase(optionKey)}-cars-${citySlug}/`}
                                    onClick={(e) =>
                                        onLinkClickHandler(e, "make", { url: `/buy-used-${dashedLowercase(optionKey)}-cars-${citySlug}/` })
                                    }>
                                    Used {optionKey} Cars in {name || DEFAULT_AE_CITY_NAME}
                                </a>
                            </li> :
                            renderBreadCrumList(`Used ${optionKey} Cars in ${name || DEFAULT_AE_CITY_NAME}`))
                    }

                    {isLisitingPageFilter && (FILTER_ENTITIES.MAKE === filterKey) && subOptionKey && !isSubFacetFullySelected &&
                        renderBreadCrumList(`Used ${optionKey} ${subOptionKey} Cars in ${name || DEFAULT_AE_CITY_NAME}`)}

                    {isLisitingPageFilter && (FILTER_ENTITIES.PRICE === filterKey) &&
                        renderBreadCrumList(renderBudgetFilter())}

                    {isDetailsPage && <li className="breadcrumb-item" aria-current="page">
                        <a href={`/buy-used-${dashedLowercase(make)}-cars-${citySlug}/`}
                            onClick={(e) =>
                                onLinkClickHandler(e, "make", { url: `/buy-used-${dashedLowercase(make)}-cars-${citySlug}/` })
                            }>
                            Used {make} Cars in {name || DEFAULT_AE_CITY_NAME}
                        </a>
                    </li>}
                    {isDetailsPage && <li className="breadcrumb-item" aria-current="page">
                        <a href={`/buy-used-${dashedLowercase(make)}-${dashedLowercase(model)}-cars-${citySlug}/`}
                            onClick={(e) =>
                                onLinkClickHandler(e, "make", { url: `/buy-used-${dashedLowercase(make)}-${dashedLowercase(model)}-cars-${citySlug}/` })
                            }>
                            Used {model} Cars in {name || DEFAULT_AE_CITY_NAME}
                        </a>
                    </li>}
                    {isDetailsPage && carName &&
                        renderBreadCrumList(`Used ${carName} in ${name || DEFAULT_AE_CITY_NAME}`)}
                </ul>
            </nav>
        </div >
    );
};

BuyCarBreadCrumb.propTypes = {
    selectedCity: PropTypes.object,
    content: PropTypes.object,
    onClickCallback: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    appliedFilters: PropTypes.array,
    clearAllFiltersConnect: PropTypes.func,
    updateSSRStatusConnect: PropTypes.func
};

export default BuyCarBreadCrumb;
