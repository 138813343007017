import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isLoading: false,
    stores: [],
    isError: false
};

export const fetchCarStoresSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isLoading: false,
        isError: false,
        stores: data.store
    };
};

export const fetchCarStoresFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        stores: [],
        isLoading: false,
        isError: true
    };
};

export const fetchCarStoresRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        stores: [],
        isLoading: true,
        isError: false
    };
};

export const HANDLERS = {
    [Types.FETCH_CAR_STORES]: fetchCarStoresRequest,
    [Types.FETCH_CAR_STORES_SUCCESS]: fetchCarStoresSuccess,
    [Types.FETCH_CAR_STORES_FAILURE]: fetchCarStoresFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);

