import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Components
import { NUMBER } from "../../../constants/app-constants";

const TdScheduleHubLocation = ({
    emiratesDetails = {},
    zonesDetails = {},
    setSelectedZoneConnect
}) => {
    const { selectedEmirate } = emiratesDetails || {};
    const { list: zonesList, selectedZone } = zonesDetails || {};
    const { regionCode: selectedEmirateCode } = selectedEmirate || {};
    const { address: selectedZoneAddress = "" } = selectedZone || {};

    useEffect(() => {
        if (selectedEmirateCode && (zonesList || []).length > NUMBER.ZERO) {
            setSelectedZoneConnect(zonesList[NUMBER.ZERO]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEmirateCode, zonesList]);

    return (
        <div styleName={"styles.wrapper"}>
            <h2 styleName={"styles.titleText"}>Hub Address</h2>
            <div styleName={"styles.hubLocationCard"}>
                <p>{selectedZoneAddress}</p>
            </div>
        </div>
    );
};

TdScheduleHubLocation.propTypes = {
    emiratesDetails: PropTypes.object,
    zonesDetails: PropTypes.object,
    setSelectedZoneConnect: PropTypes.func
};

export default TdScheduleHubLocation;
