import React from "react";

// Styles
import styles from "./styles.css";

// Components
import ShareAppLink from "../share-app-link";
import SalesOfferBanerRamadan from "../sales-offer-baner-ramadan";
// import SalesOfferBaner from "../sales-offer-baner";

const SalesOfferListing = () => (
    <div styleName={"styles.sectionWrapper"}>
        <div className="container">
            <div styleName={"styles.bannerWrapper"}>
                <div className="row">
                    <div className="col-6">
                        {/* Old "bumper to bumper" sales
                        <SalesOfferBaner /> */}
                        <SalesOfferBanerRamadan />
                    </div>
                    <div className="col-6">
                        <div styleName={"styles.shareAppLink"}>
                            <ShareAppLink/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default SalesOfferListing;
