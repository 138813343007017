import { NUMBER } from "../../constants/app-constants";

export default (date = new Date(), isAbbr = false, timeZone) => {
    let hour;
    let minute;
    try {
        let hours = Number(new Date(date).toLocaleString("en-IN", { hour: "2-digit", hour12: false, timeZone }));
        minute = Number(new Date(date).toLocaleString("en-IN", { minute: "2-digit", hour12: false, timeZone }));
        const abbr = hours >= NUMBER.TWELVE ? "PM" : "AM";
        hours %= NUMBER.TWELVE;
        hours = hours ? hours : NUMBER.TWELVE; // the hour '0' should be '12'
        hour = `${hours}${isAbbr ? ` ${abbr}` : ""}`;
    } catch (err) {
        hour = "";
    }
    return { hour, minute };
};
