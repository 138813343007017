/* eslint-disable complexity */
/* eslint-disable max-statements */
import parsePopularAll from "../utils/parsers/popular-brands";
import { normalizeKeys } from "../utils/parsers/normalize-keys";

const API_MAKE = /\/make/;
const API_MODEL = /\/model/;

export default (response) => {
    let parsedResponse = response;
    const path = response.config.url;
    if (path.match(API_MAKE)) {
        parsedResponse = normalizeKeys(response.data);
        parsedResponse = parsePopularAll(response.data);
    } else if (path.match(API_MODEL)) {
        parsedResponse = normalizeKeys(response.data, "model");
        parsedResponse = parsePopularAll(response.data);
    }
    return parsedResponse;
};
