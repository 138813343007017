import {FILTER_TYPES } from "./constants";

const uiTypeMap = {
    "STATS": "RANGE",
    "TERMS": "CHECKBOX"
};

const getParsedOptions = (buckets, isRangeFilter) => {
    return buckets.map((bucket) => ({
        key: bucket.name,
        displayText: bucket.name,
        count: bucket.count,
        isSubFacetFullySelected: true,
        ...(
            isRangeFilter && {
                min: bucket.min,
                max: bucket.max
            }
        ),
        ...(bucket.subFacet && {
            subFilter: {
                key: bucket.subFacet.name,
                dispalyText: bucket.subFacet.name,
                count: bucket.subFacet.count,
                options: bucket.subFacet?.buckets?.map((subBucket) => ({
                    key: subBucket.name,
                    displayText: subBucket.name
                }))
            }
        }
        )
    }));
};

const getDefaultFilterSuggestionsData = (filters) => {
    const modifiedFilters = [];
    (filters || []).forEach((filter, index) => {
        const {
            filterType,
            type,
            min,
            max,
            mainFilter,
            buckets,
            isSingleEntity,
            displayName,
            enabledForSeo,
            priority,
            stepRate,
            name
        } = filter;

        modifiedFilters[index] = {
            key: name,
            displayText: displayName,
            filterType,
            enabledForSeo,
            priority,
            mainFilter,
            stepRate,
            uiType: uiTypeMap[type],
            isSingleEntity,
            ...(
                filterType === FILTER_TYPES.RF && {
                    min,
                    max
                }
            ),
            ...(
                buckets && buckets.length && {
                    options: getParsedOptions(buckets)
                }
            )
        };
    });
    return modifiedFilters;
};

export default getDefaultFilterSuggestionsData;
