/* eslint-disable max-depth */
import { NUMBER } from "../../constants/app-constants";

let abortInstance = null;
let otpTimer = null;

const readSMS = async(cb, signal) => {
    if ("OTPCredential" in window) {
        try {
            if (window.navigator.credentials) {
                try {
                    const content = await window.navigator.credentials.get({ abort: signal, otp: { transport: ["sms"]}});
                    if (content && content.code) {
                        cb(content.code);
                    }
                } catch (e) {
                    return;
                }
            }
        } catch (err) {
            return;
        }
    }
};

export const autoReadSMS = (cb) =>  {
    if (abortInstance) {
        abortInstance.abort();
    }
    // used AbortController with setTimeout so that WebOTP API (Autoread sms) will get disabled after 1min
    window.clearTimeout(otpTimer);
    abortInstance = new window.AbortController();
    otpTimer = window.setTimeout(() => {
        abortInstance.abort();
    }, NUMBER.ONE * NUMBER.SIXTY * NUMBER.THOUSAND);

    readSMS(cb, abortInstance);
};
