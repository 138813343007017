import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Components

// Styles
import styles from "./styles.css";

// Images
import FullOption from "./images/full-option.svg";
import LowMileage from "./images/low-mileage.svg";

const LEVER_TYPES = {
    mfgWarranty: {
        text: "Manufacturer warranty",
        img: FullOption
    },
    Offer: {
        text: "Offer",
        img: FullOption
    },
    hotDeals: {
        text: "Hot Deals",
        img: FullOption
    },
    lowMileage: {
        text: "Low Mileage",
        img: LowMileage
    },
    optionsType: {
        text: "Full Option",
        img: FullOption
    },
    discountedCars: {
        text: "AED 5000 OFF",
        img: FullOption
    },
    lighteningDeals: {
        text: "Lightening deals",
        img: FullOption
    }
};

const SalesLever = ({ lever }) => {
    return (
        <Fragment>
            {lever ? (
                <div className={"salesLever"} styleName={"styles.wrapper"}>
                    <div styleName={"styles.iconWrapper"}>
                        <img
                            src={LEVER_TYPES[lever.key]?.img || FullOption}
                            width="12"
                            height="12"
                            alt="Offer"
                        />
                    </div>
                    {lever.secondaryLabel ? (
                        <div styleName={"styles.slideWrapper"}>
                            <span styleName={"styles.text1"}>{lever.label}</span>
                            <span styleName={"styles.text_2"}>{lever.secondaryLabel}</span>
                        </div>
                    ) : <span>{lever.label}</span>}
                </div>
            ) : null}
        </Fragment>
    );
};

SalesLever.propTypes = {
    lever: PropTypes.object
};

export default SalesLever;
