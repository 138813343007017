import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const FaqCategory = ({
    content,
    setActiveFaq
}) => {

    const onClick = (index) => {
        setActiveFaq(index);
    };

    return (
        <div styleName={"styles.questionContainer"}>
            <ul>
                { content.map(({categoryTitle}, index) => <li onClick={() => onClick(index)} key={index}><a rel="canonical">{categoryTitle}</a></li>) }
            </ul>
        </div>

    );
};

FaqCategory.propTypes = {
    content: PropTypes.array,
    setActiveFaq: PropTypes.func
};

export default FaqCategory;
