import React from "react";
import CheckoutInfoV2 from "../../../components/ae.desktop/checkout-info-v2";
import withLayout from "../../../components/ae.desktop/layout";

const Checkout = () => {
    return (
        <CheckoutInfoV2 />
    );
};

export default withLayout(Checkout, true, false);
