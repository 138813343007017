import React from "react";
import styles from "./styles.css";
import BannerImage from "./images/sale-banner-car.png";
import LogoImage from "./images/logo.svg";

const SalesOfferBanerRamadan = () => {
    return (
        <div styleName={"styles.bannerWrapper"}>
            <div>
                <h3>Ramadan Bumper Offer</h3>
                <p>
                Do a test drive in Dubai and stand<br />a chance to win an iPhone daily*
                </p>
                <h4>Only available in <img src={LogoImage} alt="CARS24" width={51} height={23} /> App</h4>
            </div>
            <img styleName={"styles.bannerCar"} src={BannerImage} alt="RAMADAN SALE" />
        </div>
    );
};

export default SalesOfferBanerRamadan;
