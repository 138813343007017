import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_LOAN_ELIGIBILITY
`,
    {
        prefix: "loanEligibility/"
    }
);
