/* eslint-disable max-statements */
/* eslint-disable max-params */
export default (api) => {

    const getFeedback = ({ orderId, feedbackType }, token) => {
        return api.get(`v2/order/${orderId ? `${orderId}/` : ""}feedback/${feedbackType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getUserFeedback = ({ feedbackType }, token) => {
        return api.get(`v2/user/feedback/${feedbackType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getFeedbackOptions = ({ feedbackType }, token) => {
        return api.get(`v2/order/feedback/${feedbackType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const postFeedback = (payload, { orderId, feedbackType }, token) => {
        return api.post(`v2/order/${orderId}/feedback/${feedbackType}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const putFeedback = (payload, { orderId, feedbackType }, token) => {
        return api.put(`v2/order/${orderId}/feedback/${feedbackType}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    // Public Api
    const getClientFeedback = ({ orderId, feedbackType }) => {
        return api.get(`v2/client/order/${orderId ? `${orderId}/` : ""}feedback/${feedbackType}`);
    };

    const getClientFeedbackOptions = ({ feedbackType }) => {
        return api.get(`v2/client/order/feedback/${feedbackType}`);
    };

    const postClientFeedback = (payload, { orderId, feedbackType }) => {
        return api.post(`v2/client/order/${orderId}/feedback/${feedbackType}`, payload);
    };

    const putClientFeedback = (payload, { orderId, feedbackType }) => {
        return api.put(`v2/client/order/${orderId}/feedback/${feedbackType}`, payload);
    };

    // Return/Repair API(s)

    const fetchReturnRepairReason = (retunRepairText, token) => {
        return api.get(`/v1/ticket/questions/${retunRepairText}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const postReturnRepairReason = (userId, orderId, payload, token) => {
        return api.post(`v1/ticket/create/${userId}/${orderId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getReturnAvailableSlots = (orderId, token) => {
        return api.get("v1/ticket/time-slots", {

            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getAllTickets = (orderId, params, token) => {
        return api.get(`v1/ticket/${orderId}/my-tickets`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const getTicket = (ticketId, token) => {
        return api.get(`v1/ticket/id/${ticketId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const postTicket = (payload, ticketId, token) => {
        return api.post(`v1/ticket/${ticketId}/comment`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    // Buyer NPS API(s)
    const getClientNPSQuestions = (feedbackSubType) => {
        return api.get(`v3/client/order/feedback/survey/delivery?feedbackSubCategory=${feedbackSubType}`);
    };

    const postClientNPSFeedback = (payload, { orderId, userId }) => {
        return api.post(`v3/client/order/${orderId}/feedback/delivery?userId=${userId}`, payload);
    };

    const getClientNPSDirtyFeedback = ({ orderId, feedbackSubCategory }) => {
        return api.get(`v3/client/order/${orderId}/feedback/delivery?feedbackSubCategory=${feedbackSubCategory}`);
    };

    // Buyer NPS API(s) V4

    const getClientNPSFeedbackStatusV4 = ({orderId, feedbackType}, token) => {
        return api.get(`v4/order/${orderId}/feedback/${feedbackType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getClientNPSQuestionsV4 = (feedbackType, token) => {
        return api.get(`/v4/order/feedback/${feedbackType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const submitClientFeedbackV4 = ({feedbackType, orderId, payload}, token) => {
        return api.post(`/v4/order/${orderId}/feedback/${feedbackType}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    // Seller API's
    const getFeedbackQuestions = (type) => {
        return api.get(`v3/client/appointment/seller-nps/feedback?feedbackSubCategory=${type}`);
    };

    const getSubmittedFeedback = (appId, type) => {
        return api.get(`v2/client/order/${appId}/feedback/${type}`);
    };

    const submitFeedback = (appId, type, payload) => {
        return api.post(`v2/client/appointment/${appId}/seller-nps/${type}`, payload);
    };

    return {
        // GET
        getFeedback,
        getUserFeedback,
        getClientFeedback,
        getFeedbackOptions,
        getClientFeedbackOptions,
        getTicket,
        fetchReturnRepairReason,
        getReturnAvailableSlots,
        getAllTickets,

        // POST
        postFeedback,
        postClientFeedback,
        postTicket,
        postReturnRepairReason,

        // PUT
        putFeedback,
        putClientFeedback,

        // Buyer NPS API(s)
        getClientNPSQuestions,
        postClientNPSFeedback,
        getClientNPSDirtyFeedback,

        // Buyer NPS API(s) V4
        getClientNPSFeedbackStatusV4,
        getClientNPSQuestionsV4,
        submitClientFeedbackV4,

        // Seller Api's
        getFeedbackQuestions,
        getSubmittedFeedback,
        submitFeedback
    };
};
