import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Skeleton from "./skeleton";

const H1 = (props) => {
    const { text, isLoading } = props;
    let heading = <h1 styleName={"styles.heading"}>{text}</h1>;
    if (isLoading) {
        heading = <Skeleton />;
    }
    return heading;
};

H1.propTypes = {
    text: PropTypes.string,
    isLoading: PropTypes.bool
};

export default H1;
