
import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

//Images
import IconTick from "./images/tick.svg";

import CloseButton from "../../shared/icon-cross";
import Modal from "../../shared/modal";

const CouponDetailsPopup = ({
    couponInfo,
    onClose = () => {}
}) => {

    const { metaContent } = couponInfo || {};
    const { popupTitle, description, termsAndConditions } = metaContent || {};

    return (
        <Modal close={onClose} isOpen={true} overlayClass="bookingOverlay">
            <div styleName="styles.popupWrapper">
                <div styleName="styles.popupHeader">
                    <h2>Coupon Details</h2>
                    <div styleName="styles.closeButton">
                        <CloseButton onClickHandler={onClose} type="orange" />
                    </div>
                </div>

                <div className="d-flex" styleName={"styles.modalContent"}>
                    <div styleName={"styles.couponMainDetail"}>
                        <p>{popupTitle}</p>
                        <p>{description}</p>
                    </div>
                    <div styleName={"styles.couponTnC"}>
                        <h3>Terms and conditions</h3>
                        {(termsAndConditions || []).map((item, index) => {
                            const { title } = item || {};
                            return <p key={index}><img src={IconTick} alt="Tick Icon" width={20} height={20} />{title}</p>;
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

CouponDetailsPopup.propTypes = {
    couponInfo: PropTypes.object,
    onClose: PropTypes.func
};

export default (CouponDetailsPopup);
