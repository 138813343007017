import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Actions
import { fetchActiveStepData, fetchOrderPostBcDetailsV2, fetchPostBookingConfig } from "../post-booking-confirmation/actions";
import { fetchCarDetails } from "../car-details/actions";

// Component
import PostBookingStepsMapper from "./component";

const mapStateToProps = ({
    carDetails,
    postBcDetails,
    user: {
        secureToken,
        isFetched: isUserDetailsFetched
    }
}) => ({
    isUserDetailsFetched,
    carDetails,
    postBcDetails,
    secureToken
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchOrderPostBcDetailsV2Connect: fetchOrderPostBcDetailsV2,
    fetchPostBookingConfigConnect: fetchPostBookingConfig,
    fetchActiveStepDataConnect: fetchActiveStepData,
    fetchCarDetailsConnect: fetchCarDetails
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostBookingStepsMapper));
