/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import SlickWrapper from "../../shared/slick-wrapper";

// Images
import IconPrimeTag from "./images/prime-tag.svg";
import IconGreenTag from "./images/green-tag.svg";
import IconLiteTag from "./images/lite-tag.svg";

// Component
import Login from "../login-revamp";
import withWishlist from "../with-wishlist";
import withLogin from "../with-login";
import IconHeart from "../../shared/icon-heart/component";

// constants
import { ASSORTMENT_CATEGORY_LABEL, CAR_STATUS, FASTLY_BASE_URL, NUMBER } from "../../../constants/app-constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { checkCarStatus, getCarName, getEmiLabel, imageAltTagLabel } from "../../../utils/helpers/car-properties.ae";
import { SOURCES } from "../login/constants";

// Utilities
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { useHistory } from "react-router-dom";

const CarDetailSimilarCarList = ({
    similarCars,
    isWishlistedCar = () => {},
    isLoggedIn,
    showLogin,
    setShowLogin,
    secureToken,
    showWishlistNudgeConnect,
    onClickAddToWishList = () => {}
}) => {

    const history = useHistory();

    const carCategory = {
        [ASSORTMENT_CATEGORY_LABEL.PRIME]: { icon: IconPrimeTag },
        [ASSORTMENT_CATEGORY_LABEL.LITE]: { icon: IconLiteTag },
        [ASSORTMENT_CATEGORY_LABEL.GREEN]: { icon: IconGreenTag }
    };

    const onChangeIndexCallback = () => {
        trackDesktopCustomEventsAE("similarCarSwipe");
    };

    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        arrow: true,
        dots: false,
        afterChange: onChangeIndexCallback
    };
    return (
    <div styleName={"styles.recentlySectionSlider"}>
    <div styleName={"styles.carsRow"}>
        <SlickWrapper {...settings} onChangeIndexCallback={onChangeIndexCallback}>
            {
                (similarCars || [])?.slice(0, NUMBER.TEN)?.map((car, index) => {
                    const { appointmentId, year, make, model, city, mainImage } = car || {};
                    const carName = `${make} ${model}`;
                    const { relativeURL, absoluteURL } = getDetailPageUrl({ year, carName, carId: appointmentId, city, country: "ae" });
                    const carImgSrc = fastlyUrl({ basePath: `${FASTLY_BASE_URL}/`, path: mainImage?.path, type: imageTypes.aeCarDetailPreview, width: 200, queryParams: mainImage?.params });

                    const {
                        listingActive, assortmentCategory, booked, appointmentId: carId
                    } = car || {};

                    const isCarSold = checkCarStatus({ listingActive }, CAR_STATUS.SOLD);
                    const isCarReserved = checkCarStatus({ booked, listingActive }, CAR_STATUS.RESERVED);
                    const isCarSoldOrReserved = isCarSold || isCarReserved;
                    const isShowViewSimilarIcon = isCarSoldOrReserved;

                    const trackAssortmentEvent = () => {
                        const eventName = {
                            [ASSORTMENT_CATEGORY_LABEL.PRIME]: "primeClick",
                            [ASSORTMENT_CATEGORY_LABEL.LITE]: "liteClick",
                            [ASSORTMENT_CATEGORY_LABEL.GREEN]: "greenClick",
                            [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: "privateSellerClick",
                            [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: "privateSellerProClick"
                        };
                        trackDesktopCustomEventsAE(eventName[assortmentCategory], {eventLabel: "listing"});
                    };

                    const preventPropagation = (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    };

                    const onClickWishlist = (e) => {
                        preventPropagation(e);
                        trackDesktopCustomEventsAE("addToWishlist", {
                            eventLabel: "similar_cars"
                        });
                        if (isCarSold) {
                            return;
                        }
                        if (isLoggedIn) {
                            onClickAddToWishList(carId, secureToken, {
                                callback: (shortListed) => {
                                    if (shortListed) {
                                        showWishlistNudgeConnect();
                                    }
                                }
                            });
                        } else {
                            setShowLogin(true);
                        }
                    };

                    const onWishlistLoginSuccess = (cbProps, id) => {
                        onClickAddToWishList(id, cbProps.token, {
                            callback: (shortListed) => {
                                if (shortListed) {
                                    showWishlistNudgeConnect();
                                }
                            }
                        });
                        setShowLogin(false);
                    };

                    const onClickSimilarCar = () => {
                        trackDesktopCustomEventsAE("similarCarClick", {
                            eventLabel: "detail"
                        });
                    };

                    return (
                        <Login key={index} isVisible={showLogin} onClose={() => setShowLogin(false)} onSuccess={(resp) => onWishlistLoginSuccess(resp, carId)} loginFor={SOURCES.ADD_TO_WISHLIST}>
                            <a
                                href={absoluteURL}
                                onClick={() => history.push(relativeURL)}
                                key={index}
                            >
                                <div styleName={"styles.carCardWrap"} key={index} onClick={onClickSimilarCar}>
                                {!isShowViewSimilarIcon && carCategory?.[assortmentCategory] && (
                                <div styleName={"styles.primeTag"} onMouseEnter={trackAssortmentEvent}>
                                    <img width="56" height="16" alt="Tag" src={carCategory?.[assortmentCategory]?.icon} />
                                </div>
                                )}
                                    <span styleName={"styles.carImage"}>
                                        <img src={carImgSrc} alt={imageAltTagLabel(car)} />
                                    </span>
                                    <div styleName={"styles.carDetail"}>
                                        <div>
                                            <p styleName={"styles.carName"}>{getCarName(car)}</p>
                                            <p styleName={"styles.carPrice"}>EMI from {getEmiLabel(car, "")}/mo</p>
                                        </div>
                                        {(!isCarSold) && (
                                            <div styleName={"styles.heartWrap"}>
                                                <IconHeart
                                                    active={isWishlistedCar(carId)}
                                                    onClick={onClickWishlist}
                                                    source="desktopListing"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </a>
                        </Login>
                    );
                })
            }
        </SlickWrapper>
    </div>
</div>
);

};

CarDetailSimilarCarList.propTypes = {
    similarCars: PropTypes.array,
    isWishlistedCar: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    secureToken: PropTypes.string,
    showWishlistNudgeConnect: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    mobile: PropTypes.number
};

export default withLogin(withWishlist(CarDetailSimilarCarList));
