export const SOURCE = {
    MY_BOOKINGS: "MY_BOOKINGS",
    LISTING: "LISTING",
    WISHLIST: "WISHLIST",
    WISHLIST_SIMILAR_CARS: "WISHLIST_SIMILAR_CARS",
    SIMILAR_CARS: "SIMILAR_CARS",
    RECENT_CARS: "RECENT_CARS",
    FEATURED_CARS: "FEATURED_CARS"
};

export const BOOKING_STATE = {
    CANCEL: "CANCEL",
    EXPIRED: "EXPIRED",
    BOOKED: "BOOKED",
    RETURNED: "RETURNED"
};

export const SOURCE_EVENT_DATA = {
    LISTING: {
        eventName: "addToWishlistListingPage",
        addEventLabel: "Add to wishlist - Listing page",
        removeEventLabel: "Remove from wishlist - Listings page"
    },
    SIMILAR_CARS: {
        eventName: "addToWishlistDetailPage",
        eventLabel: "Add to wishlist - Similar car",
        removeEventLabel: "Remove from wishlist - Similar car"
    },
    FEATURED_CARS: {
        eventName: "addToWishlistHomePage",
        eventLabel: "Add to wishlist - Featured car",
        removeEventLabel: "Remove from wishlist - Featured car"
    }
};

export const DISCOUNT_FILTERS = ["discountedCars", "lighteningDeals"];
