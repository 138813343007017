/* eslint-disable complexity */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Components
import Button from "../../shared/button";
// import IconHeart from "../../shared/icon-heart/component";
import withWishlist from "../with-wishlist";
import withLogin from "../with-login";
import Login from "../login";
import TransparentButton from "../../shared/button/transparent-button";

// Tracking
import { trackMobileCustomEventsAE } from "../../../tracking";
import cleverTap from "../../../tracking/clevertap";

// Constants
import { PRIMARY_CTA_TEXT } from "../../../constants/app-constants";

// utils
import { capitalizeFirstLetter } from "../../../utils/helpers/title-to-upperCase";
import { SOURCES } from "../login/constants";
import { getCarPriceLabelWithoutCurrency, getEmiLabelWithoutCurrency } from "../../../utils/helpers/car-properties.ae";

const CarDetailsCtaV2 = ({
    appointmentId,
    carDetails,
    text = PRIMARY_CTA_TEXT.BOOK_FREE_TEST_DRIVE,
    onPrimaryCtaClick,
    isPrimaryCtaDisabled,
    isWishlistedCar,
    onClickAddToWishList,
    showLogin,
    setShowLogin,
    notify,
    showPopularSection,
    showPriceSection
}) => {
    const isCarWishListed = isWishlistedCar(appointmentId);
    const { content } = carDetails;
    const { make, model, shortListCount, showShortListCount, listingActive } = content || {};

    const trackGaEvents = () => {
        if (isCarWishListed) {
            trackMobileCustomEventsAE("removeFromWishlist", {
                eventLabel: "Remove from wishlist - Car details page"
            });
        } else {
            trackMobileCustomEventsAE("addToWishlistDetailPage", {
                eventLabel: "Add to wishlist - Car details page"
            });
        }
    };

    const onLoginSuccess = ({ response: { access_token: token } }) => {
        trackGaEvents();
        onClickAddToWishList(appointmentId, token, {
            callback: (shortListed) => {
                if (shortListed) cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.WISHLISTED_CAR, carDetails);
            }
        });
    };

    const onClose = () => setShowLogin(false);
    const bookTestDriveLoginText = `Please login to book your ${capitalizeFirstLetter(make)} ${capitalizeFirstLetter(model)} test drive`;

    const carPrice = getCarPriceLabelWithoutCurrency(content);
    const emiLabel = getEmiLabelWithoutCurrency(content, "");

    const isCarWishlistedBySomeoneElse = useMemo(() => {
        if (shortListCount > 0 && showShortListCount) {
            if (isCarWishListed && shortListCount === 1) {
                return false;
            }
            return true;
        }
        return false;
    }, [isCarWishListed, shortListCount, showShortListCount]);

    return (
        <Login isVisible={showLogin}
            onSuccess={onLoginSuccess}
            onClose={onClose}
            loginFor={SOURCES.START_PURCHASE}
            loginSource={bookTestDriveLoginText}>
            {!isCarWishListed && <div styleName={"styles.buttonContainer"}>
                {isCarWishlistedBySomeoneElse && !showPopularSection && <p styleName={"styles.wishlistedCar"}>🔥 Popular: Recently {shortListCount} wishlisted this car</p>}
                <div styleName={"styles.flexRow"}>
                    {showPriceSection && (
                        <React.Fragment>
                            <div styleName={"styles.emiAmountWrap"}>
                                <p><small>AED</small>{listingActive ? carPrice : "AED ***"}</p>
                                <span><small>EMI</small>{listingActive ? emiLabel : "EMI ***"}<small>/mo</small></span>
                            </div>
                            <Button
                                styleName={showPriceSection ? "" : "styles.widthFull"}
                                onClick={onPrimaryCtaClick}
                                text={notify && showPriceSection ? "Notify Me" : text}
                                disabled={isPrimaryCtaDisabled}
                            />
                        </React.Fragment>
                    )}
                    {!showPriceSection && <Button
                        styleName={showPriceSection ? "" : "styles.widthFull"}
                        onClick={onPrimaryCtaClick}
                        text={showPriceSection ? PRIMARY_CTA_TEXT.FREE_TEST_DRIVE : text}
                        disabled={isPrimaryCtaDisabled}
                    />}
                </div>
            </div>}
            {isCarWishListed && <div styleName={"styles.buttonContainer"}>
                {notify ? <TransparentButton
                    onClick={onPrimaryCtaClick}
                    text={text}
                    disabled={isPrimaryCtaDisabled}
                /> : <Button styleName={"styles.widthFull"}
                    onClick={onPrimaryCtaClick}
                    text={text}
                    disabled={isPrimaryCtaDisabled}
                />}
            </div>}
        </Login>
    );
};

CarDetailsCtaV2.propTypes = {
    appointmentId: PropTypes.string,
    carDetails: PropTypes.object,
    text: PropTypes.string,
    onPrimaryCtaClick: PropTypes.func,
    isPrimaryCtaDisabled: PropTypes.bool,
    isWishlistedCar: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    notify: PropTypes.bool,
    onNotifyIconClick: PropTypes.func,
    showPopularSection: PropTypes.bool,
    showPriceSection: PropTypes.bool
};

export default withLogin(withWishlist(CarDetailsCtaV2));
