// HC :: HELP CENTER
export default {
    HC_WHATSAPP: "helpcenterWhatsapp",
    HC_HEADER_CLOSE: "helpcenterHeaderClose",
    REFRESH: "refresh",
    REDIRECT: "redirect",
    HEADER_BACK: "headerBack",
    HEADER_CALL: "headerCall",
    CANCEL_BOOKING: "cancelBooking",
    SEE_MORE: "seeMore",
    MODAL: {
        BOOKING_FEE_PAID: "bookingFeePaid",
        BOOKING_FEE_REFUNDED: "bookingFeeRefunded",
        BOOKING_FEE_TO_BE_REFUNDED: "bookingFeeToBeRefunded",
        TOKEN_AMT_INFO: "tokenAmtInfo",
        TOKEN_AMT_REFUNDED: "tokenAmtRefunded",
        OPEN_BOOKING_INFO: "openBookingInfo",
        EXCLUSIVE_BOOKING_INFO: "exclusiveBookingInfo",
        SPLIT_PAYMENT: "splitPayment",
        EXTRA_AMOUNT_TO_BE_REFUNDED: "extraAmountToBeRefunded",
        CANCEL_FEE: "cancelFee"
    },
    POPUP: {
        PAYMENT_SUCCESS: "paymentSuccess"
    },
    OPEN_CAR_DETAIL: "openCarDetail",
    OPEN_URL: "openUrl",
    OPEN_MAP: "openMap",
    OPEN_LISTING: "openListing",
    SIMILAR_CAR_MODAL: "similarCarModal",
    DIY_SELL_ALL_CAR: "diySeeAllCar",
    TD_SCHEDULED: "tdScheduled",
    DEV: "dev",
    SHARE_VIDEO_TD_LINK: "shareLink",
    OPEN_VIDEO_TD_LINK: "meetLinkOpen",
    BVC_DOWNLOAD_REPORT: "bvcDownloadReport",
    WEBVIEW_LOGIN_SUCCESS: "WEBVIEW_LOGIN_SUCCESS"

};

export const APP_NAVIGATIONS = {
    myBookings: "myBookings",
    listing: "listing",
    carDetails: "carDetails",
    home: "home",
    helpCenter: "helpCenter",
    sellerHome: "sellerHome"
};
