/* eslint-disable no-console */
import axios from "axios";
import { captureException } from "@sentry/browser";
const DEBUG = process.env.NODE_ENV === "development2";

const api = axios.create({});

api.interceptors.request.use((config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("✉️ ", config);
    }
    return config;
}, (error) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("✉️ ", error);
    }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("📩 ", response);
    }
    return response;
}, (error) => {
    captureException(error);
    if (error.response && error.response.data) {
        if (DEBUG) {
            // eslint-disable-next-line no-undef
            console.error("Error: ", error.response.data);
        }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("📩 ", error);
    }
    return Promise.reject(error.message);
});

export default api;
