import { useHistory } from "react-router-dom";
import { appUrl } from "../constants/url-constants";

const startsWithString = /(^[a-zA-Z0-9])/;

const sanitizePath = (path = "") => {
    if (startsWithString.test(path)) {
        return `/${path}`;
    }
    return path;
};

export const createLink = (path = "", baseURL = appUrl()) => {
    return {
        relativeUrl: sanitizePath(path),
        absoluteUrl: [baseURL, sanitizePath(path)].join("")
    };
};

const useLink = () => {
    const history = useHistory();

    return {
        createLink: (path, baseURL) => createLink(path, baseURL),
        getLinkProps: (path, { onClickHandler, preventDefault = true } = {}) => {
            const { relativeUrl, absoluteUrl } = createLink(path);
            return {
                href: absoluteUrl,
                onClick: (e) => {
                    if (preventDefault) { e.preventDefault(); }
                    if (onClickHandler && typeof onClickHandler === "function") {
                        onClickHandler(e);
                        return;
                    }
                    history.push(relativeUrl);
                }
            };
        }
    };
};

export default useLink;
