
/* eslint-disable max-params */
export default (api) => {
    const setSellerPrice = (appId, payload, secureToken, unpause) => api.put(`/v1/inventory/control/${appId}/price${unpause ? "?unpause=true" : ""}`, payload, {headers: {
        "Authorization": `Bearer ${secureToken}`,
        accept: "application/json, text/plain, */*"
    }});
    const fetchInventoryData = (appId, secureToken) => api.get(`/v1/inventory/control/${appId}`, {headers: { "Authorization": `Bearer ${secureToken}` }});
    const changeTDLocation = (appId, payload, secureToken) => api.put(`/v1/inventory/control/${appId}/location`, payload, {headers: { "Authorization": `Bearer ${secureToken}` }});
    const getSuccessFee = (appId, listingPrice, secureToken) => api.get(`/v1/inventory/control/${appId}/success-fee?price=${listingPrice}`, {headers: { "Authorization": `Bearer ${secureToken}` }});
    const updateDelistReason = (appId, payload, secureToken) => api.put(`/v1/inventory/control/${appId}/de-list-reason`, payload, {headers: { "Authorization": `Bearer ${secureToken}` }});

    return {
        setSellerPrice,
        fetchInventoryData,
        changeTDLocation,
        getSuccessFee,
        updateDelistReason
    };
};
