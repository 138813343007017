import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import InternalLinkCarDetailCard from "../internal-link-car-detail-card";

// Utils
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";

const InternalLinkCarDetail = ({
    internalLinks = [],
    selectedCityName = "",
    config = {}
}) => {

    const makeLink = internalLinks && internalLinks.find(ilink => ilink.key === "make");
    const makeValue = makeLink?.value;

    const carInternalLinks = internalLinks && internalLinks.map((ilink) => {
        let url = "";

        if (ilink?.key === "model") {
            url = `/buy-used-${dashedLowercase(typeof makeValue === PropTypes.string ? makeValue : makeValue?.toString())}-${dashedLowercase(typeof ilink?.value === PropTypes.string ? ilink?.value : ilink?.value?.toString())}-cars-${dashedLowercase(selectedCityName)}/`;
        } else {
            url = `/buy-used-${dashedLowercase(typeof ilink?.value === PropTypes.string ? ilink?.value : ilink?.value?.toString())}-cars-${dashedLowercase(selectedCityName)}/`;
        }

        return {
            ...ilink,
            label: `${ilink?.label} in ${selectedCityName}`,
            url
        };
    });

    return (
        <ul styleName={"styles.carDetail"}>
            {carInternalLinks && carInternalLinks.map((item, index) =>
            (<li key={`moreCar_${index}`}>
                <InternalLinkCarDetailCard
                    linkLabel={item?.label}
                    carCount={item?.count}
                    link={item?.url}
                    imgSrc={`${config?.imageHost}${item?.path}`}
                />
            </li>))
            }
        </ul>

    );
};

InternalLinkCarDetail.propTypes = {
    internalLinks: PropTypes.array,
    selectedCityName: PropTypes.string,
    config: PropTypes.object
};

export default InternalLinkCarDetail;
