/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect, useRef } from "react";
import Modal from "../../shared/modal";
import styles from "./styles.css";
import CloseButton from "../../shared/icon-cross";
import PropTypes from "prop-types";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { getCarName, getCarPriceLabel, getEmiLabel } from "../../../utils/helpers/car-properties.ae";
import Button from "../../shared/button";
import Login from "../login-revamp";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import withLogin from "../with-login";
// Constants
import { GA_EVENT_SOURCE, INVENTORY_TYPE_LABEL } from "../../../constants/app-constants";
import spinCarImageListParser from "../../../utils/helpers/spin-car-image-list-parser";
import { GALLERY_TAB_TYPE } from "../car-details/constant";
import { SOURCES } from "../login/constants";

const CarGallery = ({
    content,
    selectedImageIndex,
    config,
    setGsGalleryDataConnect,
    onCloseGalleryCallback = () => { },
    ctaProps,
    showLogin,
    setShowLogin,
    resolutions
}) => {
    const {
        inventoryType,
        variant,
        appointmentId,
        spinMedia
    } = content;
    const isC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;
    const { interiorImages, exteriorImages } = spinMedia || {};
    const interiorImagesList = spinCarImageListParser(interiorImages);
    const exteriorImagesList = spinCarImageListParser(exteriorImages);
    let images = [...exteriorImagesList.map((item) => ({ ...item, type: GALLERY_TAB_TYPE.EXTERIOR })),
    ...interiorImagesList.map((item) => ({ ...item, type: GALLERY_TAB_TYPE.INTERIOR }))];
    images = images.sort((a, b) => a?.order - b?.order);

    const carName = getCarName(content);
    const carPrice = getCarPriceLabel(content);
    const carEmiLabel = getEmiLabel(content);

    const imageRef = useRef();
    const onClose = () => {
        onCloseGalleryCallback();
        setGsGalleryDataConnect({
            selectedImageIndex: null,
            isOpen: false
        });
    };

    useEffect(() => {
        if (selectedImageIndex && imageRef) {
            window.setTimeout(() => {
                if (imageRef.current) {
                    imageRef.current.scrollIntoView({
                        behavior: "smooth"
                    });
                }
            });
        }
    }, [selectedImageIndex, imageRef]);

    const {
        onCheckoutButtonClick,
        onLoginSuccessCallback
    } = ctaProps;

    const onClickPurchase = () => {
        trackDesktopCustomEventsAE("startPurchaseDetailPage", {
            appointment_id: appointmentId,
            eventLabel: GA_EVENT_SOURCE.GALLERY_PAGE
        });
        onCheckoutButtonClick(setShowLogin, GA_EVENT_SOURCE.GALLERY_PAGE);
    };

    const onLoginSucess = (cbProps = {}) => {
        onLoginSuccessCallback(setShowLogin, cbProps);
    };

    return (
        <Modal close={onClose} isOpen={true}
            overlayClass="bookingOverlay">
            <div styleName="styles.popupWrapper">
                <div styleName="styles.popupHeader">
                    <div styleName="styles.closeButton">
                        <CloseButton
                            onClickHandler={onClose}
                            type="blue" />
                    </div>
                    <h2 styleName={"styles.heading"}>Image Gallery</h2>
                </div>

                <div className="d-flex" styleName={"styles.modalContent"}>
                    <div styleName="styles.popupImages">
                        {
                            images.map((item, index) => (
                                <div
                                    ref={index === selectedImageIndex ? imageRef : undefined}
                                    key={index}
                                    style={{ minHeight: 250 }}
                                    styleName="styles.carDetailImage">
                                    {!isC2C && <div styleName="styles.textWrap">{item.title}</div>}
                                    <img alt={`${carName}-${item.label}`} src={fastlyUrl({ basePath: config.imageHost, path: item.path, type: imageTypes.desktopCarouselImage, width: resolutions.gallery })} />
                                </div>
                            ))
                        }
                    </div>

                    <div styleName="styles.popupcarDetails">
                        <h4>{carName} {variant || ""}</h4>
                        <div styleName={"styles.emiWrap"}>
                            <span>EMI starts from {carEmiLabel}</span>
                        </div>
                        <h6>
                            {carPrice}
                        </h6>

                        <Login isVisible={showLogin} onSuccess={onLoginSucess} onClose={() => setShowLogin(false)} isCheckoutFlow={true} loginFor={SOURCES.START_PURCHASE}>
                            <Button text={ctaProps.text} onClick={onClickPurchase} disabled={ctaProps.disabled} />
                        </Login>

                    </div>
                </div>
            </div>
        </Modal>
    );
};

CarGallery.propTypes = {
    content: PropTypes.object,
    selectedImageIndex: PropTypes.number,
    config: PropTypes.object,
    setGsGalleryDataConnect: PropTypes.func,
    offerCtaProps: PropTypes.object,
    view360data: PropTypes.object,
    onCloseGalleryCallback: PropTypes.func,
    modalSelectedTab: PropTypes.string,
    images: PropTypes.array,
    ctaProps: PropTypes.object,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    resolutions: PropTypes.object
};

CarGallery.defaultProps = {
    onCloseGalleryCallback: () => { }
};

export default withLogin(CarGallery);
