import { NUMBER } from "../../constants/app-constants";
import cleverTap from "../../tracking/clevertap";
import { fastlyUrl, imageTypes } from "./cdn-image-url";
import spinCarImageListParser from "./spin-car-image-list-parser";

const carDetailClevertapData = (detail = {}, config = {}, cityName) => {
        const { interiorImages, exteriorImages } = detail?.spinMedia || {};
        const imagesList = [...spinCarImageListParser(exteriorImages).slice(NUMBER.ZERO, NUMBER.THREE), ...spinCarImageListParser(interiorImages).slice(NUMBER.ZERO, NUMBER.TWO)];
        const imageHost = config?.imageHost;
        cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.VIEWED_CAR, {content: detail, config});
        cleverTap.desktopCleverTapEvent(cleverTap.mobileConstants.CAR_DETAILS_PAGE, {
            imageHost,
            image1: fastlyUrl({
                    basePath: imageHost,
                    path: imagesList[NUMBER.ZERO]?.path,
                    type: imageTypes.aeCarDetailPreview
                }),
            image2: fastlyUrl({
                basePath: imageHost,
                path: imagesList[NUMBER.ONE]?.path,
                type: imageTypes.aeCarDetailPreview
            }),
            image3: fastlyUrl({
                basePath: imageHost,
                path: imagesList[NUMBER.TWO]?.path,
                type: imageTypes.aeCarDetailPreview
            }),
            image4: fastlyUrl({
                basePath: imageHost,
                path: imagesList[NUMBER.THREE]?.path,
                type: imageTypes.aeCarDetailPreview
            }),
            image5: fastlyUrl({
                basePath: imageHost,
                path: imagesList[NUMBER.FOUR]?.path,
                type: imageTypes.aeCarDetailPreview
            }),
            carDetailsContent: detail,
            cityName
        });
};
export default carDetailClevertapData;
