import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import CheckoutWrapper from "./component";
import { fetchCarDetails } from "../car-details/actions";
import { getNumberOfBookings } from "../config-ssr/actions";
import { fetchUserDetails } from "../trade-in-interest/actions";

const mapStateToProps = ({
    carDetails,
    user: { isFetched: isUserDetailsFetched, isLoggedIn, secureToken },
    checkout,
    postBcDetails: {
        orderId
    }
}) => ({
    isUserDetailsFetched,
    isLoggedIn,
    secureToken,
    carDetails,
    checkout,
    orderId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarDetailsConnect: fetchCarDetails,
    getNumberOfBookingsConnect: getNumberOfBookings,
    fetchUserDetailsConnect: fetchUserDetails

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutWrapper));
