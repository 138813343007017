import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Actions
import { confirmSlotReScheduling, confirmSlotScheduling, fetchSelectedEmirateZones, fetchSlotAvailabilityDetails, setAddress, setSelectedEmirate, setSelectedZone } from "../td-schedule/actions";
import { fetchActiveStepData } from "../post-booking-confirmation/actions";

// Component
import TdScheduleHomeMrl from "./component";

const mapStateToProps = ({
    tdSchedule,
    postBcDetails,
    carDetails
}) => ({
    tdSchedule,
    postBcDetails,
    carDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSlotAvailabilityDetailsConnect: fetchSlotAvailabilityDetails,
    confirmSlotSchedulingConnect: confirmSlotScheduling,
    confirmSlotReSchedulingConnect: confirmSlotReScheduling,
    setSelectedZoneConnect: setSelectedZone,
    fetchSelectedEmirateZonesConnect: fetchSelectedEmirateZones,
    setSelectedEmirateConnect: setSelectedEmirate,
    setAddressConnect: setAddress,
    fetchActiveStepDataConnect: fetchActiveStepData
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TdScheduleHomeMrl));
