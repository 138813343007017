import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

const DEFAULT_PAGE_SIZE = 20;

export const INITIAL_STATE = {
    bookings: {
        cars: [],
        // pageable: {},
        totalElements: null,
        totalPages: null,
        pageSize: DEFAULT_PAGE_SIZE,
        currentPage: 0
    },
    myBookingError: null,
    myBookingsFetched: false,
    isFetching: false,
    selectedCarId: "",
    isCancelBookingLoading: false
};

export const getMyBookingsInitiated = (state = INITIAL_STATE) => {
    return { ...state, isFetching: true};
};

export const getMyBookingsSuccess = (state = INITIAL_STATE, { data, params }) => {
    const newCars = data.data || [];
    return {
        ...state,
        bookings: {
            cars: params.page === 0 ? newCars : [...state.bookings.cars, ...newCars],
            // pageable: data.pageable,
            totalElements: data.totalOrder,
            totalPages: Math.ceil(data.totalOrder / DEFAULT_PAGE_SIZE),
            currentPage: params.page,
            pageSize: DEFAULT_PAGE_SIZE
        },
        myBookingsFetched: true,
        isFetching: false,
        myBookingError: null
    };
};

export const getMyBookingsFailure = (state = INITIAL_STATE, {error}) => {
    return { ...state, myBookingError: error, isFetching: false, myBookingsFetched: true};
};

export const updateSelectedCar = (state = INITIAL_STATE, {carId}) => {
    return { ...state, selectedCarId: carId};
};

const bookingCancelInitiated = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCancelBookingLoading: true
    };
};

const bookingCancelSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCancelBookingLoading: false
    };
};

const bookingCancelFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCancelBookingLoading: false
    };
};

export const HANDLERS = {
    [Types.GET_MY_BOOKINGS_INITIATED]: getMyBookingsInitiated,
    [Types.GET_MY_BOOKINGS_SUCCESS]: getMyBookingsSuccess,
    [Types.GET_MY_BOOKINGS_FAILURE]: getMyBookingsFailure,
    [Types.UPDATE_SELECTED_CAR]: updateSelectedCar,
    [Types.BOOKING_CANCELLATION_INITIATED]: bookingCancelInitiated,
    [Types.BOOKING_CANCELLATION_SUCCESS]: bookingCancelSuccess,
    [Types.BOOKING_CANCELLATION_FAILURE]: bookingCancelFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
