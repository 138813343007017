/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToast } from "../../shared/toast-message/actions";
import { TOAST_MESSAGE, TOAST_VARIANT } from "../../shared/toast-message/constant";
import { setTradeInChoice } from "../checkout-revamp-v2/action";
import { postUserDetails } from "../trade-in-interest/actions";
import withLogin from "../with-login";
import Login from "../login-revamp";
import ToastMessage from "../../shared/toast-message";

export default function withCarExchange(Component) {
    const WithCarExchange = (props) => {
        const {
            isLoggedIn,
            secureToken,
            showLogin,
            setShowLogin,
            showToastConnect,
            setTradeInChoiceConnect,
            postUserDetailsConnect,
            tradeInChoice,
            name
        } = props;

        const [tradeInDone, setTradeInDone] = React.useState(false);
        const onProceed = async (token) => {
            try {
                const params = {
                    source: "Buyer_website"
                };
                if (tradeInChoice === null && !tradeInDone) {

                    await postUserDetailsConnect(token, true, params);
                    showToastConnect({
                        variant: TOAST_VARIANT.SUCCESS,
                        text: TOAST_MESSAGE.sellCarListingBanner
                    });
                    setTradeInDone(true);
                    if (name) {
                        setTradeInChoiceConnect(true);
                    }
                } else {
                    showToastConnect({
                        variant: TOAST_VARIANT.SUCCESS,
                        text: TOAST_MESSAGE.alreadyRegisteredBanner
                    });
                    setTradeInChoiceConnect(true);
                }
                setShowLogin(false);
                // eslint-disable-next-line no-empty
            } catch (error) {
            }
        };

        const onLoginSuccess = ({ token = secureToken }) => {
            onProceed(token);
        };

        const handleTradeInClick = () => {
            if (isLoggedIn) {
                onProceed(secureToken);
            } else {
                setShowLogin(true);
            }
        };

        return (
            <Login
                isVisible={showLogin}
                onClose={() => setShowLogin(false)}
                onSuccess={onLoginSuccess}
            >
                <Component
                    {...props}
                    handleTradeInClick={handleTradeInClick}
                />
                <ToastMessage />
            </Login>
        );
    };

    WithCarExchange.propTypes = {
        isLoggedIn: PropTypes.bool,
        secureToken: PropTypes.string,
        showLogin: PropTypes.bool,
        setShowLogin: PropTypes.func,
        showToastConnect: PropTypes.func,
        setTradeInChoiceConnect: PropTypes.func,
        postUserDetailsConnect: PropTypes.func,
        tradeInChoice: PropTypes.bool,
        name: PropTypes.string
    };

    const mapStateToProps = ({
        checkout: {
            tradeInChoice
        },
        user: {
            name
        }
    }) => ({
        tradeInChoice,
        name
    });

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        showToastConnect: showToast,
        setTradeInChoiceConnect: setTradeInChoice,
        postUserDetailsConnect: postUserDetails
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(
        withLogin(WithCarExchange)
    );
}
