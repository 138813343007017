import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchFaq } from "../faq/actions";
import ConfirmationFaq from "./component";

const mapStateToProps = ({
    faq: {
        homePageFaq
    }
}) => ({
    homePageFaq
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFaqConnect: fetchFaq
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationFaq);
