import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_FAQ
    FETCH_FAQ_SUCCESS
    FETCH_FAQ_FAILURE
`,
    {
        prefix: "faq/"
    }
);
