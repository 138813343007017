import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import errorIcon from "./images/error-icon.svg";

// Components
import Button from "../../shared/button";

const NoApiResult = ({ showErrorScreen, setShowErrorScreen }) => {

    const handleReTry = () => {
        setShowErrorScreen(false);
        if (window !== undefined) {
            const location = window.location;
            location.reload(false);
        }
    };

    return (
        <div styleName={`styles.wrapper ${showErrorScreen ? "styles.visible" : ""}`}>
           <img styleName={"styles.statusIcon"} src={errorIcon} alt=""/>
           <h3 styleName={"styles.titleText"}>Ugh... System Error!</h3>
           <p styleName={"styles.contentText"}>Something went wrong at our end. <br/>Don’t worry, it’s not you - it’s us.</p>
           <Button text="Try again" onClick={handleReTry} />
        </div>
    );
};

NoApiResult.propTypes = {
    showErrorScreen: PropTypes.bool,
    setShowErrorScreen: PropTypes.func
};

export default NoApiResult;
