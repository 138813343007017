import { CloudService } from "../../../service";

const sendAppLinkToUser = (payload) => () => {
    return new Promise((resolve, reject) => {
        CloudService.sendAppLink(payload)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => reject(err));
    });
};

export {
    sendAppLinkToUser
};
