import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SETUSER_PERSONAL_DETAIL
    SET_CURRENT_STEP
    SET_SELECTED_FINANCING_DATA
    SET_FINANCE_DATA
    SET_LOAN_TENURE_VALUE
    SET_CREDIT_SCORE_VALUE
    SET_DOWNPAYMENT_VALUE
    SET_MONTHLY_EMI_VALUE
    SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE
    SET_ORDER_CREATED_DATE_TIME
    SET_DELIVERY_MODE
    SET_FINANCING_TAB
    SET_MIN_MAX_DOWNPAYMENT_VALUE
    SET_MIN_MAX_EMI_VALUE
    SET_FINANCE_SELECTED
    SET_ORDER_ID
    SET_ORDER
    
    SET_FINANCE_UPDATED_DATA
    SET_DELIVERY_DATA

    GET_DELIVERY_INFO_REQUEST
    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE

    SET_FINANCE_SCREEN
    UPDATE_DELIVERY_MODE_SUCCESS
    UPDATE_DELIVERY_MODE_FAILURE

    UPDATE_BOOKING_TYPE_SUCCESS
    UPDATE_BOOKING_TYPE_FAILURE

    CREATE_ORDER_SUCCESS
    CREATE_ORDER_FAILURE

    SET_CONFIRM_BOOKING_DATA

    UPDATE_VISITED_STEPS

    SET_FINANCE_CHARGE_LIST
    SET_CHARGE_LIST

    GET_ORDER_DETAIL_SUCCESS
    GET_ORDER_DETAIL_FAILURE
    GET_ORDER_DETAIL_INIT
    TRADE_IN_CHOICE
    SET_TEST_DRIVE_MODE
    SET_SELECTED_DELIVERY_INFO

    SET_CHANGE_TD_LOCATION

    SUBMIT_CAR_OFFER_INIT
    SUBMIT_CAR_OFFER_SUCCESS
    SUBMIT_CAR_OFFER_FAILURE

    ACCEPT_CAR_OFFER_INIT
    ACCEPT_CAR_OFFER_SUCCESS
    ACCEPT_CAR_OFFER_FAILURE
`,
    {
        prefix: "checkout-v2/"
    }
);
