import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { setUserPersonalDetail } from "./action";
import { CHECKOUT_STEP_KEY_DESKTOP, DELIVERY_PICKUP_TAB, FINANCE_TAB, OFFER_TYPE, CHECKOUT_STEP_CONTROL_TAB_DESKTOP } from "../../../constants/ae/checkout-constants";
import { KYC_SCREEN_TYPE } from "./constant";
import { CURRENCY } from "../../../constants/app-constants";
import { COUNTRY_CODE } from "../../../constants/api-constants";

const getStepindex = (currentStep, tabs = CHECKOUT_STEP_CONTROL_TAB_DESKTOP) => {
    const currentTab = (tabs || []).filter(tab =>
        currentStep.toLowerCase().includes(tab.name.toLowerCase())
    );
    return currentTab[0].index;
};

const SELECTED_EMIRATE_INITIAL_STATE = {
    regionName: null,
    regionCode: null,
    workingHoursStart: null,
    workingHoursEnd: null,
    isHomeTD: true,
    isYardTD: false
};

export const INITIAL_STATE = {
    userPersonalDetail: {
        name: "",
        mobile: "",
        email: "",
        otp: "",
        accessToken: ""
    },
    financePersonalDetail: {
        nameOnPanCard: "",
        dateOfBirth: "",
        gender: "",
        pinCode: "",
        panNumber: ""
    },
    financeSelectedData: {
        selectedLoanTenure: "",
        selectedCreditScore: "",
        selectedDownPaymentValue: "",
        selectedMonthlyEmiValue: ""
    },
    financeUpdatedData: {
        creditScore: 0,
        downPayment: 0,
        emi: 0,
        tenure: 0,
        chargeList: []
    },
    order: {
        bookingAmount: "",
        bookingVariables: {
            nexBookingAmount: "",
            exBookingAmount: "",
            bookingType: ""
        },
        bookingType: null,
        bookingConfirmDate: null,
        bookingExpireDate: null,
        chargeDetails: null,
        checkoutState: null,
        country: COUNTRY_CODE.UAE,
        createdDate: null,
        currency: CURRENCY.AE,
        deliveryAddress: null,
        deliveryMode: null,
        financeOpted: true,
        financeTerms: null,
        id: "",
        orderId: "",
        pickupStoreId: null,
        pickupTime: null,
        status: null,
        multipleBookingCase: false,
        storeId: "",
        totalAmount: 0,
        userId: "",
        vehiclePrice: 0,
        vehicleType: "car",
        isRefundableHomeBc: null,
        latestOffer: {}
    },
    financeData: {
        creditScore: [],
        loanTenure: [],
        minEmiValue: "",
        maxEmiValue: "",
        minDownpaymentValue: "",
        maxDownpaymentValue: "",
        paymentStepSize: "",
        emiStepSize: "",
        defaultLoanTenureValue: 12,
        defaultRoi: 13,
        defaultMonthlyEmiValue: "",
        defaultDownPaymentValue: "",
        defaultCreditScoreValue: "",
        offerType: OFFER_TYPE.User
    },
    deliveryInfo: {
        pincodeDeliveryData: {},
        deliveryInfoError: null,
        deliveryInfoFetched: false,
        appliedDeliveryPincode: "",
        selectedPickupLocationIndex: 0,
        selectedHomeDeliveryIndex: 0,
        updateDeliveryError: null,
        isDeliveryInfoLoading: false
    },
    financeChargeList: [],
    chargeDetailsList: [],
    userPickupLocation: [],
    vehiclePrice: 200000,
    financeSelected: false,
    orderStatus: "",
    orderId: 0,
    warrantyCharge: 0,
    insuranceChange: 0,
    rcHandling: 0,
    currentStep: CHECKOUT_STEP_KEY_DESKTOP.Process,
    currentStepIndex: getStepindex(CHECKOUT_STEP_KEY_DESKTOP.Process),
    currentTabIndex: getStepindex(CHECKOUT_STEP_KEY_DESKTOP.Process),
    prevTabIndex: 0,
    showPersonalDetailSuccessMessage: false,
    orderCreatedDatetime: new Date().toISOString(),
    deliveryMode: DELIVERY_PICKUP_TAB.HomeDelivery,
    selectedFinancingTab: FINANCE_TAB.GetFinancing,
    processingFee: 0,
    stampDuty: 0,
    chm: 0,
    isKycDone: false,
    kycInfo: {
        activeScreen: KYC_SCREEN_TYPE.DOCUMENT_UPLOAD
    },
    isOrderDetailLoading: false,
    isOrderDetailFetched: false,
    getOrderDetailError: false,
    tradeInChoice: null,
    emiratesDetails: {
        list: [],
        isFetched: false,
        isLoading: false,
        error: null,
        selectedEmirate: SELECTED_EMIRATE_INITIAL_STATE
    },
    testDriveDetails: {
        testDriveLocations: [],
        isFetched: false,
        isLoading: false,
        error: null
    },
    isOrderLoading: false,
    isOrderFetched: false,
    acceptedOffer: {
        isLoading: false,
        data: null,
        hasError: false
    },
    isCounterOfferClicked: false,
    showMakeAnOfferLoader: false,
    userEnteredOfferValue: 0,
    isUserEnteredOfferValueErrored: false
};

const getUpdatedFinanceTerms = (financeTerms, currentFinanceTerms) => {
    if (!financeTerms && !currentFinanceTerms) {
        return null;
    }
    return {
        ...currentFinanceTerms,
        chargeDetails: financeTerms.chargeDetails,
        downPayment: financeTerms.downPayment,
        emiAmount: financeTerms.emiAmount,
        emiType: financeTerms.emiType,
        interestRate: financeTerms.interestRate,
        loanAmount: financeTerms.loanAmount,
        offerType: financeTerms.offerType,
        tenure: financeTerms.tenure
    };
};

const getDeliveryAddress = (deliveryAddress, currentDeliveryAddress) => {
    if (!deliveryAddress && !currentDeliveryAddress) {
        return null;
    }
    return {
        ...currentDeliveryAddress,
        addressLine1: deliveryAddress.addressLine1,
        addressLine2: deliveryAddress.addressLine2,
        landmark: deliveryAddress.landmark,
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        pincode: deliveryAddress.pincode,
        customerName: deliveryAddress.customerName,
        customerPhoneNumber: deliveryAddress.customerPhoneNumber,
        latitude: deliveryAddress.latitude,
        longitude: deliveryAddress.longitude,
        mapLink: deliveryAddress.mapLink
    };
};

export const setOrder = (state = INITIAL_STATE, {
    bookingAmount,
    bookingConfirmDate,
    bookingExpireDate,
    bookingType,
    bookingVariables,
    chargeDetails,
    country,
    checkoutState,
    createdDate,
    currency,
    deliveryAddress,
    deliveryMode,
    financeOpted,
    financeTerms,
    id,
    orderId,
    pickupStoreId,
    pickupTime,
    status,
    storeId,
    claimedCoupons,
    totalAmount,
    userId,
    multipleBookingCase,
    vehiclePrice,
    vehicleType,
    isRefundableHomeBc,
    latestOffer
}) => {
    return {
        ...state,
        order: {
            ...state.order,
            bookingAmount,
            bookingConfirmDate,
            bookingExpireDate,
            bookingType,
            bookingVariables,
            chargeDetails,
            country,
            claimedCoupons,
            checkoutState,
            createdDate,
            currency,
            deliveryAddress: getDeliveryAddress(deliveryAddress, state.deliveryAddress),
            deliveryMode,
            financeOpted,
            financeTerms: getUpdatedFinanceTerms(financeTerms, state.financeTerms),
            id,
            orderId,
            pickupStoreId,
            pickupTime,
            status,
            storeId,
            totalAmount,
            userId,
            multipleBookingCase,
            vehiclePrice,
            vehicleType,
            isRefundableHomeBc,
            latestOffer
        }
    };
};

export const setKycDone = (state = INITIAL_STATE, { isKycDone }) => {
    return {
        ...state,
        isKycDone
    };
};

export const setKycData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        kycInfo: {
            ...state.kycInfo,
            ...data
        }
    };
};

export const setKycScreen = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        kycInfo: {
            ...state.kycInfo,
            ...data
        },
        currentStepIndex: state.currentStepIndex + 1
    };
};

export const setSelectedFinancingData = (state = INITIAL_STATE, { loanTenure }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedLoanTenure: loanTenure
        }
    };
};

export const setFinanceUpdatedData = (state = INITIAL_STATE, {
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList,
    totalLoanAmount
}) => {
    return {
        ...state,
        financeUpdatedData: {
            ...state.financeUpdatedData,
            ...(creditScore && { creditScore }),
            ...(downPayment && { downPayment }),
            ...(emi && { emi }),
            ...(tenure && { tenure }),
            ...(chargeList && { chargeList }),
            ...(totalLoanAmount && { totalLoanAmount })
        }
    };
};

export const setDeliveryMode = (state = INITIAL_STATE, { deliveryMode }) => {
    return {
        ...state,
        deliveryMode
    };
};

export const setOrderId = (state = INITIAL_STATE, { orderId }) => {
    return {
        ...state,
        orderId
    };
};

export const setFinancingTab = (state = INITIAL_STATE, { financingTab }) => {
    return {
        ...state,
        selectedFinancingTab: financingTab
    };
};

export const setDownPaymentValue = (state = INITIAL_STATE, { downPaymentValue }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedDownPaymentValue: downPaymentValue
        }
    };
};

export const setFinancePersonalDetails = (state = INITIAL_STATE, { nameOnPanCard, dateOfBirth, gender, pinCode, panNumber }) => {
    return {
        ...state,
        financePersonalDetail: {
            ...state.financePersonalDetail,
            nameOnPanCard, dateOfBirth, gender, pinCode, panNumber
        }
    };
};

export const setMonthlyEmiValue = (state = INITIAL_STATE, { monthlyEmiValue }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedMonthlyEmiValue: monthlyEmiValue
        }
    };
};

export const setOrderCreatedDateTime = (state = INITIAL_STATE, { orderCreatedDatetime }) => {
    return {
        ...state,
        orderCreatedDatetime
    };
};

export const setFinanceSelected = (state = INITIAL_STATE, { financeSelected }) => {
    return {
        ...state,
        financeSelected
    };
};

export const setSelectedLoanTenure = (state = INITIAL_STATE, { loanTenure }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedLoanTenure: loanTenure
        }
    };
};

export const setCreditScoreValue = (state = INITIAL_STATE, { creditScoreValue }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedCreditScore: creditScoreValue
        }
    };
};

export const setMinMaxDownPaymentValue = (state = INITIAL_STATE, { minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            minDownpaymentValue,
            maxDownpaymentValue
        }
    };
};

export const setFinanceChargeList = (state = INITIAL_STATE, { financeChargeList }) => {
    return {
        ...state,
        financeChargeList
    };
};

export const setChargeDetailsList = (state = INITIAL_STATE, { chargeDetailsList }) => {
    return {
        ...state,
        chargeDetailsList
    };
};

export const setUserPickupLocation = (state = INITIAL_STATE, { userPickupLocation }) => {
    // console.log("userPickupLocation", userPickupLocation);
    return {
        ...state,
        userPickupLocation
    };
};

export const setMinMaxEmiValue = (state = INITIAL_STATE, { minEmiValue, maxEmiValue }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            minEmiValue,
            maxEmiValue
        }
    };
};

export const setFinanceData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            creditScore: data.creditScoreList,
            loanTenure: data.loanTenureList,
            defaultLoanTenureValue: data.defaultLoanTenureValue,
            defaultRoi: data.defaultRoi,
            defaultMonthlyEmiValue: data.defaultMonthlyEmiValue,
            defaultDownPaymentValue: data.defaultDownPaymentValue,
            minDownpaymentValue: data.minDownpaymentValue,
            maxDownpaymentValue: data.maxDownpaymentValue,
            minEmiValue: data.minEmiValue,
            maxEmiValue: data.maxEmiValue,
            paymentStepSize: data.paymentStepSize,
            emiStepSize: data.emiStepSize,
            defaultCreditScoreValue: data.defaultCreditScoreValue,
            totalLoanAmount: data.defaultLoanAmount,
            maxLoanAmount: data.maxLoanAmount,
            minLoanAmount: data.minLoanAmount
        }
    };
};

export const setPersonalDetailSucessMessage = (state = INITIAL_STATE, { successMessage }) => {
    return { ...state, showPersonalDetailSuccessMessage: successMessage };
};

export const setStep = (state = INITIAL_STATE, { currentStep, tabs }) => {
    const prevTabIndex = state.currentTabIndex;
    const currentTabIndex = getStepindex(currentStep, tabs);
    const currentStepIndex = getStepindex(currentStep, tabs);
    return { ...state, currentStep, currentStepIndex, currentTabIndex, prevTabIndex };
};

export const getDeliveryInfoRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        deliveryInfo: {
            ...state.deliveryInfo,
            isDeliveryInfoLoading: true
        }
    };
};

export const getDeliveryInfoSuccess = (state = INITIAL_STATE, { data, pincode }) => {
    return {
        ...state,
        deliveryInfo: {
            ...state.deliveryInfo,
            pincodeDeliveryData: data,
            appliedDeliveryPincode: pincode,
            deliveryInfoFetched: true,
            deliveryInfoError: null,
            isDeliveryInfoLoading: false
        }
    };
};

export const getDeliveryInfoFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        deliveryInfo: {
            ...state.deliveryInfo,
            deliveryInfoError: error,
            deliveryInfoFetched: true,
            isDeliveryInfoLoading: false
        }
    };
};

export const setDeliveryData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        deliveryInfo: {
            ...state.deliveryInfo,
            ...data
        }
    };
};

export const updateBookingTypeSuccess = (state = INITIAL_STATE, { data }) => {
    return setOrder(state, data);
};

export const getOrderDetailInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isOrderDetailLoading: true,
        getOrderDetailError: false
    };
};

export const getOrderDetailSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        isOrderDetailLoading: false,
        isOrderDetailFetched: true,
        getOrderDetailError: false
    };
};

export const getOrderDetailFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isOrderDetailLoading: false,
        isOrderDetailFetched: true,
        getOrderDetailError: error
    };
};

export const setTradeInChoice = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tradeInChoice: data
    };
};

const fetchEmiratesListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        emiratesDetails: {
            ...state.emiratesDetails,
            error: null,
            isLoading: false,
            isFetched: true,
            list: data
        }
    };
};

const fetchEmiratesListFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        emiratesDetails: {
            ...state.emiratesDetails,
            error,
            isLoading: false,
            isFetched: true
        }
    };
};

const fetchTestDriveLocationsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        testDriveDetails: {
            ...state.testDriveDetails,
            error: null,
            isLoading: false,
            isFetched: true,
            testDriveLocations: data.testDriveLocations
        }
    };
};

const fetchTestDriveLocationsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        testDriveDetails: {
            ...state.testDriveDetails,
            error,
            isLoading: false,
            isFetched: true
        }
    };
};
// C2C Checkout : START

const submitCarOfferInit = (state = INITIAL_STATE, { withLoader = true }) => {
    return {
        ...state,
        isOrderLoading: withLoader,
        isError: false
    };
};

const submitCarOfferSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isOrderLoading: false,
        isOrderFetched: true,
        isError: false,
        order: { ...data }
    };
};

const submitCarOfferFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isOrderLoading: false,
        isOrderFetched: true,
        isError: true,
        order: { ...INITIAL_STATE }
    };
};

const acceptCarOfferInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        acceptedOffer: {
            isLoading: true,
            data: null,
            hasError: false
        }
    };
};

const acceptCarOfferSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        acceptedOffer: {
            isLoading: false,
            data,
            hasError: false
        }
    };
};

const acceptCarOfferFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        acceptedOffer: {
            isLoading: false,
            data: null,
            hasError: true
        }
    };
};

const updateUserEnteredOfferPrice = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        userEnteredOfferValue: data
    };
};

const updateShowMakeAnOfferLoader = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        showMakeAnOfferLoader: data
    };
};

const updateCounterOfferClickedStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isCounterOfferClicked: data
    };
};

const updateUserEnteredOfferPriceErrorStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isUserEnteredOfferValueErrored: data
    };
};

// C2C Checkout : END

export const HANDLERS = {
    [Types.SETUSER_PERSONAL_DETAIL]: setUserPersonalDetail,
    [Types.SET_CURRENT_STEP]: setStep,
    [Types.SET_SELECTED_FINANCING_DATA]: setSelectedFinancingData,
    [Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE]: setPersonalDetailSucessMessage,
    [Types.SET_FINANCE_DATA]: setFinanceData,
    [Types.SET_CREDIT_SCORE_VALUE]: setCreditScoreValue,
    [Types.SET_DOWNPAYMENT_VALUE]: setDownPaymentValue,
    [Types.SET_MONTHLY_EMI_VALUE]: setMonthlyEmiValue,
    [Types.SET_LOAN_TENURE_VALUE]: setSelectedLoanTenure,
    [Types.SET_DELIVERY_MODE]: setDeliveryMode,
    [Types.SET_FINANCING_TAB]: setFinancingTab,
    [Types.SET_MIN_MAX_DOWNPAYMENT_VALUE]: setMinMaxDownPaymentValue,
    [Types.SET_MIN_MAX_EMI_VALUE]: setMinMaxEmiValue,
    [Types.SET_FINANCE_SELECTED]: setFinanceSelected,
    [Types.SET_FINANCE_PERSONAL_DETAILS]: setFinancePersonalDetails,
    [Types.SET_ORDER_ID]: setOrderId,
    [Types.SET_ORDER]: setOrder,
    [Types.SET_FINANCE_UPDATED_DATA]: setFinanceUpdatedData,
    [Types.SET_FINANCE_CHARGE_LIST]: setFinanceChargeList,
    [Types.SET_KYC_DONE]: setKycDone,
    [Types.SET_CHARGE_LIST]: setChargeDetailsList,
    [Types.SET_USER_PICKUP_LOCATION]: setUserPickupLocation,
    [Types.SET_KYC_DATA]: setKycData,
    [Types.SET_KYC_SCREEN]: setKycScreen,
    [Types.GET_DELIVERY_INFO_REQUEST]: getDeliveryInfoRequest,
    [Types.GET_DELIVERY_INFO_SUCCESS]: getDeliveryInfoSuccess,
    [Types.GET_DELIVERY_INFO_FAILURE]: getDeliveryInfoFailure,
    [Types.UPDATE_BOOKING_TYPE_SUCCESS]: updateBookingTypeSuccess,
    [Types.SET_DELIVERY_DATA]: setDeliveryData,
    [Types.GET_ORDER_DETAIL_SUCCESS]: getOrderDetailSuccess,
    [Types.GET_ORDER_DETAIL_FAILURE]: getOrderDetailFailure,
    [Types.GET_ORDER_DETAIL_INIT]: getOrderDetailInit,
    [Types.TRADE_IN_CHOICE]: setTradeInChoice,
    [Types.FETCH_EMIRATES_LIST_SUCCESS]: fetchEmiratesListSuccess,
    [Types.FETCH_EMIRATES_LIST_FAILURE]: fetchEmiratesListFailure,
    [Types.FETCH_TEST_DRIVE_LOCATIONS_SUCCESS]: fetchTestDriveLocationsSuccess,
    [Types.FETCH_EMIRATES_LIST_FAILURE]: fetchTestDriveLocationsFailure,

     // C2C Checkout : START
     [Types.SUBMIT_CAR_OFFER_INIT]: submitCarOfferInit,
     [Types.SUBMIT_CAR_OFFER_SUCCESS]: submitCarOfferSuccess,
     [Types.SUBMIT_CAR_OFFER_FAILURE]: submitCarOfferFailure,

     [Types.ACCEPT_CAR_OFFER_INIT]: acceptCarOfferInit,
     [Types.ACCEPT_CAR_OFFER_SUCCESS]: acceptCarOfferSuccess,
     [Types.ACCEPT_CAR_OFFER_FAILURE]: acceptCarOfferFailure,

     [Types.UPDATE_SHOW_MAKE_AN_OFFER_LOADER]: updateShowMakeAnOfferLoader,
     [Types.UPDATE_USER_ENTERED_OFFER_PRICE]: updateUserEnteredOfferPrice,
     [Types.UPDATE_COUNTER_OFFER_CLICKED_STATUS]: updateCounterOfferClickedStatus,
     [Types.UPDATE_USER_ENTERED_OFFER_PRICE_ERROR_STATUS]: updateUserEnteredOfferPriceErrorStatus
     // C2C Checkout : END

};

export default createReducer(INITIAL_STATE, HANDLERS);
