import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_CAR_STORES
        FETCH_CAR_STORES_FAILURE
        FETCH_CAR_STORES_SUCCESS
    `,
    {
        prefix: "carstores/"
    }
);
