/* eslint-disable max-params */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Checkbox from "../../shared/checkbox/component";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateAllSubFilterOptions } from "../filters/actions";

const DoorsAndSeats = ({
    appliedFilters,
    filter,
    onOptionSelection,
    handleUpdateSelectedFilters
}) => {
    const {
        filterType,
        groupFacet
    } = filter;

    const { numberOfDoors, numberOfSeats } = groupFacet;

    const onChangeSubFilterOption = (filterKey, filterOptionKey, subFilterOptionKey, isSelected) => {
        handleUpdateSelectedFilters({
            filterKey,
            optionKey: filterOptionKey,
            subOptionKey: subFilterOptionKey,
            isSelected,
            filterType,
            displayText: subFilterOptionKey
        });
        onOptionSelection();
    };

    return (
        <div styleName={"styles.genericWrap"}>
            <div styleName={"styles.doorSeatsHeading"}>{numberOfDoors.displayText}</div>
            <div styleName={"styles.genericWrap"}>
                {
                    numberOfDoors.options.sort((a, b) => a.key - b.key).map((option) => {
                        const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: numberOfDoors.key, subOptionKey: option.key }, appliedFilters);
                        return (
                            <div key={option.key} styleName={"styles.itemWrap"}>
                                <Checkbox checkedStatus={isSelected} onClickCallback={onChangeSubFilterOption.bind(null, filter.key, numberOfDoors.key, option.key, !isSelected, `${option.displayText} ${numberOfDoors.displayText}`)} id={`${numberOfDoors.key}${option.key}`} name="" />
                                <div styleName={`styles.listTextRevamp ${isSelected ? `styles.active` : ``}`}>
                                    {`${option.displayText} ${numberOfDoors.displayText}`}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div styleName={"styles.emptySpace"} />
            <div styleName={"styles.doorSeatsHeading"}>{numberOfSeats.displayText}</div>
            <div styleName={"styles.genericWrap"}>
                {
                    numberOfSeats.options.sort((a, b) => a.key - b.key).map((option) => {
                        const isLdSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: numberOfSeats.key, subOptionKey: option.key }, appliedFilters);
                        return (
                            <div key={option.key} styleName={"styles.seatsOuter"}>
                                <Checkbox checkedStatus={isLdSelected} onClickCallback={onChangeSubFilterOption.bind(null, filter.key, numberOfSeats.key, option.key, !isLdSelected, `${option.displayText} ${numberOfSeats.displayText}`)} id={`${numberOfSeats.key}${option.key}`} name="" />
                                <div styleName={`styles.listTextRevamp ${isLdSelected ? `styles.active` : ``}`}>
                                    {`${option.displayText} ${numberOfSeats.displayText}`}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>);
};

DoorsAndSeats.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    restProps: PropTypes.object,
    handleUpdateSelectedFilters: PropTypes.func,
    updateAllSubFilterOptionsConnect: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateAllSubFilterOptionsConnect: updateAllSubFilterOptions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DoorsAndSeats);
