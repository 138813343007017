import React from "react";
import PropTypes from "prop-types";

// Component
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";

// Styles
import styles from "./styles.css";

const TdTermsModal = ({
    onClose = () => {}
}) => {

    return (
        <Modal isOpen close={onClose}>
            <div styleName={"styles.termsWrapper"}>
                <div styleName={"styles.header"}>
                    <h2>Terms & Conditions</h2>
                    <CloseButton type="orange" onClickHandler={onClose} />
                </div>
                <div styleName={"styles.cardBody"}>
                    <ul>
                        <li>One customer qualifies for only one gift every month, irrespective of the number of Test Drives done</li>
                        <li>If your Test Drive gets auto-cancelled, you will be eligible for a gift on the new booking and slot that you choose.</li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

TdTermsModal.propTypes = {
    onClose: PropTypes.func
};

export default TdTermsModal;
