import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    content: [],
    error: null,
    config: {}
};

export const fetchRecentlyViewedCarListSuccess = (state = INITIAL_STATE, { data: { results, config } }) => {
    return { ...state, content: results, config };
};

export const fetchRecentlyViewedCarListFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error};
};

export const HANDLERS = {
    [Types.FETCH_RECENTLY_VIEWED_SUCCESS]: fetchRecentlyViewedCarListSuccess,
    [Types.FETCH_RECENTLY_VIEWED_FAILURE]: fetchRecentlyViewedCarListFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
