import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const WhiteCard = ({ children, onClick = () => {} }) => {
    return (
        <div onClick={onClick} styleName={"styles.whiteCard"}>
            {children}
        </div>
    );
};

WhiteCard.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func
};

export default WhiteCard;
