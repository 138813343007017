import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SETUSER_PERSONAL_DETAIL
    SET_CURRENT_STEP
    SET_SELECTED_FINANCING_DATA
    SET_FINANCE_DATA
    SET_LOAN_TENURE_VALUE
    SET_CREDIT_SCORE_VALUE
    SET_DOWNPAYMENT_VALUE
    SET_MONTHLY_EMI_VALUE
    SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE
    SET_ORDER_CREATED_DATE_TIME
    SET_DELIVERY_MODE
    SET_FINANCING_TAB
    SET_MIN_MAX_DOWNPAYMENT_VALUE
    SET_MIN_MAX_EMI_VALUE
    SET_FINANCE_SELECTED
    SET_FINANCE_PERSONAL_DETAILS
    SET_ORDER_ID
    SET_ORDER
    SET_FINANCE_UPDATED_DATA
    SET_FINANCE_CHARGE_LIST
    SET_KYC_DONE
    SET_CHARGE_LIST
    SET_USER_PICKUP_LOCATION
    SET_KYC_DATA
    SET_KYC_SCREEN
    
    GET_DELIVERY_INFO_REQUEST
    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE
    SET_DELIVERY_DATA

    UPDATE_BOOKING_TYPE_SUCCESS

    GET_ORDER_DETAIL_INIT
    GET_ORDER_DETAIL_SUCCESS
    GET_ORDER_DETAIL_FAILURE
    TRADE_IN_CHOICE

    FETCH_EMIRATES_LIST_SUCCESS
    FETCH_EMIRATES_LIST_FAILURE
   
    FETCH_TEST_DRIVE_LOCATIONS_SUCCESS
    FETCH_TEST_DRIVE_LOCATIONS_FAILURE

    SUBMIT_CAR_OFFER_INIT
    SUBMIT_CAR_OFFER_SUCCESS
    SUBMIT_CAR_OFFER_FAILURE

    ACCEPT_CAR_OFFER_INIT
    ACCEPT_CAR_OFFER_SUCCESS
    ACCEPT_CAR_OFFER_FAILURE

    UPDATE_COUNTER_OFFER_CLICKED_STATUS
    UPDATE_USER_ENTERED_OFFER_PRICE
    UPDATE_USER_ENTERED_OFFER_PRICE_ERROR_STATUS
    UPDATE_SHOW_MAKE_AN_OFFER_LOADER
`,
    {
        prefix: "checkout/"
    }
);
