import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SaleStripListing from "./component";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    config: {
        activeSaleConfig
    }
}) => ({activeSaleConfig});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleStripListing);
