/* eslint-disable no-empty */

const parse = JSON.parse;
const stringify = JSON.stringify;

export const getItem = (key = "") => {
    try {
        if (!window) return null;
        // eslint-disable-next-line no-undef
        return window && window?.localStorage ? parse(localStorage?.getItem(key || "")) || null : null;
    } catch (e) {
        return null;
    }
};

export const setItem = (value, key) => {
    try {
        if (!value || !window) {
            return null;
        }
        // eslint-disable-next-line no-undef
        return localStorage.setItem(key, stringify(value));
    } catch (e) {
        return null;
    }
};

export const clearItem = (key) => {
    try {
        if (!window) return null;
        // eslint-disable-next-line no-undef
        return localStorage.removeItem(key);
    } catch (e) {
        return null;
    }
};

export const getStringItem = (key) => {
    try {
        if (!window) return null;
        // eslint-disable-next-line no-undef
        return localStorage.getItem(key) || null;
    } catch (e) {
        return null;
    }
};
