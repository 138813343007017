/* eslint-disable no-console */
import axios from "axios";
import { b2cMasterDataMeApi } from "../constants/url-constants";
// import { apiUrl, c2bAuthKey } from "../constants/url-constants";
import { captureException } from "@sentry/browser";
import interceptor from "./interceptor";

const DEBUG = process.env.NODE_ENV === "development2";

const BASE_API_URL = `${b2cMasterDataMeApi()}/`;

/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X_COUNTRY": `AE`,
        "X_VEHICLE_TYPE": "CAR"
    }
    // withCredentials: true
});

api.interceptors.request.use((config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("✉️ ", config);
    }
    return config;
}, (error) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("✉️ ", error);
    }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("📩 ", response);
    }
    if (response.config.parse) {
        return interceptor(response);
    }
    return response;
    // if (DEBUG) { console.info("📩 validResponse", validResponse); }
    // return validResponse;
}, (error) => {
    captureException(error);
    if (error.response && error.response.data) {
        if (DEBUG) {
            // eslint-disable-next-line no-undef
            console.error("Error: ", error.response.data);
        }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("📩 ", error);
    }
    return Promise.reject(error.message);
});

export default api;
