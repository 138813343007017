import React from "react";
import PropTypes from "prop-types";
import styles from "./style.css";

const PaginationDot = ({
    active,
    index,
    onClick
}) => {
    const handleClick = (e) => {
        onClick(index, e);
    };

    return (
        <button type="button" aria-label="slider pagination" onClick={handleClick} styleName={`styles.buttonDots ${active ? `styles.active` : ``}`} className={`${active ? `active` : ``}`}>
            <div />
        </button>
    );
};

PaginationDot.propTypes = {
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
};

export default PaginationDot;
