import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Banner from "./images/exchange-banner.png";

const CarExchangeBanner = ({exchangeOffer}) => {
    return (
        <div styleName={"styles.configuredBanner"}>
            <img src={Banner} />
            <div styleName={"styles.caption"}>
                <h3>Exchange your car with us</h3>
                {exchangeOffer?.amount &&
                    <React.Fragment>
                        <p>Get AED <strong>{exchangeOffer?.amount} Off On</strong></p>
                        <p>Your Purchase</p>
                    </React.Fragment>}
            </div>
        </div>
    );
};

CarExchangeBanner.propTypes = {
    exchangeOffer: PropTypes.object
};

export default CarExchangeBanner;
