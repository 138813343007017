import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Component
import TdSchedule from "./component";
import { fetchPostBookingConfig } from "../post-booking-confirmation/actions";

const mapStateToProps = ({
    postBcDetails
}) => ({
    postBcDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPostBookingConfigConnect: fetchPostBookingConfig
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TdSchedule));
