import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const RangeFilterDisplayRevamp = ({
    minText,
    maxText
}) => {
    return (
        <React.Fragment>
            <div styleName={"styles.outerWrapper styles.firstTabWrap"}>
                <span className={"text-right"} styleName={"styles.priceLabel"}>{maxText}</span>
            </div>
            <div styleName={"styles.outerWrapper styles.secondTabWrap"}>
                <span styleName={"styles.priceLabel"}>{minText}</span>
            </div>
        </React.Fragment>
    );
};

RangeFilterDisplayRevamp.propTypes = {
    minText: PropTypes.string,
    maxText: PropTypes.string
};

export default RangeFilterDisplayRevamp;
