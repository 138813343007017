import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchInfo from "./component";
import { withRouter } from "react-router-dom";
import { reloadCarList } from "../car-listing/actions";
import { clearAllFilters, fetchAutoSuggestions, fetchListingCount } from "../filters/actions";

const mapStateToProps = ({
    filters: {
        selectedFilters,
        allFilters,
        urlOptions
    },
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName,
            hasCitySkip
        } = {}
    },
    user: { mobile, isLoggedIn }
}) => ({
    urlOptions,
    allFilters,
    selectedFilters,
    selectedCityCode,
    selectedCityName,
    mobile,
    isLoggedIn,
    hasCitySkip
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAutoSuggestionsConnect: fetchAutoSuggestions,
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters,
    fetchListingCountConnect: fetchListingCount
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchInfo));
