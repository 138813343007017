import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_SEO_CAR_DETAILS_INIT
    FETCH_SEO_CAR_DETAILS_SUCCESS
    FETCH_SEO_CAR_DETAILS_FAILURE
    
    UPDATE_SEO_CAR_DETAILS_SSR_STATUS

    FETCH_SEO_SIMILAR_CAR_LIST_INIT
    FETCH_SEO_SIMILAR_CAR_LIST_SUCCESS
    FETCH_SEO_SIMILAR_CAR_LIST_FAILURE
`,
    {
        prefix: "seoCarDetails/"
    }
);
