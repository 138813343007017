import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

const SIMILAR_CAR_LIST_INITIAL_STATE = {
    isLoading: false,
    isFetched: false,
    isError: false,
    data: {}
};

export const INITIAL_STATE = {
    isLoading: false,
    isFetched: false,
    isError: false,
    isSSR: false,
    pageData: {},
    similarCarData: SIMILAR_CAR_LIST_INITIAL_STATE
};

// FETCH SEO CAR DETAILS
const fetchSeoCarDetailsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true,
        isFetched: false,
        isError: false
    };
};

export const fetchSeoCarDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isLoading: false,
        isFetched: true,
        isError: false,
        pageData: data
    };
};

const fetchSeoCarDetailsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: false,
        isFetched: true,
        isError: true,
        pageData: {}
    };
};

export const updateSeoCarDetailsSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return {
        ...state,
        isSSR
    };
};

// FETCH SEO SIMILAR CAR LIST
const fetchSeoSimilarCarListInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarData: {
            isLoading: true,
            isFetched: false,
            isError: false
        }
    };
};

const fetchSeoSimilarCarListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        similarCarData: {
            isLoading: false,
            isFetched: true,
            isError: false,
            data
        }
    };
};

const fetchSeoSimilarCarListFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarData: {
            isLoading: false,
            isFetched: true,
            isError: true,
            data: {}
        }
    };
};

export const HANDLERS = {
    [Types.FETCH_SEO_CAR_DETAILS_INIT]: fetchSeoCarDetailsInit,
    [Types.FETCH_SEO_CAR_DETAILS_SUCCESS]: fetchSeoCarDetailsSuccess,
    [Types.FETCH_SEO_CAR_DETAILS_FAILURE]: fetchSeoCarDetailsFailure,

    [Types.UPDATE_SEO_CAR_DETAILS_SSR_STATUS]: updateSeoCarDetailsSSRStatus,

    [Types.FETCH_SEO_SIMILAR_CAR_LIST_INIT]: fetchSeoSimilarCarListInit,
    [Types.FETCH_SEO_SIMILAR_CAR_LIST_SUCCESS]: fetchSeoSimilarCarListSuccess,
    [Types.FETCH_SEO_SIMILAR_CAR_LIST_FAILURE]: fetchSeoSimilarCarListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
