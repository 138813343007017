import React, { useRef, useEffect, useState } from "react";
import loadable from "@loadable/component";
const AsyncSelect = loadable.lib(() => import("react-select/lib/Async"), { ssr: false });
import styles from "./style.css";
import PropTypes from "prop-types";

const Search = ({
    data = [],
    defaultMenuIsOpen = false,
    onChange = () => { },
    placeholder = "Select...",
    customDefaultValue = "",
    autoFocus = false,
    onMenuOpenCallback = () => { },
    onMenuCloseCallback = () => { },
    ...restProps
}) => {
    const searchRef = useRef();
    const {current} = searchRef || {};
    const [inputValue, setInputValue] = useState(customDefaultValue || "");
    useEffect(() => {
        if (autoFocus && current) {
            current.focus();
        }
    }, [autoFocus, current]);

    const filterOptions = (input) => {
        return new Promise((resolve) => {
            const result = data.filter(({ label }) =>
                label.toLowerCase().includes(input.toLowerCase()));
            resolve(result);
        });
    };

    const onInputChange = (v, action) => {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setInputValue(v);
        }
    };

    return (
        <AsyncSelect fallback={<React.Fragment />}>
        {({ default: AsyncSelectLoadable }) => (<AsyncSelectLoadable
            inputValue={inputValue}
            onInputChange={onInputChange}
            ref={searchRef}
            onChange={onChange}
            className="search-select-container"
            placeholder={placeholder}
            defaultOptions={data}
            loadOptions={filterOptions}
            classNamePrefix="search-select"
            defaultMenuIsOpen={defaultMenuIsOpen}
            onMenuOpen={onMenuOpenCallback}
            onMenuClose={onMenuCloseCallback}
            {...restProps}
            tabIndex={-1}
            autoFocus={autoFocus}
            />)}
        </AsyncSelect>
    );
};

Search.propTypes = {
    data: PropTypes.array,
    defaultMenuIsOpen: PropTypes.bool,
    onChange: PropTypes.func,
    customDefaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    autoFocus: PropTypes.bool,
    onMenuOpenCallback: PropTypes.func,
    onMenuCloseCallback: PropTypes.func
};

export default Search;
