import React from "react";
import withAELayout from "../../../components/ae.desktop/layout";
import C2COpenInMobile from "../../../components/ae.desktop/c2c-open-in-mobile";

const C2COpenInMobilePage = ({ ...props }) => {
    return (
        <C2COpenInMobile {...props} />
    );
};

export default withAELayout(C2COpenInMobilePage);

