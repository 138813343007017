/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import { NUMBER } from "../../../constants/app-constants";

const InputMobile = ({
    onChange = () => { },
    inputRef,
    value = "",
    ripple = true,
    placeholder = "Mobile Number",
    hidePrefixIfNoValuePresent = false,
    showError = true,
    onFocusCallback = () => { },
    onBlurCallback = () => { },
    newTheme = false,
    onClick = () => { },
    countryCode = "91",
    maxLength = "10",
    validator = undefined,
    onErrorCallback = () => { },
    autoComplete = "off",
    ...rest }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState("");
    // const [isPrefixVisible, setIsPrefixVisible] = useState(true);

    const mobileValidator = validator || mobileNumberValidator; // defaults to India validator

    const onFocus = (e) => {
        const phone = (e.target.value || "");
        if (phone.length === NUMBER.TEN || phone.length === 0) {
            setIsFocused(true);
            setIsTouched(true);
        }
        // setIsPrefixVisible(true);
        onFocusCallback();
    };
    const onBlur = (e) => {
        setIsFocused(false);
        if (hidePrefixIfNoValuePresent && !e.target.value.length) {
            // setIsPrefixVisible(false);
        }
        onBlurCallback(e.target.value);
    };

    const onChangeHandler = (e) => {
        setIsFocused(true);
        const regNumbers = /^\d{0,10}$/;
        let number;
        if (regNumbers.test(e.target.value)) {
            number = e.target.value;
            if (!mobileValidator(number)) {
                setHasError(true);
                onErrorCallback(true);
                setError("Mobile number not valid");
            } else {
                setHasError(false);
                onErrorCallback(false);
                setError("");
            }
            onChange(e);
        }
    };

    const isError = (showError && hasError && ((isTouched) || (value.length === NUMBER.TEN)));

    return (
        <div className="inputNumbWrap">
            <div styleName={`styles.inputShift styles.withPrefix ${newTheme ? `styles.inputNew ${isError ? "styles.errorInput" : ""} ${mobileValidator(value) ? "styles.successInput" : ""}` : ""}`}>
                <input
                    ref={inputRef}
                    type="tel"
                    maxLength={maxLength}
                    name="phone"
                    className="form-control"
                    onFocus={(e) => onFocus(e)}
                    onBlur={(e) => onBlur(e)}
                    onClick={e => onClick(e)}
                    value={value}
                    placeholder={!ripple ? placeholder : ""}
                    onChange={onChangeHandler}
                    autoComplete={autoComplete}
                    {...rest}
                // required
                // autoComplete={"off"}
                />
                {countryCode &&
                    <span id="prefix" styleName={`styles.phoneNumberPrefix ${newTheme ? "styles.newDls" : ""}`}>
                        +{countryCode}
                    </span>
                }
                {ripple && <label
                    styleName={`${(value || isFocused || hasError) ? "active" : ""}`}>
                    {(value || isFocused || hasError) ? "Mobile Number" : placeholder}
                </label>}

            </div>
            {
                isError
                &&
                <small styleName={"styles.error"}>{error}</small>
            }
        </div>
    );
};

InputMobile.propTypes = {
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputRef: PropTypes.any,
    onBlurCallback: PropTypes.func,
    showError: PropTypes.bool,
    placeholder: PropTypes.string,
    onFocusCallback: PropTypes.func,
    onClick: PropTypes.func,
    hidePrefixIfNoValuePresent: PropTypes.bool,
    newTheme: PropTypes.bool,
    countryCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    validator: PropTypes.func,
    ripple: PropTypes.bool,
    onErrorCallback: PropTypes.func,
    autoComplete: PropTypes.string
};

export default InputMobile;
