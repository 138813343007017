/* eslint-disable no-magic-numbers */
import React from "react";
import PropTypes from "prop-types";
import H2 from "../../shared/h2";
import styles from "./styles.css";
import SchemaValuationFaq from "../../shared/schema-valuation-faq";

const FaqContent = ({
    content,
    activeFaqRef,
    activeFaq
}) => {
    const getFaqsForSchema = () => {
        let faqsForSchema = [];
        content.forEach(({ faqList }) => {
            faqsForSchema = [...faqsForSchema, ...faqList];
        });
        return faqsForSchema;
    };
    return (
        <div>
            <div styleName={"styles.answercatCol"}>
                <div>
                    <div styleName={"styles.answerCategory"}>
                        { (content || []).map(({ categoryTitle, faqList }, categoryIndex) => (
                            <React.Fragment key={categoryIndex}>
                                <div ref={categoryIndex === activeFaq ? activeFaqRef : undefined} styleName={"styles.lineText"}>
                                    <span>{categoryTitle}</span>
                                </div>
                                { (faqList || []).map(({ question, answer }, index) => (
                                    <div key={`${categoryIndex}-${index}`} styleName={"styles.answerContainer"}>
                                        <H2 text={question} />
                                        <div dangerouslySetInnerHTML={
                                            { __html: answer }
                                        } />
                                    </div>
                                ))}
                            </React.Fragment>
                        ))}
                        <SchemaValuationFaq faqs={getFaqsForSchema()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

FaqContent.propTypes = {
    content: PropTypes.array,
    activeFaqRef: PropTypes.object,
    activeFaq: PropTypes.index
};

export default FaqContent;
