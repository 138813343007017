import React from "react";
import withAELayout from "../../../components/ae.desktop/layout";
import Helmet from "react-helmet";
import PrivacyInfoAe from "../../../components/shared/privacy-info-ae";

// Constants
import { appUrl } from "../../../constants/url-constants";

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>CARS24 UAE | Privacy Policy</title>
                <meta name="description" content={"Review CARS24 UAE's Privacy Policy to see how we protect your information and uphold your rights as you use our services. For inquiries or concerns, contact our support."} />
                <link href={`${appUrl()}/privacy-policy/`} rel="canonical" />
            </Helmet>
            <PrivacyInfoAe />
        </React.Fragment>
    );
};

export default withAELayout(PrivacyPolicy);
