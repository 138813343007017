/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

// Component
import withLogin from "../with-login";
import PostBcTdSummary from "../post-bc-td-summary";
import PostBcTdComplete from "../post-bc-td-complete";
import TdScheduleHomeMrl from "../td-schedule-home-mrl";

// Constants
import { TD_STEP_NAMES } from "./constants";

// Utils
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const TdSchedule = ({
    match: { params: { lid } },
    history,
    location,
    postBcDetails = {},
    fetchPostBookingConfigConnect
}) => {

    const appointmentId = lid;
    const queryParams = parseLocationSearchParams(location?.search);
    const stepType = queryParams?.type || TD_STEP_NAMES.TD_SELECTION;

    const { activeStepData = {}, isMRL = false, orderId } = postBcDetails || {};
    const { tdCompleted = false, tdScheduled = false, isLoading: isActiveStepLoading = false } = activeStepData || {};

    const navigateToTestDrive = (tdStepType = "") => {
        history.push(`/booking-confirmation/${appointmentId}/testDrive?type=${tdStepType}`);
    };

    useEffect(() => {
        trackDesktopCustomEventsAE("checkoutTdVisited", { eventLabel: isMRL ? "Hub" : "Home" });
    }, [isMRL]);

    useEffect(() => {
        if (orderId && stepType && stepType === TD_STEP_NAMES.TD_SUMMARY) {
            fetchPostBookingConfigConnect({ orderId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepType]);

    useEffect(() => {
        if (!isActiveStepLoading) {
            if (tdCompleted) {
                navigateToTestDrive(TD_STEP_NAMES.TD_COMPLETED);
            } else if (tdScheduled) {
                navigateToTestDrive(TD_STEP_NAMES.TD_SUMMARY);
            } else {
                navigateToTestDrive(TD_STEP_NAMES.TD_SCHEDULE);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tdCompleted, tdScheduled, isActiveStepLoading]);

    const renderCurrentSteps = () => {
        switch (stepType) {
            case TD_STEP_NAMES.TD_SCHEDULE:
                return <TdScheduleHomeMrl />;
            case TD_STEP_NAMES.TD_SUMMARY:
                return <PostBcTdSummary />;
            case TD_STEP_NAMES.TD_COMPLETED:
                return <PostBcTdComplete />;
            default: return <TdScheduleHomeMrl />;
        }
    };

    return (
        <Fragment>
            {renderCurrentSteps()}
        </Fragment>
    );
};

TdSchedule.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    postBcDetails: PropTypes.object,
    location: PropTypes.object,
    fetchPostBookingConfigConnect: PropTypes.func
};

export default withLogin(TdSchedule);
