import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Faq from "./component";
import { fetchFaq } from "./actions";

const mapStateToProps = ({
    faq: {
        content,
        sellerFaq,
        isFetching
    }
}) => ({
    content,
    sellerFaq,
    isFetching
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFaqConnect: fetchFaq
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
