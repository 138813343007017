import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import Button from "../../shared/button";

//Images
import IconError from "./images/error.svg";

const TdSlotErrorModal = ({
    onClose = () => {},
    handleAnotherTimeSlot = () => {}
}) => {

    return (
        <Modal isOpen close={onClose}>
            <div styleName={"styles.slotErrorModal"}>
                <div styleName={"styles.header"}>
                    <CloseButton type="orange" onClickHandler={onClose} />
                </div>
                <div styleName={"styles.cardBody"}>
                    <figure>
                        <img src={IconError} alt="Icon Error" />
                    </figure>
                    <h3>Slots not available</h3>
                    <p>Someone else may have booked this slot before you. Please select other slot to confirm your test drive</p>
                    <Button text={"SELECT ANOTHER TIME SLOT"} onClick={handleAnotherTimeSlot}/>
                </div>
            </div>
        </Modal>
    );
};

TdSlotErrorModal.propTypes = {
    onClose: PropTypes.func,
    handleAnotherTimeSlot: PropTypes.func
};

export default TdSlotErrorModal;
