import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimilarCarList from "./component";
import { fetchSimilarCars } from "../car-details/actions";

const mapStateToProps = ({
    carDetails: {
        similarCars,
        config
    },
    user: { mobile }
}) => ({
    similarCars,
    config,
    mobile
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSimilarCarsConnect: fetchSimilarCars
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SimilarCarList);
