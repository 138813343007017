import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import FlexColumnLayout from "../../shared/flex-column-layout/component";
const FilterLabel = ({minText, maxText}) => {
    return (
        <React.Fragment>
            <FlexColumnLayout>
                <span styleName={"styles.priceLabel"}>{minText}</span>
                <span styleName={"styles.priceLabel"}>{maxText}</span>
            </FlexColumnLayout>
        </React.Fragment>
    );
};
FilterLabel.propTypes = {
    minText: PropTypes.string,
    maxText: PropTypes.string
};
export default FilterLabel;
