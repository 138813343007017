import React, {useState, useMemo} from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import matchSorter from "match-sorter";
import InputSearch from "../../shared/input-search/component";
import debounce from "../../../utils/helpers/debounce";
import CrossIcon from "../../shared/icon-cross";

const SearchFilterV2 = ({
    placeholder,
    searchItems,
    onSearchHandler = () => {}
}) => {
    const { options } = searchItems;
    const [searchKeyword, setSearchKeyword] = useState("");
    // const searchKey = "displayText";

    const debouncedSearch = debounce((value) => {
        const filteredItemsOptions = matchSorter(options, value, {
            keys: [
                item => item.subFilter.options.map(i => i.displayText),
                item => item.displayText
            ],
            threshold: matchSorter.rankings.CONTAINS
        });
        // console.log("filtered items are -------", filteredItems);
        onSearchHandler({ options: filteredItemsOptions, value });
    // eslint-disable-next-line no-magic-numbers
    }, 100);
    const memoizedDebouncedSearch = useMemo(() => debouncedSearch, [debouncedSearch]);

    const onSearch = (value) => {
        setSearchKeyword(value);
        memoizedDebouncedSearch(value);
    };

    const onCrossClick = () => {
        setSearchKeyword("");
        onSearchHandler({ options, value: "" });
    };

    return (
        <div styleName={"styles.searchWrap"}>
            <InputSearch
                onChange={(e) => onSearch(e.target.value)}
                value={searchKeyword}
                placeholder={placeholder}
            />
        {searchKeyword && <span styleName={"styles.closeIcon"}><CrossIcon  onClickHandler={onCrossClick} type="darkblue"/></span> }
        </div>
    );
};

SearchFilterV2.propTypes = {
    placeholder: PropTypes.string,
    searchItems: PropTypes.object,
    onSearchHandler: PropTypes.func
};

export default SearchFilterV2;
