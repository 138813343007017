
export default (api) => {

    const getUserConsentDetails = (params, product = "consumer-app") => {
        return api.get(`/consent/${product}`, { params });
    };

    const sendConsent = (params, product = "consumer-app") => {
        return api.post(`/consent/${product}`, params);
    };

    return {
        sendConsent,
        getUserConsentDetails
    };
};
