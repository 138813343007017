/* eslint-disable no-console */
import axios from "axios";
import { captureException } from "@sentry/browser";

const DEBUG = process.env.NODE_ENV === "development";

let BASE_API_URL = "https://sell24-engine.qac24svc.dev/";
switch (process.env.HOST_ENV) {
case "QA":
    BASE_API_URL = "https://sell24-engine.qac24svc.dev/";
    // BASE_API_URL = "https://sell24engine-staging.24c.in/";
    break;
case "QA2":
    BASE_API_URL = "https://sell24-engine.qac24svc.dev/";
    break;
case "LNOD":
    BASE_API_URL = "https://sell24engine-lnod.ninja24.in/";
    break;
case "STAGING":
    BASE_API_URL = "https://stage-sell24-engine.qac24svc.dev/";
    break;
case "PRODUCTION":
    BASE_API_URL = "https://api-sell24.cars24.team/";
    break;
default: break;
}

/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json"
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
    }
    // withCredentials: true,
});

api.interceptors.request.use((config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("✉️ ", config);
    }
    return config;
}, (error) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("✉️ ", error);
    }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("📩 ", response);
    }
    return response;
}, (error) => {
    captureException(error);
    if (error.response && error.response.data) {
        if (DEBUG) {
            // eslint-disable-next-line no-undef
            console.error("Error: ", error.response.data);
        }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("📩 ", error);
    }
    return Promise.reject(error.message);
});

export default api;
