/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import BgGradientStripped from "../../shared/bg-gradeint-stripped";
import Image from "../../shared/images/site-map.png";
import ArrowLiCollection from "../../shared/arrow-links-collection";
import styles from "./styles.css";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import { pageUrl } from "../../../constants/ae/sitemap";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import getSitemapLinks from "../../../utils/filters-v2/get-sitemap-links";
import { TOP_SELL_CITIES } from "../../../constants/seller-app-constants";
import { NUMBER } from "../../../constants/app-constants";

const SiteMapInfo = ({
    cityList,
    allFilters,
    selectedCityName,
    isFiltersSSR,
    fetchFiltersConnect,
    fetchSiteMapConnect,
    updateFilterSSRStatusConnect
}) => {

    const [sitemapItems, setSitemapItems] = useState({});
    const temporaryDisableNewCarPage = true;

    const featuredCities = cityList.map(({ name }) => ({
        title: name,
        url: `/buy-used-cars-${dashedLowercase(name)}/`
    }));
    const featuredCitiesForSell = TOP_SELL_CITIES.map(({ name }) => ({
        title: name,
        url: `/sell-used-car-${dashedLowercase(name)}/`
    }));

    const getLinks = useCallback((type, heading) => {
        if (!Object.values(allFilters).length) {
            return (<ArrowLiCollection
                heading={heading}
                items={[]}
            />);
        }
        const filter = Object.values(allFilters).filter((f) => f.key === type)[0];
        const bucketOptions = getSitemapLinks(filter, selectedCityName);
        return (<ArrowLiCollection
            heading={heading}
            items={bucketOptions}
        />);
    }, [allFilters, selectedCityName]);

    const getMakeModelLinks = (makeData, heading) => {
        if (!Object.values(allFilters).length) {
            return (<ArrowLiCollection
                heading={heading}
                items={[]}
            />);
        }
        const bucketOptions = getSitemapLinks(makeData, selectedCityName, "makeModel");
        return (<ArrowLiCollection
            heading={heading}
            items={bucketOptions}
        />);
    };

    const getSitemapItems = async () => {
        try {
            const sitemap = await fetchSiteMapConnect();
            setSitemapItems(sitemap);
            // eslint-disable-next-line no-empty
        } catch (err) { }
    };

    useEffect(() => {
        if (!temporaryDisableNewCarPage) {
            getSitemapItems();
        }

        if (!isFiltersSSR) {
            try {
                const filterParams = { variant: "filterV3" };
                fetchFiltersConnect(filterParams);
            } catch (err) {
                // Handle if any error
            }
        } else {
            updateFilterSSRStatusConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName={"styles.mainWrapper"}>
            <BgGradientStripped>
                <div className="container d-flex justify-content-end">
                    <img src={Image} />
                </div>
                <div styleName={"styles.heading"}>
                    <div styleName={"styles.topBorder"} />
                    <div className="container">
                        <h2>CARS24 SITE MAP</h2>
                    </div>
                </div>
            </BgGradientStripped>
            <div className="container" styleName={"styles.linksWrap"} >
                <p>All the important information you wish to find
                    on CARS24 is listed here in categories</p>
                <div className="row" >
                    <div className="col-12 col-md-3">
                        <ArrowLiCollection
                            heading="CARS24"
                            items={pageUrl}
                        />
                    </div>
                    {Object.keys(sitemapItems || {})?.length > NUMBER.ZERO && (
                        <div className="col-12 col-md-3">
                            <ArrowLiCollection
                                heading={sitemapItems?.heading}
                                items={sitemapItems?.content}
                                addAe={true}
                            />
                        </div>
                    )}
                    <div className="col-12 col-md-3">
                        <ArrowLiCollection
                            heading="BUY USED CARS IN TOP LOCATION"
                            items={featuredCities}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        {getLinks(FILTER_ENTITIES.MAKE, "BUY USED CARS BY TOP BRANDS")}
                    </div>
                    {temporaryDisableNewCarPage && (
                        <div className="col-12 col-md-3">
                            {getLinks(FILTER_ENTITIES.BODY_TYPE, "BUY USED CARS BY BODY TYPE")}
                        </div>
                    )}
                </div>
                <div className="row">
                    {!temporaryDisableNewCarPage && (
                        <div className="col-12 col-md-3">
                            {getLinks(FILTER_ENTITIES.BODY_TYPE, "BUY USED CARS BY BODY TYPE")}
                        </div>
                    )}
                    <div className="col-12 col-md-3">
                        <ArrowLiCollection
                            heading="SELL USED CARS IN TOP LOCATION"
                            items={featuredCitiesForSell}
                        />
                    </div>
                    {
                        allFilters[FILTER_ENTITIES.MAKE]?.options?.map((makeData) => {
                            return (
                                <div className="col-12 col-md-3" key={makeData?.key}>
                                    {getMakeModelLinks(makeData, `BUY USED ${makeData.displayText} CARS IN UAE`)}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

SiteMapInfo.propTypes = {
    cityList: PropTypes.array,
    allFilters: PropTypes.object,
    selectedCityName: PropTypes.string,
    fetchSiteMapConnect: PropTypes.func,
    isFiltersSSR: PropTypes.bool,
    fetchFiltersConnect: PropTypes.func,
    updateFilterSSRStatusConnect: PropTypes.func
};

export default SiteMapInfo;
