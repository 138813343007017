import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

const SELECTED_EMIRATE_INITIAL_STATE = {
    regionName: null,
    regionCode: null,
    workingHoursStart: null,
    workingHoursEnd: null,
    isHomeTD: true,
    isYardTD: false
};

const SELECTED_ZONE_INITIAL_STATE = {
    locationCode: null,
    locationName: null,
    zoneId: null
};

const LOCATION_DETAILS_INITIAL_STATE = {
    error: null,
    mainText: null,
    addressLabel: null,
    addressLine2: null,
    city: null,
    pincode: null,
    latitude: null,
    longitude: null,
    placeId: null,
    state: null
};

export const SELECTED_SLOT_INITIAL_STATE = {
    slotDate: null,
    slotKey: null,
    from: null,
    to: null,
    giftAvailable: false
};

export const INITIAL_ADDRESS_STATE = {
    addressLine1: null,
    addressLine2: null
};

export const INITIAL_RESCHEDULE_STATE = {
    selectedReason: null
};

export const INITIAL_SLOT_AVAILABILITY_DETAILS_STATE = {
    appointmentId: "",
    orderId: null,
    regionCode: null,
    locationCode: null,
    zoneId: null,
    isFetched: false,
    isLoading: false,
    error: null,
    availableSlots: [],
    selectedSlot: SELECTED_SLOT_INITIAL_STATE
};

export const INITIAL_STATE = {
    isMRL: null,
    emiratesDetails: {
        list: [],
        isFetched: false,
        isLoading: false,
        error: null,
        selectedEmirate: SELECTED_EMIRATE_INITIAL_STATE
    },
    zonesDetails: {
        list: [],
        isFetched: false,
        isLoading: false,
        error: null,
        selectedZone: SELECTED_ZONE_INITIAL_STATE
    },
    locationDetails: LOCATION_DETAILS_INITIAL_STATE,
    slotAvailabilityDetails: INITIAL_SLOT_AVAILABILITY_DETAILS_STATE,
    addressDetails: INITIAL_ADDRESS_STATE,
    rescheduleDetails: INITIAL_RESCHEDULE_STATE
};

const setSelectedEmirate = (state = INITIAL_STATE, { selectedEmirate }) => {
    return {
        ...state,
        emiratesDetails: {
            ...state.emiratesDetails,
            selectedEmirate
        },
        zonesDetails: {
            ...state.zonesDetails,
            selectedZone: SELECTED_ZONE_INITIAL_STATE
        }
    };
};

const setSelectedZone = (state = INITIAL_STATE, { selectedZone }) => {
    return {
        ...state,
        zonesDetails: {
            ...state.zonesDetails,
            selectedZone
        }
    };
};

const setSelectedLocation = (state = INITIAL_STATE, { selectedLocation }) => {
    return {
        ...state,
        locationDetails: {
            ...state.locationDetails,
           ...selectedLocation
        }
    };
};

const resetSelectedLocation = (state = INITIAL_STATE) => {
    return {
        ...state,
        locationDetails: LOCATION_DETAILS_INITIAL_STATE
    };
};

const setSelectedSlot = (state = INITIAL_STATE, { selectedSlot }) => {
    return {
        ...state,
        slotAvailabilityDetails: {
            ...state.slotAvailabilityDetails,
            selectedSlot
        }
    };
};

const resetSelectedSlot = (state = INITIAL_STATE) => {
    return {
        ...state,
        slotAvailabilityDetails: INITIAL_SLOT_AVAILABILITY_DETAILS_STATE
    };
};

const setAddress = (state = INITIAL_STATE, { addressDetails }) => {
    return {
        ...state,
        addressDetails
    };
};

const resetAddress = (state = INITIAL_STATE) => {
    return {
        ...state,
        addressDetails: INITIAL_ADDRESS_STATE
    };
};

const setRescheduleReason = (state = INITIAL_STATE, { selectedReason }) => {
    return {
        ...state,
        rescheduleDetails: {
            ...state.rescheduleDetails,
            selectedReason
        }
    };
};

const fetchEmiratesListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        emiratesDetails: {
            ...state.emiratesDetails,
            error: null,
            isLoading: false,
            isFetched: true,
            list: data
        }
    };
};

const fetchEmiratesListFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        emiratesDetails: {
            ...state.emiratesDetails,
            error,
            isLoading: false,
            isFetched: true
        }
    };
};

const fetchSelectedEmirateZonesSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        zonesDetails: {
            ...state.zonesDetails,
            error: null,
            isLoading: false,
            isFetched: true,
            list: data
        }
    };
};

const fetchSelectedEmirateZonesFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        zonesDetails: {
            ...state.zonesDetails,
            error,
            isLoading: false,
            isFetched: true
        }
    };
};

const fetchSlotAvailabilityDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        slotAvailabilityDetails: {
            ...state.slotAvailabilityDetails,
            error: null,
            isLoading: false,
            isFetched: true,
            ...data
        }
    };
};

const fetchSlotAvailabilityDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        slotAvailabilityDetails: {
            ...state.slotAvailabilityDetails,
            error,
            isLoading: false,
            isFetched: true
        }
    };
};

export const HANDLERS = {
    [Types.SET_SELECTED_EMIRATE]: setSelectedEmirate,
    [Types.SET_SELECTED_ZONE]: setSelectedZone,
    [Types.SET_SELECTED_LOCATION]: setSelectedLocation,
    [Types.RESET_SELECTED_LOCATION]: resetSelectedLocation,
    [Types.SET_SELECTED_SLOT]: setSelectedSlot,
    [Types.RESET_SELECTED_SLOT]: resetSelectedSlot,
    [Types.SET_ADDRESS]: setAddress,
    [Types.RESET_ADDRESS]: resetAddress,
    [Types.SET_RESCHEDULE_REASON]: setRescheduleReason,
    [Types.FETCH_EMIRATES_LIST_SUCCESS]: fetchEmiratesListSuccess,
    [Types.FETCH_EMIRATES_LIST_FAILURE]: fetchEmiratesListFailure,
    [Types.FETCH_SELECTED_EMIRATE_ZONES_SUCCESS]: fetchSelectedEmirateZonesSuccess,
    [Types.FETCH_SELECTED_EMIRATE_ZONES_FAILURE]: fetchSelectedEmirateZonesFailure,
    [Types.FETCH_SLOT_AVAILABILITY_SUCCESS]: fetchSlotAvailabilityDetailsSuccess,
    [Types.FETCH_SLOT_AVAILABILITY_FAILURE]: fetchSlotAvailabilityDetailsFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
