/* eslint-disable max-params */
export default (api) => {

    const fetchNationality = () => {
        return api.get("api/v1/masterdata/country?size=1000&page=0");
    };

    return {
        fetchNationality
    };
};
