import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    GET_CAR_CF_DETAILS_REQUEST
    GET_CAR_CF_DETAILS_SUCCESS
    GET_CAR_CF_DETAILS_FAILURE

    CREATE_CAR_CF_DETAILS_REQUEST
    CREATE_CAR_CF_DETAILS_SUCCESS
    CREATE_CAR_CF_DETAILS_FAILURE

    UPDATE_CAR_CF_DETAILS_REQUEST
    UPDATE_CAR_CF_DETAILS_SUCCESS
    UPDATE_CAR_CF_DETAILS_FAILURE

    SET_CAR_CF_BACK_URL
`,
    {
        prefix: "checkoutfinanceinfo/"
    }
);
