import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchTdAllActiveBooking } from "../post-booking-confirmation/actions";
import { getNumberOfBookings } from "../config-ssr/actions";

// Component
import PostBcTdSummary from "./component";
import { clearAllFilters } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    postBcDetails,
    carDetails,
    config,
    user: {
        secureToken,
        isLoggedIn,
        mobile
    },
    location: { selectedCity }
}) => ({
    postBcDetails,
    carDetails,
    config,
    secureToken,
    isLoggedIn,
    mobile,
    selectedCity
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearAllFiltersConnect: clearAllFilters,
    reloadCarListConnect: reloadCarList,
    fetchTdAllActiveBookingConnect: fetchTdAllActiveBooking,
    getNumberOfBookingsConnect: getNumberOfBookings
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostBcTdSummary));
