import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_SELECTED_EMIRATE
    SET_SELECTED_ZONE
    SET_SELECTED_SLOT
    RESET_SELECTED_SLOT
    SET_ADDRESS
    RESET_ADDRESS
    SET_RESCHEDULE_REASON
    SET_SELECTED_LOCATION
    RESET_SELECTED_LOCATION

    FETCH_EMIRATES_LIST_SUCCESS
    FETCH_EMIRATES_LIST_FAILURE

    FETCH_SELECTED_EMIRATE_ZONES_SUCCESS
    FETCH_SELECTED_EMIRATE_ZONES_FAILURE

    FETCH_SLOT_AVAILABILITY_SUCCESS
    FETCH_SLOT_AVAILABILITY_FAILURE
`,
    {
        prefix: "td-schedule/"
    }
);
