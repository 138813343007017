import React from "react";
import PropTypes from "prop-types";

// Icons
import googlePlay from "./images/play-store-new.svg";
import appStore from "./images/app-store-new.svg";
import twitterBlue from "./images/twitter-blue-1.svg";

// Component
import LazyImage from "../lazy-image";

const SocialIcon = ({ name, imageUrl, ...rest }) => {

    const imageMap = {
        googlePlay,
        appStore,
        twitterBlue
    };

    const getURL = () => imageMap[name] || imageUrl;

    return <LazyImage src={getURL()} alt={name} {...rest} />;
};

SocialIcon.propTypes = {
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string
};

export default SocialIcon;
