/* eslint-disable max-params */
export default (api) => {

    const getPickupLocation = (appointmentId, cityCode) => {
        return api.get(`api/inventory/availability/apt-${appointmentId}?cityCode=${cityCode}`);
    };

    return {
        getPickupLocation
    };
};
