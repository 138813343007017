
export const FINANCE_STEPS = {
    PITCH: "pitch",
    ELIGIBILITY_FLOW: "eligibility",
    LOAN_OFFERS: "offers",
    INDICATIVE_LOAN_OFFERS: "indicative-loan-offer"
};

export const EMPLOYMENT_TYPES_LIST = [
    {label: "Salaried", value: "SALARIED"},
    {label: "Self Employed", value: "SELF_EMPLOYED"}
];

export const EMPLOYMENT_TYPES = {
    SALARIED: "SALARIED",
    SELF_EMPLOYED: "SELF_EMPLOYED"
};

export const BOOL_OPTIONS = [
    {label: "Yes", value: true},
    {label: "No", value: false}
];

export const CF_STATUS = {
    CREATED: "CREATED",
    CREATE_OFFER: "CREATE_OFFER",
    OFFER_SELECTED: "OFFER_SELECTED",
    RECALCULATE_OFFER: "RECALCULATE_OFFER",
    RECREATE_OFFER: "RECREATE_OFFER"
};

export const CALCULATOR_TYPE = {
    OFFLINE: "OFFLINE",
    ONLINE: "ONLINE"
};
