import { useHistory } from "react-router-dom";
import yieldToMainThread from "../utils/helpers/yield-to-main-thread";

const useCustomHistoryOperations = () => {
  const history = useHistory();

  const customPush = async(relativeUrl) => {
    await yieldToMainThread();
    // Push the route using relativeUrl
    history.push(relativeUrl);
  };

  const customReplace = async(relativeUrl) => {
    await yieldToMainThread();
    // Replace the route using relativeUrl
    history.replace(relativeUrl);
  };

  const customGoBack = async () => {
    await yieldToMainThread();
    // Replace the route using relativeUrl
    history.goBack();
  };

  return {customPush, customReplace, customGoBack};
};

export default useCustomHistoryOperations;
