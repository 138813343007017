/* eslint-disable no-magic-numbers */
// eslint-disable-next-line no-magic-numbers
const VALID_MOBILE_NUMBER = new RegExp(/^[6789]\d{9}$/);
const INVALID_MOBILE_NUMBER = new RegExp(/^([0-9])\1*$/);
const MOBILE_NUMBER_TEST = new RegExp(/^(0|91)?[1-9][0-9]{8}$/);

// const MOBILE_REGEX_AE = new RegExp(/^(?:0*)??(?:|50|52|54|55|56|58)/);
const MOBILE_REGEX_AE = new RegExp(/^(0|5|5[024568]\d{0,7}|05|05[024568]\d{0,7})$/);
//^(?:\0)(?!2)((?:2|3|4|6|7|9|50|52|54|55|56|58)[0-9]{7,})$
//(+971/0971/0/+/971) + (01/02/03/04/06/07/09/050/052/054/055/056/058) + (XXXXXXX i.e. 7 digit) ==> from product

export const mobileNumberValidator = (mobile) => {
    return VALID_MOBILE_NUMBER.test(parseInt(mobile)) &&
    !INVALID_MOBILE_NUMBER.test(parseInt(mobile));
};

export const mobileNumberValidatorAE = (mobile, checkLength = false) => {
    if (process.env.HOST_ENV === "QA") {
        return mobileNumberValidator(mobile);
    } else if (process.env.HOST_ENV === "STAGING") {
        return MOBILE_NUMBER_TEST.test(mobile);
    } else {
        return checkLength ? MOBILE_REGEX_AE.test(parseInt(mobile)) && ((mobile.length === 9 && mobile.indexOf("0") !== 0) || (mobile.length === 10 && mobile.indexOf("0") === 0))
            : MOBILE_REGEX_AE.test(parseInt(mobile));
    }
};

export const mobileNumberValidatorUAE = (mobile, checkLength = false) => {
    return checkLength ? MOBILE_REGEX_AE.test(parseInt(mobile)) && ((mobile.length === 9 && mobile.indexOf("0") !== 0) || (mobile.length === 10 && mobile.indexOf("0") === 0))
        : MOBILE_REGEX_AE.test(parseInt(mobile));
};
