/* eslint-disable max-params */
/* eslint-disable max-statements */
export default (api) => {

    const sendReportNotification = (payload, token) => {
        return api.post(`/v1/send/report/notification`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchSalesforceDetails = (orderId, step, token) => {
        return api.get(`/v1/order-delivery-docs/${orderId}${step ? `?step=${step}` : ""}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const updateDocument = (orderId, token, params, { step, categoryId }) => {
        return api.post(`/v1/order-delivery-docs/${orderId}/upload${categoryId || step ? "?" : ""}${step ? `step=${step}` : ""}${categoryId ? `categoryId=${categoryId}` : ""}`, params, {
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "multipart/form-data;"
            }
        });
    };

    const submitDocmentsDetails = (orderId, token) => {
        return api.post(`/v1/order-delivery-docs/${orderId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const submitTokenDocmentsDetails = (orderId, token) => {
        return api.put(`/v1/order/${orderId}/token-doc-upload?tokenDocSubmitted=true`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const submitFinanceDetails = (orderId, token, payload, params = {}) => {
        return api.post(`/v1/order/${orderId}/finance-detail`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const updateEmail = (emailId, token) => {
        return api.put(`/v1/order-delivery/update-email`, { emailId }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchPostBcStepsData = (appId, token, poll = false) => {
        return api.get(`/v2/order-delivery?appId=${appId}${poll ? "&poll=true" : ""}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchPostBcStepsDataV2 = (appId, token, poll = false) => {
        return api.get(`/v3/order-delivery?appId=${appId}${poll ? "&poll=true" : ""}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const generatePaymentLink = (orderId, token, payload) => {
        return api.post(`/v1/order-delivery/${orderId}/payment`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const updateDbrDetails = (orderId, token, payload) => {
        return api.put(`/v1/order/${orderId}/dbr-detail`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getPostBcDeliveryDocuments = (orderId, token) => {
        return api.get(`/v1/order-delivery-docs/${orderId}/internal`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getSplitConfig = (orderId, token) => {
        return api.get(`/v1/order-delivery/${orderId}/payment/split-config`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getOrderTransactionHistory = (orderId, token) => {
        return api.get(`/v1/order-delivery/${orderId}/payment/txn-history`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getVasConfig = (token, params) => {
        return api.get(`/v1/config`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const getVasServiceList = (params, token) => {
        return api.get(`/v1/vas`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const sendWarrantyInfo = (orderId, token, payload) => {
        return api.post(`/v1/vas/order/${orderId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const updateWarrantyInfo = (orderId, token, payload) => {
        return api.put(`/v1/vas/order/${orderId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const setInsuredBuyerDetails = (orderId, token, payload) => {
        return api.post(`/v1/order/${orderId}/user`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchInsuredBuyerDetails = (orderId, token) => {
        return api.get(`/v1/order/${orderId}/user`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getVasBundleList = (params, token) => {
        return api.get(`/v1/vas/bundle`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const sendBundleInfo = (params, token, payload) => {
        return api.post(`/v1/vas/bundle`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const getChargeConfig = (orderId, token) => {
        return api.get(`/v1/order-delivery/${orderId}/payment/charge-config`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getDiscountAndTotal = (params, payload, token) => {
        return api.post(`/v1/vas/bundle/discount`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const generateInsuracePaymentLink = (orderId, payload, token) => {
        return api.post(`/v1/insurance/${orderId}/payment`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchServiceDescription = (orderId, token) => {
        return api.get(`/v1/vas/services-description?orderId=${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const saveVasServices = (orderId, token, payload) => {
        return api.post(`/v1/vas/${orderId}/submit`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getSavedVAS = (orderId, token) => {
        return api.get(`/v1/vas/order-delivery/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const deleteBundle = (params, token) => {
        return api.delete(`v1/vas/bundle`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const acknowledgeSalesAgreement = ({ orderId, token }) => {
        return api.put(`v1/sales/agreement/auto/verify/${orderId}?isSaAutoVerified=true`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const checkNpsStatus = (params) => {
        return api.get(`/v1/feedback/order`, {
            params
        });
    };

    const getPostBCActiveStepData = ({ orderId, stepType, token }) => {
        return api.get(`/v3/order/${orderId}/step?stepType=${stepType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchTdAllActiveBooking = (mobile, token) => {
        return api.get(`/v1/order-delivery/user/${mobile}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const updateRefurbRequest = (orderId, payload, token) => {
        return api.put(`/v1/order/${orderId}/refurb`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const generateTokenPaymentLink = (orderId, token, payload) => {
        return api.post(`/v1/order-delivery/${orderId}/token-payment`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getTdNpsFeedbackInfo = (token) => {
        return api.get(`/v1/order-delivery/td-nps-feedback-popup`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    return {
        sendReportNotification,
        fetchSalesforceDetails,
        updateDocument,
        submitDocmentsDetails,
        updateEmail,
        fetchPostBcStepsData,
        fetchPostBcStepsDataV2,
        generatePaymentLink,
        updateDbrDetails,
        getPostBcDeliveryDocuments,
        getOrderTransactionHistory,
        getSplitConfig,
        getVasServiceList,
        sendWarrantyInfo,
        updateWarrantyInfo,
        setInsuredBuyerDetails,
        fetchInsuredBuyerDetails,
        getChargeConfig,
        getVasConfig,
        getVasBundleList,
        sendBundleInfo,
        getDiscountAndTotal,
        generateInsuracePaymentLink,
        fetchServiceDescription,
        saveVasServices,
        getSavedVAS,
        deleteBundle,
        acknowledgeSalesAgreement,
        checkNpsStatus,
        getPostBCActiveStepData,
        fetchTdAllActiveBooking,
        updateRefurbRequest,
        submitFinanceDetails,
        submitTokenDocmentsDetails,
        generateTokenPaymentLink,
        getTdNpsFeedbackInfo
    };
};
