/* eslint-disable max-params */
export default (inputArray, chunkSize) => {
    if (chunkSize === 0) return inputArray;
    return inputArray.reduce((accumulator, current, index, array) => {
        if (index % chunkSize === 0) {
            accumulator.push(array.slice(index, index + chunkSize));
        }
        return accumulator;
    }, []);
};
