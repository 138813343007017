import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";

// Component
import withAELayout from "../../../components/ae.desktop/layout";
import PageLoader from "../../../components/shared/page-loader";
import ErrorHandler from "../../../components/shared/with-error-handler";
const MyBookingsComponent = loadable(() => import("../../../components/ae.desktop/my-bookings"), { fallback: <PageLoader /> });

const MyBookings = () => {
    return (
        <Fragment>
            <Helmet>
                <meta name="ROBOTS" content="NOINDEX,NOFOLLOW" />
            </Helmet>
            <ErrorHandler>
                <MyBookingsComponent />
            </ErrorHandler>
        </Fragment>
    );
};

export default withAELayout(MyBookings, true, false);
