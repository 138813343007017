import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    GET_USER_CF_DETAILS_REQUEST
    GET_USER_CF_DETAILS_SUCCESS
    GET_USER_CF_DETAILS_FAILURE

    CREATE_USER_CF_DETAILS_REQUEST
    CREATE_USER_CF_DETAILS_SUCCESS
    CREATE_USER_CF_DETAILS_FAILURE

    UPDATE_USER_CF_DETAILS_REQUEST
    UPDATE_USER_CF_DETAILS_SUCCESS
    UPDATE_USER_CF_DETAILS_FAILURE

    SET_USER_CF_BACK_URL
    SET_CAR_CF_FLOW
`,
    {
        prefix: "financinginfo/"
    }
);
