import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
UPDATE_CITY
UPDATE_CITY_WITH_CODE
UPDATE_HUB_SELECTED
SET_LOCATIONS
UPDATE_CITY_FROM_COOKIE
`,
    {
        prefix: "locationpicker/"
    }
);
