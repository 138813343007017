/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";

// Images
import Close from "./images/close.svg";
import BlueCross from "./images/icon-close.svg";
import BlueFilter from "./images/close-filter.svg";
import WhiteCross from "./images/white-cross.svg";
import Cancel from "./images/cancel.svg";
import GreyCross from "./images/grey-cross.svg";
import OrangeCross from "./images/orange-close.svg";
import GreenCross from "./images/green-close.svg";
import RedCross from "./images/close-icon-red.svg";
import DarkBlue from "./images/dark-blue.svg";

const CloseButton = ({ type, onClickHandler = null, ...props }) => {
    let icon = Close;
    if (type === "blue") {
        icon = BlueCross;
    }
    if (type === "blueFilter") {
        icon = BlueFilter;
    }
    if (type === "white") {
        icon = WhiteCross;
    }
    if (type === "cancel") {
        icon = Cancel;
    }
    if (type === "grey") {
        icon = GreyCross;
    }
    if (type === "orange") {
        icon = OrangeCross;
    }
    if (type === "green") {
        icon = GreenCross;
    }
    if (type === "red") {
        icon = RedCross;
    }
    if (type === "darkblue" || type === "darkBlue") {
        icon = DarkBlue;
    }
    return (
        <img src={icon} alt="close" onClick={onClickHandler} {...props} />
    );
};
CloseButton.propTypes = {
    type: PropTypes.string,
    onClickHandler: PropTypes.func
};
export default CloseButton;
