/* eslint-disable complexity */
import { appUrl, listingApiUrl } from "../constants/url-constants";
import getCarUrl from "../utils/helpers/get-detail-page-url";
import mobileCleverTapEventsAE from "./ae.mobile-clevertap";
import desktopCleverTapEventsAE from "./ae.desktop-clevertap";
import { getDeviceType } from "./c2c-tracking-params";
import { fastlyUrl, imageTypes } from "../utils/helpers/cdn-image-url";

const cleverTap = {
    login: ({ user, platform, isWhatsappOptIn }) => {
        if (window && !window.clevertap) return;
        window.clevertap.onUserLogin.push({
            Site: {
                Country: "UAE",
                Name: user?.name || "",
                Email: user?.email || "",
                Phone: user?.mobile ? `+971${user.mobile}` : "",
                Identity: user?.mobile ? `+971${user?.mobile}` : "",
                ["User ID"]: user?.mobile ? `+971${user?.mobile}` : "",
                ["Acquistion Source/Medium"]: platform === "desktop" ? "website" : "msite",
                ["Acquistion Campaign"]: "Website",
                ["Preferred Language"]: "En", // todo make it dynamic with i18n implementation
                ["Whatsapp Opt-In"]: isWhatsappOptIn ? "Y" : "N",
                ["MSG-whatsapp"]: isWhatsappOptIn
                // "MSG_EMAIL": isWhatsappOptIn,
                // "MSG_PUSH": isWhatsappOptIn,
                // "MSG_SMS": isWhatsappOptIn,
                // "MSG_WHATSAPP": isWhatsappOptIn
            }
        });
    },

    updateProfile: (data) => {
        if (window && !window.clevertap) return;
        window.clevertap.profile.push({ Site: data });
    },

    updateDND: (isDNDActive, { mobile, email }) => {
        cleverTap.updateProfile({
            Email: email || "",
            Phone: mobile ? `+971${mobile}` : "",
            "Whatsapp Opt-In": isDNDActive ? "N" : "Y",
            "MSG-whatsapp": !isDNDActive,
            "MSG-email": !isDNDActive,
            "MSG-push": !isDNDActive,
            "MSG-sms": !isDNDActive
            // "MSG_EMAIL": !isDNDActive,
            // "MSG_PUSH": !isDNDActive,
            // "MSG_SMS": !isDNDActive,
            // "MSG_WHATSAPP": !isDNDActive,
        });
    },

    updateEmirate: (cityName) => {
        cleverTap.updateProfile({ Emirate: cityName });
    },

    updateFinanceIntentBool: (bool) => {
        cleverTap.updateProfile({ ["Finance Intent"]: bool ? "Y" : "N" });
    },

    updateFinanceOptInBool: (bool) => {
        cleverTap.updateProfile({ ["Finance Opt-in"]: bool ? "Y" : "N" });
    },

    updateWishlistedCarsBool: (bool) => {
        cleverTap.updateProfile({ ["Wishlisted cars"]: bool ? "Y" : "N" });
    },

    profileUpdateTypes: {
        VIEWED_CAR: "Viewed car",
        WISHLISTED_CAR: "Wishlisted Car",
        BOOKING_INITIATED: "Booking Initiated Car",
        BOOKING_PROCEED: "Booking proceed car",
        BOOKED_CAR: "Booked Car",
        DETAIL_PAGE_VIEW: "Detail Page View"
    },

    /**
     * Updates user profile with car details
     *
     * @param {string} profileUpdateType one of cleverTap.profileUpdateTypes
     * @param {object} carDetails carDetails object with content and config
     * @return {void} nothing
     */
    updateProfileCarDetails: (profileUpdateType, carDetails) => {
        const { imageHost } = carDetails?.config || {};
        const {
            year = "", make = "", model = "", appointmentId = "", city = "", price = 0,
            mainImage = {}, checkoutUrl = "", shareUrl = "", emiDetails = {}, parentHubLocation = {},
            assortmentCategory = "", hotOfferDetails, isHotOffer
        } = carDetails?.content || {};
        const carName = `${make} ${model}`;
        let hotOfferTitle = (hotOfferDetails && hotOfferDetails?.isHotOffer) ? hotOfferDetails?.title : "NA";
        hotOfferTitle = hotOfferTitle === "NA" && isHotOffer ?  "true" : hotOfferTitle;

        const { absoluteURL: abdoluteCheckoutUrl } = getCarUrl({ year, carName, carId: appointmentId, city, isCheckout: true, country: "ae" });
        cleverTap.updateProfile({
            [`${profileUpdateType} Brand`]: make,
            [`${profileUpdateType} ID`]: appointmentId,
            [`${profileUpdateType} Image Url`]: imageHost && mainImage?.path ? `${imageHost}${mainImage?.path}` : "",
            [`${profileUpdateType} Model`]: model,
            [`${profileUpdateType} Name`]: carName.trim(),
            [`${profileUpdateType} Price`]: price,
            [`${profileUpdateType} Checkout Url`]: abdoluteCheckoutUrl,
            [`${profileUpdateType} Checkout Deeplink`]: checkoutUrl,
            [`${profileUpdateType} Emi`]: emiDetails?.emi,
            [`${profileUpdateType} Car Type`]: assortmentCategory || "",
            [`${profileUpdateType} Parent hub location`]: parentHubLocation?.locationName,
            [`${profileUpdateType} Similar car Url`]: `${appUrl()}/similar-cars?appointmentId=${appointmentId}`,
            [`${profileUpdateType} Recommended car Url`]: `${appUrl()}/recommended-cars?appointmentId=${appointmentId}`,
            [`${profileUpdateType} Detail Page Deeplink`]: shareUrl || `${listingApiUrl()}/v1/vehicle/${appointmentId}`,
            [`${profileUpdateType} hotOffer`]: hotOfferTitle || `NA`
        });
    },

    updateSellerProfileCarDetails: (profileUpdateType, carDetails, isDateTime) => {
        const obj = {};
        if (isDateTime) {
            obj[`${profileUpdateType} Date`] = carDetails?.bookingDetail?.date || "";
            obj[`${profileUpdateType} Time`] = carDetails?.bookingDetail?.timeSlotBucket || "";
        }
        cleverTap.updateProfile({
            [`${profileUpdateType} Brand`]: carDetails?.make?.name || "",
            [`${profileUpdateType} Model`]: carDetails?.model?.name || "",
            [`${profileUpdateType} Variant`]: carDetails?.variant,
            [`${profileUpdateType} Year`]: carDetails?.year || "",
            [`${profileUpdateType} Number`]: carDetails?.customer?.phone || "",
            [`${profileUpdateType} App ID`]: carDetails?.appointmentId || "",
            ...obj
        });
    },

    updateProfileCarDetailsView: (eventData = {}) => {
        const {
            resumeSessionDeepLink = "",
            makeModelDeepLink = "",
            makeDeepLink = "",
            bodyTypeDeepLink = "",
            priceRange = "",
            priceRangeDeepLink = "",
            topHighlights = "",
            similarCarsUrl = "",
            recommendedCarsUrl = "",
            parentHubLocation = "",
            emi = "",
            couponDiscount,
            couponCode,
            couponExpiryDate,
            carType
        } = eventData || {};
        cleverTap.updateProfile({
            ["Resume Session Deep Link"]: resumeSessionDeepLink,
            ["Make Model Deep Link"]: makeModelDeepLink,
            ["Make Deep Link"]: makeDeepLink,
            ["Body Type Deep Link"]: bodyTypeDeepLink,
            ["Price Range"]: priceRange,
            ["Price Range Deep Link"]: priceRangeDeepLink,
            ["Top Highlights"]: topHighlights,
            ["Similar Cars Url"]: similarCarsUrl,
            ["Recommended Cars link"]: recommendedCarsUrl,
            ["Parent Hub Location"]: parentHubLocation,
            ["Emi"]: emi,
            ["coupon_discount"]: couponDiscount,
            ["coupon_code"]: couponCode,
            ["coupon_expiry_date"]: couponExpiryDate,
            ["car_type"]: carType
        });
    },

    // eslint-disable-next-line complexity
    prepareData: (carDetailsContent, cityName, imageHost) => {
        if (carDetailsContent) {
            const { hotOfferDetails, isHotOffer } = carDetailsContent || {};
            let hotOfferTitle = (hotOfferDetails && hotOfferDetails?.isHotOffer) ? hotOfferDetails?.title : "NA";
            hotOfferTitle = hotOfferTitle === "NA" && isHotOffer ?  "true" : hotOfferTitle;

            return {
                make: carDetailsContent?.make || "",
                model: carDetailsContent?.model || "",
                appointmentId: carDetailsContent?.appointmentId || "",
                price: carDetailsContent?.price || 0,
                title: `${carDetailsContent?.year || ""} ${carDetailsContent?.make || ""} ${carDetailsContent?.model || ""}`.trim(),
                variant: carDetailsContent?.variant || "",
                kilometerDriven: carDetailsContent?.odometerReading || 0,
                similarCarUrl: `${appUrl()}/similar-cars?appointmentId=${carDetailsContent?.appointmentId}`,
                recommendedCarsUrl: `${appUrl()}/recommended-cars?appointmentId=${carDetailsContent?.appointmentId}`,
                checkoutPageURL: carDetailsContent?.checkoutUrl || "",
                year: carDetailsContent?.year || "",
                parentHubLocation: carDetailsContent?.parentHubLocation?.locationName,
                emi: carDetailsContent?.emiDetails?.emi,
                category: carDetailsContent?.bodyType || "",
                wishlistCount: carDetailsContent?.shortListCount || 0,
                similarCarCount: 10, // TODO:: Check this count it should be defined on global level (Shashwat)
                carDetailPageUrl: carDetailsContent?.shareUrl || `${listingApiUrl()}/v1/vehicle/${carDetailsContent?.appointmentId}`,
                emirate: cityName || carDetailsContent?.city || "",
                carType: carDetailsContent?.assortmentCategory || "",
                ...((imageHost && carDetailsContent?.mainImage?.path) ? { carImageUrl: `${imageHost}${carDetailsContent?.mainImage?.path}` } : {}),
                hotOffer: hotOfferTitle || "NA"
            };
        }
        return {};
    },

    prepareSellerData: (carEvaluation, isSoq) => {
        if (carEvaluation) {
            const { make, model, year, variant, appointmentId, noOfCylinders, engineSize, fuelType, transmission, customer: { phone }, questions: { soQuestion } } = carEvaluation || {};
            const questionsObj = {};
            if (isSoq && soQuestion) {
                for (const key in soQuestion) {
                    questionsObj[key] = soQuestion[key]?.answer;
                }
            }
            return {
                make: make?.code,
                model: model?.code,
                appointmentId,
                variant,
                year,
                noOfCylinders,
                engineSize,
                fuelType,
                transmission,
                phone,
                ...questionsObj
            };
        }
        return {};
    },

    prepareFinanceExploreData: (carDetails, location) => {
        if (carDetails) {
            const { imageHost } = carDetails?.config || {};
            const { year = "", make = "", model = "", appointmentId = "", city = "", price = 0, mainImage = {}, variant = "", emiDetails = {} } = carDetails?.content || {};
            const carName = `${make} ${model}`;
            const { absoluteURL } = getCarUrl({ year, carName, carId: appointmentId, city, isCheckout: false, country: "ae" });
            return {
                "detailPageUrl": absoluteURL,
                appointmentId,
                make,
                model,
                variant,
                year,
                "imageUrl": fastlyUrl({
                    basePath: imageHost,
                    path: mainImage?.path,
                    type: imageTypes.aeCarDetailPreview
                }),
                location,
                price,
                "emi": `${emiDetails?.emi}/month`
            };
        }
        return {};
    },

    trackEvent: (eventName, data) => {
        if (window && !window.clevertap) return;
        window.clevertap.event.push(eventName, data);
    },

    desktopCleverTapEvent: (eventName, data = {}, { userAgent = false } = {}) => {
        data = userAgent ? { ...data, Device_type: getDeviceType() } : { ...data };
        if (typeof window !== "undefined" && window.clevertap && window.clevertap.event) {
            const { carDetailsContent, cityName, ...rest } = data;
            cleverTap.trackEvent(desktopCleverTapEventsAE[eventName], {
                ...cleverTap.prepareData(carDetailsContent, cityName),
                ...rest
            });
        }
    },

    mobileCleverTapEvent: (eventName, data = {}, { userAgent = false } = {}) => {

        data = userAgent ? { ...data, Device_type: getDeviceType() } : { ...data };
        if (typeof window !== "undefined" && window.clevertap && window.clevertap.event) {
            if (eventName === mobileCleverTapEventsAE.FINANCE_EXPLORE) {
                const { carDetails, location } = data;
                cleverTap.trackEvent(mobileCleverTapEventsAE[eventName], {
                    ...cleverTap.prepareFinanceExploreData(carDetails, location)
                });
            } else if (eventName === mobileCleverTapEventsAE.RESUME_SESSION_DEEPLINK) {
                cleverTap.trackEvent(mobileCleverTapEventsAE[eventName], {
                    ...data
                });
            } else {
                const { carDetailsContent, cityName, imageHost, ...rest } = data;
                cleverTap.trackEvent(mobileCleverTapEventsAE[eventName], {
                    ...cleverTap.prepareData(carDetailsContent, cityName, imageHost),
                    ...rest
                });
            }
        }
    },

    mobileSellerCleverTapEvent: (eventName, data = {}) => {
        if (typeof window !== "undefined" && window.clevertap && window.clevertap.event) {
            const { carEvaluation, soQuestion, ...rest } = data;
            cleverTap.trackEvent(mobileCleverTapEventsAE[eventName], {
                ...cleverTap.prepareSellerData(carEvaluation, soQuestion),
                ...(rest || {})
            });
        }
    },

    mobileConstants: {
        CAR_DETAILS_PAGE: "CAR_DETAILS_PAGE",
        START_PURCHASE: "START_PURCHASE",
        FINANCE_PROCEED: "FINANCE_PROCEED",
        DELIVERY_PROCEED: "DELIVERY_PROCEED",
        PAYMENT_INITIATED: "PAYMENT_INITIATED",
        CANCELLATION: "CANCELLATION",
        FINANCE_EXPLORE: "FINANCE_EXPLORE",
        RESUME_SESSION_DEEPLINK: "RESUME_SESSION_DEEPLINK",
        // SELLER EVENTS
        SELL_HOME_PAGE: "SELL_HOME_PAGE",
        SELL_GET_CAR_PRICE: "SELL_GET_CAR_PRICE",
        SELL_MAKE: "SELL_MAKE",
        SELL_MODEL: "SELL_MODEL",
        SELL_YEAR: "SELL_YEAR",
        SELL_VARIANT: "SELL_VARIANT",
        SELL_TIME: "SELL_TIME",
        SELL_MORE_CAR_BRANDS: "SELL_MORE_CAR_BRANDS",
        SELL_CANCEL_APPOINTMENT: "SELL_CANCEL_APPOINTMENT",
        B2C_MOBILE_NUMBER_1DIGIT: "B2C_MOBILE_NUMBER_1DIGIT",
        SELL_NUMBER_PROCEED: "SELL_NUMBER_PROCEED",
        SELL_OTP_ENTERED: "SELL_OTP_ENTERED",
        SELL_OTP_VERIFIED: "SELL_OTP_VERIFIED",
        SELL_SEND_OTP: "SELL_SEND_OTP",
        SELL_OTP_VERIFY_CLICK: "SELL_OTP_VERIFY_CLICK",
        FULL_PAYMENT_COUPON: "FULL_PAYMENT_COUPON"
    },
    desktopConstants: {
        CAR_DETAILS_PAGE: "CAR_DETAILS_PAGE",
        START_PURCHASE: "START_PURCHASE",
        FINANCE_PROCEED: "FINANCE_PROCEED",
        DELIVERY_PROCEED: "DELIVERY_PROCEED",
        PAYMENT_INITIATED: "PAYMENT_INITIATED",
        CANCELLATION: "CANCELLATION",
        FULL_PAYMENT_COUPON: "FULL_PAYMENT_COUPON"
    }
};

export default cleverTap;
