/* eslint-disable react/jsx-handler-names */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Components
import Button from "../../shared/button";
import WhiteCard from "../../shared/white-card";
import TransparentButton from "../../shared/button/transparent-button";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

// Constants
import { PRIMARY_CTA_TEXT, GA_EVENT_SOURCE } from "../../../constants/app-constants";

const BTN_STATE = {
    BOOK: "BOOK",
    RESERVED: "RESERVED",
    BOOK_AGAIN: "BOOK_AGAIN"
};

const BookingCta = ({
    onClickStartBooking,
    onClickRemoveFromWishlist,
    wishlistedCars = [],
    wishlistedSelectedCarId = "",
    onClickSeeDetails,
    carDetailsData
}) => {
    const carsList = wishlistedCars;
    const currentSelectedCarId = wishlistedSelectedCarId;
    const carIdKey = "appointmentId";
    const selectedCar = (carsList || []).filter(car => car[carIdKey] === currentSelectedCarId)[0] || {};
    const { appointmentId = "" } = carDetailsData;
    const {
        booked,
        listingActive
    } = selectedCar;
    const onCheckout = () => {
        trackDesktopCustomEventsAE("startPurchaseDetailPage", {
            appointment_id: appointmentId || "NA",
            eventLabel: GA_EVENT_SOURCE.WISHLIST
        });
        return onClickStartBooking(selectedCar, GA_EVENT_SOURCE.WISHLIST);
    };

    const onRemoveFromWishlist = () => {
        return onClickRemoveFromWishlist(selectedCar);
    };

    const goToCarDetails = () => {
        return onClickSeeDetails(selectedCar);
    };

    const getBtnProps = () => {

        let primaryText = PRIMARY_CTA_TEXT.BOOK_FREE_TEST_DRIVE;
        let secondaryText = PRIMARY_CTA_TEXT.REMOVE_FROM_WISHLIST;
        let text = "This car is still available for you!";
        let subText = "Hurry before it gets booked by someone else";
        let disabled = false;
        let primaryAction = onCheckout;
        let secondaryAction = onRemoveFromWishlist;
        let state = BTN_STATE.BOOK;
        if (booked || !listingActive) {
            primaryText = "SEE DETAILS";
            secondaryText = "REMOVE FROM WISHLIST";
            text = "Oops, someone else has booked this car while you were gone!";
            subText = "We’ll notify you if this car becomes available again";
            disabled = false;
            primaryAction = goToCarDetails;
            secondaryAction = onRemoveFromWishlist;
            state = BTN_STATE.BOOK;
        }

        return { primaryAction, secondaryAction, primaryText, secondaryText, disabled, text, subText, state };
    };
    const ctaProps = getBtnProps();
    return (

        <div styleName={"styles.stickyButtonOuter"}>
            <WhiteCard>
                <div styleName={"styles.textOuter"}>
                    <p styleName={"styles.heading"}>{ctaProps.text}</p>
                    <p styleName={"styles.detail"}>{ctaProps.subText}</p>
                </div>
                <div styleName={"styles.buttonOuter"}>
                    <Button text={ctaProps.primaryText} onClick={ctaProps.primaryAction} />
                    {ctaProps.secondaryAction && <TransparentButton text={ctaProps.secondaryText} onClick={ctaProps.secondaryAction} />}
                </div>
            </WhiteCard>
        </div>
    );
};

BookingCta.propTypes = {
    type: PropTypes.string,
    onClickStartBooking: PropTypes.func,
    onClickRemoveFromWishlist: PropTypes.func,
    wishlistedCars: PropTypes.array,
    wishlistedSelectedCarId: PropTypes.string,
    onClickSeeDetails: PropTypes.func,
    carDetailsData: PropTypes.object
};

export default BookingCta;
