import React from "react";

// Components
import PostBcStepMapper from "../../../components/ae.desktop/post-booking-steps-mapper";
import ErrorHandler from "../../../components/shared/with-error-handler";

const PostBCSteps = () => {
    return (
        <ErrorHandler>
            <PostBcStepMapper />
        </ErrorHandler>
    );
};

export default PostBCSteps;
