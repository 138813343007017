import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_EVALUATION_BRAND
    SET_EVALUATION_MODEL
    SET_EVALUATION_YEAR
    SET_EVALUATION_DATA
    SET_EVALUATION_DATA_BY_VARIANTS
    SET_BOOKING_DETAILS

    CREATE_LEAD_APPOINTMENT_REQUEST
    CREATE_LEAD_APPOINTMENT_SUCCESS
    CREATE_LEAD_APPOINTMENT_FAILURE

    UPDATE_LEAD_APPOINTMENT_SUCCESS
    UPDATE_LEAD_APPOINTMENT_FAILURE
    UPDATE_LEAD_APPOINTMENT_REQUEST

    FETCH_LEAD_APPOINTMENT_INFO_REQUEST
    FETCH_LEAD_APPOINTMENT_INFO_SUCCESS
    FETCH_LEAD_APPOINTMENT_INFO_FAILURE

    FETCH_DS_OFFERED_PRICE_REQUEST
    FETCH_DS_OFFERED_PRICE_SUCCESS
    FETCH_DS_OFFERED_PRICE_FAILURE
    RESET_CAR_EVALUATION

    FETCH_APPOINTMENT_SLOT_REQUEST
    FETCH_APPOINTMENT_SLOT_SUCCESS
    FETCH_APPOINTMENT_SLOT_FAILURE
    SET_APPOINTMENT_SLOTS

    CONFIRM_LEAD_APPOINTMENT_REQUEST
    CONFIRM_LEAD_APPOINTMENT_SUCCESS
    CONFIRM_LEAD_APPOINTMENT_FAILURE

    CAR_SELF_INSPECTION_REQUEST
    CAR_SELF_INSPECTION_SUCCESS
    CAR_SELF_INSPECTION_FAILURE

    TOGGLE_APPOINMENT_LOCATION_PICKER
    SET_IS_FETCH_INSPECTION_SLOTS
    SET_SELLER_RA
    SET_IS_SELLER_PREFERRED_DATE_SELECTION
`,
    {
        prefix: "sellercarevaluation/"
    }
);
