import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import SeasonalSaleHome from "./component";

// Actions
import { reloadCarList } from "../car-listing/actions";
import { clearAllFilters } from "../filters/actions";

const mapStateToProps = ({
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName,
            hasCitySkip
        } = {}
    },
    config: {
        activeSaleConfig = {}
    }
}) => ({
    selectedCityCode,
    selectedCityName,
    hasCitySkip,
    activeSaleConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SeasonalSaleHome);
