/* eslint-disable max-statements */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import InputRadioButton from "../../shared/input-radio-button";
import Button from "../../shared/button";
import InputMobile from "../../shared/input-mobile";
import AppStore from "./images/app-store.png";
import GooglePlay from "./images/google-play.png";
import { APP_SENT_MESSAGE, COUNTDOWN_INTERVAL, DEFAULT_SOURCE_TYPE, SOURCE_TYPES, STATUS_TYPE } from "../get-cars24-app-v2/constants";
import { COUNTRY_CODE, DOWNLOAD_APP_URL, DOWNLOAD_APP_URL_EMAIL, NUMBER } from "../../../constants/app-constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { mobileNumberValidatorAE as mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import { validateEmail } from "../../../utils/validators/email-validator";
import InputText from "../../shared/input-text";
import DownloadAppSuccessStrip from "../../shared/download-app-success-strip";
import DownloadAppPendingStrip from "../../shared/download-app-pending-strip";

const ShareAppLink = ({
    sendAppLinkToUserConnect
}) => {

    const [selectedSource, setSelectedSource] = useState(DEFAULT_SOURCE_TYPE);
    const [userMobile, setUserMobile] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [sendingLink, setSendingLink] = useState(false);
    const [countdownInterval, setCountdownInterval] = useState(0);
    const [appSentStatus, setAppSentStatus] = useState(null);
    const [prevInput, setPrevInput] = useState("");
    const intervalRef = useRef(null);
    const onChangeSelection = (displayText, e) => {
        setUserEmail("");
        setUserMobile("");
        setSelectedSource({
            key: e.target.value,
            displayText
        });
    };

    const handleMobile = (e) => {
        const { value } = e.target;
        setUserMobile(value);
    };

    const onEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    const resetTimer = () => {
        window.clearInterval(intervalRef.current);
        setCountdownInterval(0);
    };

    const startTimer = () => {
        intervalRef.current = window.setInterval(() => {
            setCountdownInterval(c => {
                if (c === 1) {
                    resetTimer();
                }
                return c - 1;
            });
        }, COUNTDOWN_INTERVAL);
    };

    const handleStatusStripState = (type) => {
        setAppSentStatus(type ? type : null);
        if (type) {
            window.setTimeout(() => {
                setAppSentStatus(null);
            }, NUMBER.FIVE_THOUSAND);
        }
    };

    const onStoreClick = () => {
        trackDesktopCustomEventsAE("desktopShareLink");
    };

    const onClickShareLink = () => {
        if (countdownInterval > 1 && prevInput === (selectedSource.key === "phone" ? userMobile : userEmail)) {
            handleStatusStripState(STATUS_TYPE.PENDING);
            return;
        }
        setSendingLink(true);
        const payload = {
            type: "app_download",
            data: {
                addressType: selectedSource.key === "phone" ? "PHONE" : "EMAIL",
                countryCode: COUNTRY_CODE.AE,
                to: selectedSource.key === "phone" ? [userMobile] : [userEmail],
                variables: {
                    link: selectedSource.key === "phone" ? DOWNLOAD_APP_URL : DOWNLOAD_APP_URL_EMAIL
                }
            }
        };
        sendAppLinkToUserConnect(payload).then(() => {
            setPrevInput(selectedSource.key === "phone" ? userMobile : userEmail);
            handleStatusStripState(STATUS_TYPE.SUCCESS);
            setCountdownInterval(NUMBER.THIRTY);
            trackDesktopCustomEventsAE(
                "b2cAppDownloadBannerListing", {
                eventLabel: selectedSource.key === "phone" ? "SMS" : "Email"
            });
            onStoreClick();
            setSendingLink(false);
            startTimer();
        });
    };

    const isClickDisabled = !mobileNumberValidator(userMobile, true) && !validateEmail(userEmail);

    return (
        <div styleName={"styles.wrapper"}>
            {appSentStatus &&
                <div styleName={"styles.messageStrip"}>
                    {appSentStatus === STATUS_TYPE.SUCCESS && <DownloadAppSuccessStrip onClose={handleStatusStripState} message={APP_SENT_MESSAGE[selectedSource.key][appSentStatus]} />}
                    {appSentStatus === STATUS_TYPE.PENDING && <DownloadAppPendingStrip onClose={handleStatusStripState} message={APP_SENT_MESSAGE[selectedSource.key][appSentStatus]} />}
                </div>
            }
            <div styleName={"styles.appIcons"} onClick={onStoreClick}>
                <h4 styleName={"styles.downloadText"}>Download app from</h4>
                <div styleName={"styles.buttonWrap"}>
                    <a href="https://apps.apple.com/ae/app/cars24-buy-used-car-in-uae/id1564454362" target=""  >
                        <img src={AppStore} alt="App Store" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.cars24.uaeusedcars" target="">
                        <img src={GooglePlay} alt="Google Play" />
                    </a>
                </div>
            </div>
            <div styleName={"styles.radioButtonWrap"}>
                {
                    SOURCE_TYPES.map((source) => (
                        <div key={source.key}>
                            <InputRadioButton
                                onChange={onChangeSelection.bind(null, source.displayText)}
                                checked={selectedSource.key === source.key}
                                id={source.key}
                                text={source.displayText}
                                value={source.key} />
                        </div>))
                }
            </div>
            <div styleName={"styles.inputWrapper"}>
                {selectedSource.key === "phone" && <InputMobile
                    value={userMobile}
                    onChange={handleMobile}
                    placeholder="Mobile Number"
                    countryCode={"971 "}
                    validator={mobileNumberValidator}
                />}
                {selectedSource.key === "email" && <InputText
                    value={userEmail}
                    onChange={onEmailChange}
                    validator={validateEmail}
                    errorMessage="Please enter a valid email!"
                    placeholder="Enter Email" />}
                <Button disabled={isClickDisabled} isLoading={sendingLink} text={sendingLink ? "Sharing Link" : "SHARE APP LINK"} onClick={onClickShareLink} />
            </div>
        </div>
    );
};

ShareAppLink.propTypes = {
    sendAppLinkToUserConnect: PropTypes.func
};

export default ShareAppLink;
