import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarListingFaq from "./component";

const mapStateToProps = ({
    carListing: {
        metaContent: {
            faqs
        }
    },
    config: { platform }
}) => ({
    platform,
    faqs
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarListingFaq);
