/* eslint-disable max-params */
export default (api) => {

    const getNoonPaymentStatus = (orderId) => {
        return api.get(`/v1/client/order/${orderId}`);
    };

    const getBeneficiaryStatus = (token) => {
        return api.get(`/v1/dapi/payment/beneficiary/get`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const putCBDPaymentStatus = (payload = {}, token) => {
        return api.put(`/v1/cbduaepg/delivery-status/update`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    return {
        getNoonPaymentStatus,
        getBeneficiaryStatus,
        putCBDPaymentStatus
    };
};
