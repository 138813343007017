import { AE_CITY } from "../../../constants/app-constants";
import { UaeApiGatewayService } from "../../../service";
import { findByKey } from "../../../utils/helpers/find-by-key";
import saveCookie from "../../../utils/helpers/save-cookie";
import Types from "./types";

const updateCity = (city) => (dispatch, store) => {
    const cityList = store().config.cityList;
    const isCityName = isNaN(Number(city));
    const selectedCity = findByKey(cityList, isCityName ? "name" : "id", city);
    saveCookie(AE_CITY, selectedCity.code);
    return dispatch({
        type: Types.UPDATE_CITY,
        city,
        cityList
    });
};

export const updateCityFromCookie = (data) => (dispatch) => dispatch({
    type: Types.UPDATE_CITY_FROM_COOKIE,
    data
});

// eslint-disable-next-line max-params
const updateCityWithCode = (cityCode, lat = null, lng = null, hasCitySkip = false, name) => (dispatch, store) => {
    const cityList = store().config.cityList;
    const selectedCity = findByKey(cityList, "code", cityCode);
    saveCookie(AE_CITY, selectedCity.code);
    return dispatch({
        type: Types.UPDATE_CITY_WITH_CODE,
        cityCode,
        cityList,
        lat,
        lng,
        hasCitySkip,
        name: name || selectedCity?.name
    });
};

const updateSelectedHub = (hub) => (dispatch) => {
    return dispatch({
        type: Types.UPDATE_HUB_SELECTED,
        selectedHub: { ...hub }
    });
};

const updateLocations = (locations) => (dispatch) => {
    return dispatch({
        type: Types.SET_LOCATIONS,
        locations
    });
};

const fetchHubLocations = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        UaeApiGatewayService.fetchHubLocations(params).then((response) => {
            if (!params) {
                dispatch(updateLocations(response.data));
            }
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

export {
    updateCity,
    updateCityWithCode,
    fetchHubLocations,
    updateSelectedHub
};
