import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SeoLinksListingPageV2 from "./component";

const mapStateToProps = ({
    config: { platform },
    carDetails: {
        footerMetaContent: carDetailsFooterMetaContent
    },
    carListing: {
        footerMetaContent: carListingFooterMetaContent
    }
}) => ({

        carListingFooterMetaContent,
        platform,
        carDetailsFooterMetaContent

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SeoLinksListingPageV2);
