/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import { findByKey } from "../../../utils/helpers/find-by-key";
import toTitleCase from "../../../utils/helpers/to-title-case";
import IconColorSelected from "./images/color-tick.svg";

const COLOR_MAP = {
    WHITE: "styles.white",
    SILVER: "styles.silver",
    BLUE: "styles.blue",
    RED: "styles.red",
    GREY: "styles.grey",
    BROWN: "styles.brown",
    GOLD: "styles.gold",
    ORANGE: "styles.orange",
    BEIGE: "styles.beige",
    BLACK: "styles.black",
    GREEN: "styles.green",
    MAROON: "styles.maroon",
    YELLOW: "styles.yellow",
    BRONZE: "styles.bronze",
    BURGUNDY: "styles.burgundy",
    PURPLE: "styles.purple",
    OTHER: "styles.other",
    "DARK MOON BLUE": "styles.darkMoon"
};
const ColorTypeRevamp = ({ appliedFilters, filter, onOptionSelection }) => {
    const {
        options
    } = filter;

    const selectOption = (filterKey, optionKey, isSelected, displayText) => {
        onOptionSelection({
            filterKey,
            optionKey,
            isSelected,
            displayText
        });
    };

    const otherFilterData = findByKey(options, "key", "OTHER");
    const isOtherSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: otherFilterData.key }, appliedFilters);
    return (
        <div styleName="styles.genericWrap styles.colorFilterWrap">
            {options.map((option) => {
                const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: option.key }, appliedFilters);
                return (
                    option.key !== "OTHER" ?
                        <div styleName={`styles.colorTypeWrap`} key={option.key} onClick={selectOption.bind(null, filter.key, option.key, !isSelected, option.displayText)} id={option.key}>
                            <div styleName={`styles.colorRevamp ${isSelected ? `styles.active` : ``}`}>
                                <span styleName={COLOR_MAP[option.displayText] || ""} />
                                {isSelected && <img src={IconColorSelected} styleName={"styles.activeColor"} alt="" />}
                            </div>
                            <p styleName={`styles.colorName ${isSelected ? "styles.activeColorName" : ""}`}>{toTitleCase(option.displayText)}</p>
                        </div> : ""
                );
            })}
            <div styleName={"styles.colorTypeWrap"} key={otherFilterData.key}
                onClick={selectOption.bind(null, filter.key, otherFilterData.key, !isOtherSelected, otherFilterData.displayText)}
                id={otherFilterData.key}
            >
                <div styleName={`styles.colorRevamp ${isOtherSelected ? `styles.active` : ``}`}>
                    {!isOtherSelected && <span styleName={COLOR_MAP[otherFilterData.displayText] || ""} />}
                </div>
                <p styleName={"styles.colorName"}>{otherFilterData.displayText}</p>
            </div>
        </div>
    );
};
ColorTypeRevamp.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array
};
export default ColorTypeRevamp;
