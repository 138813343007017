import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_EXPERIMENT
    SET_GAID_EXPERIMENT
    ALL_CLEAR_EXPERIMENT
    SET_STATSIG_INITIALIZATION
    CLEAR_EXPERIMENT
`,
    {
        prefix: "abexperiment/"
    }
);
