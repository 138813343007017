import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_LEADS_FAILURE
        FETCH_LEADS_SUCCESS
    `,
    {
        prefix: "sellerleadssnackbar/"
    }
);
