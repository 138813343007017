/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import dashedLowercase from "../helpers/dashed-lowercase";
import { FILTER_ENTITIES } from "./constants";

const getSitemapLinks = (filter = {}, selectedCityName, makeModel = "") => {
    const filterOptions = makeModel === "makeModel" ?
        filter && (filter?.subFilter?.options || filter?.subFilter?.suggestions) :
        filter && (filter.suggestions || filter.options || filter.buckets);
    let bucketOptions = [];
    if (makeModel === "makeModel") {
        bucketOptions = filterOptions && filterOptions.map((f) => {
            const title = `Buy used ${dashedLowercase(filter.displayText)} ${dashedLowercase(f.displayText)} cars`;
            const rangeLink = `/buy-used-${dashedLowercase(filter.displayText)}-${dashedLowercase(f.displayText)}-cars-${dashedLowercase(selectedCityName)}/`;
            return {
                title,
                url: rangeLink
            };
        });
    } else if (filter.key === FILTER_ENTITIES.YEAR) {
        bucketOptions = filterOptions && filterOptions.map((f) => {
            const title = f.displayText.includes("-") && `between ${f.min}-${f.max}`;
            const rangeText = f.displayText.includes("-") && `between-${f.min}-to-${f.max}`;
            const rangeLink = `/buy-used-cars-${rangeText}-year-${dashedLowercase(selectedCityName)}/`;
            return {
                title: `Buy used cars ${title}`,
                url: rangeLink
            };
        });
    } else {
        bucketOptions = filterOptions && filterOptions.map((f) => {
            const title = `Buy used ${dashedLowercase(f.displayText)} cars`;
            const rangeLink = `/buy-used-${dashedLowercase(f.displayText)}-cars-${dashedLowercase(selectedCityName)}/`;
            return {
                title,
                url: rangeLink
            };
        });
    }
    return bucketOptions;
};

export default getSitemapLinks;
