
const sendEvent = (data) => {
    try {
        if (typeof window !== "undefined" && typeof window.ReactNativeWebView !== "undefined") {
            const dataStringified = JSON.stringify({ ...data });
            window.ReactNativeWebView.postMessage(dataStringified);
        }
    } catch (err) {
    //    console.log(err);
    }
};

export const trackWebViewEvents = (event, extras = {}) => {
    const data = { ...extras, event };
    sendEvent(data);
};
