import React, { useEffect, useRef } from "react";
import loadable from "@loadable/component";
const SellerLandingHero = loadable(() => import("../seller-landing-hero"), { ssr: true });
const SellerHowToSell = loadable(() => import("../seller-how-to-sell"), { ssr: true });
const SellerWhySellToCars = loadable(() => import("../seller-why-sell-to-cars"), { ssr: true });
const SellerGetAppLink = loadable(() => import("../seller-get-app-link"), { ssr: true });
const SellerHappyToHelp = loadable(() => import("../seller-happy-to-help"), { ssr: true });

import styles from "./styles.css";
import { MAIL_CARE_HELP_CENTER } from "../../../constants/app-constants";
import { SELLER_DESKTOP_EVENTS } from "../../../tracking/desktop/ae.desktop-seller-events";
import SellerFaqLanding from "../seller-faq-landing";
import { SELLER_CALL_NUMBER } from "../../../constants/seller-app-constants";
import { SELLER_CITY_CODES } from "../../../constants/ae/sell-car-online";
import { useParams } from "react-router-dom";
import SellerSeoContent from "../../shared/seller-seo-content";
import { trackDesktopSellerCustomerEvents } from "../../../tracking/desktop/tracking";
import { loadGoogleMap } from "../../ae.configuration/dynamic-scripts";

const SellCarHome = () => {
    const appLinkRef = useRef();
    const { city } = useParams() || {};
    const isValidCity = city && Object.keys(SELLER_CITY_CODES).includes(city?.toLowerCase());
    useEffect(() => {
        trackDesktopSellerCustomerEvents(SELLER_DESKTOP_EVENTS.homePageLoad);
        loadGoogleMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDownload = () => {
        trackDesktopSellerCustomerEvents(SELLER_DESKTOP_EVENTS.sellDownloadClick);
        appLinkRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    };
    const handleMailClick = () => {
        if (window) {
            window.open(`mailto:${MAIL_CARE_HELP_CENTER}`, "_self");
        }
    };

    const handleCallClick = () => {
        if (window) {
            window.open(`tel:${SELLER_CALL_NUMBER}`, "_self");
        }
    };
    return (
        <React.Fragment>
            <SellerLandingHero handleDownload={handleDownload} city={isValidCity && city} />
            <SellerHowToSell />
            <SellerWhySellToCars />
            <div ref={appLinkRef}>
                <SellerGetAppLink />
            </div>
            <SellerHappyToHelp onClickCall={handleCallClick} onClickMail={handleMailClick} />
            <section styleName={"styles.faqWrapper"}>
                <SellerFaqLanding />
            </section>
            <div className="container"><SellerSeoContent city={city} isWeb={true} /></div>
        </React.Fragment>

    );
};
export default SellCarHome;
