import AutoComplete from ".";

const MIN_LENGTH_REQUIRED = 3;

export const googlePlacesOptions = async (input, type) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (input.length < MIN_LENGTH_REQUIRED) reject([]);
            const response = await AutoComplete.getInstance(type).getPlaces({ search: input });
            const result = response.map((item) =>
                ({ label: item.description, value: item.place_id, formattedPlace: item.structured_formatting }));
            resolve(result);
        } catch (err) {
            reject([]);
        }
    });
};
