import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import CarLoanHome from "./component";

const mapStateToProps = ({
    location: {
        selectedCity: {
            name: selectedCityName
        } = {}
    }
}) => ({
    selectedCityName
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarLoanHome);
