import React, { useState, useEffect } from "react";
import styles from "./styles.css";
import ArrowIcon from "../../shared/arrow";
import { PropTypes } from "prop-types";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import SchemaValuationFaq from "../../shared/schema-valuation-faq";
import { NUMBER } from "../../../constants/app-constants";
import arrayChunks from "../../../utils/helpers/array-chunks";

// eslint-disable-next-line no-magic-numbers
const PilotFaq = ({
    listingFaq,
    homePageFaq,
    heading = "FREQUENTLY ASKED QUESTIONS",
    chunkSize = NUMBER.FOUR,
    columns = NUMBER.ONE,
    pushEvent = true,
    fetchFaqConnect,
    isListingPage = false
}) => {
    const [isOpen, setIsOpen] = useState({
        0: true
    });
    const ssrContent = arrayChunks(listingFaq.map((item, index) => ({...item, id: index})), chunkSize);
    const [faqContent, setFaqContent] = useState(ssrContent || []);
    const [faqSchema, setFaqSchema] = useState(listingFaq || []);

    const handleHomepageFaqs = () => {
        let destructedListFaqs = [];
        homePageFaq.forEach(({ faqList }) => {
            destructedListFaqs = [...destructedListFaqs, ...faqList];
        });
        setFaqSchema(destructedListFaqs);
        const content = arrayChunks(destructedListFaqs.map((item, index) => ({...item, id: index})), chunkSize);
        setFaqContent(content);
    };

    const getColumnWidth = () => {
        const columnMap = {
            1: "col-12",
            2: "col-12"
        };
        return columnMap[columns];
    };

    const onClick  = (id) => {
        setIsOpen((state) => (
            { [id]: !state[id] }
        ));
        if (pushEvent && !isListingPage) {
            trackDesktopCustomEventsAE("faqClickHome", {
                eventLabel: homePageFaq[id].question || "NA"
            });
        }
    };

    useEffect(() => {
        if (isListingPage) {
            const content = arrayChunks(listingFaq.map((item, index) => ({...item, id: index})), chunkSize);
            setFaqSchema(listingFaq);
            setFaqContent(content);
        } else if (!isListingPage && !homePageFaq) {
            fetchFaqConnect({ isHomepage: true});
        } else {
            handleHomepageFaqs(homePageFaq);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingFaq]);

    return (
        <div styleName={"styles.faq"}>
            <h2>{heading}</h2>
            <div className="row">
                {
                    faqContent.map((faqList, index) => (
                        <div key={index} className={getColumnWidth()}>
                            {
                                faqList.map(({id, question, answer}) => {
                                    const rightArrow = 0;
                                    const downArrow = 180;
                                    return (
                                        <div styleName={"styles.wrap"} key={id}>
                                            <h3 styleName={`styles.title ${isOpen[id] ?
                                                "styles.active" : ""}`}
                                            onClick={() => onClick(id)}
                                            >{question}
                                                <ArrowIcon orangeDownArrow
                                                    rotateBy={
                                                        isOpen[id] ? downArrow : rightArrow
                                                    } />
                                            </h3>
                                            <p styleName={isOpen[id] ? "styles.content" : "styles.hideContent"}
                                                dangerouslySetInnerHTML={
                                                    { __html: answer }
                                            } />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ))
                }
                {faqSchema && <SchemaValuationFaq faqs={faqSchema} />}
            </div>
        </div>
    );
};

PilotFaq.propTypes = {
    listingFaq: PropTypes.array,
    homePageFaq: PropTypes.array,
    faqs: PropTypes.array,
    chunkSize: PropTypes.number,
    columns: PropTypes.number,
    fetchFaqConnect: PropTypes.func,
    pushEvent: PropTypes.bool,
    heading: PropTypes.string,
    isListingPage: PropTypes.bool
};

export default PilotFaq;
