import { createReducer } from "../../../utils/redux/create-reducer";
import { AB_TEST_VARIATIONS } from "../../../constants/app-constants";
import Types from "./types";

export const INITIAL_STATE = {
    // Buyer
    showCurrentLocationCta: AB_TEST_VARIATIONS.CONTROL,
    statsigInitialized: false

    // Seller
};

export const setExperiment = (state = INITIAL_STATE, {
    experimentType, experimentData, flattened = false
}) => {
    return {
        ...state,
        ...(flattened ? { [experimentType]: experimentData } :
            {
                [experimentType]: {
                    data: experimentData
                }
            })
    };
};

export const setGaIdExperiment = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const setStatsigInitialization = (state = INITIAL_STATE, { statsigInitialized }) => {
    return { ...state, statsigInitialized };
};
export const allClearExperiment = () => {
    return {};
};
export const clearExperiment = ({ updatedExperiments }) => {
    return {
        updatedExperiments
    };
};
export const HANDLERS = {
    [Types.SET_EXPERIMENT]: setExperiment,
    [Types.SET_GAID_EXPERIMENT]: setGaIdExperiment,
    [Types.SET_STATSIG_INITIALIZATION]: setStatsigInitialization,
    [Types.ALL_CLEAR_EXPERIMENT]: allClearExperiment,
    [Types.CLEAR_EXPERIMENT]: clearExperiment
};

export default createReducer(INITIAL_STATE, HANDLERS);
