export default {
    CAR_DETAILS_PAGE: "Car Detailed Page",
    START_PURCHASE: "Start Purchase",
    BOOKING_INITIATED: "BI",
    FINANCE_PROCEED: "Finance Proceed",
    DELIVERY_PROCEED: "Delivery Proceed",
    PAYMENT_INITIATED: "Payment Initiated",
    BOOKING_CONFIRMATION: "booking confirmation",
    CANCELLATION: "Cancellation",
    FINANCE_EXPLORE: "FINANCE_EXPLORE",
    RESUME_SESSION_DEEPLINK: "RESUME_SESSION_DEEPLINK",
    FULL_PAYMENT_COUPON: "FP Coupon",
    // SELLER EVENTS
    SELL_HOME_PAGE: "Sell Home Page",
    SELL_GET_CAR_PRICE: "Sell Get Car Price",
    SELL_MAKE: "Sell Make",
    SELL_MODEL: "Sell Model",
    SELL_YEAR: "Sell Year",
    SELL_VARIANT: "Sell Variant",
    SELL_TIME: "Sell Time",
    SELL_MORE_CAR_BRANDS: "Sell More Car Brands",
    SELL_CANCEL_APPOINTMENT: "SELL_CANCEL_APPOINTMENT",
    B2C_MOBILE_NUMBER_1DIGIT: "b2c_mobile_number_1digit",
    SELL_NUMBER_PROCEED: "sell_number_proceed",
    SELL_OTP_ENTERED: "sell_otp_entered",
    SELL_SEND_OTP: "sell_send_otp",
    SELL_OTP_VERIFIED: "sell_otp_verified",
    SELL_OTP_VERIFY_CLICK: "sell_otp_verify_click"
};
