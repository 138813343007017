import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import withLogin from "../with-login";
import ExchangeCouponSliderCard from "./component";

// Actions
import { updateUnlockCoupon } from "../post-booking-confirmation/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        secureToken,
        mobile: userId
    }
}) => ({
    userId,
    isLoggedIn,
    secureToken
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUnlockCouponConnect: updateUnlockCoupon
}, dispatch);

export default withLogin(connect(mapStateToProps, mapDispatchToProps)(ExchangeCouponSliderCard));
