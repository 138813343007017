import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        GET_MY_BOOKINGS_INITIATED
        REFRESH_MY_BOOKINGS_INITIATED
        GET_MY_BOOKINGS_SUCCESS
        GET_MY_BOOKINGS_FAILURE

        BOOKING_CANCELLATION_INITIATED
        BOOKING_CANCELLATION_SUCCESS
        BOOKING_CANCELLATION_FAILURE

        UPDATE_SELECTED_CAR
`,
    {
        prefix: "mybookings/"
    }
);
