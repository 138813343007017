
import { LOCALSTORAGE_CONSTANTS } from "../../constants/app-constants";
import { decodeToBase64, encodeToBase64 } from "./filter-resume-session";
import { clearItem, getItem, setItem } from "./storage-crud";

export const getPrevAppliedFilters = () => {
    try {
        const filterSessionData = getItem(LOCALSTORAGE_CONSTANTS.PREV_APPLIED_FILTERS);
        const updatedFilterSessionData = {
            latestFilterUrl: decodeToBase64(filterSessionData?.latestFilterUrl)
        };
        return updatedFilterSessionData;
    } catch (e) {
        clearItem(LOCALSTORAGE_CONSTANTS.PREV_APPLIED_FILTERS);
        return { };
    }
};

export const addToPrevAppliedFilters = (latestFilterUrl = "") => {
    try {
        const filterSessionData = {
            latestFilterUrl: encodeToBase64(latestFilterUrl || "")
        };
        setItem(filterSessionData, LOCALSTORAGE_CONSTANTS.PREV_APPLIED_FILTERS);
    } catch (e) {
        setItem({}, LOCALSTORAGE_CONSTANTS.PREV_APPLIED_FILTERS);
    }
};
