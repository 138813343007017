/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

import Manual from "./images/manual.svg";
import Automatic from "./images/automatic.svg";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";

// import Wagon from "./images/wagon.svg";

const TRANSMISSION = {
    Manual,
    Automatic
};

const TransmissionTypeRevamp = ({ appliedFilters, filter, onOptionSelection }) => {
    const {
        options
    } = filter;

    const selectOption = (filterKey, optionKey, isSelected, displayText) => {
        onOptionSelection({
            filterKey,
            optionKey,
            isSelected,
            displayText
        });
    };

    return (
        <div styleName="styles.genericWrap styles.transmissionWrap">
            {options.map((option) => {
                const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: option.key }, appliedFilters);
                return (
                    <div onClick={selectOption.bind(null, filter.key, option.key, !isSelected, option.displayText)} key={option.key} styleName={`styles.transmissionTypeWrap ${isSelected ? `styles.active` : ``}`}>
                        <span styleName={"styles.transmissionImage"}><img src={TRANSMISSION[option.key] || Manual} /></span>
                        <span styleName={"styles.transmissionnName"}>{option.displayText}</span>
                    </div>
                );
            })}
        </div>
    );
};
TransmissionTypeRevamp.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array
};
export default TransmissionTypeRevamp;
