export default (value) => {
    // Check for primitive data types (null, undefined)
    if (value === null || value === undefined) {
        return true;
    }

    // Check for empty strings
    if (typeof value === "string" && value.trim() === "") {
        return true;
    }

    // Check for empty arrays and objects
    if (typeof value === "object") {
        // Handle arrays (length check)
        if (Array.isArray(value) && value.length === 0) {
            return true;
        }

        // Handle objects (no enumerable own properties)
        if (!Object.keys(value).length) {
            return true;
        }
    }

    // Not empty for other data types
    return false;
};
