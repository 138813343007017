/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Utilities
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";

// Component
import Checkbox from "../../shared/checkbox/component";

const BookingFilter = ({ appliedFilters, filter, onOptionSelection }) => {

    const isChecked = !!getAppliedFilter({ filterKey: filter.key, optionKey: "false" }, appliedFilters);

    const selectOption = (filterKey, optionKey, isSelected, displayText) => {
        onOptionSelection({
            filterKey,
            optionKey,
            isSelected,
            displayText
        });
    };

    return (
        <div styleName={"styles.itemWrap"}>
            <Checkbox checkedStatus={isChecked} onClickCallback={selectOption.bind(null, filter.key, "false", !isChecked, "Non-Reserved")} />
            <div styleName={`styles.listTextRevamp ${isChecked ? `styles.active` : ``}`}>
                Exclude Reserved Cars
            </div>
        </div>
    );
};
BookingFilter.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array
};
export default BookingFilter;
