import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Actions
import { fetchCarDetails } from "../car-details/actions";
import { submitCarOffer } from "../checkout-revamp-v2/action";
import { showToast } from "../../shared/toast-message/actions";

// Component
import TdScheduleC2C from "./component";

const mapStateToProps = ({
    postBcDetails,
    carDetails,
    checkout: {
        order
    }
}) => ({
    postBcDetails,
    carDetails,
    order
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarDetailsConnect: fetchCarDetails,
    submitCarOfferConnect: submitCarOffer,
    showToastConnect: showToast
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TdScheduleC2C));
