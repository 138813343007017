import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
    fetchListingCount,
    setSearchResultTrackingData,
    updateAllSubFilterOptions,
    updateAppliedFilters,
    updateAppliedSuggestions,
    updateFilterOptionData,
    updateSelectedOnScreenFilter
} from "../filters/actions";
import FilterRevamp from "./component";
import { reloadCarList, updateSSRStatus } from "../car-listing/actions";

const mapStateToProps = ({
    filters: {
        selectedOnScreenFilter,
        allFilters,
        isListingCountLoading,
        selectedFilters,
        selectedSuggestions,
        urlOptions
    },
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    } = {},
    carListing: {
        isSSR
    },
    config: {
        activeSaleConfig
    }
}) => ({
    selectedOnScreenFilter,
    selectedCityName,
    allFilters,
    isListingCountLoading,
    selectedCityCode,
    selectedFilters,
    selectedSuggestions,
    urlOptions,
    isSSR,
    activeSaleConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
    fetchListingCountConnect: fetchListingCount,
    updateAppliedFiltersConnect: updateAppliedFilters,
    updateAppliedSuggestionsConnect: updateAppliedSuggestions,
    reloadCarListConnect: reloadCarList,
    setSearchResultTrackingDataConnect: setSearchResultTrackingData,
    updateAllSubFilterOptionsConnect: updateAllSubFilterOptions,
    updateSSRStatusConnect: updateSSRStatus

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterRevamp));
