import { listingApiUrl } from "../constants/url-constants";

export default (api) => {
    const BASE_API_URL = `${listingApiUrl()}/secure/v1`;

    const fetchWishlistCarsList = (token) => {
        return api.get(`${BASE_API_URL}/shortlist/vehicles`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const fetchWishlistCars = (token, params = {}) => {
        return api.get(`${BASE_API_URL}/shortlist`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const addOrRemoveFromWishlist = (token, data) => {
        return api.post(`${BASE_API_URL}/shortlist`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    return {
        fetchWishlistCars,
        fetchWishlistCarsList,
        addOrRemoveFromWishlist
    };
};
