import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DetailsCtaSectionV4 from "./component";
import { updateSpincarModalStatus } from "../car-details-v2/actions";
import { fetchOrderPostBcDetailsV2, resetPostBc } from "../post-booking-confirmation-v2/actions";

const mapStateToProps = ({
    carDetails,
    checkout: {
        order
    },
    config: {
        bookingAllowed,
        activeBookingCount
    },
    user: { mobile }
}) => ({
    carDetails,
    order,
    bookingAllowed,
    activeBookingCount,
    mobile
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSpincarModalStatusConnect: updateSpincarModalStatus,
    fetchOrderPostBcDetailsV2Connect: fetchOrderPostBcDetailsV2,
    resetPostBcConnect: resetPostBc
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsCtaSectionV4);
