import React from "react";
import PropTypes from "prop-types";
import styles from "./tick.css";

const Tick = ({ tick, displayValue }) => {
    return (
        <div className="tick">
            <div className="tickContent" styleName={`styles.sliderTicks ${displayValue ? "styles.valueOnTrack" : ""}`}
                style={{
                    left: `${tick.percent}%`
                }}
            >
                {displayValue && <span>{tick.value}</span>}
            </div>
        </div>
    );
};

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
    displayValue: PropTypes.bool
};

Tick.defaultProps = {
    format: d => d
};

export default Tick;
