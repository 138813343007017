import React, { Fragment } from "react";
import styles from "./handle.css";
import PropTypes from "prop-types";
const Handle = ({
    domain: [min, max],
    handle: { id, value, percent },
    disabled,
    getHandleProps,
    animatedHandle,
    handleColor,
    getHandleID,
    emojiType
}) => {
    return (
        <Fragment>
            <div className={`left${percent}`}
                style={{
                    left: `${percent}%`,
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    WebkitTapHighlightColor: "rgba(0,0,0,0)",
                    zIndex: 5,
                    width: 28,
                    height: 42,
                    cursor: "pointer",
                    // border: '1px solid white',
                    backgroundColor: "none"
                }}
                {...getHandleProps(id)}
                {...getHandleID(id)}
            />
            <div styleName={"styles.sliderCircle"}
                className={
                    // eslint-disable-next-line no-nested-ternary
                    (animatedHandle && !emojiType) ? `rippleEffect ${handleColor || "orange"}` : emojiType ? `sliderHandleEmoji left${percent} ${emojiType}` : "sliderCircle"
                }
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `${percent}%`,
                    backgroundImage: disabled ? "#fff" :
                        `${handleColor === "orange" ?
                            "linear-gradient(225deg, #f69b00, #ed6a00)" :
                            "linear-gradient(225deg, #ff6359, #ff6359)"}`
                }}
            />
        </Fragment>
    );
};

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    animatedHandle: PropTypes.bool,
    handleColor: PropTypes.string,
    getHandleID: PropTypes.func,
    emojiType: PropTypes.bool
};

Handle.defaultProps = {
    disabled: false
};

export default Handle;
