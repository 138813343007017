import React from "react";

// Styling
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <div styleName={"styles.postBcWrapper"}>
            <div styleName={"styles.postBcContainer"}>
                <div styleName={"styles.postBcRow"}>
                    <div styleName={"styles.leftSection"}>
                        <div styleName={"styles.header"}>
                            <div className="shimmer" styleName={"styles.backArrow"} />
                            <div className="shimmer" styleName={"styles.heading"} />
                        </div>
                        <div styleName={"styles.hubAddress"} className="shimmer"  />
                        <div styleName={"styles.para"} className="shimmer"  />
                        <div styleName={"styles.availableDate"} className="shimmer"  />
                        <div styleName={"styles.para"} className="shimmer"  />
                        <div styleName={"styles.availableTime"} className="shimmer"  />
                    </div>
                    <div styleName={"styles.rightSec"}>
                        <div className="shimmer" styleName={"styles.bookingId"} />
                        <div className="shimmer" styleName={"styles.carCard"} />
                        <div className="shimmer" styleName={"styles.whatsNext"} />
                        <div className="shimmer" styleName={"styles.btn"} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skeleton;
