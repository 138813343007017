import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./slider-rail.css";
const SliderRail = ({ getRailProps, railColor, height = "2px" }) => {
    const customStyles = {
        backgroundColor: railColor,
        height
    };
    return (
        <Fragment>
            <div styleName={"styles.railOuter"} {...getRailProps()} />
            <div styleName={"styles.railInner"} style={customStyles} />
        </Fragment>
    );
};

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
    railColor: PropTypes.string,
    height: PropTypes.string
};

export default SliderRail;
