import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

// components
import InputMobile from "../../shared/input-mobile";
import SellerBookingOtp from "../../shared/seller-booking-otp";

// Images
import IconLock from "./image/lock.svg";
import IconSuccess from "./image/success-icon.svg";
import IconError from "./image/error-icon.svg";

// Utils
import { mobileNumberValidatorAE as mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import { OTP_MAX_LENGTH } from "../../../constants/app-constants";

const MobileVerify = ({
    mobile = "",
    errorMessage = "",
    showOtpField = false,
    handler,
    handleMobile = () => { },
    handleOtp = () => { },
    onResendClick = () => { }
}) => {
    const isMobileNumberValid = mobileNumberValidator(mobile, true);
    const [errNum, setErrNum] = useState(false);

    const showOTP = isMobileNumberValid && showOtpField;
    return (
        <React.Fragment>
            <div styleName={"styles.formGroup"}>
                <p styleName={"styles.enterPhone"}>Enter Phone Number</p>
                <InputMobile
                    value={mobile}
                    onChange={handleMobile}
                    ripple={false}
                    newTheme={true}
                    placeholder="500050000"
                    countryCode={"971 "}
                    onErrorCallback={setErrNum}
                    validator={mobileNumberValidator}
                />
                {mobileNumberValidator(mobile) && <img src={IconSuccess} styleName={"styles.validIcon"} alt="" />}
                {errNum && <img src={IconError} styleName={"styles.validIcon"} alt="" />}
            </div>
            <div styleName={"styles.safeGrid"}><img src={IconLock} alt="" />Your information is safe with us.</div>
            <div styleName={"styles.otpInputWrap"}>
                {showOTP && (
                    <SellerBookingOtp
                        otpHeading={"Enter 4 digit verification code"}
                        otpLength={OTP_MAX_LENGTH}
                        resendOTP={onResendClick}
                        onOTPVerifyCallback={handler}
                        newTheme={true}
                        onOtpChange={handleOtp}
                        isOTPInvalid={!!errorMessage}
                        errorMessage={errorMessage}
                    />
                )}
            </div>

        </React.Fragment>
    );
};

MobileVerify.propTypes = {
    mobile: PropTypes.string,
    otp: PropTypes.string,
    handleMobile: PropTypes.func,
    handler: PropTypes.func,
    handleOtp: PropTypes.func,
    showOtpField: PropTypes.bool,
    onCompleteTimer: PropTypes.func,
    onResendClick: PropTypes.func,
    showTimer: PropTypes.bool,
    errorMessage: PropTypes.bool
};

export default MobileVerify;
