import { createReducer } from "../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    mobile: "",
    lastVerifiedMobile: "",
    name: "",
    email: "",
    isLoggedIn: false,
    loginModal: false,
    error: null,
    unAuthorized: true,
    isFetched: false,
    checkAuth: false,
    isFirstLoad: true,
    leadType: "",
    socketId: null,
    isSSR: false,
    trueCallerPromptCount: 0,
    isWebview: false,
    gaId: null,
    userId: "",
    currentPageType: null,
    secureToken: "",
    isDNDActive: false,
    isWAConsent: true,
    couponList: [],
    showCouponNudge: false,
    showLocationPopup: false,
    openAICarFinderModal: false,
    showLoanBugetNudge: false,
    ip: null
};

export const setMobile = (state = INITIAL_STATE, { mobile }) => {
    return { ...state, mobile };
};

export const setLastVerifiedMobile = (state = INITIAL_STATE, { lastVerifiedMobile }) => {
    return { ...state, lastVerifiedMobile };
};

export const setProfileMobile = (state = INITIAL_STATE, { profileMobile }) => {
    return { ...state, profileMobile };
};

export const setName = (state = INITIAL_STATE, { name }) => {
    return { ...state, name };
};

export const setProfileName = (state = INITIAL_STATE, { profileName }) => {
    return { ...state, profileName };
};

export const setEmail = (state = INITIAL_STATE, { email }) => {
    return { ...state, email };
};

export const getProfileDetailsSuccess = (state = INITIAL_STATE,
    { name = "", phoneNumber = "", email = "", token = "" }
) => {
    return {
        ...state,
        name: name ? name : INITIAL_STATE.name,
        mobile: phoneNumber.toString(),
        email,
        isLoggedIn: true,
        unAuthorized: false,
        isFetched: true,
        secureToken: token
    };
};

export const getProfileDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isLoggedIn: false, isFetched: true, secureToken: "" };
};

export const markUnauthorized = (state = INITIAL_STATE) => {
    return { ...state, isLoggedIn: false, isFetched: true, secureToken: "" };
};

export const setCheckAuth = (state = INITIAL_STATE, { checkAuth }) => {
    return { ...state, isFetched: true, checkAuth };
};

export const setFirstLoad = (state = INITIAL_STATE) => {
    return { ...state, isFirstLoad: false };
};

export const updateLeadType = (state = INITIAL_STATE, { leadType }) => {
    return { ...state, leadType };
};

export const setSocketId = (state = INITIAL_STATE, { socketId }) => {
    return { ...state, socketId };
};

export const setTruecallerPromptCount = (state = INITIAL_STATE, { trueCallerPromptCount }) => {
    return { ...state, trueCallerPromptCount };
};

export const updateWebviewStatus = (state = INITIAL_STATE, { isWebview }) => {
    return { ...state, isWebview };
};

export const setGAId = (state = INITIAL_STATE, { gaId }) => {
    return { ...state, gaId };
};

export const updateCurrentPageType = (state = INITIAL_STATE, { currentPageType }) => {
    return { ...state, currentPageType };
};

export const updateProfileSuccess = (state = INITIAL_STATE,
    { name = "", email = "" }
) => {
    return {
        ...state,
        name,
        profileName: name,
        email
    };
};

export const updateDNDStatus = (state = INITIAL_STATE, { isDNDActive }) => {
    return { ...state, isDNDActive };
};

export const updateWAStatus = (state = INITIAL_STATE, { isWAConsent }) => {
    return { ...state, isWAConsent };
};

const logoutSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        ...INITIAL_STATE,
        isFetched: true,
        gaId: state?.gaId || null,
        ...(state?.showCouponNudge && { showCouponNudge: state?.showCouponNudge })
    };
};

const logoutFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error };
};

export const setLoginModal = (state = INITIAL_STATE, { isOpen }) => {
    return { ...state, loginModal: isOpen };
};

const updateShowCouponNudge = (state = INITIAL_STATE, { showCouponNudge }) => {
    return { ...state, coupon: {showCouponNudge} };
};

const updateDiscountCoupon = (state = INITIAL_STATE, { discountCoupon }) => {
    return { ...state, coupon: {discountCoupon} };
};

const updateLocationPopupShown = (state = INITIAL_STATE, { showLocationPopup }) => {
    return { ...state, showLocationPopup };
};

const updateOpenAICarFinderModal = (state = INITIAL_STATE, { openAICarFinderModal }) => {
    return { ...state, openAICarFinderModal };
};

const updateLoanbudgetNudge = (state = INITIAL_STATE, { showLoanBugetNudge }) => {
    return { ...state, showLoanBugetNudge };
};
const updateDownloadNudgeStatus = (state = INITIAL_STATE, { showDownloadNudge }) => {
    return { ...state, showDownloadNudge };
};
export const HANDLERS = {
    [Types.SET_NAME]: setName,
    [Types.SET_PROFILE_NAME]: setProfileName,
    [Types.SET_MOBILE]: setMobile,
    [Types.SET_LAST_VERIFIED_MOBILE]: setLastVerifiedMobile,
    [Types.SET_PROFILE_MOBILE]: setProfileMobile,
    [Types.SET_EMAIL]: setEmail,
    [Types.UPDATE_DND_STATUS]: updateDNDStatus,
    [Types.UPDATE_WA_STATUS]: updateWAStatus,
    [Types.GET_PROFILE_DETAILS_SUCCESS]: getProfileDetailsSuccess,
    [Types.MARK_UNAUTHORIZED]: markUnauthorized,
    [Types.CHECK_AUTH]: setCheckAuth,
    [Types.SET_FIRST_LOAD]: setFirstLoad,
    [Types.UPDATE_LEAD_TYPE]: updateLeadType,
    [Types.SET_SOCKET_ID]: setSocketId,
    [Types.TRUECALLER_PROMPT_COUNT]: setTruecallerPromptCount,
    [Types.UPDATE_WEBVIEW_STATUS]: updateWebviewStatus,
    [Types.SET_GA_ID]: setGAId,
    [Types.UPDATE_CURRENT_PAGE_TYPE]: updateCurrentPageType,
    [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
    [Types.LOGOUT_SUCCESS]: logoutSuccess,
    [Types.LOGOUT_FAILURE]: logoutFailure,
    [Types.SET_LOGIN_MODAL]: setLoginModal,
    [Types.UPDATE_SHOW_COUPON_NUDGE]: updateShowCouponNudge,
    [Types.UPDATE_LOCATION_POPUP_SHOWN]: updateLocationPopupShown,
    [Types.UPDATE_OPEN_AI_CAR_FINDER_MODAL]: updateOpenAICarFinderModal,
    [Types.UPDATE_LOAN_BUDGET_NUDGE]: updateLoanbudgetNudge,
    [Types.UPDATE_DISCOUNT_COUPON]: updateDiscountCoupon,
    [Types.UPDATE_DOWNLOAD_NUDGE_STATUS]: updateDownloadNudgeStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
