import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import UsedCarsCatagory from "./component";

// Actions
import { fetchFilters, updateSSRStatus as updateFilterSSRStatus } from "../../ae.mobile/filters/actions";
import { updateFilterOptionData } from "../filters/actions";

const mapStateToProps = ({
    filters: {
        isSSR: isFilterSSR,
        allFilters,
        appliedFilters
    }
}) => ({
    allFilters,
    appliedFilters,
    isFilterSSR
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    updateFilterSSRStatusConnect: updateFilterSSRStatus,
    updateFilterOptionDataConnect: updateFilterOptionData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsedCarsCatagory);
