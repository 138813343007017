import React from "react";
import styles from "./styles.css";
import Banner from "./images/no-car-found-banner.svg";

const BannerNoCarFound = () => {
    return (
        <div styleName={"bannerWrapper"}>
            <img src={Banner} width={138} height={130} />
        </div>
    );
};

export default BannerNoCarFound;
