import React from "react";
import styles from "./styles.css";
import H1 from "../h1/component";
import H2 from "../h2/component";
import Banner from "./images/about_us.jpg";

const AboutUsAeContent = () => {

    return (
        <React.Fragment>
            <div styleName={"styles.banner"}>
                <img src={Banner} />
            </div>
            <div className="container" styleName={"styles.main"}>
                <H1 text="About Us"/>
                <p styleName={"styles.about"}>The first-class destination for second-hand cars!</p>
                <p styleName={"styles.about"}>
                    CARS24 is your single stop for buying or selling used cars. We've brought together cutting-edge technology with world and country-wide partners
                    and more importantly, a deep understanding of what buyers and sellers need. We solve all pain points associated with selling an existing car,
                    or purchasing a pre-loved one. Whether you're buying or selling, you get a quick, easy, fair, transparent, hassle (and haggle) free process,
                    with a guarantee of top service and quality.
                </p>
                <p styleName={"styles.about"}>
                    Founded in India in 2015, we’ve now established ourselves in the United Arab Emirates. We are registered under the legal entity - Global Access
                    Cars Automobile Trading LLC.
                </p>
                <p styleName={"styles.about"}>
                    We are a next generation and fast growing ecommerce platform valued at over $1 billion dollars, aiming to revolutionise the way pre-owned cars
                    are bought in the UAE.
                </p>

                <H2 text="Our Mission"/>
                <p styleName={"styles.about"}>
                    Our mission is to revolutionise the way used cars are bought and sold in the UAE. We aim to make vehicle purchase as easy as buying a book or
                    phone online, with our efficient, reliable and hassle-free transaction experience.
                </p>
                <p styleName={"styles.about"}>
                    With continuous innovation, more partnerships, and valuable relationships with people, we want to keep solving problems for
                    consumers and add ease and value to their lives.
                </p>

                <H2 text="Our Values"/>
                <p styleName={"styles.about"}>
                    <strong>
                        We’ve told you what CARS24 does. But what is the big picture that we abide by? What values keep driving us to help you drive the vehicle you choose?
                    </strong>
                </p>
                <h3 styleName={"styles.aboutTittle"}>We trust, and build trust</h3>
                <p styleName={"styles.about"}>
                    We work hard to get the customer to trust us, and we do it by placing them first. Whether it’s evaluation, customer protection policies,
                    warranties, or just customer support - we’re there for every customer, every stakeholder. This is the bedrock of everything we do,
                    and we work hard with committed goals and timelines to keep improving.
                </p>
                <h3 styleName={"styles.aboutTittle"}>We invest in employees and partners</h3>
                <p styleName={"styles.about"}>
                    A natural extension of our first point - A culture of trust and cooperation means that every employee and partner is a crucial part of our ecosystem.
                    We invest in every one of them, and we measure success not only in terms of profits and balance sheets but in the success each employee and partner
                    sees in their professional and personal lives.
                </p>
                <h3 styleName={"styles.aboutTittle"}>We take ownership</h3>
                <p styleName={"styles.about"}>
                    We don’t believe in a system of micromanagement and constant oversight. We train our employees to take control, and they are the owners of the work they do.
                    This is inculcated from the time we hire to each project that comes their way, and in our mind, this is what defines a great workplace - the number
                    of people willing to take ownership.
                </p>
                <h3 styleName={"styles.aboutTittle"}>We believe in having high standards and topping our results</h3>
                <p styleName={"styles.about"}>
                    Perfection and progress is what we work towards. And once we achieve that, we move the bar higher and higher, so we can keep outdoing ourselves.
                </p>
                <h3 styleName={"styles.aboutTittle"}>We’re a meritocracy</h3>
                <p styleName={"styles.about"}>
                    We never let exceptional performance go unrewarded. We encourage healthy competition, but never at the expense of others. Everyone has
                    a chance to succeed, and a chance to reap the benefits. After all, the more talented people we have on board, the more there is to accomplish and reward.
                </p>
                <h3 styleName={"styles.aboutTittle"}>We’re frugal, in a prudent way</h3>
                <p styleName={"styles.about"}>
                    We’re all for spending, but only when we see it adding value - to our employees, partners, customers, or any other stakeholder. A great
                    workplace isn’t defined by bells and whistles like grand offices and fancy food, but in learning, value and profits that benefit every member of CARS24.
                </p>
                <h3 styleName={"styles.aboutTittle"}>We’re in it for the long haul</h3>
                <p styleName={"styles.about"}>
                    CARS24 is on a long journey, and we’re not taking any shortcuts. The milestones we pass, like quarterly results and yearly targets are but smaller
                    rewards in our longer and uncompromising drive to the very top, and the success we see in the long term is what keeps us going.
                </p>
            </div>
        </React.Fragment>
    );
};

export default AboutUsAeContent;
