/* eslint-disable max-statements */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { Fragment, useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { subscribe } from "valtio/vanilla";
import { captureMessage } from "@sentry/browser";
import Smartlook from "smartlook-client";

// Utils
// import EventHandler from "../../utils/event-handler";
import { loadGoogleMap, loadDapi } from "./dynamic-scripts";
// import { statsigInitialization } from "../../utils/statsig-ab-utils/initialize-user-csr";
// import isEmpty from "../../utils/helpers/is-empty";
// import getDynamicConfigData from "../../utils/statsig-dynamic-config-utils/getConfigData";
// import { checkDateWithinInterval } from "../../utils/helpers/check-date-within-interval";

// Constants
import { MAP_TYPE, DEVICE_TYPE, SENTRY_ERRORS, GOOGLE_MAP_PATH, LISTING_DETAILS_REGEX, COOKIE_CONSTANTS, NUMBER, DEFAULT_CITY } from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/routes-constants";

// Services
import { proxyAuthState } from "../../service/userAuthApi";
import { trackMobileCustomEventsAE } from "../../tracking";
import parseCookie from "../../utils/helpers/parse-cookie";
import saveCookie from "../../utils/helpers/save-cookie";
import { getItem } from "../../utils/helpers/storage-crud";
import parseLocationSearchParams from "../../utils/helpers/parse-location-search-params";

const AEConfiguration = ({
    platform,
    children,
    mapType,
    getRefreshTokenInfoConnect,
    reloadCarListConnect,
    isListingSSR,
    setProfileWithTokenConnect,
    logoutSuccessConnect,
    getConfigConnect,
    setGAIdConnect,
    mobile,
    isLoggedIn,
    isUserDetailsFetched,
    name,
    email,
    // experiments = {},
    updateCityWithCodeMobileConnect,
    updateCityWithCodeDesktopConnect,
    selectedCity,
    selectedCityInfo,
    ip,
    // setStatsigInitializationConnect,
    userProfileConnect,
    secureToken,
    gaId,
    fetchSeoContentConnect
    // updateSaleDataMsiteConnect,
    // updateSaleDataDesktopConnect,
    // activeSaleConfig = {}
}) => {
    const { pathname, search } = useLocation();
    const queryParams = parseLocationSearchParams(search);
    // const { statsigId, statsigInitialized } = experiments || {};
    /*
        initializes event handler class
    */
    // useEffect(() => {
    //     const eventInstance = EventHandler.getInstance();
    //     window.EventHandler = eventInstance;
    //     const { code } = selectedCity || {};
    //     const initateStatsig = async () => {
    //         await statsigInitialization({ city: code, statsigId });
    //         setStatsigInitializationConnect({ statsigInitialized: true });
    //     };
    //     initateStatsig();
    // }, []);

    //SALE RELATED STATSIG INTEGRATION

    // useEffect(() => {
    //     if (statsigInitialized && isEmpty(activeSaleConfig)) {
    //         const saleConfig = getDynamicConfigData("sale_banner");
    //         let saleDetails = saleConfig.get("saleData", {});
    //         if (saleDetails && saleDetails?.active) {
    //             const {start, end} = saleDetails || {};
    //             saleDetails = {...saleDetails, ...{ active: checkDateWithinInterval(start, end) }};
    //         }
    //         if (platform === DEVICE_TYPE.MOBILE) {
    //             updateSaleDataMsiteConnect({activeSaleConfig: saleDetails});
    //         } else {
    //             updateSaleDataDesktopConnect({activeSaleConfig: saleDetails});
    //         }
    //     }
    // }, [statsigInitialized]);

    const handleSeoCityChanges = (cookieCity) => {
        const selectedCityObj = Object.keys(selectedCity).length > NUMBER.ZERO ? selectedCity : selectedCityInfo;
        if (selectedCityObj && selectedCityObj.code && selectedCityObj.code !== cookieCity) {
            saveCookie(COOKIE_CONSTANTS.AE_CITY, selectedCityObj.code);
        } else if (!selectedCityObj?.code && cookieCity) {
            if (platform === DEVICE_TYPE.MOBILE) {
                updateCityWithCodeMobileConnect(cookieCity);
            } else {
                updateCityWithCodeDesktopConnect(cookieCity);
            }
        }
    };

    useEffect(() => {
        const isCleverTapIdGaIdSend = parseCookie(COOKIE_CONSTANTS.CLEVER_TAP_ID_SEND) || false;
        if (!isCleverTapIdGaIdSend && window.clevertap && window.clevertap.getCleverTapID) {
            trackMobileCustomEventsAE("userSessionStart", {
                userAction: "User starts a session",
                eventLabel: window.clevertap.getCleverTapID()
            });
            saveCookie(COOKIE_CONSTANTS.CLEVER_TAP_ID_SEND, true);
        }
    }, []);

    useEffect(() => {
        const cookieCity = parseCookie(COOKIE_CONSTANTS.AE_CITY);
        const selectedCityCode = cookieCity !== "undefined" && cookieCity ? cookieCity : DEFAULT_CITY.AE.code;
        handleSeoCityChanges(selectedCityCode);
        trackMobileCustomEventsAE("captureUserIp", { eventLabel: ip });
        trackMobileCustomEventsAE("captureUserEmirate", { eventLabel: selectedCityCode });
    }, []);

    useEffect(() => {
        if ((isLoggedIn && isUserDetailsFetched)) {
            const configQueryParams = {
                mobile_number: mobile,
                ...(gaId && { gaId })
            };
            getConfigConnect(configQueryParams).then(configInfo => {
                fetchSeoContentConnect();
                if (Object.keys(configInfo?.clientInfo || {}).length > 0) {
                    const {cityCode = ""} = configInfo.clientInfo || {};
                    const selectedCityObj = Object.keys(selectedCity).length > NUMBER.ZERO ? selectedCity : selectedCityInfo;
                    userProfileConnect(secureToken, {
                        uid: mobile,
                        country: selectedCityObj?.countryCode,
                        phoneNumber: mobile,
                        userName: name,
                        gaId,
                        cityCode,
                        lat: selectedCityObj?.lat,
                        lng: selectedCityObj?.lng
                    });
                }
            });
        }
    }, [isLoggedIn, isUserDetailsFetched]);

    // eslint-disable-next-line complexity
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];

        /**
         * DAPI: Only used in post booking final payment
         * Google Map: Only used in post booking test drive scheduling
        */
        if (platform === DEVICE_TYPE.MOBILE || platform === DEVICE_TYPE.DESKTOP) {
            if (pathname.includes(`/${ROUTE_PATHS.paymentMethod}`) || pathname.includes(`/${ROUTE_PATHS.postBooking}`)) {
                loadDapi();
            }
            if (mapType === MAP_TYPE.GOOGLE && (
                pathname.includes(GOOGLE_MAP_PATH.BUYER_TD_SCHEDULE_LOCATION)
                || pathname.includes(GOOGLE_MAP_PATH.SELLER_APPOINTMENT_ADDRESS)
                || pathname.includes(GOOGLE_MAP_PATH.BUYER_DELIVERY_SCHEDULE_LOCATION)
                || pathname.includes(GOOGLE_MAP_PATH.SER)
                || pathname.match(LISTING_DETAILS_REGEX)
                || pathname.includes(GOOGLE_MAP_PATH.SERVICING_SCHEDULE)
                || pathname.includes(GOOGLE_MAP_PATH.SERVICING_SELECTION)
                || pathname.includes(GOOGLE_MAP_PATH.TEST_DRIVE_SCHEDULE_DESKTOP)
            )) {
                loadGoogleMap();
            }
        }
    }, [pathname]);

    useEffect(() => {
        const isWebView = getItem("isWebView") || queryParams?.utm_source === "more_tab_app" || queryParams?.view_source === "app";
        const tokenPassed = queryParams?.token;

        if (isWebView && queryParams?.token !== undefined) {
            getRefreshTokenInfoConnect(!!tokenPassed).catch(() => { });
        } else {
            getRefreshTokenInfoConnect().catch(() => { });
        }

    }, []);

    useEffect(() => {
        const unsubscribe = subscribe(proxyAuthState, () => {
            if (proxyAuthState.isLoggedIn) {
                setProfileWithTokenConnect(proxyAuthState.accessToken);
            } else {
                logoutSuccessConnect();
            }

        });
        // Unsubscribe by calling the result
        return () => {
            unsubscribe();
        };

    }, [proxyAuthState]);

    useEffect(() => {
        try {
            let clientId = "";
            if (window && window.ga && window.ga.getAll !== undefined && window.ga.getAll()[0] !== undefined) {
                clientId = window.ga.getAll()[0].get("clientId") || "";
            }
            if (window && window.ga && !clientId) {
                window.ga((tracker) => {
                    clientId = tracker.get("clientId");
                });
            }
            if (!clientId) {
                captureMessage(SENTRY_ERRORS.GA_ID_ERROR, "info");
            }
            setGAIdConnect(clientId);
        } catch (error) {
            captureMessage(SENTRY_ERRORS.GA_ID_CATCH_ERROR, "error");
        }
    }, [setGAIdConnect]);

    useEffect(() => {
        if (isLoggedIn && process.env.HOST_ENV === "PRODUCTION") {
            Smartlook.init(
                process.env.SMARTLOOK_PROJECT_ID,
                { region: "eu" },
                () => {
                    Smartlook.properties({
                        environment: (process.env.HOST_ENV || "").toLowerCase()
                    });
                    Smartlook.identify(email || mobile, {
                        name,
                        email,
                        mobile,
                        userId: mobile
                    });
                }
            );
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (!isListingSSR) {
            reloadCarListConnect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Helmet
                script={[{
                    type: "text/javascript",
                    innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
                        '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${process.env.GTM_ID_AE}');`
                },
                {
                    type: "text/javascript",
                    innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function()
            { (i[r].q=i[r].q||[]).push(arguments)}
            ,i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.defer=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            ga('create', '${process.env.GA_ID}', 'auto');ga('require', '${process.env.OPTIMISE_ID_2}');`
                },
                {
                    type: "text/javascript",
                    innerHTML: `var clevertap = {event:[], profile:[{Site: {'GA-ID': '${process.env.GA_ID}'}}], account:[], onUserLogin:[], notifications:[], privacy:[]};
                        clevertap.account.push({"id": "${process.env.CLEVERTAP_ACCOUNT_ID}"});
                        clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
                        clevertap.privacy.push({useIP: false}); //set the flag to true, if the user agrees to share their IP data
                        (function () {
                            var wzrk = document.createElement('script');
                            wzrk.type = 'text/javascript';
                            wzrk.defer = true;
                            wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
                            var s = document.getElementsByTagName('script')[0];
                            s.parentNode.insertBefore(wzrk, s);
                        })();`
                },
                ...(process.env.HOST_ENV === "PRODUCTION" ? [{
                    type: "text/javascript",
                    innerHTML: `(function(){var dbpr=100;if(Math.random()*100>100-dbpr){var d="dbbRum",w=window,o=document,a=addEventListener,scr=o.createElement("script");w[d]=w[d]||[];w[d].push(["presampling",dbpr]);["error","unhandledrejection"].forEach(function(t){a(t,function(e){w[d].push([t,e])});});}scr.src="https://cdn.debugbear.com/8xLF5qTTDgiA.js";o.head.appendChild(scr);})()`
                }] : []),
                ...(platform === DEVICE_TYPE.DESKTOP ? [{
                    type: "text/javascript",
                    innerHTML: `
                        var MessageBirdChatWidgetSettings = { 
                            widgetId: "${process.env.MESSENGER_BIRD}", 
                            initializeOnLoad: true, 
                        };
                        (function(){"use strict";if(Boolean(document.getElementById("live-chat-widget-script")))console.error("MessageBirdChatWidget:Snippet loaded twice on page");else{var e,t;window.MessageBirdChatWidget={},window.MessageBirdChatWidget.queue=[];for(var i=["init","setConfig","toggleChat","identify","hide","on","shutdown"],n=function(){var e=i[d];window.MessageBirdChatWidget[e]=function(){for(var t=arguments.length,i=new Array(t),n=0;n<t;n++)i[n]=arguments[n];window.MessageBirdChatWidget.queue.push([[e,i]])}},d=0;d<i.length;d++)n();var a=(null===(e=window)||void 0===e||null===(t=e.MessageBirdChatWidgetSettings)||void 0===t?void 0:t.widgetId)||"",o=function(){var e,t=document.createElement("script");t.type="text/javascript",t.src="https://livechat.messagebird.com/bootstrap.js?widgetId=".concat(a),t.async=!0,t.id="live-chat-widget-script";var i=document.getElementsByTagName("script")[0];null==i||null===(e=i.parentNode)||void 0===e||e.insertBefore(t,i)};"complete"===document.readyState?o():window.attachEvent?window.attachEvent("onload",o):window.addEventListener("load",o,!1)}})();`
                }] : [])
                ]}>
                <meta name="google-site-verification" content="VUY_EcsQTevkNtbtQbpgkZrJoLQE4L1OincJrfjbJcw" />
                <meta name="google-site-verification" content="-3bTXBGIJOjz-ZwWlxQRvZ0KgxXbwOZC2_AgHHR2QEI" />
            </Helmet>
            {children}
            <div />
        </Fragment>
    );
};

AEConfiguration.propTypes = {
    platform: PropTypes.string,
    children: PropTypes.any.isRequired,
    mapType: PropTypes.string,
    getRefreshTokenInfoConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    isListingSSR: PropTypes.bool,
    setProfileWithTokenConnect: PropTypes.func,
    getConfigConnect: PropTypes.func,
    logoutSuccessConnect: PropTypes.func,
    setExperimentConnect: PropTypes.func,
    setGAIdConnect: PropTypes.func,
    pathname: PropTypes.object,
    mobile: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isUserDetailsFetched: PropTypes.bool,
    name: PropTypes.string,
    email: PropTypes.string,
    gaId: PropTypes.string,
    experiments: PropTypes.object,
    updateCityWithCodeMobileConnect: PropTypes.func,
    updateCityWithCodeDesktopConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    selectedCityInfo: PropTypes.object,
    ip: PropTypes.string,
    setStatsigInitializationConnect: PropTypes.func,
    updateSaleDataMsiteConnect: PropTypes.func,
    updateSaleDataDesktopConnect: PropTypes.func,
    activeSaleConfig: PropTypes.object,
    userProfileConnect: PropTypes.func,
    secureToken: PropTypes.string,
    fetchSeoContentConnect: PropTypes.func
};

export default AEConfiguration;
