/* eslint-disable no-magic-numbers */
import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
const AsyncSlider = loadable.lib(() => import("react-compound-slider"), { ssr: false });
import SliderRail from "./slider-rail";
import Handle from "./handle";
import Track from "./track";
import Tick from "./tick";
import styles from "./styles.css";

const Slider = ({
    domain = [1, 10],
    defaultValues = [5],
    step = 1,
    mode = 2,
    tickCount = 8,
    onChange = () => { },
    onUpdate = () => { },
    hideHandles = [],
    railColor = "#dde5eb",
    disabledTrackStyles = {
        backgroundImage: "linear-gradient(269deg, #ed6a00, #f69b00)"
    },
    onSlideStart = () => { },
    onSlideEnd = () => { },
    animatedHandles = [],
    handleColor = "orange",
    trackProps = { right: false },
    hideTicksOnEdges = true,
    disabled = false,
    getHandleID = () => { },
    vertical = false,
    displayValue,
    railHeight,
    trackClassName = "",
    emojiType
}) => {
    return (
      <AsyncSlider fallback={<React.Fragment />}>
        {({ default: CompoundSlider, Rail, Handles, Tracks, Ticks  }) => (
        <CompoundSlider
        mode={mode}
        step={step}
        domain={domain}
        onUpdate={onUpdate}
        onSlideStart={onSlideStart}
        onSlideEnd={onSlideEnd}
        onChange={onChange}
        values={defaultValues.slice()}
        styleName={"styles.sliderWrapper"}
        className={"sliderWrapper"}
        disabled={disabled}
        vertical={vertical}
    >
        <Rail>
            {({ getRailProps }) =>
                <SliderRail railColor={railColor} getRailProps={getRailProps} height={railHeight} />}
        </Rail>
        <Handles>
            {({ handles, getHandleProps, activeHandleID }) => (
                <div>
                    {handles.map((handle, index) => {
                        if (hideHandles.indexOf(index) !== -1) return null;
                        return (<Handle
                            key={handle.id}
                            handle={handle}
                            domain={domain}
                            animatedHandle={animatedHandles.includes(index)}
                            isActive={handle.id === activeHandleID}
                            getHandleProps={getHandleProps}
                            handleColor={handleColor}
                            getHandleID={() => getHandleID(activeHandleID)}
                            emojiType={emojiType}
                        />);
                    })}
                </div>
            )}
        </Handles>
        <Tracks {...trackProps}>
            {({ tracks, getTrackProps }) => (
                <div>
                    {tracks.map(({ id, source, target }) => {
                        return (<Track
                            key={id}
                            source={source}
                            target={target}
                            railColor={railColor}
                            height={railHeight}
                            trackClassName={trackClassName}
                            disabledTrackStyles={disabledTrackStyles}
                            getTrackProps={getTrackProps}
                        />);
                    })}
                </div>
            )}
        </Tracks>
        <Ticks count={tickCount}>
            {({ ticks }) => (
                <div className="ticksMainWrapper">
                    {ticks.map((tick, index) => {
                        return (hideTicksOnEdges && (index === 0 || index === ticks.length - 1)) ?
                            null :
                            <Tick
                                key={tick.id}
                                tick={tick}
                                count={ticks.length}
                                displayValue={displayValue}
                            />;
                    })}
                </div>
            )}
        </Ticks>
    </CompoundSlider>
        )}
      </AsyncSlider>

    );
};

Slider.propTypes = {
    domain: PropTypes.array,
    defaultValues: PropTypes.array,
    step: PropTypes.number,
    mode: PropTypes.number,
    onChange: PropTypes.func,
    onUpdate: PropTypes.func,
    onSlideStart: PropTypes.func,
    onSlideEnd: PropTypes.func,
    tickCount: PropTypes.number,
    hideHandles: PropTypes.array,
    railColor: PropTypes.string,
    disabledTrackStyles: PropTypes.object,
    animatedHandles: PropTypes.array,
    handleColor: PropTypes.string,
    trackProps: PropTypes.object,
    hideTicksOnEdges: PropTypes.bool,
    disabled: PropTypes.bool,
    getHandleID: PropTypes.func,
    vertical: PropTypes.bool,
    displayValue: PropTypes.bool,
    railHeight: PropTypes.string,
    trackClassName: PropTypes.string,
    emojiType: PropTypes.bool
};

export default Slider;
