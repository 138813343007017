/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import IconSave from "./images/save-icon.png";
import WalletIcon from "./images/wallet.svg";

// Component
import CouponDetailsPopup from "../coupon-details-popup";

// Constant
import { COOKIE_CONSTANTS, NUMBER } from "../../../constants/app-constants";

// Utils
import { makePriceLabelRoundAE } from "../../../utils/helpers/make-price-label";
import saveCookie from "../../../utils/helpers/save-cookie";
import gaId from "../../../utils/helpers/get-ga-from-cookie";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import cleverTap from "../../../tracking/clevertap";
import parseCookie from "../../../utils/helpers/parse-cookie";

const ExchangeCouponSliderCard = ({
    userId = "",
    secureToken = "",
    isLoggedIn,
    showLogin,
    setShowLogin,
    couponInfo = {},
    carPrice = NUMBER.ZERO,
    hideLockedCoupons = false,
    onUnlockCoupon = () => {},
    updateUnlockCouponConnect
}) => {

    const [showCouponUnlocked, setShowCouponUnlocked] = useState(false);
    const [showCouponInfoPopup, setShowCouponInfoPopup] = useState(false);
    const { code = [], expiry = null, locked = false, grey = false, config = {}, metaContent = {} } = couponInfo || {};
    const isFullPayOffer = (code || [])?.includes("FULLPAYOFFER") || false;
    const isShowUnlockCta = (locked && !hideLockedCoupons) || (isFullPayOffer && !isLoggedIn);
    const isShowViewDetailsCta = !isShowUnlockCta || !isFullPayOffer;
    const timeout = useRef(null);

    const getFullPayOfferPrice = () => {
        const { discountCap = NUMBER.ZERO, percentage = NUMBER.ZERO } = config?.discountPercentageSlots?.[NUMBER.ZERO] || {};
        const discountedPrice = carPrice * (percentage / NUMBER.HUNDRED);
        const finalDiscountAmount = discountedPrice >= discountCap ? discountCap : discountedPrice;
        return carPrice - finalDiscountAmount;
    };

    useEffect(() => {
        if (showCouponUnlocked && window !== undefined) {
            timeout.current = window.setTimeout(() => {
                setShowCouponUnlocked(false);
            }, NUMBER.FIVE_THOUSAND);
        }
        return () => {
            if (timeout.current) window.clearTimeout(timeout.current);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCouponUnlocked]);

    const cleverTapLoginEvent = () => {
        cleverTap.desktopCleverTapEvent(cleverTap.desktopConstants.FULL_PAYMENT_COUPON, {
            fp_coupon_code: code,
            fp_coupon_value: config?.maxCouponValue,
            fp_coupon_expiry: expiry
        }, { userAgent: true });
    };

    const openLogin = () => {
        setShowLogin(true);
    };

    const closeLogin = () => {
        setShowLogin(false);
        if (isFullPayOffer) {
            cleverTapLoginEvent();
        }
    };

    const handleUnlock = async ({ mobilePhone, token }) => {
        if (showLogin) closeLogin();

        if (!mobilePhone) return;
        await updateUnlockCouponConnect({
            userId: mobilePhone,
            ...(gaId && { gaid: gaId })
        }, token);

        const seenCouponIds = parseCookie(COOKIE_CONSTANTS.LOCKED_COUPONS) || "[]";
        saveCookie(
            COOKIE_CONSTANTS.LOCKED_COUPONS,
            JSON.stringify([...JSON.parse(seenCouponIds), code])
        );

        if (onUnlockCoupon) onUnlockCoupon(mobilePhone);
        setShowCouponUnlocked(true);
    };

    const handleUnlockCouponClick = (e) => {
        e.stopPropagation();
        if (isLoggedIn) {
            const argObj = {
                mobilePhone: userId,
                token: secureToken
            };
            handleUnlock(argObj);
        } else {
            openLogin();
        }
    };

    const onViewCouponDetailsClick = (e) => {
        e.stopPropagation();
        setShowCouponInfoPopup(true);
        trackDesktopCustomEventsAE("cdpCouponViewDetailsClick", { eventLabel: couponInfo?.code });
    };

    const onCloseCouponDetailsPopup = () => {
        setShowCouponInfoPopup(false);
    };

    return (
        <Fragment>
                <div styleName={`styles.cardWrapper ${grey ? "styles.cardDisabled" : ""}`}>
                    <div styleName={"styles.saveStrip"}>
                        <img src={isFullPayOffer ? WalletIcon : IconSave} alt="Wallet Icon" width="22" height="22" />
                    </div>
                    <div styleName={"styles.cardContent"}>
                        <div styleName={"styles.headerWrapper"}>
                            {isFullPayOffer ?
                                <p styleName={"styles.couponCode"}>Get this for as low as <strong>{makePriceLabelRoundAE(getFullPayOfferPrice())}</strong></p> :
                                <p styleName={"styles.couponCode"}>{code.includes("TRADEIN") ? "Exchange your car" : code}</p>
                            }
                            {isShowUnlockCta && (
                                <button styleName={"styles.applyUnlockCta"} onClick={(e) => handleUnlockCouponClick(e)}>UNLOCK</button>
                            )}
                        </div>
                        <p styleName={"styles.couponDetail"}>
                            <span styleName={"styles.couponDescText"}>
                                {isFullPayOffer ? `Only valid for payment in full.` : metaContent?.description}
                            </span>
                            {isShowViewDetailsCta && (
                                <span styleName={"styles.viewDetail"} onClick={onViewCouponDetailsClick}>View Details</span>
                            )}
                        </p>
                    </div>
                </div>

                {showCouponInfoPopup && (
                    <CouponDetailsPopup
                        couponInfo={couponInfo}
                        onClose={onCloseCouponDetailsPopup}
                    />
                )}
            </Fragment>
    );
};

ExchangeCouponSliderCard.propTypes = {
    userId: PropTypes.string,
    secureToken: PropTypes.string,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    couponInfo: PropTypes.object,
    carPrice: PropTypes.number,
    hideLockedCoupons: PropTypes.bool,
    onUnlockCoupon: PropTypes.func,
    updateUnlockCouponConnect: PropTypes.func
};

export default ExchangeCouponSliderCard;
