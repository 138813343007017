import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_ORDER_POSTBC_DETAILS_INIT
    FETCH_ORDER_POSTBC_DETAILS_SUCCESS
    FETCH_ORDER_POSTBC_DETAILS_FAILURE
    SET_DAPI_LOADED
    SET_IS_MRL
    SET_SHOW_PROCESS_GUIDE
    SHOW_PROGRESS_LOADER
    UPDATE_SLOT_TD
    UPDATE_SLOT_DL
    SET_IS_DELIVERY_PICKUP

    // Charge Config
    FETCH_CHARGE_CONFIG_INIT
    FETCH_CHARGE_CONFIG_SUCCESS
    FETCH_CHARGE_CONFIG_FAILURE
    FETCH_POST_BC_CONFIG_SUCCESS
    FETCH_POST_BC_CONFIG_FAILURE

    SET_FINANCING_DOCUMENT_POPUP
    SET_VAS_DETAIL_INFO_POPUP
    SET_SCHEDULE_TEST_DRIVE_SHOW_MORE_CTA
    SET_IS_SHOW_TOKEN_PAYMENT_STEPPER

    // NPS
    FETCH_NPS_STATUS_SUCCESS
    FETCH_NPS_QUESTIONS_SUCCESS
    FETCH_NPS_DIRTY_FEEDBACK

    // ACTIVE STEP
    FETCH_ACTIVE_STEP_DATA_INIT
    FETCH_ACTIVE_STEP_DATA_SUCCESS
    FETCH_ACTIVE_STEP_DATA_FOR_DOCUMENTS
    FETCH_ACTIVE_STEP_DATA_FAILURE
    RESET_ACTIVE_STEP_DATA

    FETCH_TD_ALL_ACTIVE_BOOKINGS_INIT
    FETCH_TD_ALL_ACTIVE_BOOKINGS_SUCCESS
    FETCH_TD_ALL_ACTIVE_BOOKINGS_FAILURE

    // REFURB REQUEST
    UPDATE_REFURB_REQUEST_INIT
    UPDATE_REFURB_REQUEST_SUCCESS
    UPDATE_REFURB_REQUEST_FAILURE

    // GENERATE PAYMENT LINK
    GENERATE_PAYMENT_LINK_INIT
    GENERATE_PAYMENT_LINK_SUCCESS
    GENERATE_PAYMENT_LINK_FAILURE

    // UPDATE DBR DETAILS
    UPDATE_DBR_DETAILS_INIT
    UPDATE_DBR_DETAILS_SUCCESS
    UPDATE_DBR_DETAILS_FAILURE

    SET_FILE_UPLOAD_PAGE_ACTIVE
`,
    {
        prefix: "postbc/"
    }
);
