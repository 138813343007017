import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarGallery from "./component";
import { setGsGalleryData } from "../car-details/actions";

const mapStateToProps = ({
    carDetails: {
        content,
        config,
        galleryProps: {
            selectedImageIndex
        }
    },
    config: {
        resolutionInfo: {
            desktop: resolutions
        }
    }
}) => ({
    content,
    config,
    selectedImageIndex,
    resolutions
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setGsGalleryDataConnect: setGsGalleryData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarGallery);
