import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const BreadcrumbHome = ({title, homeRoute = "/"}) => {
    return (
        <React.Fragment>
            <ul styleName={"styles.breadcrumbList"}>
                <li><Link to={homeRoute}>Home</Link></li>
                <li>&nbsp;{title}</li>
            </ul>
        </React.Fragment>
    );
};

BreadcrumbHome.propTypes = {
    title: PropTypes.string,
    homeRoute: PropTypes.string
};

export default BreadcrumbHome;
