import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Icons
import CloseButton from "../icon-cross";

// Constants
import { CURRENCY } from "../../../constants/app-constants";

// Utils
import { getAppliedFilterTagsName } from "../../../utils/helpers/applied-filter-tags";

const AppliedFilterTag = ({
    filter = {},
    allFilters = {},
    onRemove,
    showClose = true,
    text = "",
    showUnit = true,
    currency = CURRENCY.IN,
    isDesktopView = false
}) => {

    const { filterKey, optionKey, subOptionKey } = filter || {};

    const onRemoveFilter = () => {
        onRemove({
            filterKey,
            optionKey,
            subOptionKey,
            isSelected: false
        });
    };

    return (
        <div styleName={"styles.wrap"} className="filterTablet">
            <p>{text || getAppliedFilterTagsName(filter, allFilters, showUnit, currency, isDesktopView)}</p>
            {showClose && <CloseButton onClickHandler={onRemoveFilter} type="darkBlue" />}
        </div>
    );
};

AppliedFilterTag.propTypes = {
    filter: PropTypes.object,
    allFilters: PropTypes.object,
    onRemove: PropTypes.func,
    showClose: PropTypes.bool,
    text: PropTypes.string,
    showUnit: PropTypes.bool,
    currency: PropTypes.string,
    isDesktopView: PropTypes.bool
};

export default AppliedFilterTag;
