import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_RECENTLY_VIEWED_SUCCESS
    FETCH_RECENTLY_VIEWED_FAILURE
`,
    {
        prefix: "recentlyviewedlist/"
    }
);
