import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { findByKey } from "../../../utils/helpers/find-by-key";
import cleverTap from "../../../tracking/clevertap";

export const INITIAL_STATE = {
    selectedCity: {}
};

export const updateCity = (state = INITIAL_STATE, { city, cityList, lat, lng, hasCitySkip }) => {
    const selectedCity = findByKey(cityList, "code", city) || INITIAL_STATE.selectedCity;
    if (selectedCity) cleverTap.updateEmirate(selectedCity?.name);
    return { ...state, selectedCity: { ...selectedCity, lat, lng, hasCitySkip} };
};

export const updateCityWithCode = (state = INITIAL_STATE, { cityCode, cityList, lat, lng, hasCitySkip = false, name }) => {
    const selectedCity = findByKey(cityList, "code", cityCode) || INITIAL_STATE.selectedCity;
    return { ...state, selectedCity: { ...selectedCity, lat, lng, hasCitySkip, name}};
};

export const HANDLERS = {
    [Types.UPDATE_CITY]: updateCity,
    [Types.UPDATE_CITY_WITH_CODE]: updateCityWithCode
};

export default createReducer(INITIAL_STATE, HANDLERS);
