import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckoutCar from "./component";

const mapStateToProps = ({
    carDetails

}) => ({
    carDetails

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCar);
