/* eslint-disable max-statements */
import React  from "react";
import styles from "./styles.css";
import BannerNoCarFound from "../../shared/banner-no-car-found";

const NoCarFound = () => {

    return (
        <div styleName={"styles.wrapper"}>
            <BannerNoCarFound />
            <h3>No cars found</h3>
            <p>No car is available for selected criteria. Try<br />using the exact car model name, type, etc.</p>
        </div>
    );
};

NoCarFound.propTypes = {

};

export default NoCarFound;
