import React from "react";
import ProfileInfo from "../../../components/ae.desktop/profile-info";
import homeObject from "../../../locales/desktop/home";
import withAELayout from "../../../components/ae.desktop/layout";

const Profile = () => {
  return (
    <React.Fragment>
      <ProfileInfo locales={homeObject?.profile}/>
    </React.Fragment>
  );
};

export default withAELayout(Profile, true, false);
