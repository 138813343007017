import React, { useState } from "react";
import PropTypes from "prop-types";

const Accordion = ({
    children,
    totalItems = 1,
    toggleAll = false,
    visibleStateFlag = false
}) => {
    const [itemsArray, setItemsArray] = React.useState([]);
    const [currentVisibleStates, setCurrentVisibleStates] = useState(
        itemsArray
    );
    React.useEffect(() => {
        setItemsArray(Array(...new Array(totalItems)).map(() => visibleStateFlag));
        setCurrentVisibleStates(Array(...new Array(totalItems)).map(() => visibleStateFlag));
    }, [totalItems, visibleStateFlag]);

    const onAccordionClickHandler = ({ index }) => {
        const currentState = !toggleAll ? [...itemsArray] : [...currentVisibleStates];
        currentState[index] = !currentVisibleStates[index];
        setCurrentVisibleStates(currentState);
    };

    return (
        <ul>
            {children({
                onClick: onAccordionClickHandler,
                currentVisibleStates
            })}
        </ul>
    );
};

Accordion.propTypes = {
    children: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
    totalItems: PropTypes.number,
    toggleAll: PropTypes.bool,
    visibleStateFlag: PropTypes.bool
};

const Heading = ({ children, headingRef }) => {
    return (
        <React.Fragment>
            {React.cloneElement(children, { ref: headingRef })}
        </React.Fragment>
    );
};
Heading.propTypes = {
    children: PropTypes.object,
    headingRef: PropTypes.object
};

const Body = ({ children }) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};
Body.propTypes = {
    children: PropTypes.object
};
const Content = ({
    children
}) => {
    return (
        <li>
            {children}
        </li>
    );
};
Content.propTypes = {
    children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ])
};

Accordion.Content = Content;
Accordion.Heading = Heading;
Accordion.Body = Body;

export default Accordion;
