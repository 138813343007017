import React from "react";
import SemiModal from "../semi-modal";
import styles from "./styles.css";
import CloseButton from "../../shared/icon-cross";
import PropTypes from "prop-types";
import DetailsCtaSectionV4 from "../details-cta-section-v4";

const VasInfoModal = ({
    heading = "",
    text,
    onClose,
    showBottomCTA
}) => {
    return (
        <SemiModal onOverlayClick={onClose}>
            <div styleName={"styles.modalWrap"}>
                <div styleName={"styles.headerWrap"}>
                    <p styleName={"styles.heading"}>{heading}</p>
                    <span styleName={"styles.closeIcon"}><CloseButton type="orange" onClickHandler={onClose} /></span>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <p styleName={"styles.content"}>{text}</p>
                </div>
                {showBottomCTA && <DetailsCtaSectionV4 isSticky slideAnimation={false} showPriceSection={false} />}
            </div>
        </SemiModal>
    );
};

VasInfoModal.propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    onClose: PropTypes.func,
    showBottomCTA: PropTypes.bool
};

export default VasInfoModal;
