/* eslint-disable max-params */
/* eslint-disable max-statements */
export default (api) => {

    const getEmiratesList = (slotType, isMRL = false) => {
        return api.get(`/v1/slots/config/regions?slotType=${slotType}${isMRL ? "&testDriveType=HUB" : ""}`);
    };

    const getSelectedEmirateZonesList = (locationCode, slotType, isMRL = false) => {
        return api.get(`/v1/slots/config/locations/${locationCode}?slotType=${slotType}${isMRL ? "&testDriveType=HUB" : ""}`);
    };

    const getAvailableSlots = (orderId, slotType, params, token) => {
        return api.get(`/v1/slot/${orderId}/available?slotType=${slotType}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: "mSite"
            },
            params
        });
    };

    const bookAvailableSlot = (orderId, slotType, payload, token) => {
        return api.post(`/v1/slot/${orderId}?slotType=${slotType}`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: "mSite"
            }
        });
    };

    const resheduleBookedSlot = (orderId, slotType, payload, token) => {
        return api.post(`/v1/slot/${orderId}/reschedule?slotType=${slotType}`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: "mSite"
            }
        });
    };

    const getRescheduleReasons = (slotType, token) => {
        return api.get(`/v1/slots/config/reason?reasonType=RESCHEDULE&slotType=${slotType}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: "mSite"
            }
        });
    };

    const cancelServiceAppointment = (orderId = "", payload = {}, token = "") => {
        return api.put(`/v1/slot/${orderId}/cancel?slotType=SERVICING`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: "mSite"
            }
        });
    };

    return {
        getEmiratesList,
        getSelectedEmirateZonesList,
        getAvailableSlots,
        bookAvailableSlot,
        resheduleBookedSlot,
        getRescheduleReasons,
        cancelServiceAppointment
    };
};
