import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const FlexColumnLayout = (
    {children, type}
) => {
    const classMap = {
        center: "flexCenter",
        start: "flexStart",
        end: "flexEnd",
        around: "flexAround",
        between: "flexBetween",
        even: "flexEvenly",
        alignCenter: "alignCenter"
    };
    return (
        <div styleName={`styles.flexItem styles.${classMap[type] || "flexBetween"}`}>
            {children}
        </div>
    );
};

FlexColumnLayout.propTypes = {
    children: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
    type: PropTypes.string
};
export default FlexColumnLayout;
