import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Components
import H2 from "../../shared/h2/component";
import ArrowIcon from "../../shared/arrow";

// Tracking
import { trackMobileCustomEventsAE } from "../../../tracking";
import SchemaValuationFaq from "../../shared/schema-valuation-faq";
import { NUMBER } from "../../../constants/app-constants";

const PilotFaq = ({
    homePageFaq = [],
    headingComponent = H2,
    heading = "Frequently Asked Questions",
    pushEvent = true,
    fetchFaqConnect,
    listingFaq,
    isListingPage = false
}) => {
    const [isOpen, setIsOpen] = useState({ 0: true });
    const [faqs, setFaqs] = useState(homePageFaq || listingFaq || []);
    const isHomePageFaq = (homePageFaq || []).length > NUMBER.ZERO;
    useEffect(() => {
        if (!isHomePageFaq && !isListingPage) {
            fetchFaqConnect({ isHomepage: true });
        }
        return () => {
            setFaqs([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isListingPage) {
            setFaqs(homePageFaq);
        } else if (isListingPage) {
            setFaqs(listingFaq);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homePageFaq, listingFaq]);

    const onClick = useCallback((index) => {
        setIsOpen((state) => ({ [index]: !state[index] }));
        const { question = "NA" } = homePageFaq[index] || {};
        if (pushEvent) {
            trackMobileCustomEventsAE("faqClickHome", {
                eventLabel: question
            });
        }
    }, [homePageFaq, pushEvent]);

    const Heading = headingComponent;

    return (
        <div styleName={"styles.faq"}>
            <Heading text={heading} />
            {
                ((faqs || []).length > 0) && faqs.map(({ question, answer }, index) => {
                    const rightArrow = 90;
                    const downArrow = -90;
                    return (
                        <div key={index} styleName={"styles.wrap"} className={`${isOpen[index] ? "activeWrap" : ""}`}>
                            <h3 styleName={`styles.title ${isOpen[index] ? "styles.active" : ""}`}
                                onClick={() => onClick(index)}
                            >{question}
                                <ArrowIcon rotateBy={isOpen[index] ? downArrow : rightArrow} />

                            </h3>
                            {
                                <div styleName={isOpen[index] ? "styles.content" : "styles.hideContent"} dangerouslySetInnerHTML={
                                    { __html: answer }
                                } />

                            }

                        </div>
                    );
                })
            }
            {((faqs || []).length > 0) && <SchemaValuationFaq faqs={faqs} />}
        </div>
    );
};

PilotFaq.propTypes = {
    listingFaq: PropTypes.array,
    homePageFaq: PropTypes.array,
    headingComponent: PropTypes.func,
    attachSchema: PropTypes.bool,
    heading: PropTypes.string,
    pushEvent: PropTypes.bool,
    fetchFaqConnect: PropTypes.func,
    isListingPage: PropTypes.bool
};

export default PilotFaq;
