/* eslint-disable no-console */
import authApi, { proxyAuthState as proxyState } from "../../service/userAuthApi";
import jwtDecode from "jwt-decode";
import saveCookie from "./save-cookie";
import { AUTH_PRODUCT_TYPE } from "../../constants/api-constants";
import { COOKIE_CONSTANTS, NUMBER } from "../../constants/app-constants";

const DEBUG = process.env.NODE_ENV === "development";

const WAIT_TIME_IN_SECS = NUMBER.SIXTY;

// Request Interceptor which handles token refresh pre and post token expiry
// eslint-disable-next-line max-statements
const authRefresh = async (config, api = authApi, proxyAuthState = proxyState) => {
    if (config.headers.Authorization) {
        const authToken = (config.headers.Authorization && config.headers.Authorization.split(" ")[1]) || null;
        const authTokenDecoded = await jwtDecode(authToken);
        const currentTimeInSec = new Date().getTime() / NUMBER.THOUSAND;
        // update access token when expiry time is less than 60 secs
        if (authToken && authTokenDecoded && authTokenDecoded.exp - currentTimeInSec < WAIT_TIME_IN_SECS) {
            try {
                const authResponse  = await api.get(`/oauth/${AUTH_PRODUCT_TYPE}/token/refresh`);
                proxyAuthState.accessToken = authResponse.data.access_token;
                proxyAuthState.expiresIn = authResponse.data.expires_in;
                proxyAuthState.isLoggedIn = true;
                config.headers.Authorization = `Bearer ${authResponse.data.access_token}`;
            } catch (err) {
                proxyAuthState.isLoggedIn = false;
            }
        }
        // Uncomment for used based sale
        if (proxyAuthState.isLoggedIn) {
            saveCookie(COOKIE_CONSTANTS.TOKEN, proxyAuthState.accessToken);
        }
    }
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("✉️ ", config);
    }
    return config;
};

export default authRefresh;
