/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Components
import withLogin from "../with-login";
import Skeleton from "./skeleton";
import TdSchedule from "../td-schedule";

// Constant
import { INVENTORY_TYPE_LABEL, NUMBER } from "../../../constants/app-constants";
import { POST_BC_STEPS } from "../../../constants/ae/post-booking-constants";
import { TD_STEP_NAMES } from "../td-schedule/constants";
import { ORDER_STATUS } from "../../../constants/ae/checkout-constants";

// Utils
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import TdScheduleC2c from "../td-schedule-c2c";

const PostBookingStepsMapper = ({
    location,
    isLoggedIn,
    isUserDetailsFetched,
    secureToken,
    carDetails = {},
    postBcDetails = {},
    fetchActiveStepDataConnect,
    fetchOrderPostBcDetailsV2Connect,
    fetchPostBookingConfigConnect,
    fetchCarDetailsConnect
}) => {
    const history = useHistory();

    const { stepid, lid: carId } = useParams();
    const queryParams = parseLocationSearchParams(location?.search);
    const isShowWhiteBg = queryParams?.type === TD_STEP_NAMES.TD_SCHEDULE;

    const { isFetching: isCarDetailsLoading } = carDetails || {};
    const {
        orderId, isLoading: postBcDetailsLoading, isFetched: postBcDetailsFetched,
        steps: postBcSteps, activeStepData: { isLoading: isActiveStepLoading }
    } = postBcDetails;
    const postBcStepsLength = postBcSteps?.length || NUMBER.ZERO;
    const { content: { year, make, model, city, appointmentId, inventoryType } } = carDetails;
    const carName = `${make}-${model}`;

    const { relativeURL: carCheckoutUrl } = getDetailPageUrl({ year, carName, carId: appointmentId, city, isCheckout: true, country: "ae" });
    const isC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;

    // Redirect to checkout login page if not loggedin
    useEffect(() => {
        if (!isLoggedIn && isUserDetailsFetched && carCheckoutUrl && !isCarDetailsLoading) {
            history.push(carCheckoutUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isUserDetailsFetched, carCheckoutUrl, isCarDetailsLoading]);

    useEffect(() => {
        if (carId && (appointmentId !== carId)) {
            fetchCarDetailsConnect(carId, false, "updatedGalleryDesign");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carId, appointmentId]);

    useEffect(() => {
        if (carId && secureToken && (postBcStepsLength === NUMBER.ZERO && !isC2C)) {
            fetchOrderPostBcDetailsV2Connect(carId, secureToken, false, { withLoader: false }).then((response) => {
                if (response && response?.order?.status !== ORDER_STATUS.BOOKED) {
                    history.push("/my-bookings");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carId, secureToken, postBcStepsLength]);

    useEffect(() => {
        if (secureToken && stepid && orderId) {
            fetchPostBookingConfigConnect({ orderId });
            fetchActiveStepDataConnect({ orderId, stepType: stepid });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken, stepid, orderId]);

    const renderCurrentSteps = () => {
        switch (stepid) {
            case POST_BC_STEPS.testDrive:
                return isC2C ? <TdScheduleC2c /> : <TdSchedule />;
            default: return null;
        }
    };

    const showSkeleton = isC2C ? (isCarDetailsLoading) : (postBcDetailsLoading || !postBcDetailsFetched || isActiveStepLoading || isCarDetailsLoading);

    return (
        <React.Fragment>
            {showSkeleton ?
                <Skeleton /> :
                <div styleName={`styles.postBcWrapper ${isShowWhiteBg ? "styles.whiteBg" : ""} ${isC2C ? "styles.whiteBg" : ""}`}>
                    <div styleName={"styles.postBcContainer"}>
                        <div className={"row"} styleName={"styles.postBcRow"}>
                            {renderCurrentSteps()}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

PostBookingStepsMapper.propTypes = {
    isLoggedIn: PropTypes.bool,
    isUserDetailsFetched: PropTypes.bool,
    location: PropTypes.object,
    secureToken: PropTypes.string,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    carDetails: PropTypes.object,
    postBcDetails: PropTypes.object,
    fetchOrderPostBcDetailsV2Connect: PropTypes.func,
    fetchActiveStepDataConnect: PropTypes.func,
    fetchPostBookingConfigConnect: PropTypes.func,
    fetchCarDetailsConnect: PropTypes.func
};

export default withLogin(PostBookingStepsMapper);
