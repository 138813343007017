import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_DATA
        FETCH_LEAD_MODELS_REQUEST
        FETCH_LEAD_MODELS_SUCCESS
        FETCH_LEAD_MODELS_FAILURE
    `,
    {
        prefix: "leadmodels/"
    }
);
