import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BookingCta from "./component";
import { cancelBooking } from "../my-bookings/actions";

const mapStateToProps = ({
    myWishlist: {
        wishlistedCars: {
            cars: wishlistedCars
        } = {},
        selectedCarId: wishlistedSelectedCarId
    },
    user: {
        secureToken
    },
    carDetails: { content: carDetailsData }
}) => ({
    wishlistedCars,
    wishlistedSelectedCarId,
    secureToken,
    carDetailsData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    cancelBookingConnect: cancelBooking
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookingCta));
