
export default (api) => {
    const userProfile = (token, params) => {
        return api.post(`/user-profile`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getUserProfile = (token) => {
        return api.get(`/user-profile`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const createServiceContractOrder = (params, token) => {
        return api.post(`/api/v1/service-order`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getPaymentStatus = (orderId, token) => {
        return api.get(`/api/v1/pay/service-order/${orderId}/payment-status`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const initiatePayment = (orderId, params, token) => {
        return api.put(`/api/v1/pay/service-order/${orderId}/payment-initiate`, params, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    return {
        userProfile,
        getUserProfile,
        createServiceContractOrder,
        initiatePayment,
        getPaymentStatus
    };
};
