import React from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <h4 className="shimmer" styleName={"styles.shimmer"} />
    );
};

export default Skeleton;
