import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { resetAddress, resetSelectedLocation, resetSelectedSlot, setSelectedLocation } from "../td-schedule/actions";

// Component
import TdScheduleLocation from "./component";

const mapStateToProps = ({
    tdSchedule: {
        locationDetails
    }
}) => ({
    locationDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setSelectedLocationConnect: setSelectedLocation,
    resetSelectedLocationConnect: resetSelectedLocation,
    resetSelectedSlotConnect: resetSelectedSlot,
    resetAddressConnect: resetAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TdScheduleLocation);
