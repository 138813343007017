import React, {useEffect} from "react";
import Modal from "../../shared/modal";
import styles from "./styles.css";
import PropTypes from "prop-types";
import TransparentButton from "../../shared/button/transparent-button";
import withWishlist from "../with-wishlist";
import withLogin from "../with-login";
import NotifyIcon from "../../shared/icon-notify";
import CheckIcon from "./images/check-icon.svg";
import Button from "../../shared/button";
import { trackMobileCustomEventsAE } from "../../../tracking";

export const NotifiedDefaultModal = ({onClick, onClose}) => (
    <Modal isOpen={true} close={onClose}>
        <div styleName={"styles.wrapper"}>
            <span styleName={"styles.iconHolder styles.notifyIcon"}><NotifyIcon /></span>
            <h3>This car is currently reserved by someone else !</h3>
            <p>You can choose to get notified when car<br/> becomes available</p>
            <div styleName={"styles.buttonWrapper"}>
                <button styleName={"styles.button"} onClick={onClick}>NOTIFY WHEN AVAILABLE</button>
            </div>
        </div>
    </Modal>
);

const NotifiedSuccessModal = ({onClick}) => (
    <div styleName={"styles.wrapper"}>
        <span styleName={"styles.iconHolder styles.notifyIcon"}>
            <img src={CheckIcon} alt="We will notify you" />
        </span>
        <h3>We will notify you</h3>
        <p>You will be notified once car becomes <br/>available. We have also added <br/>this car to your wishlist</p>
        <div styleName={"styles.buttonWrapper"}>
            <button styleName={"styles.button"} onClick={onClick}>Got it</button>
        </div>
    </div>
);

const NotifiedWarningModal = ({onClick, onClose}) => (
    <div styleName={"styles.wrapper"}>
        <div styleName={"styles.removeContent"}>
            <p>Are you sure you want to remove this car from your wishlist? We’ll not be able to notify you when this becomes available</p>

            <div styleName={"styles.buttonWrapper"}>
                <TransparentButton text="Remove" onClick={onClick} />
                <Button text="Keep" onClick={onClose} />
            </div>
        </div>
    </div>
);

const NotifyMeModal = ({
    onClose,
    carId,
    isWishlistedCar,
    onClickAddToWishList,
    secureToken,
    isNotified = false,
    mobile
}) => {

    const removeFromWishlist = () => {
        onClickAddToWishList(carId, secureToken, {}, true);
        onClose();
    };

    useEffect(() => {
        if (isNotified) {
            onClickAddToWishList(carId, secureToken, {}, false);
            trackMobileCustomEventsAE("reservedcarNotifyMe", {
                eventLabel: carId,
                mobile_number: mobile
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal isOpen={true} close={onClose}>
            {isNotified && <NotifiedSuccessModal onClick={onClose} />}
            {isWishlistedCar(carId) && !isNotified && (
                <NotifiedWarningModal onClose={onClose} onClick={removeFromWishlist} />
            )}
        </Modal>
    );
};

NotifyMeModal.propTypes = {
    onClose: PropTypes.func,
    isWishlistedCar: PropTypes.func,
    carId: PropTypes.string,
    onClickAddToWishList: PropTypes.func,
    secureToken: PropTypes.string,
    isNotified: PropTypes.bool,
    mobile: PropTypes.string
};

NotifiedDefaultModal.propTypes = {
    onClose: PropTypes.func,
    onClick: PropTypes.func
};

NotifiedSuccessModal.propTypes = {
    onClick: PropTypes.func
};

NotifiedWarningModal.propTypes = {
    onClick: PropTypes.func,
    onClose: PropTypes.func
};

export default withWishlist(withLogin(NotifyMeModal));
