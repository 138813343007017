import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_SELECTED_FINANCING_DATA
    SET_FINANCE_DATA
    SET_LOAN_TENURE_VALUE
    SET_CREDIT_SCORE_VALUE
    SET_DOWNPAYMENT_VALUE
    SET_MONTHLY_EMI_VALUE
    SET_FINANCING_TAB
    SET_MIN_MAX_DOWNPAYMENT_VALUE
    SET_MIN_MAX_EMI_VALUE
    SET_FINANCE_SELECTED
    SET_FINANCE_UPDATED_DATA
    SET_FINANCE_SCREEN
`,
    {
        prefix: "emiDetails/"
    }
);
