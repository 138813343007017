import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import CarDescriptionSidebar from "./component";
import { fetchCouponList } from "../post-booking-confirmation/actions";
import { showWishlistNudge } from "../my-wishlist/actions";
import { fetchCarDetails, togglePriceBenifits } from "../car-details/actions";

const mapStateToProps = ({
    carDetails,
    user: { mobile },
    config: {
        bookingAllowed,
        activeBookingCount,
        activeSaleConfig = {}
    },
    experiments: {
        desktopDpAb: {
            data: abDetailPageDesktop
        } = {}
    } = {}
}) => ({
    carDetails,
    mobile,
    bookingAllowed,
    activeBookingCount,
    activeSaleConfig,
    abDetailPageDesktop
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showWishlistNudgeConnect: showWishlistNudge,
    fetchCouponListConnect: fetchCouponList,
    fetchCarDetailsConnect: fetchCarDetails,
    togglePriceBenifitsConnect: togglePriceBenifits
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarDescriptionSidebar));
