/* eslint-disable max-params */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Checkbox from "../../shared/checkbox/component";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateAllSubFilterOptions } from "../filters/actions";

const Features = ({
    appliedFilters,
    filter,
    onOptionSelection,
    handleUpdateSelectedFilters
}) => {
    const {
        filterType,
        groupFacet
    } = filter;

    const onChangeSubFilterOption = (filterKey, filterOptionKey, subFilterOptionKey, isSelected) => {
        handleUpdateSelectedFilters({
            filterKey,
            optionKey: filterOptionKey,
            subOptionKey: subFilterOptionKey,
            isSelected,
            filterType,
            displayText: subFilterOptionKey
        });
        onOptionSelection();
    };

    return (
        <div>
            <div styleName={"styles.genericWrap"}>
                {
                    Object.keys(groupFacet).map((facet) => {
                        if (groupFacet[facet]?.options) {
                         return groupFacet[facet].options.map((option) => {
                                const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: facet, subOptionKey: option.key }, appliedFilters);
                                return (
                                    <div key={option.key} styleName={"styles.itemWrap"}>
                                        <Checkbox checkedStatus={isSelected} onClickCallback={onChangeSubFilterOption.bind(null, filter.key, facet, option.key, !isSelected, `${option.displayText}`)} id={`${option.key}${option.key}`} name="" />
                                        <div styleName={`styles.listTextRevamp ${isSelected ? `styles.active` : ``}`}>
                                            {`${option.displayText}`}
                                        </div>
                                    </div>
                                );
                            });
                        } else {
                            return "";
                        }
                    }
                    )
                }
            </div>
        </div>);
};

Features.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    restProps: PropTypes.object,
    handleUpdateSelectedFilters: PropTypes.func,
    updateAllSubFilterOptionsConnect: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateAllSubFilterOptionsConnect: updateAllSubFilterOptions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Features);
