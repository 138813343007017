/* eslint-disable complexity */
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import H2 from "../../shared/h2/component";
import NotFound from "./images/wishlist-not-found.svg";
import { DEFAULT_AE_CITY_NAME, LISTING_TYPE, NUMBER } from "../../../constants/app-constants";
import Skeleton from "../inventory-car-card/skeleton";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import withWishlist from "../with-wishlist";
import styles from "./styles.css";
import InfiniteScroll from "react-infinite-scroller";
import InventoryCarCard from "../inventory-car-card";
import BookingCtaWishlist from "../booking-cta-wishlist";
import TrackableCarCardItem from "../trackable-car-card-item";
// import Breadcrumb from "../../shared/breadcrumb";
import BreadcrumbHome from "../../shared/breadcrumb-home/component";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
// import { useInView } from "react-intersection-observer";
import carListingEventDataParser from "../../../tracking/parser/car-listing";
import { carListingEventDataParserGA4 } from "../../../tracking/parser/car-listing-ga4";
import Login from "../login-revamp";

// eslint-disable-next-line max-statements
const MyWishlist = ({
    wishlistedCars,
    selectedCarId,
    fetchWishlistedCarsConnect,
    fetchWishlistedCarsListConnect,
    // showMyWishlist,
    secureToken,
    isLoggedIn,
    isFetching,
    updateSelectedCarConnect,
    history,
    onClickAddToWishList,
    config,
    selectedCity,
    reloadCarListConnect,
    mobile,
    isUserDetailsFetched
}) => {

    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const inifiniteLoaderRef = useRef();
    const activeCardRef = useRef();

    const { cars, totalElements, totalPages, pageSize, currentPage } = wishlistedCars || {};
    const hasMore = (currentPage < totalPages) && totalPages !== null && secureToken && !isFetching;

    useEffect(() => {
        if (isLoggedIn && secureToken) {
            fetchWishlistedCarsListConnect(secureToken);
            fetchWishlistedCarsConnect(secureToken, { page: NUMBER.ZERO, size: pageSize });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, secureToken]);

    useEffect(() => {
        if (isUserDetailsFetched && isLoggedIn) {
            setShowLoginPopup(false);
        } else if (isUserDetailsFetched && !isLoggedIn) {
            setShowLoginPopup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isUserDetailsFetched]);

    const loadMore = (page) => {
        fetchWishlistedCarsConnect(secureToken, { page, size: pageSize });
    };

    useEffect(() => {
        if (activeCardRef && activeCardRef.current) {
            window.requestAnimationFrame(() => {
                activeCardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            });
        }
    }, [selectedCarId]);

    const onClickStartBooking = (car, source = null) => {
        trackDesktopCustomEventsAE("startPurchaseFromWishlist");
        const data = carListingEventDataParser(car, {});
        trackDesktopCustomEventsAE("startPurchase", data.startPurchase);
        const { year, make, model, appointmentId, city } = car;
        const { relativeURL } = getDetailPageUrl({ year, carName: `${make} ${model}`, carId: appointmentId, city, country: "ae", isCheckout: true });
        history.push(`${relativeURL}${source ? `?source=${source}` : ""}`);
    };

    const onClickSeeDetails = (car) => {
        const { year, make, model, appointmentId, city } = car;
        const { relativeURL } = getDetailPageUrl({ year, carName: `${make} ${model}`, carId: appointmentId, city, country: "ae" });
        history.push(relativeURL);
    };

    const onClickRemoveFromWishlist = (car) => {
        onClickAddToWishList(car.appointmentId, secureToken, {
            removeFromWishlist: true
        });
    };

    const onClickSearchNow = () => {
        reloadCarListConnect(true);
        history.push(`/buy-used-cars-${dashedLowercase(selectedCity.name || DEFAULT_AE_CITY_NAME)}`);
        window.setTimeout(() => {
            window.scrollTo(0, 0);
        });
    };

    const triggerGA4ViewItemListEvent = (index = 0, carObject = {}) => {
        const dataGA4 = carListingEventDataParserGA4(carObject, {
            position: index + 1,
            filterDimensions: {},
            listingType: LISTING_TYPE.WISHLIST_PAGE
        });
        return {
            name: "ga4ViewItemList",
            data: {
                user_id: mobile,
                ...dataGA4
            }
        };
    };

    const triggerGA4SelectContentEvent = (index, car = {}) => {
        const dataGA4 = carListingEventDataParserGA4(car, {
            position: index + 1,
            filterDimensions: {},
            listingType: LISTING_TYPE.WISHLIST_PAGE
        });
        trackDesktopCustomEventsAE("clearEccomerce");
        trackDesktopCustomEventsAE("ga4SelectContent", {
            user_id: mobile,
            ...dataGA4
        });
    };

    const onClickCallback = (index, car = {}) => {
        updateSelectedCarConnect(selectedCarId ? "" : car?.appointmentId);
        triggerGA4SelectContentEvent(index, car);
    };

    return (
        <Login isVisible={showLoginPopup} onClose={() => { setShowLoginPopup(false); }}>
            <div styleName={"styles.outer"}>
                <div className="container mt-3">
                    <BreadcrumbHome title={` My Wishlist`} homeRoute={`/`} />
                    <H2 text="WISHLIST" />
                    <p styleName={"styles.subtitle"}>{totalElements} cars currently in your wishlist</p>
                    <div styleName={"styles.cardsOuter"}>
                        {/* Car Cards Will Come here */}
                        <InfiniteScroll
                            ref={inifiniteLoaderRef}
                            pageStart={NUMBER.ZERO}
                            loadMore={loadMore}
                            hasMore={hasMore}
                        >
                            <div className="row" >
                                {(cars || []).map((item, index) => (
                                    <div className="col-3" styleName={"styles.cardItems"} key={index}>
                                        <TrackableCarCardItem
                                            eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                            itemIndex={index}
                                        >
                                            <InventoryCarCard
                                                shouldRedirect={false}
                                                onClickCallback={() => onClickCallback(index, item)}
                                                key={item.appointmentId}
                                                {...item}
                                                carData={item}
                                                isActive={item.appointmentId === selectedCarId}
                                                cardRef={item.appointmentId === selectedCarId ? activeCardRef : undefined}
                                                config={config}
                                            />
                                        </TrackableCarCardItem>
                                    </div>

                                ))}
                            </div>
                        </InfiniteScroll>
                        {(cars || []).length === 0 && !isFetching &&
                            <div styleName={"styles.notFoundWrap"}>
                                <img src={NotFound} />
                                <h5>You have no saved cars!</h5>
                                <p>It’s never too late. Begin exploring and find your dream car now</p>
                                <Button onClick={onClickSearchNow} text="Search Now" />
                            </div>
                        }
                        <div className="row"   >
                            {isFetching && Array(...new Array(NUMBER.THREE)).map((i, index) => {
                                return (
                                    <div key={index} className={"col-3"}><Skeleton /></div>
                                );
                            })}
                        </div>
                    </div>

                </div>

                {selectedCarId && <BookingCtaWishlist
                    type={"wishlist"}
                    selectedCarId={selectedCarId}
                    onClickStartBooking={onClickStartBooking}
                    onClickRemoveFromWishlist={onClickRemoveFromWishlist}
                    onClickSeeDetails={onClickSeeDetails}
                />}
                {/* <div ref={ref}/> */}
            </div>
        </Login>
    );
};

MyWishlist.propTypes = {
    wishlistedCars: PropTypes.object,
    fetchWishlistedCarsConnect: PropTypes.func,
    fetchWishlistedCarsListConnect: PropTypes.func,
    selectedCarId: PropTypes.string,
    secureToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isFetching: PropTypes.bool,
    shouldRedirect: PropTypes.bool,
    updateSelectedCarConnect: PropTypes.func,
    history: PropTypes.object,
    onClickAddToWishList: PropTypes.func,
    config: PropTypes.object,
    selectedCity: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    mobile: PropTypes.string,
    isUserDetailsFetched: PropTypes.string
};

export default (withWishlist(MyWishlist));
