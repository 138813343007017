import React, {Fragment} from "react";

// Styling
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <Fragment>
            <div styleName={"styles.cardSkeleton"}>
                <div className="shimmer" styleName={"styles.carImage"}/>
                <div className="shimmer" styleName={"styles.testDrive"} />
                <div styleName={"styles.carGrid"}>
                    <div className="shimmer" styleName={"styles.price"}/>
                    <div className="shimmer" styleName={"styles.price"}/>
                    <div className="shimmer" styleName={"styles.price"}/>
                </div>
                <div className="shimmer" styleName={"styles.bookText"} />
                <div className="shimmer" styleName={"styles.addBtn"} />

            </div>
            <div styleName={"styles.giftWrap"}>
                    <div className="shimmer" styleName={"styles.giftIcon"} />
                <div className="shimmer" styleName={"styles.giftPara"} />
            </div>
        </Fragment>
    );
};

export default Skeleton;
