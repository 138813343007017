/* eslint-disable no-unused-expressions */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import loadable from "@loadable/component";

// Styles
import "../../../styles/reset.desktop.css";
import "../../../styles/slick.css";
import "../../../styles/slick-theme.css";
import "../../../styles/slick-reset-desktop.css";
import "../../../fonts/fonts.css";

// Components
import Error from "../../shared/error";
import ErrorHandler from "../../shared/with-error-handler";
import NoInternet from "../no-internet";
const FooterV2 = loadable(() => import("../footer-v2"));
const HeaderRevampV2 = loadable(() => import("../header-revamp-v2"));
const LocationOnboarding = loadable(() => import("../../ae.desktop/location-onboarding"));
const ChatIcon = loadable(() => import("../chat-icon"));

//Styles
import styles from "./styles.css";

// Utils
import { updateLocationPopupShown } from "../../ae.configuration/actions";

export default function withLayout(WrappedComponent, header = true, footer = true) {
    const Layout = (props) => {
        const { route = {}, iconProperties } = props;

        const getLayout = () => {
            let component = (
                <Fragment>
                    {header && (
                        <React.Fragment>
                            <HeaderRevampV2 {...props} />
                        </React.Fragment>
                    )}
                    {iconProperties[route?.name] &&
                        <ErrorHandler>
                            <div className="container" styleName={"styles.whatsappDetailWrap"}>
                                <ChatIcon {...props} />
                            </div>
                        </ErrorHandler>}
                    <ErrorHandler>
                        <WrappedComponent {...props} />
                    </ErrorHandler>

                    <ErrorHandler>
                        <NoInternet />
                    </ErrorHandler>
                    {footer && <FooterV2 {...props} />}
                    {props.showLocationPopup && (
                        <ErrorHandler>
                            <LocationOnboarding route={props.route} />
                        </ErrorHandler>
                    )}
                </Fragment>
            );
            if (props.isSSRError) {
                component = <Error />;
            }
            return component;
        };
        return getLayout();
    };
    const mapStateToProps = ({
        user: { isSSRError, showLocationPopup },
        chatIcon: { iconProperties }
    }) => {
        return {
            isSSRError,
            showLocationPopup,
            iconProperties
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        updateLocationPopupShownConnect: updateLocationPopupShown
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(Layout);
}
