import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SortBy from "./component";
import {updateSelectedSort, updateUrlOptions} from "../filters/actions";
import {reloadCarList} from "../car-listing/actions";

const mapStateToProps = ({
    filters: {
        sorts: {
            sortIds,
            sortData
        },
        allFilters,
        selectedSort,
        appliedFilters,
        appliedSuggestions
    },
    location: {
        selectedCity
    }
}) => ({
    sortIds,
    sortData,
    allFilters,
    selectedSort,
    appliedFilters,
    appliedSuggestions,
    selectedCity: (selectedCity || {})
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSelectedSortConnect: updateSelectedSort,
    updateUrlOptionsConnect: updateUrlOptions,
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SortBy);
