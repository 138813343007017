/* eslint-disable max-statements */
export default (api) => {

    // eslint-disable-next-line max-params
    const getAvailableInspectionSlots = (appointmentId, params, token, isDesktop = false) => {
        return api.get(`/${isDesktop ? "v1" : "v2"}/cj-appId/available/${appointmentId}`, {
            headers: {
                Authorization: token,
                SOURCE: "mSite"
            },
            isSeller: true,
            params
        });
    };

    const getAvailableVideoInspectionSlots = (appointmentId, params, token) => {
        return api.get(`/v1/cj-appId/available-video-link-slots/${appointmentId}`, {
            headers: {
                Authorization: token,
                SOURCE: "mSite"
            },
            isSeller: true,
            params
        });
    };

    const fetchCarStores = () => {
        return api.get(`/v1/config/cars24-stores`);
    };

    const bookCarInspection = (appointmentId, payload, token) => {
        return api.post(`/v1/cj-appId/${appointmentId}/booked`, payload, {
            headers: {
                Authorization: token,
                SOURCE: "mSite"
            }
        });
    };

    const bookCarVideoInspection = (appointmentId, payload, token) => {
        return api.post(`/v1/cj-appId/${appointmentId}/booked-video-link`, payload, {
            headers: {
                Authorization: token,
                SOURCE: "mSite"
            }
        });
    };
    const fetchSellerSlots = (appId, token) => api.get(`/v1/seller-schedule/${appId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            SOURCE: "mSite"
        }
    });

    const updateSellerSlots = (appId, payload, token) => api.put(`/v1/seller-schedule/${appId}`, payload, {
        headers: {
            Authorization: `Bearer ${token}`,
            SOURCE: "mSite"
        }
    });

    const confirmBuyerTDSlot = (orderId, payload, token) => api.put(`/v1/slot/${orderId}/confirm?slotType=C2C_TEST_DRIVE`, payload, {
        headers: {
            Authorization: `Bearer ${token}`,
            SOURCE: "mSite"
        }
    });

    return {
        getAvailableInspectionSlots,
        bookCarInspection,
        getAvailableVideoInspectionSlots,
        bookCarVideoInspection,
        fetchSellerSlots,
        updateSellerSlots,
        confirmBuyerTDSlot,
        fetchCarStores
    };
};
