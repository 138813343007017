import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./handle.css";

// Constants
import { NUMBER } from "../../../constants/app-constants";

// Utils
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import priceFormatter from "../../../utils/helpers/price-formatter";

const Handle = ({
    domain: [min, max],
    handle: { id, value, percent },
    disabled,
    getHandleProps,
    animatedHandle,
    handleColor,
    getHandleID,
    prefix,
    rangeValues = [],
    rangeType = ""
}) => {

    return (
        <Fragment>
            <div
                style={{
                    top: `${percent}%`,
                    position: "absolute",
                    transform: "translate(-10%, -50%)",
                    WebkitTapHighlightColor: "rgba(0,0,0,0)",
                    zIndex: 5,
                    width: 180,
                    height: 28,
                    cursor: "pointer",
                    // border: '1px solid white',
                    backgroundColor: "none"
                }}
                {...getHandleProps(id)}
                {...getHandleID(id)}
            />
            <div styleName={"styles.sliderCircle"}
                className={
                    animatedHandle ? `rippleEffect ${handleColor || "orange"}` : ""
                }
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    top: `${percent}%`,
                    backgroundImage: disabled ? "#fff" : ""
                }}
             />
            <div
                styleName={"styles.sliderData"}
                className={animatedHandle ? `rippleEffect ${handleColor || "orange"}` : ""}
                style={{ top: `${percent - NUMBER.FIVE}%`, backgroundImage: disabled ? "#fff" : ""}}
            >
                {rangeType === FILTER_ENTITIES.PRICE ?
                    <p>{prefix && prefix} {priceFormatter(rangeValues[value], true, "AE")}</p> :
                    <p>{prefix && prefix} {value}</p>
                }
            </div>
        </Fragment>
    );
};

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    animatedHandle: PropTypes.bool,
    handleColor: PropTypes.string,
    getHandleID: PropTypes.func,
    prefix: PropTypes.string,
    rangeValues: PropTypes.array,
    rangeType: PropTypes.string
};

Handle.defaultProps = {
    disabled: false
};

export default Handle;
