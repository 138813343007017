import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarList from "./component";
import { fetchCarList, updateSSRStatus, reloadCarList, resetCarList, fetchCarListTotalCount, fetchSimilarCarListOnSearch } from "../car-listing/actions";
import { fetchWishlistedCarsList } from "../my-wishlist/actions";
import { fetchRecentlyViewedCars, updateFilterOptionData, updateSelectedSort } from "../filters/actions";
import { updateLocationPopupShown } from "../../ae.configuration/actions";

const mapStateToProps = ({
    carListing: {
        content,
        totalCars,
        page,
        isLoading,
        config,
        isSSR,
        shouldReloadList,
        similarCarPage
    },
    filters: {
        allFilters,
        urlOptions,
        selectedFilters,
        appliedFilters,
        selectedSort,
        appliedSuggestions,
        recentlyViewedCars = [],
        isRecentlyViewedSSR
    },
    carDetails: {
        impressionSource
    },
    user: { mobile, isLoggedIn },
    config: {
       activeSaleConfig = {}
    }
}) => ({
    content,
    totalCars,
    page,
    isLoading,
    config,
    allFilters,
    isSSR,
    urlOptions,
    selectedFilters,
    appliedFilters,
    selectedSort,
    mobile,
    appliedSuggestions,
    shouldReloadList,
    impressionSource,
    recentlyViewedCars,
    isRecentlyViewedSSR,
    similarCarPage,
    activeSaleConfig,
    isLoggedIn

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarListConnect: fetchCarList,
    updateSSRStatusConnect: updateSSRStatus,
    reloadCarListConnect: reloadCarList,
    resetCarListConnect: resetCarList,
    fetchWishlistedCarsListConnect: fetchWishlistedCarsList,
    fetchRecentlyViewedCarsConnect: fetchRecentlyViewedCars,
    fetchCarListTotalCountConnect: fetchCarListTotalCount,
    fetchSimilarCarListOnSearchConnect: fetchSimilarCarListOnSearch,
    updateSelectedSortConnect: updateSelectedSort,
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateLocationPopupShownConnect: updateLocationPopupShown
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarList);
