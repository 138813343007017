import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Styles
import styles from "./styles.css";

// Images
import Banner from "./images/banner.png";
import IconHighApprovalRate from "./images/high-approval-rate.svg";
import IconLowInterestRate from "./images/low-interest-rate.svg";
import IconInhouseTeam from "./images/inhouse-team.svg";

// Component
import LazyImage from "../../shared/lazy-image";

// Constant
import { LOAN_TYPE } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/routes-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const LoanTypeBannerLanding = ({
    financePartner = [],
    carDetails = {}
}) => {
    const { emiDetails: { financeEligibility } = {} } = carDetails?.content || {};

    const zeroDpBannerClick = () => {
        trackDesktopCustomEventsAE("loanPageZeroDpBanner", { eventLabel: "home" });
    };

    return (
        <React.Fragment>
            <a href={`/${ROUTE_PATHS.carLoan}/`}>
                <div styleName={"styles.zeroDpWrapper"} onClick={zeroDpBannerClick}>
                    <h3>ZERO DOWNPAYMENT*</h3>
                    <div styleName={"styles.flexRow"}>
                        <article>
                            <p>Quick & Easy Finance</p>
                            <ul>
                                <li><img src={IconHighApprovalRate} alt="" height={16} width={16} />High approval rates</li>
                                <li><img src={IconLowInterestRate} alt="" height={16} width={16} />Low interest rate</li>
                                <li><img src={IconInhouseTeam} alt="" height={16} width={16} />In-house team</li>
                            </ul>
                        </article>
                        <figure>
                            <img src={Banner} alt="" width={211} height={113} />
                        </figure>
                    </div>
                    <div styleName={"styles.partnersRow"}>
                        <h3>Our Lending Partners</h3>
                        <div styleName={"styles.partnerLogo"}>
                            {((financeEligibility === LOAN_TYPE.PERSONAL_LOAN ?
                                financePartner?.personalLoanBanks :
                                financePartner?.autoLoanBanks) || []).map(({ key: bankKey, logo }) => (
                                    <p key={bankKey}><LazyImage src={logo} alt={bankKey} /></p>
                                ))}
                        </div>
                    </div>
                </div>
            </a>
        </React.Fragment>
    );
};

LoanTypeBannerLanding.propTypes = {
    history: PropTypes.object,
    financePartner: PropTypes.array,
    carDetails: PropTypes.object
};

const mapStateToProps = ({
    config: {
        financePartner
    },
    carDetails
}) => ({ financePartner, carDetails });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoanTypeBannerLanding));
