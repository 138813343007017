import { DEFAULT_CITY } from "../../constants/app-constants";
import { appUrl } from "../../constants/url-constants";
import getLowerCase from "../../utils/helpers/get-lower-case";

export default ({ carName, year, carId, city, pincode, isCheckout }) => {
    const url = ["buy", "used"];
    const carText = (carName || "");
    if (carText.includes("Suzuki")) carText.replace("Suzuki ", "");
    url.push(carText.split(" ").join("-"));
    url.push(year);
    url.push("cars");
    url.push((city || "").split(" ").join("-"));
    url.push(carId);
    const normalizedURL = url.join("-");
    return {
        absoluteURL: getLowerCase(`${appUrl()}/${normalizedURL}/${isCheckout ? `checkout/` : ``}${pincode ? `?pinId=${pincode}` : ``}`),
        relativeURL: getLowerCase(`/${normalizedURL}/${isCheckout ? `checkout/` : ``}${pincode ? `?pinId=${pincode}` : ``}`)
    };
};

export const getCarDetailUrl = ({ content }) => {
    const { make, model, year, city = DEFAULT_CITY.AE.city_slug, appointmentId } = content;
    const url = ["buy", "used"];
    const carText = (`${make}-${model}` || "");
    if (carText.includes("Suzuki")) carText.replace("Suzuki ", "");
    url.push(carText.split(" ").join("-"));
    url.push(year);
    url.push("cars");
    url.push((city || "").split(" ").join("-"));
    url.push(appointmentId);
    const normalizedURL = url.join("-");
    return {
        absoluteURL: getLowerCase(`${appUrl()}/${normalizedURL}/`),
        relativeURL: getLowerCase(`/${normalizedURL}/`)
    };
};

export const getCarDetailCustomUrl = ({ content, path = "" }) => {
    const { absoluteURL, relativeURL } = getCarDetailUrl({ content });

    return {
        absoluteURL: getLowerCase(`${absoluteURL}${path}`),
        relativeURL: getLowerCase(`${relativeURL}${path}`)
    };
};
