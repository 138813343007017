import { AB_TEST_VARIATIONS } from "../app-constants";

export const TD_SCHEDULE_STEP_KEY = {
    location: "location",
    slot: "slot",
    address: "address",
    reschedule: "reschedule",
    confirmation: "confirmation"
};

const COMMON_LIST = [
    {
        heading: "SELECT YOUR LOCATION",
        key: TD_SCHEDULE_STEP_KEY.location
    },
    {
        heading: "SELECT DATE & TIME",
        key: TD_SCHEDULE_STEP_KEY.slot
    },
    {
        heading: "DETAILED ADDRESS",
        key: TD_SCHEDULE_STEP_KEY.address
    },
    {
        heading: "RESCHEDULE REASON",
        key: TD_SCHEDULE_STEP_KEY.reschedule
    }
];

const TD_SCHEDULE_PROGRESS_CONTROL_ITEMS = [
    ...COMMON_LIST
];

const TD_SCHEDULE_PROGRESS_VARIANT_ITEMS = [
    ...COMMON_LIST
];

const COMMON_TAB = [
    { name: TD_SCHEDULE_STEP_KEY.location, index: 1 },
    { name: TD_SCHEDULE_STEP_KEY.slot, index: 2 },
    { name: TD_SCHEDULE_STEP_KEY.address, index: 3 },
    { name: TD_SCHEDULE_STEP_KEY.reschedule, index: 4 }
];

export const TD_SCHEDULE_CONTROL_TAB = [
    ...COMMON_TAB
];

export const TD_SCHEDULE_VARIANT_TAB = [
    ...COMMON_TAB
];

export const STEP_PROGRESS_ITEMS = {
    [AB_TEST_VARIATIONS.CONTROL]: TD_SCHEDULE_PROGRESS_CONTROL_ITEMS,
    [AB_TEST_VARIATIONS.VARIANT]: TD_SCHEDULE_PROGRESS_VARIANT_ITEMS
};

export const TD_ERROR_CODES = {
    "SLOT_ALREADY_BOOKED1": "SLOT_ALREADY_BOOKED1",
    "SLOT_ALREADY_BOOKED2": "SLOT_ALREADY_BOOKED2",
    "SLOT_ALREADY_BOOKED3": "SLOT_ALREADY_BOOKED3",
    "SLOT_RESCHEDULE1": "SLOT_RESCHEDULE1"
};

export const ERROR_DETAILS = {
    SLOT_ALREADY_BOOKED1: {
        heading: "Slot not available",
        detail: "Someone else may have booked this slot before you. Please select other slot to confirm your test drive",
        ctaText: "SELECT ANOTHER TIME SLOT"
    },
    SLOT_ALREADY_BOOKED2: {
        heading: "Slot not available",
        detail: "Someone else may have booked this slot before you. Please select other slot to confirm your test drive",
        ctaText: "SELECT ANOTHER TIME SLOT"
    },
    SLOT_ALREADY_BOOKED3: {
        heading: "Slot not available",
        detail: "Someone else may have booked this slot before you. Please select other slot to confirm your test drive",
        ctaText: "SELECT ANOTHER TIME SLOT"
    },
    SLOT_RESCHEDULE1: {
        heading: "You Can’t Reschedule Now ",
        detail: "Please contact your relationship manager if  you wish to reschedule your test drive.",
        ctaText: "GOT IT"
    }
};

export const TD_SLOT_STATUS = {
    CONFIRMED: "CONFIRMED",
    SCHEDULED: "SCHEDULED",
    CANCELLED: "CANCELLED"
};

export const TD_LOCATION_EMIRATES_MAPPING = {
    CC_AE_2: "Abu Dhabi",
    CC_AE_8: "Abu Dhabi",
    DU_DUBAI: "Dubai",
    CC_AE_3: "Dubai",
    CC_AE_4: "Dubai",
    CC_AE_5: "Dubai",
    CC_AE_6: "Dubai",
    CC_AE_7: "Dubai"
};

export const TD_EMIRATES_MAPPING = {
    "Dubai": "Dubai",
    "AbuDhabi": "Abu Dhabi",
    "Sharjah": "Sharjah",
    "Ajman": "Ajman"
};
