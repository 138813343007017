import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginSignupModal from "./component";
import { sendOTP, verifyOtp, fetchUserConsent, updateUserConsent } from "./actions";
import { updateProfile, getTokenInfo, userProfile, setProfileWithToken, updateDNDStatus, updateWAStatus, updateUnlockCoupon } from "../../ae.configuration/actions";

const mapStateToProps = ({
    user: { mobile, name, isLoggedIn, email, isFetched, isDNDActive, isWAConsent, isWebview },
    cities: {
        selectedCity: {
            code: cityCode,
            lat,
            lng
        }
    },
    loanEligibility,
    config: {activeSaleConfig}
}) => ({
    mobile,
    name,
    isLoggedIn,
    email,
    isFetched,
    isDNDActive,
    isWAConsent,
    cityCode,
    lat,
    lng,
    loanEligibility,
    activeSaleConfig,
    isWebview
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    verifyOtpConnect: verifyOtp,
    sendOTPConnect: sendOTP,
    updateProfileConnect: updateProfile,
    getTokenInfoConnect: getTokenInfo,
    setProfileWithTokenConnect: setProfileWithToken,
    fetchUserConsentConnect: fetchUserConsent,
    updateUserConsentConnect: updateUserConsent,
    updateDNDStatusConnect: updateDNDStatus,
    updateWAStatusConnect: updateWAStatus,
    userProfileConnect: userProfile,
    updateUnlockCouponConnect: updateUnlockCoupon
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignupModal);
