/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styling
import styles from "./styles.css";

// Images
import BookedInnfoIcon from "./images/error-info.svg";
import HomeIcon from "./images/home.svg";
import LocationIcon from "./images/location-on.svg";
import IconPrimeTag from "../../shared/images/icons/prime-car-tag-new.svg";
import IconliteTag from "../../shared/images/icons/lite-car-tag.svg";
import PrivateSellerTag from "../../shared/images/icons/private-seller-tag.svg";
import PrivateSellerProTag from "../../shared/images/icons/private-seller-pro-tag.svg";
import IconGreenTag from "../../shared/images/icons/green-car-tag.svg";
import IconAssured from "./images/assured.png";
import IconGuranteed from "./images/guaranteed-sales.png";
import IconInfoOutline from "./images/info-outline-blue.svg";

// Components
import Button from "../../shared/button";
import Login from "../login-revamp";
import withLogin from "../with-login";
import withWishlist from "../with-wishlist";
import LazyImage from "../../shared/lazy-image";
const CustomizeEmiPlansModal = loadable(() => import("../customize-emi-plans-modal"), { ssr: false });
const InfoCarPopup = loadable(() => import("../info-car-popup"), { ssr: false });
const CarPriceNegotiablePopup = loadable(() => import("../car-price-negotiable-popup"), {ssr: false});
const CarAssortmentPopup = loadable(() => import("../car-assortment-popup"), { ssr: false });
const CarPrimePopup = loadable(() => import("../car-prime-popup/component"), { ssr: false });
const CarLitePopup = loadable(() => import("../car-lite-popup"), { ssr: false });
const CarGreenPopup = loadable(() => import("../car-green-popup"), { ssr: false });
const PrivateSellerPopup = loadable(() => import("../private-seller-popup"), { ssr: false });
const PrivateSellerProPopup = loadable(() => import("../private-seller-pro-popup"), { ssr: false });
const SimilarCarModal = loadable(() => import("../similar-car-modal"));
const UnderstandPricePopup = loadable(() => import("../understand-price-popup"),  { ssr: false });
const HotOfferNudge = loadable(() => import("../../shared/hot-offer-nudge"),  { ssr: false });

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import cleverTap from "../../../tracking/clevertap";

// Constants
import { ASSORTMENT_CATEGORY_LABEL, CAR_STATUS, LOAN_TYPE, NUMBER, PRIMARY_CTA_TEXT, INVENTORY_TYPE_LABEL, AB_TEST_VARIATIONS } from "../../../constants/app-constants";
import { SOURCES } from "../login/constants";

// Utilities
import { checkCarStatus, getCarName, getCarPriceLabel, getShortKmLabel, getCarPriceLabelWithoutCurrency } from "../../../utils/helpers/car-properties.ae";
import priceFormatter from "../../../utils/helpers/price-formatter";
import { makePriceLabelRoundAE } from "../../../utils/helpers/make-price-label";
import Swipeable from "../../shared/swipeable";
import ExchangeCouponSliderCard from "../exchange-coupon-slider-card";
import { SIMILAR_CAR_MODAL_INITIAL } from "../similar-car-modal/constants";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import SaleStripDetailPage from "../sale-strip-detail-page";
import { ROUTE_NAME } from "../../../constants/routes-constants";

const carCategory = {
    [ASSORTMENT_CATEGORY_LABEL.PRIME]: {
        icon: IconPrimeTag, label: "PRIME", PreviewComponent: CarPrimePopup, height: "20", width: "80"
    },
    [ASSORTMENT_CATEGORY_LABEL.LITE]: {
        icon: IconliteTag, label: "LITE", PreviewComponent: CarLitePopup, height: "20", width: "65"
    },
    [ASSORTMENT_CATEGORY_LABEL.GREEN]: {
        icon: IconGreenTag, label: "GREEN", PreviewComponent: CarGreenPopup, height: "20", width: "82"
    },
    [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: {
        icon: PrivateSellerTag, label: "PRIVATE SELLER", PreviewComponent: PrivateSellerPopup, height: "20", width: "93"
    },
    [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: {
        icon: PrivateSellerProTag, label: "PRIVATE SELLER PRO", PreviewComponent: PrivateSellerProPopup, height: "20", width: "118"
    }
};

const CarDescriptionSidebar = ({
    carDetails,
    showLogin,
    setShowLogin,
    ctaProps,
    fetchCouponListConnect,
    mobile = "",
    showWishlistNudgeConnect = () => { },
    isWishlistedCar = () => { },
    togglePriceBenifitsConnect = () => {},
    onClickAddToWishList,
    isLoggedIn,
    secureToken,
    bookingAllowed,
    activeBookingCount,
    history,
    abDetailPageDesktop,
    activeSaleConfig = {}
}) => {
    const { content, config, showPriceBenifits } = carDetails || {};

    const {
        year,
        odometerReading,
        targetPrice,
        discounted,
        make,
        model,
        appointmentId,
        emiDetails,
        parentHubLocation = {},
        price,
        discountAmount,
        assortmentCategory,
        inventoryType,
        warrantyDuration,
        shortListCount,
        optionsType,
        listingActive,
        variant,
        booked,
        specs,
        homeTdAvailable, hotOfferDetails,
        categoryComparisons = {},
        isComingSoon,
        isBrandNew = false
    } = content || {};

    const carName = `${make || ""} ${model || ""}`;
    const isPrime = assortmentCategory === ASSORTMENT_CATEGORY_LABEL.PRIME;
    const isGreen = assortmentCategory === ASSORTMENT_CATEGORY_LABEL.GREEN;
    const isPrivateSellerPro = assortmentCategory === ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO;
    const isC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;
    // const [showEpicLogin, setShowEpicLogin] = useState(false);
    const scrollRef = useRef(null);
    const [hideCoupon, setHideCoupon] = useState(true);
    const { isHotOffer } = hotOfferDetails || {};
    useEffect(() => {
        const hideCouponHandler = () => {
            const shorterDiv = scrollRef?.current;
            if (shorterDiv?.offsetTop > NUMBER.THREE_HUNDRED) setHideCoupon(false);
            else setHideCoupon(true);
        };

        window.addEventListener("scroll", hideCouponHandler);

        return () => {
            window.removeEventListener("scroll", hideCouponHandler);
        };
    }, []);

    useEffect(() => {
        if (abDetailPageDesktop) {
            trackDesktopCustomEventsAE("desktopDPAbExperiment", {eventLabel: abDetailPageDesktop});
        }
    }, [abDetailPageDesktop]);

    const [couponList, setCouponList] = useState([]);
    const carPrice = getCarPriceLabel(content || {});
    const carPriceWithoutCurrency = getCarPriceLabelWithoutCurrency(content || {});
    const { tenure = "", downPaymentPercentage, financeEligibility, emi } = emiDetails || {};
    // const loanTypeLabelText = financeEligibility === LOAN_TYPE.PERSONAL_LOAN ? `For ${tenure} years with Personal Loan` :
    // financeEligibility === LOAN_TYPE.CUSTOM_DP ? `For ${tenure} years with ${downPaymentPercentage}% Downpayment applicable` : `For ${tenure} years with Zero downpayment options available`;
    const [loginSource, setLoginSource] = useState();
    const isElectricCar = assortmentCategory === ASSORTMENT_CATEGORY_LABEL.GREEN;
    const {
        onCheckoutButtonClick,
        onLoginSuccessCallback,
        notify
    } = ctaProps;
    const [isEmiModalVisible, setIsEmiModalVisible] = useState(false);
    const [showTestDrivePopup, setShowTestDrivePopup] = useState(false);
    const [showAssortmentPopup, setShowAssortmentPopup] = useState(false);
    const [showPriceNegotiablePopup, setShowPriceNegotiablePopup] = useState(false);
    const [similarCarModalData, setSimilarCarModalData] = useState(SIMILAR_CAR_MODAL_INITIAL);
    const isCarSold = checkCarStatus({ listingActive }, CAR_STATUS.SOLD);
    const isReservedCar = checkCarStatus({ booked, listingActive }, CAR_STATUS.RESERVED);
    const showPriceBenefitsHyperlink = [ASSORTMENT_CATEGORY_LABEL.PRIME, ASSORTMENT_CATEGORY_LABEL.LITE].includes(assortmentCategory);

    const onClickPurchase = () => {
        setLoginSource("checkout");
        onCheckoutButtonClick(setShowLogin, "detail_page");
    };

    const isCarWishListed = isWishlistedCar(appointmentId);
    const trackGaEvents = () => {
        if (isCarWishListed) {
            trackDesktopCustomEventsAE("removeFromWishlist", {
                eventLabel: "Remove from wishlist - Car details page"
            });
        } else {
            trackDesktopCustomEventsAE("addToWishlistDetailPage", {
                eventLabel: "Add to wishlist - Car details page"
            });
        }
    };

    const options = {
        enableMouseEvents: true,
        enableArrows: false,
        autoplay: true,
        enableDots: !!((couponList?.length > 1) || (isHotOffer && couponList?.length)),
        enablePagination: !!((couponList?.length > 1) || (isHotOffer && couponList?.length))
    };

    const onLoginSuccess = (loginProps) => {
        if (loginSource === "checkout") {
            onLoginSuccessCallback(setShowLogin, loginProps?.token);
        } else if (loginSource === "wishlist") {
            trackGaEvents();
            onClickAddToWishList(appointmentId, loginProps?.token, {
                callback: (shortListed) => {
                    if (shortListed) cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.WISHLISTED_CAR, carDetails);
                }
            });
            setShowLogin(false);
        }
    };

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onClickWishlist = (e) => {
        setLoginSource("wishlist");
        preventPropagation(e);
        if (isLoggedIn) {
            trackGaEvents();
            onClickAddToWishList(appointmentId, secureToken, {
                callback: (shortListed) => {
                    if (shortListed) {
                        showWishlistNudgeConnect();
                        cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.WISHLISTED_CAR, carDetails);
                    }
                }
            });
        } else {
            setShowLogin(true);
        }
    };

    const closeLoginModal = () => {
        setShowLogin(false);
    };

    const getLoanTypeLabel = () => {
        let loanTypeLabel = "";
        if (financeEligibility === LOAN_TYPE.PERSONAL_LOAN) {
            loanTypeLabel = "Only Personal Loan Available";
        } else if (financeEligibility === LOAN_TYPE.CUSTOM_DP) {
            loanTypeLabel = `Auto loan with ${downPaymentPercentage}% downpayment`;
        } else if (!isElectricCar || financeEligibility === LOAN_TYPE.GREEN_ZERO_DOWN_PAYMENT) {
            loanTypeLabel = "Zero downpayment options available";
        } else if (financeEligibility === LOAN_TYPE.GREEN_CUSTOM_DOWN_PAYMENT) {
            loanTypeLabel = `Green loan with ${downPaymentPercentage}% downpayment`;
        }
        return loanTypeLabel;
    };

    const getCouponList = async () => {
        try {
            const offersList = await fetchCouponListConnect({
                ...(mobile && { userId: mobile }),
                appointmentId
            }, true); // 1st agr = param, 2nd = version
            setCouponList(offersList);
            // eslint-disable-next-line no-empty
        } catch (err) { }
    };

    useEffect(() => {
        if ((couponList || []).length === NUMBER.ZERO) {
            getCouponList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleTestDrivePopup = (location) => {
        if (location) {
            trackDesktopCustomEventsAE("tdLinerDetailClick", {
                eventLabel: location
            });
        }
        setShowTestDrivePopup(!showTestDrivePopup);
    };

    const togglePriceNegotiablePopup = () => {
        trackDesktopCustomEventsAE("negotiatePrice");
        setShowPriceNegotiablePopup(!showPriceNegotiablePopup);
    };

    const toggleAssortmentPopup = () => {
        setShowAssortmentPopup(!showAssortmentPopup);
        trackDesktopCustomEventsAE("certifiedDetailClick", {
            eventLabel: carCategory[assortmentCategory].label
        });
    };

    const handleViewSimilarIconClick = (e) => {
        preventPropagation(e);
        trackDesktopCustomEventsAE("similarCarCTA", {
            eventLabel: isCarSold ? "sold" : "reserved"
        });
        setSimilarCarModalData({ isOpen: true, carData: content });
    };

    const handleSimilarCarModalClose = () => {
        setSimilarCarModalData(SIMILAR_CAR_MODAL_INITIAL);
    };

    const redirectToCarDetailPage = (car = {}) => {
        const { year: selectedCarYear, make: selectedCarMake, model: selectedCarModel, appointmentId: selectedCarApptId, city: selectedCarCity } = car || {};
        const fullCarName = getCarName({ year: selectedCarYear, make: selectedCarMake, model: selectedCarModel });
        const { relativeURL: redirectRelativeUrl } = getDetailPageUrl({ year: selectedCarYear, carName: fullCarName, carId: selectedCarApptId, city: selectedCarCity, country: "ae" });
        history.push(redirectRelativeUrl);
    };
    const handleUnderStandPrice = () => {
        togglePriceBenifitsConnect();
        trackDesktopCustomEventsAE("priceBenefit", { eventLabel: carCategory[assortmentCategory]?.label });
    };
    const onPriceBenefitPopupClose = () => {
        togglePriceBenifitsConnect();
    };
    const hasEpicSaleDiscount = listingActive && discounted;
    const showEmiPriceExperiment = abDetailPageDesktop === AB_TEST_VARIATIONS.VARIANT;

    return (
        <React.Fragment>
            {shortListCount > 0 && <p styleName={"styles.popularTextWrap"}> <span>🔥</span> Popular: Recently {shortListCount} wishlisted this car</p>}
            <div styleName={"styles.coverWrapper"}>
                <div styleName={"styles.cover"} ref={scrollRef}>
                    <div styleName={"styles.flexItem"}>
                        <div styleName={`styles.assortmentIcon`} onClick={toggleAssortmentPopup}>
                            <img
                                src={carCategory[assortmentCategory]?.icon}
                                alt=""
                                height={carCategory[assortmentCategory]?.height}
                                width={carCategory[assortmentCategory]?.width}
                                styleName={`${assortmentCategory === ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER ? "styles.border-radius-4" : ""}`}
                            />
                            <img src={IconInfoOutline} width={16} height={16} alt="info icon" />
                        </div>
                    </div>
                    <div styleName={"styles.flexNameRow"}>
                        <h1 styleName={"styles.carName"}>{year || ""} {carName || ""} {variant || ""}</h1>
                    </div>

                    <div styleName={"styles.variantWrapper"}>
                        <p>{optionsType && `${optionsType}`}  <span>{getShortKmLabel(odometerReading)}</span>
                        {/* {(!isElectricCar && engineSpecsLabel) &&  <span>{engineSpecsLabel}</span>}  */}
                        <span>{specs ? `${specs} Specs` : ""}</span></p>
                    </div>
                    <div styleName={`styles.carLocation ${!listingActive ? "styles.disableItem" : ""}`}>
                        {parentHubLocation?.locationName && (
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div styleName={`styles.flexRow styles.freeHubTd ${isElectricCar ? "styles.noMarginBtm" : ""}`}>
                                        <img src={LocationIcon} alt="Location Icon" />
                                        <p styleName={"styles.parkingLocation"}>
                                            { !isC2C ? `Free Hub ${isComingSoon ? "car viewing" : "test drive"} in ${parentHubLocation?.locationName}` : `Free test drive at ${parentHubLocation?.locationName} location`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isElectricCar && !isC2C && !isPrivateSellerPro && homeTdAvailable && !isComingSoon && <div className={"row"}>
                            <div className={"col-12"}>
                                <div styleName={"styles.flexRow"}>
                                    <img src={HomeIcon} alt="Home Icon" />
                                    <p styleName={"styles.parkingLocation"}>
                                        Home test drive at your preferred location
                                    </p>
                                </div>
                            </div>
                        </div>}
                    </div>
                    {showEmiPriceExperiment ? <React.Fragment>
                        <div styleName={"styles.newVariantDesignRow"}>
                                <div styleName={"styles.fixedPriceGrid"}>
                                    <div styleName="styles.topSide">
                                        <div styleName="styles.leftData">
                                            <h3>
                                                <div styleName={"styles.priceInfo"}>
                                                    <small>{"AED "}</small>{listingActive ? carPriceWithoutCurrency : "******"}
                                                </div>
                                                {hasEpicSaleDiscount && !isC2C && <p> {"AED "} <span styleName="styles.textStrikeOff">{makePriceLabelRoundAE(targetPrice, false)}</span></p> }
                                            </h3>
                                        </div>
                                        {isC2C && hasEpicSaleDiscount && <div styleName={"styles.discountWrap"}> Was AED
                                            <span styleName="styles.textStrikeOff">{makePriceLabelRoundAE(targetPrice, false)}</span>
                                            {/* <p styleName={"styles.salePriceDrop"}> AED {discountAmount} DROP</p> */}
                                        </div> }
                                        { isC2C && <p styleName={`${!isCarSold ? "styles.priceBenefit styles.mtEight" : "styles.priceBenefitDisable"}`} onClick={togglePriceNegotiablePopup}>NEGOTIABLE</p>}
                                    </div>
                                </div>
                                <div styleName={"styles.orSeprator"}>
                                    <span />or<span />
                                </div>
                                <div>
                                    <div styleName={"styles.emiPriceGrid"}>
                                        <h3><small>EMI  </small> {listingActive ? priceFormatter(emi) : "*****"} <small>/ month</small></h3>
                                    </div>
                                    <p styleName={"styles.loanTypeText"}>For {tenure} yrs with {getLoanTypeLabel()}</p>
                                </div>
                        </div>
                        </React.Fragment> : <React.Fragment>
                            <div styleName={"styles.fixedPriceGrid"}>
                                <div styleName="styles.topSide">
                                    <div styleName="styles.leftData">
                                        <h3>
                                            {listingActive ? carPrice : "AED ******"}
                                            {hasEpicSaleDiscount && !isC2C && <span styleName="styles.textStrikeOff">{makePriceLabelRoundAE(targetPrice)}</span>}
                                        </h3>
                                        {hasEpicSaleDiscount && !isC2C && <p styleName={"styles.salePriceDrop styles.mlAuto"}>
                                            AED {discountAmount} OFF
                                        </p>}
                                        { isC2C && <p styleName={`${!isCarSold ? "styles.priceBenefit" : "styles.priceBenefitDisable"}`} onClick={togglePriceNegotiablePopup}>NEGOTIABLE</p>}
                                    </div>
                                    {isC2C && hasEpicSaleDiscount && <div styleName={"styles.discountWrap"}>
                                        <span styleName="styles.textStrikeOff">{makePriceLabelRoundAE(targetPrice)}</span>
                                        <p styleName={"styles.salePriceDrop"}> AED {discountAmount} DROP</p>
                                    </div> }
                                    {/* {!isLoggedIn &&
                                        <Login isVisible={showEpicLogin} isSaleLogin={true} loginFor={"epic_sale"} onSuccess={onEpicSaleLogin}
                                            onClose={setShowEpicLogin.bind(null, false)}>
                                            <p onClick={setShowEpicLogin.bind(null, true)} styleName="styles.epicLoginCta">CHECK PRICE DROP</p>
                                        </Login>
                                    } */}
                                </div>
                            </div>
                            <div styleName={"styles.emiPriceGrid"}>
                                <h3><small>EMI  </small> {listingActive ? priceFormatter(emi) : "*****"} <small>/ month</small></h3>
                                { showPriceBenefitsHyperlink && <span styleName={"styles.understandPrice"} onClick={handleUnderStandPrice}>UNDERSTAND PRICE</span>}
                                {/* <span styleName={listingActive ? "" : "styles.disableItem"} onClick={listingActive ? () => onCustomizeEMI(true) : () => { }}>EMI Plans</span> */}
                            </div>
                            <p styleName={"styles.loanTypeText"}>For {tenure} yrs with {getLoanTypeLabel()}</p>
                            {/* <p styleName={"styles.downPayment"}>
                            {loanTypeLabelText}
                        </p> */}
                    </React.Fragment>}
                    <div styleName={`styles.buttonWrapper ${isReservedCar ? "styles.oneFr" : ""}`}>
                        <Login isVisible={showLogin} onSuccess={onLoginSuccess} onClose={closeLoginModal} loginFor={loginSource === "wishlist" ? SOURCES.ADD_TO_WISHLIST : SOURCES.START_PURCHASE}>
                            <div styleName={"styles.purchase"}>
                                {!isCarSold && <Button text={ctaProps.text} onClick={onClickPurchase} disabled={ctaProps.disabled} />}
                                {(isCarSold || isReservedCar) && <Button styleName={"styles.similarBtn"} text={"Similar Cars"} onClick={handleViewSimilarIconClick} />}
                            </div>
                            {!(isCarSold || isReservedCar) &&
                                <div styleName={"styles.wishlist"}>
                                    {(isWishlistedCar(appointmentId) && !notify) ? (
                                        <button onClick={onClickWishlist} styleName={"styles.wishlistBtn"}>{PRIMARY_CTA_TEXT.WISHLISTED}</button>
                                    ) : (
                                        <button onClick={onClickWishlist} styleName={"styles.wishlistBtn"}>{PRIMARY_CTA_TEXT.WISHLIST_NOW}</button>
                                    )}
                                </div>
                            }
                        </Login>
                        {!bookingAllowed && ctaProps.disabled && <p styleName={"styles.bookedInfoText"}><LazyImage src={BookedInnfoIcon} alt="ifo" />{`Can’t book now! ${activeBookingCount} bookings already done.`}</p>}
                    </div>

                    {(isPrime || isGreen) && (
                        <div styleName={"styles.warrantyGrid"}>
                            <figure>
                                <img src={IconAssured} alt="" width={40} height={40} />
                            </figure>
                            <article>
                                <p>{warrantyDuration} months <strong>FREE</strong> warranty with this car  </p>
                                <span>Extended warranty plans available </span> <span styleName={"styles.learnMore"} onClick={toggleAssortmentPopup}>Learn more</span>
                            </article>

                        </div>
                    )}
                    {isPrivateSellerPro &&
                        <div styleName={"styles.warrantyGridSales"}>
                            <figure>
                                <img src={IconGuranteed} alt="" width={46} height={46} />
                            </figure>
                            <article>
                                <p>PRIVATE SELLER PRO <span onClick={toggleAssortmentPopup}>Learn more</span></p>
                                <span>Zero seller dependency, RTA standard guaranteed </span>
                            </article>
                        </div>
                    }
                    {showTestDrivePopup && <InfoCarPopup homeTdAvailable={homeTdAvailable} onClose={toggleTestDrivePopup} />}
                    {showPriceNegotiablePopup && <CarPriceNegotiablePopup onClose={togglePriceNegotiablePopup} /> }
                    {isEmiModalVisible && (
                        <CustomizeEmiPlansModal
                            onClose={() => setIsEmiModalVisible(false)}
                        />
                    )}
                    {showAssortmentPopup && <CarAssortmentPopup onClose={toggleAssortmentPopup} content={carCategory[assortmentCategory]} categoryComparisons={categoryComparisons} source={ROUTE_NAME.DETAIL_PAGE} isBrandNew={isBrandNew}/>}
                </div>
                {(couponList.length > NUMBER.ZERO && hideCoupon && !isC2C) && (
                    <div styleName={`styles.couponCardSliderWrap ${isHotOffer ? "styles.hotOfferWrap" : ""}`} onClick={() => trackDesktopCustomEventsAE("couponClickDetail")}>
                        <Swipeable options={options}>
                            {isHotOffer && <HotOfferNudge hotOfferDetails={hotOfferDetails} isMobile={false} />}
                            {couponList.map(coupon => (
                                <div key={coupon.id} styleName={`${isHotOffer ? "styles.otherCouponWrap" : ""}`}>
                                    <ExchangeCouponSliderCard
                                        carPrice={price}
                                        couponInfo={coupon}
                                        isApplicable={false}
                                        onUnlockCoupon={getCouponList}
                                    />
                                </div>
                            ))}
                        </Swipeable>
                    </div>
                )}
                {activeSaleConfig?.active && !isPrivateSellerPro && !isC2C && <SaleStripDetailPage />}
            </div>
            {similarCarModalData?.isOpen && (
                <SimilarCarModal
                    config={config}
                    carData={similarCarModalData?.carData}
                    redirectToCarDetailPage={redirectToCarDetailPage}
                    handleClose={handleSimilarCarModalClose}
                />
            )}
            {showPriceBenifits && <UnderstandPricePopup onClose={onPriceBenefitPopupClose} />}
        </React.Fragment>
    );
};

CarDescriptionSidebar.propTypes = {
    carDetails: PropTypes.object,
    history: PropTypes.any,
    secureToken: PropTypes.string,
    showLogin: PropTypes.bool,
    mobile: PropTypes.string,
    fetchCouponListConnect: PropTypes.func,
    setShowLogin: PropTypes.func,
    ctaProps: PropTypes.object,
    isWishlistedCar: PropTypes.func,
    showWishlistNudgeConnect: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    bookingAllowed: PropTypes.bool,
    activeBookingCount: PropTypes.number,
    activeSaleConfig: PropTypes.object,
    fetchCarDetailsConnect: PropTypes.func,
    abDetailPageDesktop: PropTypes.string,
    togglePriceBenifitsConnect: PropTypes.func
};

export default withLogin(withWishlist(CarDescriptionSidebar));
