export default {
    CAR_DETAILS_PAGE: "Car Detailed Page",
    START_PURCHASE: "Start Purchase",
    BOOKING_INITIATED: "BI",
    FINANCE_PROCEED: "Finance Proceed",
    DELIVERY_PROCEED: "Delivery Proceed",
    PAYMENT_INITIATED: "Payment Initiated",
    BOOKING_CONFIRMATION: "booking confirmation",
    CANCELLATION: "Cancellation",
    FULL_PAYMENT_COUPON: "FP Coupon"
};
