import Types from "./types";

const setExperiment = (experimentType, experimentData, flattened) => ({
    type: Types.SET_EXPERIMENT,
    experimentType,
    experimentData,
    flattened
});

const setGaIdExperiment = (data) => ({
    type: Types.SET_GAID_EXPERIMENT,
    data
});

const setStatsigInitialization = ({ statsigInitialized }) => ({
    type: Types.SET_STATSIG_INITIALIZATION,
    statsigInitialized
});
const allClearExperiment = () => ({
    type: Types.ALL_CLEAR_EXPERIMENT
});

export {
    setExperiment,
    setGaIdExperiment,
    setStatsigInitialization,
    allClearExperiment
};
