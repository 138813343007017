import React from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Component
const CarLoanEmiCalculator = loadable(() => import("../car-loan-emi-calculator"), { ssr: true });
const CarLoanWhyGetLoan = loadable(() => import("../car-loan-why-get-loan"), { ssr: true });
const CarLoanDocumentRequired = loadable(() => import("../car-loan-document-required"), { ssr: true });
const CarLoanProcess = loadable(() => import("../car-loan-process"), { ssr: true });
const CarLoanZeroDownpayment = loadable(() => import("../car-loan-zero-downpayment"), { ssr: true });
const CarLoanHappyCustomer = loadable(() => import("../car-loan-happy-customer"), { ssr: true });
const FaqSection = loadable(() => import("../faq-section"), { ssr: true });

// Constant
import { CAR_LOAN_FAQS } from "./constant";
import { NUMBER } from "../../../constants/app-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const CarLoanHome = ({
    selectedCityName = ""
}) => {

    const onViewAllCallback = () => {
        trackDesktopCustomEventsAE("loanPageFinanceFaq", { eventLabel: "viewall" });
    };

    const onFaqExpandCallback = (_, index) => {
        trackDesktopCustomEventsAE("loanPageFinanceFaq", { eventLabel: `faq${index + NUMBER.ONE}` });
    };

    return (
        <div styleName={"styles.carLoanHomeWrapper"}>
            <CarLoanWhyGetLoan />
            <CarLoanEmiCalculator />
            <CarLoanProcess />
            <CarLoanZeroDownpayment />
            <CarLoanDocumentRequired />
            <CarLoanHappyCustomer />
            <div styleName={"styles.faqSectionCtn"}>
                <FaqSection
                    selectedCityName={selectedCityName}
                    faqs={CAR_LOAN_FAQS}
                    onViewAllCallback={onViewAllCallback}
                    onFaqExpandCallback={onFaqExpandCallback}
                />
            </div>
        </div>
    );
};

CarLoanHome.propTypes = {
    selectedCityName: PropTypes.string
};

export default CarLoanHome;
