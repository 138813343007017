import React from "react";
import PropTypes from "prop-types";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const LazyComponent = (props) => (
    <React.Fragment>
        <LazyLoadComponent {...props} />
    </React.Fragment>
);

LazyComponent.propTypes = {
    src: PropTypes.string
};

export default LazyComponent;
