import React from "react";
import RotatationIcon from "../../shared/images/icons/360-view.svg";

const IconRotate = () => {
    return (
        <img src={RotatationIcon} alt="Car in 360 View"/>
    );
};

export default IconRotate;
