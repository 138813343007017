import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout, setLoginModal } from "../../ae.configuration/actions";

export default function withLogin(Component) {
    const AuthenticatedComponent = (props) => {
        const {
            isLoggedIn,
            setLoginModalConnect
        } = props;

        const [showLogin, setShowLogin] = useState(false);

        const handleLogin = (isOpen) => {
            setShowLogin(isOpen);
            setLoginModalConnect(isOpen);
        };

        return (
            <Component
                {...props}
                isLoggedIn={isLoggedIn}
                showLogin={showLogin}
                setShowLogin={handleLogin}
            />
        );
    };

    AuthenticatedComponent.propTypes = {
        isLoggedIn: PropTypes.bool,
        setLoginModalConnect: PropTypes.func
    };

    const mapStateToProps = ({ user: { isLoggedIn, secureToken, name, isFetched } }) => {
        return {
            isFetched,
            isLoggedIn,
            secureToken,
            name
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        logoutConnect: logout,
        setLoginModalConnect: setLoginModal
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(
        AuthenticatedComponent
    );
}
