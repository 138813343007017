import { createReducer } from "../../../utils/redux/create-reducer";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import Types from "./types";

export const INITIAL_STATE = {
    iconProperties: {
        [ROUTE_NAME.DETAIL_PAGE]: {
            bottom: 98,
            variation: "collapsible"
        }
    }
};

export const setIconProperty = (state = INITIAL_STATE, { page, properties }) => {
    return {
        ...state,
        iconProperties: {
            ...state.iconProperties,
            [page]: {
                ...(state.iconProperties[page] || {}),
                ...properties
            }
        }
    };
};

export const HANDLERS = {
    [Types.SET_ICON_PROPERTY]: setIconProperty
};

export default createReducer(INITIAL_STATE, HANDLERS);
