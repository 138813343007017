/* eslint-disable no-sequences */

// Constants
import { NUMBER, AB_TEST_VARIATIONS } from "../../../constants/app-constants";
import { FREE_AMOUNT_TAG, DEFAULT_PROCESSING_FEE, NUMBER_TO_PERCENT_FACTOR, SHIPPING_CHARGE_DESCRIPTIONS, SHIPPING_CHARGE_KEY, RC_TRANSFER_CHARGE_DESCRIPTIONS, RC_CHARGE_KEY } from "./constant";

// Utilities
import { emiCalculatorAE } from "../../../utils/helpers/emi-calculator";
import { getBookingDateAndTime, getDeliveryDate } from "../../../utils/helpers/get-day-from-date";
import priceFormatter from "../../../utils/helpers/price-formatter";
import { BOOKING_TYPES, CHECKOUT_STEP_TAB_DESKTOP } from "../../../constants/ae/checkout-constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

export default (userVariant) => {
    return CHECKOUT_STEP_TAB_DESKTOP[userVariant === "B" ? AB_TEST_VARIATIONS.VARIANT : AB_TEST_VARIATIONS.CONTROL];
};

export const getLoanAmountWithCharges = (totalCharges, vehiclePrice, downPayment) => {
    return Math.round((+vehiclePrice - +downPayment) + +totalCharges);
};

export const getMinMaxDownPaymentValue = (vehiclePrice, minDownPaymentPercentValue, maxDownPaymentPercentValue) => {
    const minDownpaymentValue = (+vehiclePrice) * (minDownPaymentPercentValue) * NUMBER_TO_PERCENT_FACTOR;
    const maxDownpaymentValue = (+vehiclePrice) * (maxDownPaymentPercentValue) * NUMBER_TO_PERCENT_FACTOR;
    return {minDownpaymentValue, maxDownpaymentValue};
};

// eslint-disable-next-line max-params
export const getMinMaxEmiValue = (vehiclePrice, minDownPaymentValue, maxDownPaymentValue, totalCharges, roi, tenure) => {
    const minLoanAmount = (+vehiclePrice - +maxDownPaymentValue) + +totalCharges;
    const maxLoanAmount = (+vehiclePrice - +minDownPaymentValue) + +totalCharges;
    const updatedMinValue = emiCalculatorAE(minLoanAmount, roi, tenure);
    const updatedMaxValue = emiCalculatorAE(maxLoanAmount, roi, tenure);
    return {minEmiValue: updatedMinValue, maxEmiValue: updatedMaxValue};
};

export const getDownPaymentValueFromLoan = (vehiclePrice, totalCharges, loanAmount) => {
    const data = (+vehiclePrice - (+loanAmount + +totalCharges));
    return data;
};

export const getTotalCharges = (list) => {
    if (!list) {
        return 0;
    }
    return list.reduce((acc, curr) => {
        if (curr.amount) {
            return acc + Math.round(+curr.amount);
        }
        return 0;
    }, 0);
};

export const getTotalChargesV2 = (list) => {
    if (!list) {
        return 0;
    }
    return list.reduce((acc, curr) => {
        if (curr.value) {
            return acc + Math.round(+curr.value);
        }
        return 0;
    }, 0);
};

export const getAddDeliveryModeCharges = (list) => {
    if (!list) {
        return 0;
    }
    return list.reduce((acc, curr) => {
        if (curr && curr.operation) {
            return acc + Math.round(+curr.operation);
        }
        return 0;
    }, 0);
};

export const getAddDeliveryModeChargesV2 = (list) => {
    if (!list) {
        return 0;
    }
    return list.reduce((acc, curr) => {
        if (curr && curr.amount) {
            return acc + Math.round(+curr.amount);
        }
        return 0;
    }, 0);
};

export const parseDate = dateString => {
    const b = dateString.split(/\D+/);
    const offsetMult = dateString.indexOf("+") !== -1 ? -1 : 1;
    const hrOffset = offsetMult * (+b[NUMBER.SEVEN] || 0);
    const minOffset = offsetMult * (+b[NUMBER.EIGHT] || 0);
    return new Date(Date.UTC(+b[0], +b[1] - 1, +b[NUMBER.TWO], +b[NUMBER.THREE] + hrOffset, +b[NUMBER.FOUR] + minOffset, +b[NUMBER.FIVE], +b[NUMBER.SIX] || 0));
};

export const timeUnitsBetween = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let delta = Math.abs(endDate - startDate) / NUMBER.THOUSAND;
    const isNegative = startDate > endDate ? -1 : 1;
    return [
        ["days", NUMBER.TWENTY_FOUR * NUMBER.SIXTY * NUMBER.SIXTY],
        ["hours", NUMBER.SIXTY * NUMBER.SIXTY],
        ["minutes", NUMBER.SIXTY],
        ["seconds", 1]
    // eslint-disable-next-line no-return-assign
    ].reduce((acc, [key, value]) => (acc[key] = Math.floor(delta / value) * isNegative, delta -= acc[key] * isNegative * value, acc), {});
};

export const readFile = (file) => {
    return new Promise((resolve) => {
        const fr = new window.FileReader();
        fr.onload = () => {
            resolve(fr.result);
        };
        fr.readAsArrayBuffer(file);
    });
};

export const  getAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const roundToNearestDecimalPlace = (val) => {
    return Math.round(val);
};

export const finalAmountPayable = ({vehiclePrice, totalCharges, bookingAmount}) => {
    return Math.round(+vehiclePrice + +totalCharges + +bookingAmount);
};

export const calculateAge = (birthday) => { // birthday is a date
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - NUMBER.NINETEEN_SEVENTY);
};

export const getConfirmBookingDate = (isoDate) => {
    const date = new Date(isoDate);
    const datePart = getBookingDateAndTime(date);
    return `${datePart}`;
};

export const getDeliveryModeDate = (isoDate) => {
    const date = new Date(isoDate);
    const datePart = getDeliveryDate(date);
    return `${datePart}`;
};

export const calculateProcessingFee = (vehiclePrice, downPayment, processingFee) => {
    const price = +vehiclePrice - +downPayment;
    const fee = processingFee * price;
    return Math.round(fee);
};

export const getDisplayForNumeric = (amount) => {
    if (amount < 1) {
        return FREE_AMOUNT_TAG;
    }
    return priceFormatter(roundToNearestDecimalPlace(amount));
};

export const getChargeDescription = (item) => {
    const itemKey = item.key || item.chargeKey;
    if (itemKey === SHIPPING_CHARGE_KEY) {
        return SHIPPING_CHARGE_DESCRIPTIONS;
    } else if (itemKey === RC_CHARGE_KEY) {
        return RC_TRANSFER_CHARGE_DESCRIPTIONS;
    }
    return item.description || item.chargeTitle;
};

export const getUpdatedProcessingFeeInChargeList = (processingFee, chargeList) => {
    const item = chargeList.find(x => x.label === "Processing Fee");
    const otherListItem = chargeList.filter(x => x.label !== "Processing Fee");
    const updatedItem = {...item, value: processingFee};
    const data =  [
        updatedItem,
        ...otherListItem
    ];
    return data;
};

export const calcualateDownPayment = (vehiclePrice, loanAmount) => {
    return +vehiclePrice - +loanAmount;
};

export const getProcesingFeeValueFromList = (chargeList) => {
    const item = (chargeList || []).find(x => x.label === "Processing Fee");
    return item ? item.value : DEFAULT_PROCESSING_FEE;
};

export const chargeListMapper = (chargeList) => {
    return (chargeList || []).map(i => {
        return {
            name: i.label,
            amount: i.value,
            description: i.label
        };
    });
};

export const chargeMapper = (chargeDetail) => {
    return (chargeDetail || []).map(i => {
        return {
            label: i.name,
            value: i.amount
        };
    });
};

export const getBookNowEventLabel = (bookingType) => {
    if (bookingType === BOOKING_TYPES.MRL) {
        return "book_now_mrl_td";
    } else if (bookingType === BOOKING_TYPES.VIRTUAL_TD) {
        return "book_now_videoTD";
    } else if (bookingType === BOOKING_TYPES.NEX) {
        return "book_now_home_td";
    }
    return "default";
};

export const getEventLabelForCheckoutFlow = (bookingType) => {
    if (bookingType === BOOKING_TYPES.MRL) {
        return "Hub";
    } else if (bookingType === BOOKING_TYPES.VIRTUAL_TD) {
        return "Video";
    } else if (bookingType === BOOKING_TYPES.NEX) {
        return "Home";
    }
    return "default";
};

export const trackDesktopCheckoutEvents  = (eventName, bookingType, extras = {}) => {
    if (!eventName) {
        return;
    }
    trackDesktopCustomEventsAE(eventName, { eventLabel: getEventLabelForCheckoutFlow(bookingType), ...(extras || {}) });
};
