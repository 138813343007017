import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { IDENTIFIERS, NUMBER, redirect } from "../../../constants/app-constants";
import { SELLER_DESKTOP_EVENTS } from "../../../tracking/desktop/ae.desktop-seller-events";
import { LOCATION_FAQ_CATEGORY } from "../../../constants/ae/sell-car-online";
import ArrowIcon from "./images/right-arrow.svg";
import { trackDesktopSellerCustomerEvents } from "../../../tracking/desktop/tracking";

const SellerFAQLanding = ({
    history,
    sellerFaq = []
}) => {
    const [activeFaq, setActiveFaq] = useState(0);
    let faqSchema = [];

    if (sellerFaq?.length) {
        let faqsForSchema = [];
        (sellerFaq || []).forEach(({ faqList }) => {
            faqsForSchema = [...faqsForSchema, ...faqList];
        });
        faqsForSchema.sort((a) => {
            if (a.categoryTitle === LOCATION_FAQ_CATEGORY) {
                return +`-${NUMBER.ONE}`;
            } return NUMBER.ZERO;

        });
        faqSchema = faqsForSchema;
    }

    const onClickFaq = (index, question) => {
        trackDesktopSellerCustomerEvents(SELLER_DESKTOP_EVENTS.sellFAQsClick, { eventLabel: question });
        setActiveFaq(index);
    };

    const onClickViewMore = (e) => {
        e.preventDefault();
        trackDesktopSellerCustomerEvents(SELLER_DESKTOP_EVENTS.sellFAQsViewMore);
        history.push(`/faq/?type=${IDENTIFIERS.SELL}`);
    };
    return (
        faqSchema?.length > 0 && (
            <div styleName={"styles.wrapper"}>
                <div className="container">
                    <h2>Frequently asked questions</h2>
                    <div styleName={"styles.faqSection"}>
                        <div styleName={"styles.faqLeft"}>
                            <ul>
                                {(faqSchema || [])?.slice(NUMBER.ZERO, NUMBER.FIVE).map(({ question }, index) => (
                                    <li key={`faq-${index}`}
                                        onClick={() => onClickFaq(index, question)}
                                        styleName={activeFaq === index ? "styles.active" : ""}>
                                        <span>{question}</span>
                                        {activeFaq === index && <img height={24} width={24} src={ArrowIcon} alt="Right arrow" />}
                                    </li>
                                ))}
                                <div styleName={"styles.viewMore"}>
                                    <a href={redirect.FAQ_AE} onClick={onClickViewMore}>View More</a>
                                </div>
                            </ul>
                        </div>
                        <div styleName={"styles.faqRight"}>
                            <div styleName={"styles.answerSection"} dangerouslySetInnerHTML={{ __html: faqSchema[activeFaq].answer }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

SellerFAQLanding.propTypes = {
    city: PropTypes.string,
    history: PropTypes.object,

    sellerFaq: PropTypes.array
};

export default withRouter(SellerFAQLanding);
