
import { COOKIE_CONSTANTS, LOCALSTORAGE_CONSTANTS, NUMBER } from "../../constants/app-constants";
// import { Buffer } from "buffer";
import { clearItem, getItem, setItem } from "./storage-crud";
import saveCookie from "./save-cookie";

const RESUME_SESSION_INITIAL_STATE = {
    searchQuery: "",
    selectedFilters: [],
    latestFilterUrl: ""
};

// export const encodeToBase64 = (str = "") => {
//     return Buffer?.from(str)?.toString("base64")?.replace(/\=/g, "*") || "";
// };

// export const decodeToBase64 = (str = "") => {
//     const rawString = str?.replace(/\*/g, "=");
//     return Buffer?.from(rawString, "base64")?.toString() || "";
// };

const saveFilterResumeSessionCookieFlag = (filterSessionData = {}) => {
    const { selectedFilters = [], searchQuery = "" } = filterSessionData || {};
    const totalFiltersCount = (selectedFilters?.length + (searchQuery?.length ? NUMBER.ONE : NUMBER.ZERO)) || NUMBER.ZERO;
    const isShowFilterResumeSessionCookieFlag = totalFiltersCount > NUMBER.ZERO;
    saveCookie(COOKIE_CONSTANTS.SHOW_RESUME_FILTER_SESSION, JSON.stringify(isShowFilterResumeSessionCookieFlag), NUMBER.THREE_HUNDRED_SIXTY_FIVE);
};

export const getFilterUrlForResumeSession = (url = "") => {
    if (url?.slice(-1) === "/") {
        url = url?.substring(0, url?.length - 1);
    }
    if (url?.includes("?")) {
        return `${url}&isFilterResumeSession=true`;
    } else {
        return `${url}?isFilterResumeSession=true`;
    }
};

export const getFilterResumeSession = () => {
    try {
        const filterSessionData = getItem(LOCALSTORAGE_CONSTANTS.RESUME_FILTER_SESSION);
        const updatedFilterSessionData = {
            ...filterSessionData,
            latestFilterUrl: filterSessionData?.latestFilterUrl
        };
        return updatedFilterSessionData;
    } catch (e) {
        clearItem(LOCALSTORAGE_CONSTANTS.RESUME_FILTER_SESSION);
        return RESUME_SESSION_INITIAL_STATE;
    }
};

export const addToFilterResumeSession = (selectedFilters = [], searchQuery = "", latestFilterUrl = "") => {
    try {
        const filterSessionData = {
            selectedFilters: selectedFilters || [],
            searchQuery: searchQuery || "",
            latestFilterUrl: latestFilterUrl || ""
        };
        setItem(filterSessionData, LOCALSTORAGE_CONSTANTS.RESUME_FILTER_SESSION);
        saveFilterResumeSessionCookieFlag(filterSessionData);
    } catch (e) {
        setItem(RESUME_SESSION_INITIAL_STATE, LOCALSTORAGE_CONSTANTS.RESUME_FILTER_SESSION);
    }
};
