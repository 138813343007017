import React from "react";
import PropTypes from "prop-types";
import styles from "./style.css";
import Arrow from  "./images/arrow.svg";
import ArrowOrange from  "./images/arrow-orange.svg";

const SlideImage = ({
    type,
    onClick = () => {},
    disable = false,
    arrowColor
}) => {
    const getImageSrc = () => {
        if (arrowColor === "orange") {
            return ArrowOrange;
        } else {
            return Arrow;
        }
    };

    return (
        <button styleName={`${type} styles.navBtn ${disable ? "styles.disable" : ""}`} className={`${type} ${disable ? "disable" : ""}`} onClick={disable ? () => {} : onClick}>
            <img src={getImageSrc()} alt={type} />
        </button>
    );
};

SlideImage.propTypes = {
    type: PropTypes.string,
    disable: PropTypes.bool,
    onClick: PropTypes.func,
    arrowColor: PropTypes.string
};

export default SlideImage;
