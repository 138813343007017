/* eslint-disable max-statements */
import { API_PATH_ALTERNATE } from "../constants/api-constants";

const {
    SEO_CONTENT,
    FOOTER_LINKS,
    CUSTOMER_REVIEW_LIST
} = API_PATH_ALTERNATE;

// isMigrated - This flag refers to the endpoints for which apis have been migrated to newer Java services.

export default (api) => {
    //params: { cmsuri, expand }
    const getCmsPageData = (params) => {
        return api.get(SEO_CONTENT, { params, parse: true });
    };

    const fetchFooterLinks = () => {
        return api.get(FOOTER_LINKS);
    };

    const fetchSEOReviews = (params) => {
        return api.get(`${CUSTOMER_REVIEW_LIST}`, { params, parse: true });
    };

    const fetchGeoSearchLoactionDetails = (key) => {
        return api.get(`/search-location?key=${key}`);
    };

    return {
        getCmsPageData,
        fetchFooterLinks,
        fetchSEOReviews,
        fetchGeoSearchLoactionDetails
    };
};
