import React from "react";
import PropTypes from "prop-types";
import FlexColumnLayout from "../../shared/flex-column-layout/component";
import styles from "./styles.css";
import priceFormatter from "../../../utils/helpers/price-formatter";
import FilterLabel from "../filter-label";
import { CURRENCY } from "../../../constants/app-constants";

const RangeFilterDisplay = ({
    filterLabel = {},
    minValue,
    maxValue,
    prefix,
    suffix,
    type,
    currency = CURRENCY.AE,
    showColumnLayout = true
}) => {
    if (type === "PRICE") {
        minValue = priceFormatter(minValue, true, currency);
        maxValue = priceFormatter(maxValue, true, currency);
    }

    const getDisplayBox = (value) => {
        return (
            <div styleName={"styles.priceBox"}>
                {
                    prefix && <span styleName={"styles.prefixText"}>{prefix}</span>
                }
                <span styleName={"styles.price"}>{value}</span>
                {
                    suffix && <span styleName={"styles.suffixText"}>{suffix}</span>
                }
            </div>
        );
    };
    return (
        <React.Fragment>
            <div className="newRangeFilter" styleName={"styles.topFilterValue"}>
                <span styleName={"styles.priceLabel"}><small>{prefix}</small> {minValue} {suffix} </span>
                <span className={"text-right"} styleName={"styles.priceLabel"}><small>{prefix}</small> {maxValue} {suffix}</span>
            </div>
           {filterLabel.min && <FilterLabel
                minText={filterLabel.min}
                maxText={filterLabel.max}
            />}
            {showColumnLayout && <FlexColumnLayout>
                {getDisplayBox(minValue)}
                <div styleName={"styles.line"} />
                {getDisplayBox(maxValue)}
            </FlexColumnLayout>}
        </React.Fragment>
    );
};

RangeFilterDisplay.propTypes = {
    filterLabel: PropTypes.object,
    minValue: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    maxValue: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    type: PropTypes.string,
    currency: PropTypes.string,
    showColumnLayout: PropTypes.bool
};

export default RangeFilterDisplay;
