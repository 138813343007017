/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Constants
import { CURRENCY, NUMBER } from "../../../constants/app-constants";

// Component
import SliderVerticalV2 from "../../shared/slider-vertical-v2";
import RangeFilterDisplayRevamp from "./range-filter-display-revamp";
import Slider from "../../shared/slider";
import FilterLabel from "../filter-label";
import RangeFilterDisplay from "../range-filter-display";

// Utils
import priceFormatter from "../../../utils/helpers/price-formatter";
import debounce from "../../../utils/helpers/debounce";

const RangeFilterV2 = ({
    filterLabel = {},
    minPrefix,
    maxPrefix,
    minValue,
    maxValue,
    minRange,
    maxRange,
    step,
    onOptionSelection,
    clearSuggestionsConnect,
    priceFormatting = true,
    currency = CURRENCY.AE,
    vertical,
    tickCount = NUMBER.TWELVE,
    showColumnLayout = true,
    rangeValues = [],
    rangeType = "",
    showLabelTop = false,
    onSliderMove = () => { }
}) => {

    let updatedMaxValue = maxValue;
    if (maxValue > maxRange) {
        updatedMaxValue = maxRange;
    }
    const [currentValues, setCurrentValues] = useState({
        min: minValue,
        max: updatedMaxValue
    });

    const sliderProps = {
        domain: [minRange, maxRange],
        defaultValues: [currentValues.min, currentValues.max],
        step,
        mode: 2,
        tickCount,
        railColor: "#F0F1F5",
        disabledTrackStyles: {
            backgroundImage: "linear-gradient(269deg, #ed6a00, #f69b00)"
        },
        animatedHandles: [],
        handleColor: "#F0F1F5",
        trackProps: { left: false, right: false },
        onChange: debounce((values) => {
            onOptionSelection({
                min: values[vertical ? 1 : 0],
                max: values[vertical ? 0 : 1]
            });
        }, NUMBER.FIVE_HUNDRED),
        onUpdate: (values) => {
            setCurrentValues({
                min: values[vertical ? 1 : 0],
                max: values[vertical ? 0 : 1]
            });
        },
        onSlideStart: () => {
            clearSuggestionsConnect();
            onSliderMove(true);
        },
        onSlideEnd: () => {
            clearSuggestionsConnect();
            onSliderMove(false);
        },
        vertical,
        prefix: minPrefix,
        rangeValues,
        rangeType
    };

    useEffect(() => {
        setCurrentValues({ min: minValue, max: updatedMaxValue });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedMaxValue, minValue]);

    let displayMinValue = currentValues.min;
    let displayMaxValue = currentValues.max;

    if (rangeValues?.length) {
        displayMaxValue = rangeValues[currentValues.max];
        displayMinValue = rangeValues[currentValues.min];
    }

    return (
        <React.Fragment>
            <div className="horizontalRangeFilter" styleName={`${vertical ? "styles.rangeFilterVertical" : ""}`}>
                {showLabelTop && <RangeFilterDisplay
                    minValue={priceFormatting ? priceFormatter(displayMinValue, true, currency) : displayMinValue}
                    maxValue={priceFormatting ? priceFormatter(displayMaxValue, true, currency) : displayMaxValue}
                    minPrefix={minPrefix}
                    prefix={minPrefix}
                    maxPrefix={maxPrefix}
                    showColumnLayout={showColumnLayout}
                />}
                <div className="newRangeSlider" styleName={`${vertical ? "styles.rangeSliderVertical" : "styles.rangeSlider"}`}>
                    {minRange !== maxRange && !vertical && <Slider {...sliderProps} />}
                    {minRange !== maxRange && vertical && <SliderVerticalV2 {...sliderProps} />}
                </div>
                {!vertical && <div className="newRangeFilterMinMaxLabel">
                    <FilterLabel
                        minText={filterLabel.min}
                        maxText={filterLabel.max}
                    />
                    {!showLabelTop &&  <RangeFilterDisplay
                        minValue={priceFormatting ? priceFormatter(displayMinValue, true, currency) : displayMinValue}
                        maxValue={priceFormatting ? priceFormatter(displayMaxValue, true, currency) : displayMaxValue}
                        minPrefix={minPrefix}
                        prefix={minPrefix}
                        maxPrefix={maxPrefix}
                        showColumnLayout={showColumnLayout}
                    />}
                </div>
                }
                {vertical && <div>
                    <RangeFilterDisplayRevamp
                        minText={filterLabel.min}
                        maxText={filterLabel.max}
                    />
                </div>}
            </div>
        </React.Fragment>
    );
};

RangeFilterV2.propTypes = {
    filterLabel: PropTypes.object,
    minPrefix: PropTypes.string,
    maxPrefix: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    step: PropTypes.number,
    onOptionSelection: PropTypes.func,
    minRange: PropTypes.number,
    maxRange: PropTypes.number,
    clearSuggestionsConnect: PropTypes.func,
    priceFormatting: PropTypes.bool,
    currency: PropTypes.string,
    vertical: PropTypes.bool,
    tickCount: PropTypes.number,
    rangeValues: PropTypes.array,
    rangeType: PropTypes.string,
    onSliderMove: PropTypes.func,
    showColumnLayout: PropTypes.bool,
    showLabelTop: PropTypes.bool
};

export default RangeFilterV2;
