
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    all: [],
    popular: [],
    error: null
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data: { all, popular } }) => {
    return { ...state, all, popular, error: null };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, all: [], popular: [] };
};

export const HANDLERS = {
    [Types.FETCH_LEAD_MODELS_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_LEAD_MODELS_FAILURE]: fetchDataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
