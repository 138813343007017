export const getClosestNumberInArray = (arr = [], val, isLargeValue = false) => {
    let value;
    if (isLargeValue) {
        value = arr.filter((f) => f >= val).reduce((prev, curr) => {
            return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
        });
    } else {
        value = arr.filter((f) => f <= val).reduce((prev, curr) => {
            return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
        });
    }
    return value;
};
