import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import VideoPlayer from "../../shared/video-player";

const VideoModal = ({
    url,
    onClose,
    wrapperClass = ""
}) => {
    return (
        <Modal isOpen={true} close={onClose} overlayClass="overlayClass sellerVideoModal">
            <div styleName={`styles.videoPlayer ${wrapperClass ? `${"styles."}${wrapperClass}` : ""}`}>
                <VideoPlayer
                    url={url}
                    width="80%"
                    height="80%"
                    controls={true}
                    playing={true}
                    volume={0.75}
                />
            </div>
        </Modal>
    );
};

VideoModal.propTypes = {
    url: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    wrapperClass: PropTypes.string
};

export default VideoModal;
