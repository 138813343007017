import React from "react";
import PropTypes from "prop-types";
import CarsLogo from "./images/cars24-logo.svg";
import CarsLogoBlue from "./images/cars24-logo-blue.svg";

const Logo = ({ width, height, isHomePage = ""  }) => <img style={{width, height}} src={isHomePage ? CarsLogo : CarsLogoBlue} alt="logo" />;

Logo.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isHomePage: PropTypes.bool
};

export default Logo;
