/* eslint-disable max-statements */
import React, { useState } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Component
import InputSearch from "../../shared/input-search/component";

// Images
import LocationIcon from "./images/location.svg";
import IconSearch from "./images/search-icon.svg";
import IconGooglePowered from "./images/powered-by-google.svg";
import IconEdit from "./images/edit.svg";

// Utils
import AutoComplete from "../../../utils/auto-complete";
import { googlePlacesOptions } from "../../../utils/auto-complete/search-options";
import { formatAddressObject } from "../../../utils/helpers/format-address-object";

// Constants
import { LOCATION_REQUEST_TYPE, MAP_TYPE, NUMBER } from "../../../constants/app-constants";
import { TD_EMIRATES_MAPPING } from "../../../constants/ae/td-schedule-constants";

// Hooks
import useOutsideClick from "../../../hooks/use-outside-click";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const TdScheduleLocation = ({
    locationDetails = {},
    setSelectedLocationConnect,
    resetSelectedLocationConnect,
    resetSelectedSlotConnect,
    resetAddressConnect
}) => {

    const { mainText = "" } = locationDetails || {};
    const [error, setError] = useState(false);
    const [searchVal, setSearchVal] = useState(mainText);
    const [placeList, setPlaceList] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const dropDownRef = useOutsideClick(() => {
        if (showDropdown) setShowDropdown(false);
    });

    const handleShowDropdown = () => {
        setShowDropdown(true);
    };

    const onSelectAddress = (location) => {
        setSearchVal(location?.mainText || "");
        if (location.state === TD_EMIRATES_MAPPING.Dubai ||
            location.state === TD_EMIRATES_MAPPING.Sharjah ||
            location.state === TD_EMIRATES_MAPPING.AbuDhabi ||
            location.state === TD_EMIRATES_MAPPING.Ajman) {
            setError(false);
            setSelectedLocationConnect({ ...location, error: false });
        } else {
            setError(true);
            setSelectedLocationConnect({ ...location, error: true });
        }
        setShowDropdown(false);
    };

    const locationSelectionCallback = (data) => {
        const addArr = data.address.full.split(", ");
        const selectedAdd = {
            addressLabel: data.address.addressLabel,
            addressLine2: addArr?.[NUMBER.ONE],
            pincode: data.address.postCode,
            city: data.address.city,
            state: data.address.state,
            placeId: data.placeId,
            mainText: data.mainText,
            ...data.coordinates
        };
        onSelectAddress(selectedAdd);
    };

    const getValuesFromGeocode = (componentKey) => (addressComponents) => {
        let value;
        const isArray = Array.isArray(componentKey);
        addressComponents.forEach((addressComponent) => {
            if (isArray) {
                if (!value) {
                    value = {};
                }
                componentKey.map((component) => {
                    if (addressComponent.types.includes(component)) {
                        value[component] = addressComponent.long_name;
                    }
                });
            } else if (addressComponent.types.includes(componentKey)) {
                value = addressComponent.long_name;
            }
        });
        return value;
    };

    const getReverseGeoCode = async (lat, lng) => {
        const response = (
            await AutoComplete.getInstance(MAP_TYPE.GOOGLE).getGeoCode({
                location: { lat, lng }
            })
        )[NUMBER.ZERO];
        return response;
    };

    const getPostalCodeByGeocode = getValuesFromGeocode("postal_code");
    const getLocalityByGeocode = getValuesFromGeocode("sublocality_level_1");
    const getCityByGeocode = getValuesFromGeocode("administrative_area_level_2");
    const getStateByGeocode = getValuesFromGeocode("administrative_area_level_1");
    const getRouteByGeocode = getValuesFromGeocode("route");

    const getOptionsCallback = (input) => {
        googlePlacesOptions(input, MAP_TYPE.GOOGLE)
            .then((resp) => {
                setPlaceList(resp);
            })
            .catch(() => { });
    };

    const resetSlotAndAddress = () => {
        resetSelectedLocationConnect();
        resetSelectedSlotConnect();
        resetAddressConnect();
    };

    const handleAddressChange = (e) => {
        const userInput = e?.target?.value || "";
        if (userInput === "") {
            setSearchVal("");
            setPlaceList([]);
        } else {
            setSearchVal(userInput);
            getOptionsCallback(userInput);
            setShowDropdown(true);
        }
        resetSlotAndAddress();
    };

    const onSelectOwnLocation = () => {
        if (window.navigator.geolocation) {
            // Geolocation available
            window.navigator.geolocation.getCurrentPosition(
                async (coordinates) => {
                    try {
                        const response = await getReverseGeoCode(
                            coordinates.coords.latitude,
                            coordinates.coords.longitude
                        );
                        const { address_components: addressComponent, formatted_address: addressLabel } = response;
                        const selectedAdd = {
                            addressLabel,
                            addressLine2:
                                getLocalityByGeocode(addressComponent) ||
                                getRouteByGeocode(addressComponent),
                            pincode: getPostalCodeByGeocode(addressComponent),
                            city: getCityByGeocode(addressComponent),
                            state: getStateByGeocode(addressComponent),
                            latitude: coordinates.coords.latitude,
                            longitude: coordinates.coords.longitude,
                            placeId: response.place_id,
                            mainText: response.formatted_address
                        };
                        onSelectAddress(selectedAdd);
                        // eslint-disable-next-line no-empty
                    } catch (err) { }
                    // eslint-disable-next-line no-unused-vars
                },
                // eslint-disable-next-line no-unused-vars
                (err) => { }
            );
        }
    };

    const onLocationSelection = async (place) => {
        const { label, value } = place;
        const response = await AutoComplete.getInstance(MAP_TYPE.GOOGLE).getGeoCode(
            {
                placeId: value
            },
            LOCATION_REQUEST_TYPE.PLACE
        );
        const coords = response?.[NUMBER.ZERO]?.geometry?.location;
        let formattedAddressObj = formatAddressObject(response?.[NUMBER.ZERO], MAP_TYPE.GOOGLE);
        formattedAddressObj = { ...formattedAddressObj, addressLabel: label };
        locationSelectionCallback({
            address: formattedAddressObj,
            coordinates: { latitude: coords.lat(), longitude: coords.lng() },
            placeId: response?.[NUMBER.ZERO]?.place_id,
            mainText: place.formattedPlace.main_text
        });
        trackDesktopCustomEventsAE("checkoutLocationSelected");
    };

    const getRemainingAddress = (addressLabel) => {
        const newAddress = addressLabel.split(", ");
        newAddress.shift();
        return newAddress.join(", ");
    };

    return (
        <React.Fragment>
        <div styleName={"styles.searchModalWrap"}>
            <div styleName={"styles.headSearch"}>
                <span styleName={"styles.saerchIcon"}><img src={IconSearch} alt="Search Icon" /></span>
                <div styleName={"styles.searchBox"}>
                    <InputSearch
                        placeholder="Search for location..."
                        value={searchVal}
                        onChange={handleAddressChange}
                        onFocus={handleShowDropdown}
                    />
                </div>
                {mainText ?
                    <div styleName={"styles.changeCta"} onClick={handleShowDropdown}>
                        <img src={IconEdit} alt="Change Icon" /> Change
                    </div> :
                    <div styleName="styles.currentLocationWrap" onClick={onSelectOwnLocation}>
                        <img src={LocationIcon} alt="Current Location Icon" />
                        <p styleName="styles.currentLocation">Use current location</p>
                    </div>
                }
            </div>
            <div styleName={"styles.noteGrid"}>
                {error ?
                    <p styleName={"styles.errorGrid"}><strong>Error:</strong>Please provide a valid location. We only provide test drive within Dubai, Abu Dhabi, Shariah and Ajman.</p> :
                    <p><strong>Note:</strong>Test drive allowed in Dubai, Abu Dhabi, Sharjah & Ajman</p>
                }
                {!mainText && (
                    <div styleName={"styles.poweredGoogle"}>
                        <img src={IconGooglePowered} alt="Powered By Google Icon" />
                    </div>
                )}
            </div>
        </div>
        {(showDropdown && placeList?.length > NUMBER.ZERO) && (
                <div styleName={"styles.modalContainer"} ref={dropDownRef}>
                    {(placeList || [])?.map((place) => (
                        <div styleName={"styles.searchList"} key={place.value} onClick={() => onLocationSelection(place)}>
                            <p>{place.label.split(", ")?.[NUMBER.ZERO]}</p>
                            {getRemainingAddress(place.label) && <p>{getRemainingAddress(place.label)}</p>}
                        </div>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

TdScheduleLocation.propTypes = {
    locationDetails: PropTypes.object,
    setSelectedLocationConnect: PropTypes.func,
    resetSelectedLocationConnect: PropTypes.func,
    resetSelectedSlotConnect: PropTypes.func,
    resetAddressConnect: PropTypes.func
};

export default TdScheduleLocation;
