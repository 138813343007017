import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Skeleton from "./skeleton";

const H4 = (props) => {
    const { text, color, isLoading } = props;
    let heading = (<h4 styleName={"styles.heading"} style={{ color }}>{text}</h4>);
    if (isLoading) {
        heading = <Skeleton />;
    }
    return heading;
};

H4.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    isLoading: PropTypes.bool
};

export default H4;
