import React from "react";
import styles from "./styles.css";
import BuyCarHomeV2 from "../../../components/ae.desktop/home";
import withAELayout from "../../../components/ae.desktop/layout";

const Home = () => {
    return (
        <React.Fragment>
            <div>
                <BuyCarHomeV2 />
            </div>
        </React.Fragment>
    );
};

export default withAELayout(Home);
