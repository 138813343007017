import React, { Fragment } from "react";

// Styles
import styles from "./styles.css";

// Images
import OpenInMobileIllustration from "./images/c2c-open-in-mobile.svg";

// Component
import SocialIcon from "../../shared/icon-social";

// Constant
import { DOWNLOAD_APP_URL, DOWNLOAD_APP_URL_IOS } from "../../../constants/app-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const C2COpenInMobile = () => {
    const onAppStoreClick = () => {
        trackDesktopCustomEventsAE("appStoreClick");
    };

    const onPlayStoreClick = () => {
        trackDesktopCustomEventsAE("playStoreClick");
    };

    return (
        <Fragment>
            <div styleName={"styles.wrapper"}>
                <h2>Please Open Mobile</h2>
                <img src={OpenInMobileIllustration} alt="Please Open In Mobile" />
                <p>
                    <strong>Sorry, this link will not work on desktop</strong>
                    Please open the same link in your mobile, or download our app and<br/>access your booking there.
                </p>
                <div styleName={"styles.AppDownloadWrapper"}>
                    <a onClick={onAppStoreClick} href={DOWNLOAD_APP_URL_IOS}><SocialIcon name="appStore" /></a>
                    <a onClick={onPlayStoreClick} href={DOWNLOAD_APP_URL}><SocialIcon name="googlePlay" /></a>
                </div>
            </div>
        </Fragment>
    );
};

export default C2COpenInMobile;
