export const pageUrl = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "Sell Any Car",
        url: "/sell-used-car/"
    },
    {
        title: "Contact Us",
        url: "/contact/"
    },
    {
        title: "About Us",
        url: "/about-us/"
    },
    {
        title: "Privacy Policy",
        url: "/privacy-policy/"
    },
    {
        title: "Terms of Use",
        url: "/terms-of-use/"
    },
    {
        title: "FAQ",
        url: "/faq/"
    },
    {
        title: "CARS24 India",
        url: ""
    },
    {
        title: "Blog",
        url: "/ae/blog/"
    }
];
