/* eslint-disable no-magic-numbers */
import { COOKIE_SEARCH_KEYWORDS } from "../../constants/app-constants";

export const updateSearchList = (selectedValue) => {
  const { value } = selectedValue;
  if (window.localStorage.getItem(COOKIE_SEARCH_KEYWORDS)) {
    let options = JSON.parse(window.localStorage.getItem(COOKIE_SEARCH_KEYWORDS));
    options.forEach((item, index) => {
      if (item?.value === value) {
        options.splice(index, 1);
      }
    });
    if (options.length === 3) options.pop(); //for fifo
    options = [selectedValue, ...options]; //treating it like queue instead of stack
    window.localStorage.setItem(COOKIE_SEARCH_KEYWORDS, JSON.stringify(options));
  } else {
    window.localStorage.setItem(COOKIE_SEARCH_KEYWORDS, JSON.stringify([selectedValue]));
  }
};

export const getRecentSearches = () => {
  const defaultOptions = [];
  let data = {};
  if (window.localStorage.getItem(COOKIE_SEARCH_KEYWORDS)) {
    data = { label: "Recent Searches" };
    const parsedOptions = JSON.parse(window.localStorage.getItem(COOKIE_SEARCH_KEYWORDS));
    //doing this manipulation to get position for ga events else not required
    data.options = parsedOptions.map((item, position) => {
      return typeof item === "object" ? {
        ...item,
        label: item.value,
        group: "Recent Searches",
        position: position + 1
      } : {
        value: item,
        label: item,
        type: "Make",
        group: "Recent Searches",
        position: position + 1
      };
    });
    defaultOptions.push(data);
  }
  return [{
    label: "Recent Searches",
    options: defaultOptions
  }];
};
