export const API_PATH_ALTERNATE = {
    SEO_CONTENT: "/get-content",
    FOOTER_LINKS: "/get-footer-links",
    CUSTOMER_REVIEW_LIST: "/customer-review/get-reviews"
};

export const COUNTRY_CODE  = {
    INDIA: "IN",
    UAE: "AE"
};

export const BOOKING_PRICE =  {
    AE: 499
};

export const SEND_APP_LINK_EXTERNAL_API = "/b2c-buyer-notification";

export const AUTH_PRODUCT_TYPE = "consumer-app";

export const CHANNEL_TYPES = {
    WHATSAPP: "WHATSAPP",
    DND: "PROMOTIONAL_DND"
};

export const API_SOURCES = {
    WEBAPP: "WebApp",
    MSITE: "mSite",
    APP: "app"
};

export const C2B_PURCHASE_REQUEST = `/v1/purchase-request/c2b`;

export const DAPI_CLIENT_HEADERS = {
    "X_COUNTRY": COUNTRY_CODE?.UAE,
    "X_VEHICLE_TYPE": "CAR"
};

export const DAPI_STATUS = {
    SUCCESS: "done"
};
