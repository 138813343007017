import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Style
import styles from "./styles.css";

// Component
import withCarExchange from "../with-car-exchange";
import Login from "../login-revamp";
import SeasonalSaleListingBanner from "../seasonal-sale-listing-banner";
import CarExchangeBanner from "../car-exchange-banner";

// Constant
import { NUMBER } from "../../../constants/app-constants";
import { BANNER_ID } from "./constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const BannerSection = ({ bannerList = [], handleTradeInClick = () => { }, isActiveSale, isLoggedIn, onSaleLogin = () => { } }) => {
    const history = useHistory();
    const [openLogin, setOpenLogin] = useState(false);

    const handleClick = (bannerAction = {}, id = "") => {
        const { isClickable = false, to = "/" } = bannerAction || {};

        if (id === BANNER_ID.WISHLIST_BANNER) {
            trackDesktopCustomEventsAE("wishlistOpen", { eventLabel: "listing_banner" });
        } else if (id === BANNER_ID.ZERO_DP_BANNER) {
            trackDesktopCustomEventsAE("loanPageZeroDpBanner", { eventLabel: "listing" });
        }

        if (isClickable) history.push(to);
    };

    const toggleLoginPopup = () => {
        setOpenLogin(!openLogin);
    };

    const onSuccessfulLogin = () => {
        setOpenLogin(false);
        onSaleLogin();
    };

    const renderBanner = ({ id, src, action, props }) => {
        const isValidExchangeBanner = id === BANNER_ID.EXCHANGE_BANNER;
        const isSaleBanner = id === BANNER_ID.SALE_DOWNLOAD_BANNER;
        switch (id) {
            case BANNER_ID.SEASONAL_SALE_BANNER:
                return (<Fragment>
                    <SeasonalSaleListingBanner {...props} />
                </Fragment>);
            case BANNER_ID.EXCHANGE_BANNER:
                return (
                    <CarExchangeBanner />
                );
            default:
                return (
                    <div key={id}>
                        <Fragment >
                            <img styleName={action.isClickable ? "styles.clickableBanner" : "styles.bannerImage"} src={src} onClick={isValidExchangeBanner ? handleTradeInClick : handleClick.bind(null, action, id)} />
                            <div styleName={"styles.ctaWrap"}>
                                {isActiveSale && !isLoggedIn && isSaleBanner && <Login isSaleLogin={true} isVisible={openLogin} onClose={setOpenLogin.bind(null, false)} onSuccess={onSuccessfulLogin}><span onClick={toggleLoginPopup}>Login to view discounts</span></Login>}
                            </div>
                        </Fragment>
                    </div>
                );
        }
    };
    return (
        <div styleName={`styles.bannerWrap ${bannerList.length > NUMBER.ONE ? "styles.multiBanner" : ""}`}>
            {bannerList.map(({ src, action, id, props }) =>
                renderBanner({ id, src, action, props })
            )}
        </div>
    );
};

BannerSection.propTypes = {
    handleTradeInClick: PropTypes.func,
    bannerList: PropTypes.array,
    isActiveSale: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    reloadCarList: PropTypes.func,
    onSaleLogin: PropTypes.func
};

export default withCarExchange(BannerSection);
