export const COUNTDOWN_INTERVAL = 1000;

export const DEFAULT_SOURCE_TYPE = {
    key: "email",
    displayText: "Email"
};

export const SOURCE_TYPES = [
    {
        key: "email",
        displayText: "Email"
    },
    {
        key: "phone",
        displayText: "Phone"
    }
];

export const STATUS_TYPE = {
    PENDING: "pending",
    SUCCESS: "success"
};

export const APP_SENT_MESSAGE = {
    phone: {
        success: "Message sent. Check your phone to find the app download link.",
        pending: "We have already sent a link to this phone number. Please try again after sometime"
    },
    email: {
        success: "Email sent. Check your email to find the app download link.",
        pending: "We have already sent a link to this email. Please try again after sometime"
    }
};
