import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarExchangeBanner from "./component";

const mapStateToProps = ({
    config: {
        bannerInfo: {
            exchangeOffer = {}
        } = {}
    }
}) => {
    return {
        exchangeOffer
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarExchangeBanner);
