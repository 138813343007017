import React from "react";
import PropTypes from "prop-types";

//Styles
import styles from "./styles.css";

//Images
import IconCheckCircle from "./images/check-circle.svg";

const OfferConfirmation = ({
    order,
    carDetails
}) => {
    const { content: {parentHubLocation: { locationName } = {} } = {} } = carDetails || {};

    const { latestOffer = {} } = order || {};
    const { offerPrice } = latestOffer || {};

    return (
        <div styleName={"styles.wrapper"}>
            <h2>Offer confirmation</h2>
            <div styleName={"styles.offerStrip"}>
                <img src={IconCheckCircle} width={20} height={20} alt="" />
                <p>{`Your offer of AED ${offerPrice} meets seller expectations`}</p>
            </div>
            { locationName && <div styleName={"styles.locationGrid"}>
                <h3>Test Drive Location</h3>
                <p>{locationName}</p>
            </div>}
            <div styleName={"styles.noteGrid"}>
                <p><strong>Note:</strong></p>
                <p>The exact location will be shared by our team on the day<br />of test drive</p>
            </div>

        </div>
    );
};

OfferConfirmation.propTypes = {
    order: PropTypes.object,
    carDetails: PropTypes.object
};
export default OfferConfirmation;
