import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./slider-rail.css";
const SliderRail = ({ getRailProps, railColor }) => {
    const railOuterStyle = {
        position: "absolute",
        height: "100%",
        width: 5,
        transform: "translate(-50%, 0%)",
        borderRadius: 7,
        cursor: "pointer"
    };

    const railInnerStyle = {
        position: "absolute",
        height: "100%",
        width: 2,
        transform: "translate(-50%, 0%)",
        borderRadius: 7,
        pointerEvents: "none",
        backgroundColor: railColor
    };
    return (
        <Fragment>
            <div style={railOuterStyle} {...getRailProps()} />
            <div style={railInnerStyle} />
        </Fragment>
    );
};

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
    railColor: PropTypes.string
};

export default SliderRail;
