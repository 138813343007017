import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import PostBcTdComplete from "./component";

const mapStateToProps = ({
    carDetails,
    postBcDetails
}) => ({
    carDetails,
    postBcDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostBcTdComplete);
