/* eslint-disable max-params */
/* eslint-disable max-statements */
export default (api) => {

    const fetchTopSellingCars = (params) => {
        return api.get("/top-selling-cars", { params });
    };

    const getGlobalCarsSearch = (params) => {
        return api.get("/global-cars-search", { params });
    };

    const fetchCars = (params) => {
        return api.get("/cars", { params });
    };

    const fetchCarsFromPathName = (url, pincode) => {
        return api.get(`/${url}`, {
            headers: {
                ...(
                    pincode && {
                        pincode
                    }
                )
            }
        });
    };

    // eslint-disable-next-line max-params
    const getPickupLocation = (appointmentId, pincode, token, userSelectedPinCode) => {
        return api.get(`/v1/ci/location/${appointmentId}?pincode=${pincode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                pincode: userSelectedPinCode,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const fetchDynamicFilters = (params) => {
        return api.get(`/filters`, { params });
    };

    const fetchDynamicFilterV3 = (params) => {
        return api.get(`/app-filters`, { params });
    };

    const fetchCarDetail = (carId, pincode, params) => {
        return api.get(`/v3/cars/${carId}`, {
            headers: {
                ...(
                    pincode && {
                        pincode
                    }
                )
            },
            params
        });
    };

    const getSimilarCarsCount = (appointmentId) => {
        return api.get(`v2/similar-cars/count/${appointmentId}`);
    };

    const fetchSimilarCars = (params) => {
        return api.post("/similar-cars", params);
    };

    const fetchSimilarCarsById = (appointmentId, params) => {
        return api.get(`/similar-cars/${appointmentId}`, { params });
    };

    const submitRecentlyViewedCars = (params) => {
        return api.post("/recently-viewed-cars", params);
    };

    const getRecentlyViewedCars = (params) => {
        return api.get("/recently-viewed-cars", { params });
    };

    const sendOTP = (params, queryString) => {
        return api.post(`/get-phone-verified${queryString ? `?${queryString}` : ""}`, params);
    };

    const verifyOtp = (params) => {
        return api.get("/verify-otp", { params });
    };

    const getPriceRange = (params) => {
        return api.get("/get-price-range", { params });
    };

    const submitUserOfferPrice = (params, queryString) => {
        return api.post(`/save-offer-price${queryString ? `?${  queryString}` : ""}`, params);
    };

    const getCityConfig = (cityId) => {
        return api.get(`/v2/city/config/${cityId}`);
    };

    const getGlobalConfig = () => {
        return api.get(`/configuration`);
    };

    const getTestDriveBranchLocation = (params) => {
        return api.get("/parking-yard/location", { params });
    };

    const getTestDriveBranchDirection = (params) => {
        return api.get("/parking-yard/direction", { params });
    };

    const hasOfferMade = (mobile, appointmentId) => {
        return api.get(`/isOfferExists/mobile/${mobile}/appId/${appointmentId}`);
    };

    const sendOTPForNoCars = (params, trackingParams) => {
        return api.post(`/no-result-found/send-otp${trackingParams ? `?${trackingParams}` : ""}`,
            params);
    };

    const verifyOTPForNoCars = (params, trackingParams) => {
        return api.post(`/no-result-found/verify-otp${trackingParams ? `?${trackingParams}` : ""}`,
            params);
    };

    const fetchCarCounts = (params) => {
        return api.get(`inventory/count?${params}`);
    };

    const submitLeads = (params) => {
        return api.post(`/leads?${params}`);
    };

    const getWhatsappConsent = (mobileNumber) => {
        return api.get(`chat-flow/opt-in/status/${mobileNumber}`);
    };

    const saveWhatsappConsent = (params) => {
        return api.post(`chat-flow/opt-in/init`, params);
    };

    const fetchUserConsent = (mobileNumber) => {
        // return api.get(`chat-flow/opt-in/status/${mobileNumber}`);
        return api.get(`/user/${mobileNumber}/optIn/all`);
    };

    const saveUserConsent = (mobileNumber, params) => {
        return api.post(`/user/${mobileNumber}/optIn`, params);
    };

    const createBookNowLead = (data, params) => {
        return api.post(`inventory/book-now`, data, { params });
    };

    const bookNowSendOtp = (data, params) => {
        return api.put(`inventory/book-now/send-otp`, data, { params });
    };

    const bookNowVerifyOtp = (data, params) => {
        return api.put(`inventory/book-now/verify-otp`, data, { params });
    };

    const fetchListingInfo = (token) => {
        return api.get(`/seller/car/uniqueIdentifier/${token}`);
    };

    const fetchDelistReasons = (type) => {
        return api.get(`/seller/car/delistReason/${type}`);
    };

    const updateInventory = ({ hashCode, isInterested, remarks, statusCode, listingPrice }) => {
        return api.post("/inventory-delist", { ...{ identifier: hashCode, isInterested, ...(remarks && { remarks }), ...(statusCode && { statusCode }), ...(listingPrice && { listingPrice }) } });
    };

    const updateInventoryPrice = ({ hashCode, listingPrice }) => {
        return api.put(`/inventory/priceRevision/uniqueIdentifier/${hashCode}`, { listingPrice });
    };

    const submitVerifiedLead = (data, params) => {
        return api.post(`/verified-lead`, data, { params });
    };

    const fetchLoanCalculationData = (appointmentId) => {
        return api.get(`/car/${appointmentId}/emi-calculation-detail/?displayPreference=emi`);
    };

    const fetchWishlistedCars = (mobileNumber, params) => {
        return api.get(`/wishlist/car-details/mobile/${mobileNumber}`, { params });
    };

    const addToWishlistlist = (data, params) => {
        return api.put(`/wishlist`, data, {params});
    };

    const removeFromWishlist = (data) => {
        return api.put(`/wishlist/delete`, data);
    };

    const unsubscribeCommunication = (data) => {
        return api.post(`/user/unsubscribe`, data);
    };

    const sendWhatsappReport = (data) => {
        return api.post(`/inspection-report/send`, data);
    };

    const fetchCitiesInCoordinates = (params) => {
        return api.get("/geolocation", { params });
    };

    const fetchCarFinderFilters = (params) => {
        return api.get("/carfinder/filters", { params });
    };

    const sendCarFinderData = (data) => {
        return api.post(`/carfinder`, data);
    };

    const submitBuyerIntent = (data) => {
        return api.post(`/user`, data);
    };

    const bookPayWallSendOtp = (data, params) => {
        return api.post(`/paywall/buynow/otp`, data, { params });
    };

    const bookPayWallVerifyOtp = (data, params) => {
        return api.put(`/paywall/buynow/otp/verify`, data, { params });
    };

    const fetchOfferScore = (mobile, appointmentId) => {
        return api.get(`/offer-profile/mobile/${mobile}/appId/${appointmentId}`);
    };

    const gsBookSendOtp = (data, params) => {
        return api.post(`/otp/send`, data, {params});
    };

    const gsBookVerifyOtp = (data, params) => {
        return api.put(`/otp/verify`, data, {params});
    };

    const getParkingYards = (appointmentId) => {
        return api.get(`/parking-yard/appointmentId/${appointmentId}`);
    };

    const getFinanceQuestions = () => {
        return api.get(`/simpler/questions`);
    };

    const submitFinanceAnswers = (data, params) => {
        return api.post(`/simpler/submit`, data, {params});
    };

    const cfOfferDetails = (mobile, source = "mSite") => {
        return api.get(`/simpler/cf-offer/${mobile}?source=${source}`);
    };

    const checkCfOffer = (mobile, source = "mSite") => {
        return api.get(`/simpler/has-filled-cf-form/${mobile}?source=${source}`);
    };

    const getNearByParkingYards = (data) => {
        return api.post(`/parking-yard/near-buyer`, data);
    };

    const getAdditionCharges = (appointmentId, pincode) => {
        return api.get(`/v1/ci/charges/${appointmentId}`, {
            headers: {pincode}
        });
    };

    const getCarLocations = (appointmentId, pincode, params) => {
        return api.get(`/v1/ci/location/${appointmentId}`, {
            headers: {pincode},
            params
        });
    };

    const fetchPdfLink = (carId) => {
        return api.get(`/inventory/${carId}`);
    };

    const fetchPincodeConfig = (pincode) => {
        return api.get(`/v1/ci/pincode/${pincode}`);
    };

    const fetchCarInspectionReport = (appId) => {
        return api.get(`v1/inspection-report/${appId}`);
    };

    const fetchBannerCarCounts = (pincode) => {
        return api.get(`inventory/count?pinId=${pincode}`);
    };

    const fetchMoreCarsForYou = (appId) => {
        return api.get(`v1/similar/banner/${appId}`);
    };
    const getCarsSearch = (params) => {
        return api.get("v2/global-cars-search", { params });
    };

    return {
        fetchTopSellingCars,
        getGlobalCarsSearch,
        fetchCars,
        fetchDynamicFilters,
        fetchCarDetail,
        getSimilarCarsCount,
        fetchSimilarCars,
        submitRecentlyViewedCars,
        getRecentlyViewedCars,
        sendOTP,
        verifyOtp,
        getPriceRange,
        submitUserOfferPrice,
        getCityConfig,
        getGlobalConfig,
        getTestDriveBranchLocation,
        hasOfferMade,
        sendOTPForNoCars,
        verifyOTPForNoCars,
        fetchCarsFromPathName,
        fetchCarCounts,
        submitLeads,
        getWhatsappConsent,
        saveWhatsappConsent,
        fetchUserConsent,
        saveUserConsent,
        createBookNowLead,
        bookNowSendOtp,
        bookNowVerifyOtp,
        fetchListingInfo,
        updateInventory,
        fetchDelistReasons,
        updateInventoryPrice,
        fetchSimilarCarsById,
        submitVerifiedLead,
        fetchLoanCalculationData,
        fetchWishlistedCars,
        addToWishlistlist,
        removeFromWishlist,
        unsubscribeCommunication,
        sendWhatsappReport,
        fetchCitiesInCoordinates,
        fetchCarFinderFilters,
        sendCarFinderData,
        submitBuyerIntent,
        bookPayWallSendOtp,
        bookPayWallVerifyOtp,
        fetchOfferScore,
        getTestDriveBranchDirection,
        gsBookSendOtp,
        gsBookVerifyOtp,
        getParkingYards,
        getFinanceQuestions,
        submitFinanceAnswers,
        cfOfferDetails,
        checkCfOffer,
        getNearByParkingYards,
        getAdditionCharges,
        fetchPdfLink,
        getCarLocations,
        fetchPincodeConfig,
        fetchCarInspectionReport,
        getPickupLocation,
        fetchBannerCarCounts,
        fetchMoreCarsForYou,
        fetchDynamicFilterV3,
        getCarsSearch
    };
};
