import React from "react";

// Styles
import skeleton from "./skeleton.css";

const Skeleton = () => {
    return (
        <div styleName={"skeleton.carCard"}>
            <div className="shimmer" styleName={"skeleton.shimmerImg"} />
            <div styleName={"skeleton.carMeta "} >
                        <h4 className="shimmer" styleName={"skeleton.heading"} />
                        <h4 className="shimmer" styleName={"skeleton.desc"} />
                <div styleName={"skeleton.info"}>
                    <p className="shimmer" />
                    <p className="shimmer" />
                </div>
                <div styleName="skeleton.border" />
                <div styleName={"skeleton.footer"}>
                    <div>
                        <h3 className="shimmer"/>
                    </div>
                    <div>
                        <p className="shimmer" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skeleton;
