import { appUrl } from "../../constants/url-constants";
import qs from "querystring";

export const getPostBcCustomUrl = ({ lid, step = "" }, params) => {
    const POSTBC_BASE_ROUTE = "booking-confirmation";
    const normalizedParams = params && qs.stringify(params);
    const normalizedURL = `${POSTBC_BASE_ROUTE}/${lid}/${step}${params ? `?${normalizedParams}` : ""}`;
    return {
        absoluteURL: `${appUrl()}/${normalizedURL}`,
        relativeURL: `/${normalizedURL}`
    };
};
