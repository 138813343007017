import parseCookie from "./parse-cookie";
import { COOKIE_CONSTANTS, NUMBER } from "../../constants/app-constants";

export default (() => {
    const gaId = parseCookie(COOKIE_CONSTANTS.GA);
    if (gaId) {
        return gaId.split(".").slice(Number(-NUMBER.TWO)).join(".");
    }

    return "";
})();

/**
 * For Testing: 646572770.1668800751
 */
