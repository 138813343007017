import React from "react";
import styles from "./styles.css";
import SearchIcon from "../search-icon/component";
import PropTypes from "prop-types";

const InputSearch = ({
    autoFocus = false,
    onFocus = () => {},
    placeholder,
    onChange = () => {},
    ...restProps
}) => {
    return (
        <div styleName={"styles.searchInput"}>
            <div className={"form-group"}>
                <input
                    type="text"
                    className={"form-control"}
                    styleName={"styles.inputSearch"}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    onChange={onChange}
                    onFocus={onFocus}
                    {...restProps}
                />
                <span styleName={"styles.searchIcon"} className="searchIcon">
                    <SearchIcon />
                </span>
            </div>
        </div>
    );
};

InputSearch.propTypes = {
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func
};

export default InputSearch;
