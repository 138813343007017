import React from "react";
import PropTypes from "prop-types";
import styles from "./track.css";

export const Track = ({
    source,
    target,
    getTrackProps,
    disabled,
    railColor,
    disabledTrackStyles
}) => {
    return (
        <div styleName={"styles.trackSlider"}
            style={{
                backgroundColor: disabled ? railColor : "",
                top: `${source.percent}%`,
                height: `${target.percent - source.percent}%`,
                ...disabledTrackStyles
            }}
            {...getTrackProps()}
        />
    );
};

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    railColor: PropTypes.string,
    disabledTrackStyles: PropTypes.object

};

Track.defaultProps = {
    disabled: false
};
export default Track;
