import { ADDRESS_LEVEL_TYPE_GOOGLE_MAP } from "../../constants/app-constants";

const formatAddressForGoogleMap = (data = {}, address = {}) => {
    const addressLevelType = data.types[0];
    switch (addressLevelType) {
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.SUBPREMISE: {
        return { ...address, unit: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.STREET_NUMBER: {
        return { ...address, streetNumber: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.ROUTE: {
        return { ...address, street: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.LOCALITY: {
        return { ...address, suburb: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.ADMINISTRATIVE_AREA_LEVEL_2: {
        return { ...address, city: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.ADMINISTRATIVE_AREA_LEVEL_1: {
        return { ...address, state: data.short_name || data.long_name};
    }
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.COUNTRY: {
        return { ...address, country: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE_GOOGLE_MAP.POSTAL_CODE: {
        return { ...address, postCode: data.long_name};
    }
    default: {
        return { ...address };
    }
    }
};

const formatAddressObject = (addressObj) => {
    let formatedAddress = {
        full: addressObj.formatted_address,
        unit: "",
        streetNumber: "",
        street: "",
        suburb: "",
        city: "",
        state: "",
        country: "",
        postCode: ""
    };
    const addressComponents = addressObj.address_components;
    if (addressComponents) {
        addressComponents.map((value) => {
            formatedAddress = formatAddressForGoogleMap(value, formatedAddress);
        });
    }
    return formatedAddress;
};

export {
    formatAddressObject
};
