import moveArrayItem from "./move-array-item";

export default (images = []) => {
    const srpImageLabel = "SRP";
    const defaultImageLabel = "RIGHT FRONT DIAGONAL (45-DEGREE) VIEW";
    const imageObjectParser = el => ({...el, title: el.label});
    const imageFilter = el => el.label !== srpImageLabel;
    const filteredImages = (images ? images : [])?.filter(imageFilter);
    const defaultImageIndex = (filteredImages || [])?.reduce((pv, cv, idx) => cv.label === defaultImageLabel ? idx : pv, 0);
    return moveArrayItem(filteredImages, defaultImageIndex, 0)?.map(imageObjectParser);
};
