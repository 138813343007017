import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_SLOT_TD
    UPDATE_SLOT_DL
    
    SET_IS_MRL
    SET_SCHEDULE_TEST_DRIVE_SHOW_MORE_CTA

    FETCH_POST_BC_CONFIG_SUCCESS
    FETCH_POST_BC_CONFIG_FAILURE

    FETCH_ORDER_POSTBC_DETAILS_INIT
    FETCH_ORDER_POSTBC_DETAILS_SUCCESS
    FETCH_ORDER_POSTBC_DETAILS_FAILURE

    FETCH_ACTIVE_STEP_DATA_INIT
    FETCH_ACTIVE_STEP_DATA_SUCCESS
    FETCH_ACTIVE_STEP_DATA_FAILURE
    RESET_ACTIVE_STEP_DATA

    FETCH_TD_ALL_ACTIVE_BOOKINGS_INIT
    FETCH_TD_ALL_ACTIVE_BOOKINGS_SUCCESS
    FETCH_TD_ALL_ACTIVE_BOOKINGS_FAILURE
`,
    {
        prefix: "postbc/"
    }
);
