export const insertBannerIntoList = (propositionCard, indexToInsert, elementToInsert) => {
    const propositionCardCopy = { ...propositionCard };
    let prevKey = 0;
    let prevElement = null;
    let distance = 0;

    (Object.keys(propositionCardCopy) || []).forEach((key, index) => {

    if (index !== 0) {
        distance = Number(key) - Number(prevKey);
    }

    if (Number(indexToInsert) === Number(key) || prevElement) {

        if (!prevElement) {
            prevElement = propositionCardCopy[key];
            propositionCardCopy[key] = elementToInsert;

        } else {
            const temp = propositionCardCopy[key];
            propositionCardCopy[key] = prevElement;
            prevElement = temp;
        }
    }

    prevKey = key;
    });

    propositionCardCopy[Number(prevKey) + Number(distance)] = prevElement;

    return propositionCardCopy;

};
