import React, { useState } from "react";
import PropTypes from "prop-types";
import VizSensor from "react-visibility-sensor";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import debounce from "../../../utils/helpers/debounce";

const TrackableCarCardItem = ({ children, event = {}, eventDataGA4 = {}, onVisible = () => { } }) => {
    const { name = null, data = null } = event;
    const { name: nameGA4 = null, data: dataGA4 = null } = eventDataGA4;

    const [isViewed, setIsViewed] = useState(false);
    const onChangeCardVisibility = debounce((isVisible) => {
        onVisible(isVisible);

        if (isVisible && !isViewed) {
            if (name && data) {
                trackDesktopCustomEventsAE(name, data);
            }
            if (nameGA4 && dataGA4) {
                trackDesktopCustomEventsAE("clearEccomerce");
                trackDesktopCustomEventsAE(nameGA4, dataGA4);
            }
            setIsViewed(true);
        }
    });

    return (
        <VizSensor
            onChange={onChangeCardVisibility}
            partialVisibility={true}
        >{children}</VizSensor>
    );
};

TrackableCarCardItem.propTypes = {
    children: PropTypes.object,
    event: PropTypes.object,
    onVisible: PropTypes.func,
    eventDataGA4: PropTypes.object
};

export default TrackableCarCardItem;
