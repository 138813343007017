import React, { useState, useRef } from "react";
// import ReactPlayer from "react-player/lazy";
import loadable from "@loadable/component";
const ReactPlayer = loadable.lib(() => import("react-player"), { ssr: false });
import PropTypes from "prop-types";
import IconSpinner from "../icon-spinner";
const VideoPlayer = ({
    url,
    autoPlay,
    playOnPreview = false,
    onEnded,
    onStartCallback = () => {},
    ...restProps
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [play, setPlay] = useState(false);
    const videoRef = useRef();
    const onPreviewClick = () => {
        if (playOnPreview) {
            setPlay(true);
        } else {
            setIsLoading(true);
        }
    };
    const onReady = () => {
        setIsLoading(false);
    };

    const onStart = () => {
        onStartCallback();
    };

    return (<React.Fragment>
        {isLoading && restProps.light && !playOnPreview && <IconSpinner />}
        <ReactPlayer fallback={<React.Fragment />}>
            {({ default: AsyncPlayer }) => (<AsyncPlayer
                autoPlay={autoPlay}
                url={url}
                playing={play}
                ref={videoRef}
                onEnded={onEnded}
                onClickPreview={onPreviewClick}
                onReady={onReady}
                onStart={onStart}
                {...restProps}
            />)}
        </ReactPlayer>

    </React.Fragment>);
};

VideoPlayer.propTypes = {
    url: PropTypes.string,
    autoPlay: PropTypes.bool,
    playOnPreview: PropTypes.bool,
    onEnded: PropTypes.func,
    onStartCallback: PropTypes.func
};

export default VideoPlayer;
