import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchFilters, fetchSiteMap, updateSSRStatus } from "../filters/actions";
import SiteMapInfo from "./component";

const mapStateToProps = ({
    config: { cityList },
    filters: {
        isSSR: isFiltersSSR,
        allFilters
    },
    location: {
        selectedCity: {
            name: selectedCityName
        } = {}
    }
}) => ({
    cityList,
    isFiltersSSR,
    allFilters,
    selectedCityName
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    fetchSiteMapConnect: fetchSiteMap,
    updateFilterSSRStatusConnect: updateSSRStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SiteMapInfo);
