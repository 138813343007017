/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import Checkbox from "../../shared/checkbox/component";

const DriveTypeFilter = ({appliedFilters, filter, onOptionSelection}) => {
    const {
        options
    } = filter;

    const selectOption = (filterKey, optionKey, isSelected, displayText) => {
        onOptionSelection({
            filterKey,
            optionKey,
            isSelected,
            displayText
        });
    };

    return (
        <div styleName={"styles.genericWrap"}>
            {
                options.map((option) => {
                    const isSelected = !!getAppliedFilter({ filterKey: filter.key, optionKey: option.key }, appliedFilters);
                    return (
                        <div key={option.key} styleName={"styles.itemWrap"}>
                            <Checkbox checkedStatus={isSelected} onClickCallback={selectOption.bind(null, filter.key, option.key, !isSelected, option.displayText)} id={`${filter.key}${option.key}`} name="" />
                            <div styleName={`styles.listTextRevamp ${isSelected ? `styles.active` : ``}`}>
                            {`${option.displayText}`}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};
DriveTypeFilter.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array,
    allFilters: PropTypes.object
};
export default DriveTypeFilter;
