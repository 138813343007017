/* eslint-disable no-console */
import axios from "axios";
import { captureException } from "@sentry/browser";
import { IGNORE_PRIVATE_PRODUCT_APIS, PLATFORM } from "../constants/app-constants";
import { COUNTRY_CODE } from "../constants/api-constants";

import { proxy } from "valtio/vanilla";
import authRefresh from "../utils/helpers/auth-refresh";

export const proxyAuthState = proxy({
    accessToken: "",
    expiresIn: 300,
    isLoggedIn: true
});

const DEBUG = process.env.NODE_ENV === "development2";

let BASE_API_URL = "https://user-auth-service.qac24svc.dev";
let X_COUNTRY_CODE = COUNTRY_CODE.UAE;
switch (process.env.HOST_ENV) {
case "QA":
    BASE_API_URL = "https://user-auth-service.qac24svc.dev";
    X_COUNTRY_CODE = COUNTRY_CODE.INDIA;
    break;
case "LNOD":
    BASE_API_URL = "https://pvt-product-stage.cars24.team/";
    X_COUNTRY_CODE = COUNTRY_CODE.UAE;
    break;
case "STAGING":
    BASE_API_URL = "https://stage-user-auth-service.qac24svc.dev";
    X_COUNTRY_CODE = COUNTRY_CODE.UAE;
    break;
case "PRODUCTION":
    BASE_API_URL = "https://oauth.cars24.ae";
    X_COUNTRY_CODE = COUNTRY_CODE.UAE;
    break;
default: break;
}

/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        X_VEHICLE_TYPE: "CAR",
        X_COUNTRY: X_COUNTRY_CODE,
        X_PLATFORM: PLATFORM
    },
    withCredentials: true
});

// Request Interceptor which handles token refresh pre and post token expiry
api.interceptors.request.use((config) => authRefresh(config, api, proxyAuthState), (error) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("✉️ ", error);
    }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.info("📩 ", response);
    }
    return response;
}, (error) => {
    if (!IGNORE_PRIVATE_PRODUCT_APIS.includes(error.config.url)) {
        captureException(error);
    }
    if (error.response && error.response.data) {
        if (DEBUG) {
            // eslint-disable-next-line no-undef
            console.error("Error: ", error.response.data);
        }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) {
        // eslint-disable-next-line no-undef
        console.error("📩 ", error);
    }
    return Promise.reject(error.message);
});

export default api;
