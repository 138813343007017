import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const descriptions = {
    ae: [
        {
            title: "Sell Your Car Online with Ease - CARS24 in UAE",
            data: [
                "Looking to sell your car in UAE? Don't worry, CARS24 has got you covered. We offer a hassle-free way to sell any car online. You can simply use our online car valuation tool to get an estimated price for your vehicle by entering its details. Our team of experts will then inspect your car and provide you with a detailed evaluation report to ensure you receive the best possible value for your car.",
                "Selling your used car online has never been easier. CARS24 provides a transparent process from start to finish, saving you time and effort. You can sell your car from the comfort of your home without worrying about the hassles of dealing with middlemen or agents. We also offer a detailed evaluation report for your car to make sure you get the best possible value."
            ]
        },
        {
            title: "Sell Your Car Quickly and Easily with CARS24 in UAE",
            data: [
                "Are you looking to sell your car in UAE? Look no further than CARS24. We offer a quick and transparent process for selling your car. When you sell your car to us, you can be sure that you'll receive the best possible value for your vehicle.",
                "With CARS24, you can sell your car directly to us without any middlemen or agents involved. We believe in complete transparency and offer a hassle-free experience with no hidden fees. Our team of experts will take care of everything from the initial inspection to the final paperwork, making the process quick and easy for you.",
                "Whether you're looking to sell a used car or sell your car online, CARS24 has got you covered. Our online platform makes it easy to sell your car in UAE. Sell your car online today with CARS24 and enjoy a quick and transparent process with no hidden fees."
            ]
        },
        {
            title: "Sell Your Used Car Online with Ease - CARS24 in UAE",
            data: [
                "Looking to sell your used car in the UAE? Let CARS24 simplify the process for you. Our platform offers a seamless and hassle-free method to list your used car for sale online. Utilize our advanced online car valuation tool to input your vehicle's specifications and receive an instant estimated price. Following this, our team of seasoned experts conducts a meticulous inspection, providing you with a comprehensive evaluation report to ensure you obtain the best value for your vehicle.",
                "Say goodbye to the complexities of listing your used car for sale online. CARS24 ensures complete transparency throughout the process, saving you valuable time and effort. Bypass the need for intermediaries or agents, as you can conveniently sell your car from the comfort of your home. Additionally, our detailed evaluation report guarantees transparency and ensures you receive the optimum value for your used car.",
                "Ready to list your used car for sale? Trust CARS24 for a quick, transparent, and rewarding experience. List your used car for sale hassle-free today and enjoy peace of mind knowing you're in expert hands."
            ]
        }
    ],
    dubai: [
        {
            title: "Hassle-free Way to Sell Your Car in Dubai - CARS24",
            data: [
                "Looking to sell your car in Dubai? CARS24 provides a quick and easy way to sell any car in Dubai. Our online car valuation tool allows you to get an estimated price for your vehicle by simply entering its details. After that, our team of experts will inspect your car and provide you with a detailed evaluation report to ensure you receive the best possible value when you sell your car in Dubai.",
                "Selling your used car in Dubai has never been easier. With CARS24, you can sell your car in Dubai online from the comfort of your home without worrying about any hassles. Our transparent process saves you time and effort, and ensures you get the best possible value when you sell your car in Dubai. We also offer a detailed evaluation report for your car to make sure you get the best possible price."
            ]
        },
        {
            title: "Quick and Easy Way to Sell Your Car in Dubai with CARS24",
            data: [
                "Want to sell your car in Dubai quickly and easily? Choose CARS24. We provide a hassle-free process to sell your car in Dubai with complete transparency. When you sell your car in Dubai to us, you can be sure that you'll receive the best possible value for your vehicle.",
                "Whether you want to sell your car online in Dubai or sell a used car in Dubai, CARS24 has got you covered. We offer a quick and transparent process for selling your car in Dubai without any middlemen or agents involved. Our team of experts will take care of everything from the initial inspection to the final paperwork, making the process quick and easy for you",
                "In summary, if you're looking to sell your car in Dubai, CARS24 is your one-stop solution. Our online platform makes it easy to sell your car in Dubai without any hassle. Get started today and sell your car in Dubai with complete peace of mind."
            ]
        }
    ],
    "abu-dhabi": [
        {
            title: "Sell Your Car in Abu Dhabi with Ease - The CARS24 Way",
            data: [
                "Looking to sell your car in Abu Dhabi? Whether you're looking to upgrade to a newer model or just need to free up some space in your garage, selling your car can be a stressful process. But it doesn't have to be! With our hassle-free process at CARS24, selling your car in Abu Dhabi is quick and easy.",
                "We buy all types of cars - whether it's a sedan, hatchback, SUV, or any other type of vehicle. You can sell any car in Abu Dhabi with us and get a fair price for it. Simply visit our website and fill in the details of your car. Our advanced valuation algorithm will give you an estimate of your car's value within seconds."
            ]
        },
        {
            title: "Why Sell Your Car in Abu Dhabi to CARS24",
            data: [
                "Selling your car in Abu Dhabi has never been this easy. You don't have to deal with middlemen or agents. You can sell your car directly to us and save time and money. Our team of experts will inspect your car and provide you with a detailed evaluation report. You can then choose to accept the offer or not - there's no obligation.",
                `So, if you're wondering "where can I sell my car in Abu Dhabi ? " look no further than CARS24. We provide a hassle-free and transparent process for selling your car in Abu Dhabi. Whether you want to sell any car in Abu Dhabi or a specific make and model, we're here to help you get the best price for your vehicle.`
            ]
        }
    ]
};

const SellerSeoContent = ({ city = "ae", isWeb }) => {
    const [showResults, setShowResults] = React.useState(false);
    const onReadMoreLess = () => setShowResults(!showResults);
    const content = descriptions && descriptions[city];
    return (
        <React.Fragment>
            <div styleName={`${isWeb ? "styles.sellUsedCardWrapper" : "styles.sellUsedCarContainer"} ${showResults ? "styles.seeMore" : "styles.seeLess"}`}>
                {content?.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <h3>{item?.title}</h3>
                        <div styleName={"styles.content"}>
                            <div>
                                {item.data.map((v, index) => (
                                    <p key={index}>{v}</p>
                                ))}
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <span styleName={`styles.sellMoreLess ${isWeb ? "styles.noMarginLeft" : ""}`} onClick={onReadMoreLess}>{showResults ? "Read less" : "Read more"}</span>
        </React.Fragment>
    );
};
SellerSeoContent.propTypes = {
    city: PropTypes.string,
    isWeb: PropTypes.bool
};
export default SellerSeoContent;
