export const getDeviceType = () => {
    if (window?.navigator?.userAgent.match(/mobile/i)) {
        return "mobile";
    } else if (window?.navigator?.userAgent.match(/iPad|Android|Touch/i)) {
        return "mobile";
    } else {
        return "desktop";
    }
};

export const getUserOs = () => {
    try {
        const userAgent = window ? window?.navigator?.userAgent : "";
        if (userAgent?.match(/iPhone/i)) {
            return "msite_iOS";
        } else if (userAgent?.match(/Android/i)) {
            return "msite_android";
        } else if (userAgent?.match(/Windows/i)) {
            return "windows";
        } else if (userAgent?.match(/Mac/i)) {
            return "mac";
        } else {
            return "msite_other";
        }
    } catch (error) {
        return "msite_other";
    }

};
