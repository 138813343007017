import React from "react";
import styles from "./styles.css";
import H1 from "../h1/component";
import H2 from "../h2/component";
import { MAIL_CARE_HELP_CENTER } from "../../../constants/app-constants";

const PrivacyInfoAe = () => {
    return (
        <div className="container" styleName={"styles.main"}>
            <H1 text="Privacy Policy" />
            <p styleName={"styles.privacy"}> Last updated on March 08, 2021</p>
            <p styleName={"styles.privacy"}>Global Cars Trading FZ LLC
                (the <strong>“Company”</strong>) firmly believes in the highest standards of integrity
                in relation to information provided by its customers at the time of
                interaction with the Company, and otherwise.</p>
            <p styleName={"styles.privacy"}>The Company values its users’ privacy and is
                committed to protect information disclosed by the users <strong>(“You” and its variants, as applicable)</strong> to the Company either, by means
                of the Company’s website or the mobile application.</p>
            <p styleName={"styles.privacy"}>Please read the following privacy policy
                terms (hereinafter referred to as <strong>“Privacy Policy”</strong>) to understand the Company’s practices
                specifically with regard to how the Company treats
                information disclosed by You to the Company. By using the domain and
                subdomains of Company including its mobile applications, Google assistant
                bot (hereinafter referred to as <strong>“Website”</strong>), You agree that You have
                acceded to the terms of the present Privacy Policy. In the event
                that You do not wish to be bound by these terms, You have a choice,
                which is (naturally and obviously) free from any form of coercion or
                undue influence, to not access the Website, any further. This Privacy
                Policy also applies generally to the websites of our parent entity, affiliates,
                subsidiaries, or joint venture entities, which may or may not be referred to in the Website’s content.</p>
            <H2 text="1. Your Consent" />
            <p styleName={"styles.privacy"}>We request You to carefully read this Privacy Policy
            and the Website’s Terms of Use under the different tab before You elect to share
            Your personal information with us. By browsing, visiting, accessing and/or using
            the services on the Website (or searching for any of the pages on the Website),
            You explicitly consent and agree to our Privacy Policy laid out herein. You also
            agree that the information furnished by You is - lawful, true, and correct and
            does not violate or infringe any laws or any contractual obligations that bind
            You at the time the information is furnished by You. No liability pertaining
            to the authenticity/ genuineness/ misrepresentation/ fraud/ negligence, etc.
            of the information disclosed by You shall lie on the Company nor will the
            Company in any way be responsible to verify any information obtained from
                You.</p>
            <H2 text="2. Information" />
            <p styleName={"styles.privacy"}>This Privacy Policy applies to:</p>
            <p styleName={"styles.privacy"}><strong>Personal information:</strong> Your personal information
                which You may voluntarily submit to the Website while registering/interacting
                on the <strong>Company’s</strong> website or the mobile application, including without limitation
                Your email address, name, and phone number.</p>
            <p styleName={"styles.privacy"}><strong>Non- Personal information:</strong> Information,
                (viz. type of internet browser and operating system used, domain name of
                the website from which You came, number of visits, average time spent on
                the site/mobile application, pages viewed etc.) may be picked up automatically
                and without explicitly being provided by You, but during Your interaction
                with the Company website or the mobile application by use of certain
                technologies, such as Cookies and Web Beacons.</p>
            <H2 text="3. Types of information received by the Company" />
            <p styleName={"styles.privacy"}>The following type of information about You may be collected and processed by the Company:</p>
            <div styleName={"styles.privacyList"}>
                <ul>
                    <li>Information about Your internet connection and mobile device.</li>
                    <li>The equipment used by You to browse the Company’s Website.</li>
                    <li>Your usage details in respect of the Website.</li>
                    <li>Internet Protocol (IP) details/ address.</li>
                    <li>Your Current Location, Device Location, Device Information, Communication Information to provide customized offerings.</li>
                    <li>Computer operating system.</li>
                    <li>Preferences and settings such as time zone and language.</li>
                    <li>Account / User Information – including but not limited to Your name, address, email address, mobile / telephone number, gender, income, and any other information sought by the Company at the time of Your making an account with the Company, or obtaining a quote from the Company.</li>
                    <li>Your searches, the results You selected from the search and other activities.</li>
                    <li>Your browsing information (browsing length, details of online ads that You clicked on).</li>
                    <li>Your transactional information.</li>
                    <li>Details in respect of Your vehicle, including but not limited to make, model, identification details of the vehicle, prior use, history, insurance details, registration certificate details, etc.</li>
                </ul>
            </div>
            <p styleName={"styles.privacy"}>Please note that the following list is only illustrative, and is by no means, exhaustive. Kindly note that any user-generated content such as reviews, blogs, testimonials etc. shall be treated by the Company as its own.</p>
            <H2 text="4. Third party integration" />
            <p styleName={"styles.privacy"}>You may access the Website through third-party websites over which the Company has no control. You may optionally provide the Company with information through third-party sign-in services such as Facebook, Pinterest, Twitter, Instagram, and Google Plus. In such cases, the Company shall fetch and store whatever information is made available to the Company by You through these sign-in services. Please also note that the Company reserves its rights to use information provided by You, in consonance with its Privacy Policy and all relevant laws and guidelines of the United Arab Emirates.</p>
            <p styleName={"styles.privacy"}>Further, it is clarified that during Your usage of the Company Website, You may see the links to third party websites / advertisements / electronic communication service(s), which are provided by third parties. As the operation of third parties is not under control of the Company, therefore the Company does not make any endorsement / guarantee of any service or product offered through third party websites nor make any representation related to the any privacy policy or other policies of such third party. Any usage of such third-party website or availing any service or product through third party shall be at Your risk and the Company is not responsible for any loss / damage or otherwise. It is recommended that You should review the privacy policy (and all other terms of use) of such third-party websites prior to using such websites.</p>
            <H2 text="5. How Your information helps in providing You with a more optimized interface and experience" />
            <p styleName={"styles.privacy"}>We use the information that You provide to enhance the functionality and improve the quality of Your interaction with us and to personalize Your experience while using our services.</p>
            <H2 text="6. Public posts" />
            <p styleName={"styles.privacy"}>As part of our services, You may provide Your reviews, feedback, comments, ratings, photos, etc. on the public groups (Posts). The Company retains an unconditional right to remove and delete any Post or such part of the Post that, in the opinion of the Company, is false, mala-fide and misrepresenting. All Posts shall be publicly accessible and visible to all users and therefore, You should be careful about revealing any sensitive details about Yourself in such Posts. The Company reserves the right to use and reproduce Your Posts for any purpose. Further, the Company may share Your Posts with the service providers, businesses or any other third party. If You delete Your Posts from any of the public groups, copies of such Posts may remain viewable in archived pages, or such Posts may have been copied or stored by any other user(s) of that public group.</p>
            <H2 text="7. Marketing Promotions, Research and Programs:" />
            <p styleName={"styles.privacy"}>Marketing promotions, research and programs help us to identify Your preferences, develop programs and improve user experience. Personal Information collected by us for such activities may include contact information and survey questions. We use such personal information to notify contest winners and survey information to develop promotions and product improvements. As a registered User, You will also occasionally receive promotional and marketing updates from us.</p>
            <p styleName={"styles.privacy"}>From time to time we may add or enhance services available on the Website. To the extent these services are provided, and used by You, we will use the Personal Information You provide to facilitate the service(s) requested. For example, if You email us with a question, we will use Your email address, name, nature of the question, etc. to respond to Your question. We may also store such Personal Information to assist us in making the Website the better and easier to use for You.</p>
            <H2 text="8. Opting out" />
            <p styleName={"styles.privacy"}>You may opt out of receiving promotional emails from the Company by following the instructions in those emails. If You opt out, the Company may still send You non-promotional emails, such as emails about Your account.</p>
            <p styleName={"styles.privacy"}>Cookie choices: Most web browsers are set to accept cookies by default. If You prefer, You can usually choose to set Your browser to remove cookies and to reject cookies. If You choose to remove cookies or reject cookies, this could affect certain features of the CARS24 Website/ mobile application.</p>
            <H2 text="9. How the Company uses and shares the information collected" />
            <p styleName={"styles.privacy"}>The Company uses information collected from and about You for a variety of purposes, including (but not limited) to:</p>
            <div styleName={"styles.privacyList"}>
                <ul>
                    <li>Processing and responding to Your queries.</li>
                    <li>Protect and improve the services that the Company offers.</li>
                    <li>Understanding users (what they do on our services, what features they like, how they use them, etc.), improving content and features of the Company’s services (such as by personalizing content to Your interests), processing and completing Your transactions, and making special offers.</li>
                    <li>Sending You communications that You have requested or that may be of interest to You.</li>
                    <li>Sending You questions that You may be able to answer if You have registered with the Company, or have scheduled an appointment at a Company location or branch.</li>
                    <li>Sending You information about Your vehicle pursuant to Your visit to a Company branch or location.</li>
                    <li>Enabling and filtering advertisements that are relevant to You.</li>
                    <li>Generating and reviewing reports and data about, and to conduct research on, the Company user base and service usage patterns.</li>
                    <li>Administering contests and sweepstakes.</li>
                    <li>Providing You with customer support.</li>
                    <li>Providing You with notices about Your account.</li>
                    <li>Internal purposes such as auditing, data analysis and research relating to the Company’s services on the Company’s Website.</li>
                    <li>Carrying out the Company’s obligations and enforcing rights arising from any contracts entered into between You and the Company, including for billing and collection.</li>
                    <li>Carrying out the Company’s obligations that arise out of all relevant statutes in relation to Your vehicle, and in relation to Your transaction and interaction with the Company.</li>
                    <li>Allowing You to participate in interactive features offered by the Company.</li>
                    <li>Granting You opportunities to avail special offers/vouchers being offered by third parties by You being a customer of the Company and/or showing Your interest for the services of the Company.</li>
                    <li>The Company is allowed to share the information, which You have shared on the Company Website, with our alliance partners. These alliances/ partnerships are done to provide perks and convenience to You. By clicking on the submit button, the Company and the partner will consider You to be an interested user and will use the details submitted by You to contact You and give You a customized experience of the Company/ Website services.</li>
                </ul>
            </div>
            <p styleName={"styles.privacy"}>The Company may use the information collected from You to enable the Company to display advertisements to its advertisers’ target audiences. Even though the Company does not disclose Your personal information for these purposes without Your consent, if You click on, or otherwise interact with an advertisement, the advertiser may assume that You meet its target criteria.</p>
            <p styleName={"styles.privacy"}>The Company hereby assures You that the collection and processing of sensitive user data over the Website is done in a secure and encrypted manner to prevent illegal collection of usernames, credit card information and banking information). We further assure you that the Website’s servers and systems have been secured by hosting with AWS, installing anti-virus and anti-malware software, and regular security auditing. We follow best practices in term of administration and operations.</p>
            <H2 text="10. Disclosure to third parties" />
            <p styleName={"styles.privacy"}>The Company shall be at liberty to disclose Your information or part thereof to third party service providers who may facilitate the providing of services, on the Company’s behalf. These service providers have access to Your data only to perform the tasks as stipulated by Company and are obligated not to disclose or use it for any other purpose. The Company may also share personal information with third-party vendors, consultants, and other service providers who work for the Company, who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which the Company disclose it to them.</p>
            <p styleName={"styles.privacy"}>In addition to the aforesaid, alliance partners/channel partners of the Company may collect information with Your consent from Your mobile device like device location, device information (including storage, model, installed apps, Wi-Fi, mobile network) transactional and promotional SMS, communication information including contacts to provide customized offerings.</p>
            <p styleName={"styles.privacy"}>We will retain Your Personal Information on our servers for as long as is reasonably necessary for the purposes listed in this policy. In some circumstances, we may retain Your Personal Information for longer periods of time, for instance where we are required to do so in accordance with any legal, regulatory, tax or accounting requirements.</p>
            <p styleName={"styles.privacy"}>Where Your personal data is no longer required we will ensure it is either securely deleted or stored in a way which means it will no longer be used by the business.</p>
            <H2 text="11. Amendment" />
            <p styleName={"styles.privacy"}>The Company reserves the right to amend or modify this Privacy Policy at any time, as and when the need arises. We request You to regularly check this Privacy Policy from time to time to keep You updated about changes made. Your continued use of the services provided by Company after any changes, shall be deemed to be Your acceptance of such changes.</p>
            <H2 text="12. Contact Us" />
            <p styleName={"styles.privacy"}>In the event You have any complaints or concerns with respect to the Company’s Website or our services or if You have any questions about this Privacy Policy, please feel free to contact us on <strong>8001110900</strong> (between 8 am - 10 pm) or reach out to our customer support  at <a href={`mailto:${MAIL_CARE_HELP_CENTER}`}>{MAIL_CARE_HELP_CENTER}</a></p>
            <H2 text="13. Grievance Redressal" />
            <p styleName={"styles.privacy"}>In administering the Company’s Website and the
            mobile application, the Company shall always adhere to the applicable laws
            of the United Arab Emirates (UAE). Therefore, in compliance with the
                <strong>‘Guidelines to Website owners and internet services’</strong> issued by the
                Telecommunications Regulatory Authority of the United Arab Emirates,
                the Company has a <strong>mechanism for reporting abuse,</strong> which is aimed at
                providing an effective and easy mechanism for You to report any abuses
                that may occur on the Website and to follow-up and resolve cases within
                a reasonable time.</p>
            <p styleName={"styles.privacy"}>As part of the mechanism for reporting abuse,
                the Website shall always have a dedicated <strong>Grievance Officer of the Company
                    (the “G.O”).</strong> </p>
            <p styleName={"styles.privacy"}>You may write to the G.O using the following [link] or the following contact details.</p>
            <table width="100%" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td><strong>Name:</strong></td>
                        <td>Mr. Tanay Gupta</td>
                    </tr>
                    <tr>
                        <td><strong>Email:</strong></td>
                        <td><a href={`mailto:${MAIL_CARE_HELP_CENTER}`}>{MAIL_CARE_HELP_CENTER}</a></td>
                    </tr>
                    <tr>
                        <td><strong>Official Address for correspondence:</strong></td>
                        {/* <td>Showroom No. 070, Ras Alkhor Industrial Area, Dubai, UAE</td> */}
                        <td>Auto New Market Block - 008, Ras Al Khor Industrial Area 3, Al Aweer, Dubai, UAE</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PrivacyInfoAe;
