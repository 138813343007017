import React from "react";
import styles from "./styles.css";
import CloseButton from "../icon-cross";
import Info from "./images/info-icon.svg";
import PropTypes from "prop-types";

const DownloadAppPendingStrip = (
    {
        message, onClose
    }
) => {
    return (
        <div styleName={"styles.wrapper"}>
            <img src={Info} />
            <p styleName={"styles.message"}>{message}</p>
            <span styleName={"styles.close"}><CloseButton type="grey" onClickHandler={onClose} /></span>
        </div>
    );
};

DownloadAppPendingStrip.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func
};

export default DownloadAppPendingStrip;
