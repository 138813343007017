import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BasicDetailsContent from "./component";

const mapStateToProps = ({
    carDetails: {
        content: {
            basicDetails,
            appointmentId,
            assortmentCategory,
            accidentFreeInfo
        }
    }
}) => ({
    basicDetails,
    appointmentId,
    assortmentCategory,
    accidentFreeInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetailsContent);
