import Types from "./types";
import { ListingService } from "../../../service";
import { GROUPED_FILTERS } from "../../../utils/filters-v2/constants";

const fetchFiltersInit = () => ({
    type: Types.FETCH_FILTERS
});

const fetchFilterSuccess = (data) => ({
    type: Types.FETCH_FILTERS_SUCCESS,
    data
});

const fetchFiltersFailure = () => ({
    type: Types.FETCH_FILTERS_FAILURE
});

const fetchFilters = (params = {}) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchFiltersInit());
    ListingService.fetchFilters({ ...params }, secureToken)
        .then((response) => {
            dispatch(fetchFilterSuccess(response.data));
        })
        .catch(() => {
            dispatch(fetchFiltersFailure());
        });
};

const fetchTrendingSearchInit = () => ({
    type: Types.FETCH_TRENDING_SEARCH_INIT
});

const fetchTrendingSearchSuccess = (data) => ({
    type: Types.FETCH_TRENDING_SEARCH_SUCCESS,
    data
});

const fetchTrendingSearchFailure = () => ({
    type: Types.FETCH_TRENDING_SEARCH_FAILURE
});

const fetchTrendingSearch = (params) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchTrendingSearchInit());
    ListingService.fetchTrendingSearch({ ...params }, secureToken)
        .then((response) => {
            dispatch(fetchTrendingSearchSuccess(response.data));
        })
        .catch(() => {
            dispatch(fetchTrendingSearchFailure());
        });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const updateSSRFiltersList = (data) => ({
    type: Types.UPDATE_SSR_FILTERS_LIST,
    data
});

const updateFilterTimestamp = (lastUpdated) => ({
    type: Types.UPDATE_FILTER_TIMESTAMP,
    lastUpdated
});

const updateSelectedOnScreenFilter = (selectedOnScreenFilter) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    selectedOnScreenFilter
});

const updatePriceFilterType = (priceFilterType) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    priceFilterType
});

const updateFilterOptionData = (data) => ({
    type: Types.UPDATE_FILTER_OPTION_DATA,
    data
});

const updateFilterOptionDataWithSavedFilterData = (data, savedSelectedFilter) => ({
    type: Types.UPDATE_SELECTED_FILTERS_WITH_SAVED_FILTERS,
    data, savedSelectedFilter
});

const updateNewFilterSelected = (flag) => ({
    type: Types.UPDATE_NEW_FILTER_SELECTED,
    flag
});

const updateAllFiltersVisibility = (showAllFilters) => ({
    type: Types.UPDATE_ALL_FILTERS_VISIBILITY,
    showAllFilters
});

const fetchListingCountInit = () => ({
    type: Types.FETCH_LISTING_COUNT
});

const fetchListingCountSuccess = (data) => ({
    type: Types.FETCH_LISTING_COUNT_SUCCESS,
    data
});

const fetchListingCountFailure = () => ({
    type: Types.FETCH_LISTING_COUNT_FAILURE
});

const fetchListingCount = (params, queryString, version = "v2") => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchListingCountInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, version, secureToken)
            .then((response) => {
                if (response.data.selectedFilters) {
                    const { operationValue } = response.data.selectedFilters[GROUPED_FILTERS.FINANCE] || {};
                    // eslint-disable-next-line no-use-before-define
                    if (operationValue) dispatch(updateCustomValue({ key: "financePriceLimit", value: operationValue }));
                }
                dispatch(fetchListingCountSuccess(response.data));
                resolve(response.data);
            })
            .catch((err) => {
                dispatch(fetchListingCountFailure());
                reject(err);
            });
    });
};

const updateFilterTabType = (activeFilterTab) => ({
    type: Types.UPDATE_FILTER_TAB_TYPE,
    activeFilterTab
});

const updateSelectedSort = (selectedSort) => ({
    type: Types.UPDATE_SELECTED_SORT,
    selectedSort
});

const clearAllFilters = () => ({
    type: Types.CLEAR_ALL_FILTERS
});

const clearSelectedFilters = (data) => ({
    type: Types.CLEAR_SELECTED_FILTERS,
    data
});
const updateAppliedFilters = (appliedFilters) => ({
    type: Types.UPDATE_APPLIED_FILTERS,
    appliedFilters
});

const updateAppliedSuggestions = () => ({
    type: Types.UPDATE_APPLIED_SUGGESTIONS
});

const updateSelectedSuggestions = (data) => ({
    type: Types.UPDATE_SELECTED_SUGGESTIONS,
    data
});

const updateUrlOptions = (urlOptions) => ({
    type: Types.UPDATE_URL_OPTIONS,
    urlOptions
});

const clearSuggestions = (filterKey) => ({
    type: Types.CLEAR_SUGGESTIONS,
    filterKey
});

const updateAllSubFilterOptions = ({ filterKey, optionKey, isSelected }) => ({
    type: Types.UPDATE_ALL_SUB_FILTER_OPTIONS,
    filterKey,
    optionKey,
    isSelected
});

const updateCustomValue = ({ key, value }) => ({
    type: Types.UPDATE_CUSTOM_VALUE,
    key,
    value
});

const notifyOnNoCarFound = (params, queryString, token) => () => {
    return new Promise((resolve, reject) => {
        ListingService.sendFiltersForNotification(params, queryString, token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};

const deleteNotifyOnNoCarFound = (nid, token) => () => {
    return new Promise((resolve, reject) => {
        ListingService.deleteNotificationInDb(nid, token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};

const fetchAutoSuggestions = (payload) => (_, getState) => {
    const {user: {secureToken = ""}} = getState() || {};
    return new Promise((resolve, reject) => {
        ListingService.fetchAutoSuggestions(payload, secureToken)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const setIsReturningUser = (isReturningUser = false) => ({
    type: Types.SET_IS_RETURNING_USER,
    isReturningUser
});

const setNudgeGlow = (showNudgeGlow = false) => ({
    type: Types.LISTING_NUDGE_GLOW,
    showNudgeGlow
});

const setSearchResultTrackingData = (data = {}) => ({
    type: Types.SET_SEARCH_RESULT_TRACKING_DATA,
    data
});

// Recommended Cars
const fetchRecommendedCarsInit = () => ({
    type: Types.FETCH_RECOMMENDED_CARS_INIT
});

const fetchRecommendedCarsSuccess = (data) => ({
    type: Types.FETCH_RECOMMENDED_CARS_SUCCESS,
    data
});

const fetchRecommendedCarsFailure = () => ({
    type: Types.FETCH_RECOMMENDED_CARS_FAILURE
});

const fetchRecommendedCars = (params, queryString) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchRecommendedCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, "", secureToken)
            .then(({ data = {} }) => {
                const carList = data?.results || [];
                dispatch(fetchRecommendedCarsSuccess(carList));
                resolve(data);
            })
            .catch((error) => {
                dispatch(fetchRecommendedCarsFailure());
                reject(error);
            });
    });
};

// Recently Viewed Cars
const updateRecentlyViewedSSRStatus = (isSSR) => ({
    type: Types.UPDATE_RECENTLY_VIEWED_SSR_STATUS,
    isSSR
});

const fetchRecentlyViewedCarsInit = () => ({
    type: Types.FETCH_RECENTLY_VIEWED_CARS_INIT
});

const fetchRecentlyViewedCarsSuccess = (data) => ({
    type: Types.FETCH_RECENTLY_VIEWED_CARS_SUCCESS,
    data
});

const fetchRecentlyViewedCarsFailure = () => ({
    type: Types.FETCH_RECENTLY_VIEWED_CARS_FAILURE
});

const fetchRecentlyViewedCars = (params, queryString) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchRecentlyViewedCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, "", secureToken)
            .then(({ data = {} }) => {
                const carList = data?.results || [];
                dispatch(fetchRecentlyViewedCarsSuccess(carList));
                resolve(data);
            })
            .catch((error) => {
                dispatch(fetchRecentlyViewedCarsFailure());
                reject(error);
            });
    });
};

// Recently Added Cars
const fetchRecentlyAddedCarsInit = () => ({
    type: Types.FETCH_RECENTLY_ADDED_CARS_INIT
});

const fetchRecentlyAddedCarsSuccess = (data) => ({
    type: Types.FETCH_RECENTLY_ADDED_CARS_SUCCESS,
    data
});

const fetchRecentlyAddedCarsFailure = () => ({
    type: Types.FETCH_RECENTLY_ADDED_CARS_FAILURE
});

const fetchRecentlyAddedCars = (params, queryString) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchRecentlyAddedCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, "", secureToken)
            .then(({ data = {} }) => {
                const carList = data?.results || [];
                dispatch(fetchRecentlyAddedCarsSuccess(carList));
                resolve(data);
            })
            .catch((error) => {
                dispatch(fetchRecentlyAddedCarsFailure());
                reject(error);
            });
    });
};

// Custom Recommended Cars
const fetchCustomRecommendedCarsInit = () => ({
    type: Types.FETCH_CUSTOM_RECOMMENDED_CARS_INIT
});

const fetchCustomRecommendedCarsSuccess = (data) => ({
    type: Types.FETCH_CUSTOM_RECOMMENDED_CARS_SUCCESS,
    data
});

const fetchCustomRecommendedCarsFailure = () => ({
    type: Types.FETCH_CUSTOM_RECOMMENDED_CARS_FAILURE
});

const fetchCustomRecommendedCars = (params, queryString) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    dispatch(fetchCustomRecommendedCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, "", secureToken)
            .then(({ data = {} }) => {
                const carList = data?.results || [];
                dispatch(fetchCustomRecommendedCarsSuccess(carList));
                resolve(data);
            })
            .catch((error) => {
                dispatch(fetchCustomRecommendedCarsFailure());
                reject(error);
            });
    });
};

// Save filters stuff

export const updateSavedFiltersApplied = (data, isDelete) => ({
    type: Types.UPDATE_FILTERS_APPLIED,
    data,
    isDelete
});

const saveFiltersApplied = (params = {}) => (_, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    return new Promise((resolve, reject) => {
        ListingService.saveAppliedFilters(params, secureToken)
        .then((response) => {
            resolve(response.data);
        })
        .catch((e) => {
            reject(e);
        });
    });
};

const fetchSavedFilters = (isDelete) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    return new Promise((resolve, reject) => {
        ListingService.fetchSavedFilters(secureToken)
            .then((response) => {
                if (response.data?.length > 0 || isDelete) {
                    dispatch(updateSavedFiltersApplied(response.data, isDelete));
                }
                resolve(response.data);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const deleteSavedFilters = (savedId = {}) => (_, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    return new Promise((resolve, reject) => {
        ListingService.deleteSavedFilter(savedId, secureToken)
        .then((response) => {
            resolve(response.data);
        })
        .catch((e) => {
            reject(e);
        });
    });
};

export {
    fetchFilters,
    updateSSRStatus,
    updateFilterTimestamp,
    updateSelectedOnScreenFilter,
    updatePriceFilterType,
    updateFilterOptionData,
    updateAllFiltersVisibility,
    fetchListingCount,
    updateFilterTabType,
    updateSelectedSort,
    clearAllFilters,
    updateAppliedFilters,
    updateUrlOptions,
    updateAppliedSuggestions,
    updateSelectedSuggestions,
    clearSuggestions,
    updateAllSubFilterOptions,
    updateSSRFiltersList,
    fetchListingCountInit,
    fetchListingCountSuccess,
    fetchListingCountFailure,
    updateCustomValue,
    notifyOnNoCarFound,
    deleteNotifyOnNoCarFound,
    fetchTrendingSearch,
    fetchAutoSuggestions,
    clearSelectedFilters,
    setIsReturningUser,
    setSearchResultTrackingData,
    fetchRecommendedCars,
    fetchRecentlyViewedCars,
    fetchRecentlyAddedCars,
    fetchCustomRecommendedCars,
    setNudgeGlow,
    updateRecentlyViewedSSRStatus,
    saveFiltersApplied,
    fetchSavedFilters,
    deleteSavedFilters,
    updateFilterOptionDataWithSavedFilterData,
    updateNewFilterSelected
};
