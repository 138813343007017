import React from "react";
import Helmet from "react-helmet";
import ErrorHandler from "../../../components/shared/with-error-handler";
import { useParams } from "react-router-dom";
import { appUrl } from "../../../constants/url-constants";
import SellerCarHome from "../../../components/ae.desktop/seller-car-home";
import withAELayout from "../../../components/ae.desktop/layout";
import createSellerCityRoute from "../../../utils/helpers/create-seller-city-route";
import homeLocale from "../../../locales/desktop/seller-home";

const SellCarDetails = () => {
    const { city: cityParam } = useParams();
    const baseUrl = `${appUrl()}${createSellerCityRoute(cityParam)}`;
    const { title: seoTitle, description, h1 } = homeLocale[cityParam];
    return (
        <React.Fragment>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={description} />
                <meta name="H1" content={h1} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${baseUrl}`} rel="canonical" />
            </Helmet>
            <ErrorHandler>
                <SellerCarHome />
            </ErrorHandler>
        </React.Fragment>
    );
};

export default withAELayout(SellCarDetails);

