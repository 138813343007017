import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_CITY
    UPDATE_CITY_FROM_COOKIE
    UPDATE_CITY_WITH_CODE
`,
    {
        prefix: "header/"
    }
);
