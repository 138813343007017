import React from "react";
import { format } from "date-fns";

// Styles
import styles from "./skeleton.css";

// Component
import ArrowIcon from "../../shared/arrow";

// Constants
import { NUMBER } from "../../../constants/app-constants";

const Skeleton = () => {

    const getFutureDate = (count = NUMBER.ZERO) => {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + count);
        return {
            dateLabel: format(futureDate, "dd") || "",
            dayLabel: format(futureDate, "EEE") || ""
        };
    };

    return (
        <div styleName={`styles.dateTimeWrapper`}>
            <p styleName={"styles.titleText"}>Available dates</p>
            <div styleName={"styles.availableDateWrap"}>
                <div styleName={"styles.slotCard styles.freeGift styles.active styles.selected"}>
                    <div styleName={"styles.cardBody"}>
                        <div styleName={"styles.graySpace"} />
                        <span>Free Gift</span>
                        <p>{getFutureDate(NUMBER.TWO)?.dayLabel}</p>
                        <p>{getFutureDate(NUMBER.TWO)?.dateLabel}</p>
                    </div>
                    <p>8 Slots</p>
                </div>
                <div styleName={"styles.slotCard styles.freeGift"}>
                    <div styleName={"styles.cardBody"}>
                        <div styleName={"styles.graySpace"} />
                        <span>Free Gift</span>
                        <p>{getFutureDate(NUMBER.THREE)?.dayLabel}</p>
                        <p>{getFutureDate(NUMBER.THREE)?.dateLabel}</p>
                    </div>
                    <p>4 Slots</p>
                </div>
                <div styleName={"styles.slotCard styles.moreDates"}>
                    <div styleName={"styles.cardBody"}>
                        <span><ArrowIcon /></span>
                    </div>
                    <p>More Dates</p>
                </div>
            </div>
            <div styleName={"styles.timeSlotWrapper"}>
                <p styleName={"styles.titleText"}>Available time slots</p>
                <ul styleName={"styles.timeSlots"}>
                    <li styleName={"styles.active"}>10:30 pm - 11:30 pm</li>
                    <li>11:30 pm - 12:30 pm</li>
                    <li>01:30 pm - 02:30 pm</li>
                    <li>03:30 pm - 04:30 pm</li>
                    <li>04:30 pm - 05:30 pm</li>
                    <li>05:30 pm - 06:30 pm</li>
                </ul>
            </div>
        </div>
    );
};

export default Skeleton;
