import React, {Fragment} from "react";
import PropTypes from "prop-types";
import PaginationDot from "./paginationDot";
import styles from "./style.css";
import SlideImage from "./slide-image";
import { NUMBER } from "../../../constants/app-constants";

const Pagination = ({
    dots,
    disableLastArrows = false,
    onChangeIndex,
    index,
    enableArrows = true,
    enableDots = true,
    arrowColor,
    allActive = false,
    showAllDots = false
}) => {
    const handleClick = (swipeIndex) => {
        onChangeIndex(swipeIndex);
    };
    const onPrevious = () => {
        onChangeIndex(index - 1);
    };
    const onNext = () => {
        onChangeIndex(index + 1);
    };
    const dotsHandler = (currentIndex, idx) => {
        if (showAllDots) return true;

        if (currentIndex < NUMBER.THREE && idx < NUMBER.FIVE) {
            return true;
        }

        if (currentIndex >= dots - NUMBER.THREE && idx >= dots - NUMBER.FIVE) {
            return true;
        }

        return currentIndex > idx - NUMBER.THREE && currentIndex < idx + NUMBER.THREE;
    };

    return (
        <React.Fragment>
            <div className="arrowWrapper">
                {enableArrows && (
                    <Fragment>
                        {(index > 0 || disableLastArrows) && <SlideImage arrowColor={arrowColor} disable={index < 1 && disableLastArrows} onClick={onPrevious} type="pre" />}
                        {(index < dots - 1 || disableLastArrows) && <SlideImage arrowColor={arrowColor} disable={index >= dots - 1 && disableLastArrows} onClick={onNext} type="next" />}
                    </Fragment>
                )}
            </div>
            <div styleName="styles.dotsWrapper" className={"dotsWrapper"}>
                {enableDots &&
                Array(...new Array(dots)).map((_, i) => {
                    let active = false;
                    if (allActive) {
                        active = i <= index;
                    } else {
                        active = i === index;
                    }
                    if (dotsHandler(index, i)) {
                        return (
                            <PaginationDot
                                key={i}
                                index={i}
                                active={active}
                                onClick={handleClick}
                            />
                        );
                    }
                    return undefined;
                })}
            </div>
        </React.Fragment>
    );
};

Pagination.propTypes = {
    dots: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onChangeIndex: PropTypes.func.isRequired,
    enableArrows: PropTypes.bool,
    enableDots: PropTypes.bool,
    disableLastArrows: PropTypes.bool,
    arrowColor: PropTypes.string,
    allActive: PropTypes.bool,
    showAllDots: PropTypes.bool
};

export default Pagination;
