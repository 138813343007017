import React from "react";
import { Helmet } from "react-helmet";
import withAELayout from "../../../components/ae.desktop/layout";
import SellerCarHome from "../../../components/ae.desktop/seller-car-home";
import ErrorHandler from "../../../components/shared/with-error-handler";
import { SELLER_HOME_ROUTE } from "../../../constants/ae/sell-car-online";
import { appUrl } from "../../../constants/url-constants";
import homeLocale from "../../../locales/desktop/seller-home";

const SellerHome = () => {
    const baseUrl = `${appUrl()}${SELLER_HOME_ROUTE}`;
    const { title: seoTitle, description, h1 } = homeLocale.ae;
    return (
        <React.Fragment>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={description} />
                <meta name="H1" content={h1} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${baseUrl}`} rel="canonical" />
            </Helmet>
            <ErrorHandler>
                <SellerCarHome />
            </ErrorHandler>
        </React.Fragment>
    );
};
export default withAELayout(SellerHome);
