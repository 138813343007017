/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Utilities
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";

// Actions
import { setCarDetailsURL } from "../car-details/actions";

// Components
import { withLogin } from "../login";
import CheckoutWrapper from "../checkout-wrapper";

const CheckoutInfoV2 = ({
    carDetails,
    setCarDetailsURLConnect,
    isLoggedIn,
    setShowLogin,
    isUserDetailsFetched
}) => {
    const { content } = carDetails;
    const { appointmentId, year, make, model, city } = content;
    const carName = `${make} ${model}`;
    const isSingleStepAbExperiment = true;

    useEffect(() => {
        const { relativeURL, absoluteURL } = getDetailPageUrl({ year, carName, carId: appointmentId, city, country: "ae" });
        setCarDetailsURLConnect({ relative: relativeURL, absolute: absoluteURL });
    }, [appointmentId]);

    useEffect(() => {
        if (isUserDetailsFetched && !isLoggedIn && !isSingleStepAbExperiment) {
            setShowLogin(true);
        }
    }, [isLoggedIn, isUserDetailsFetched]);

    return (
        <Fragment>
            <CheckoutWrapper />
        </Fragment>
    );
};

CheckoutInfoV2.propTypes = {
    history: PropTypes.object,
    carDetails: PropTypes.object,
    setCarDetailsURLConnect: PropTypes.func,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isUserDetailsFetched: PropTypes.bool
};

const mapStateToProps = ({
    user: {
        isFetched: isUserDetailsFetched
    },
    carDetails
}) => ({
    carDetails,
    isUserDetailsFetched
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setCarDetailsURLConnect: setCarDetailsURL
}, dispatch);

export default withLogin(withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutInfoV2)));
