import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { setSelectedZone } from "../td-schedule/actions";

// Component
import TdScheduleLocation from "./component";

const mapStateToProps = ({
    tdSchedule: {
        emiratesDetails,
        zonesDetails
    }
}) => ({
    emiratesDetails,
    zonesDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setSelectedZoneConnect: setSelectedZone
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TdScheduleLocation);
